import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {Button, capitalize} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        height: 'calc(100vh - 65px)',
        flexFlow:'row wrap',
        padding:'20px 20px 0px 20px',
        maxWidth:'1000px'
    },
    negativeColumn: {
        flex: '2',
        // flexShrink:"0",
        // WebkitBoxFlex:"100",
        // flexGrow:"100",
        // background:'red',
        display:'flex',
        flexDirection:'column'
    },
    dimensionColumn:{
        // flex: '1 10%',
        // flexShrink:"0",
        // WebkitBoxFlex:"100",
        // flexGrow:"100",
        // background:'blue',
        padding:'0 10px'
    },
    positiveColumn:{
        flex: '2',
        // flexShrink:"0",
        // WebkitBoxFlex:"100",
        // flexGrow:"100",
        // background:'green',
        display:'flex',
        flexDirection:'column'
    },
    negativeContent:{
        display:'flex',
        flexDirection:'column',
        color:theme.custom.textColorDarkBG,
        // fontWeight:700,
        overflow:'hidden'
    },
    dimensionContent:{
        display:'flex',
        flexDirection:'column',
    },
    positiveContent:{
        display:'flex',
        flexDirection:'column',
        color:theme.custom.textColorDarkBG,
        // fontWeight:700,
        overflow:'hidden'
    },
    contentWrapNegHeader: {
        alignSelf:'flex-end',
        background:theme.custom.impactBarRed,
        padding:'10px 0',
        borderRadius:'5px 0 0 5px',
        margin:'2px 0',
        color: theme.custom.textColorWhiteBG
    },
    contentWrapNeg: {
        alignSelf:'flex-end',
        background:theme.custom.impactBarSmall,
        padding:'0',
        borderRadius:'5px 0 0 5px',
        margin:'2px 0'
    },
    contentWrapPosHeader: {
        alignSelf:'flex-start',
        background:theme.custom.impactBarGreen,
        padding:'10px 0',
        borderRadius:'0 5px 5px 0',
        display:'flex',
        flexDirection:'row-reverse',
        margin:'2px 0',
        color: theme.custom.textColorWhiteBG
    },
    contentWrapPos: {
        alignSelf:'flex-start',
        background:theme.custom.impactBarSmall,
        padding:'0',
        borderRadius:'0 5px 5px 0',
        display:'flex',
        flexDirection:'row-reverse',
        margin:'2px 0'
    },
    dimensionContentWrapHeader: {
        margin:'2px auto',
        textAlign:'center',
        background:'#f5f5f5',
        padding:'10px 6px',
        fontSize:'14px',
        fontWeight:700,
        width:'100%',
        textTransform:'capitalize'
    },
    dimensionContentWrap: {
        margin:'2px auto',
        textAlign:'center',
        padding:'0 6px',
        width:'100%'
    },
    contentNumber:{
        margin:'0 5px'
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%",
        "display":"flex",
        "zIndex":"1100",
        "boxSizing":"border-box",
        "flexShrink":"0",
        "flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    UpdateButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    }


}));

export default function ImpactDetails({userData, reportData, FetchAIData}) {
    const classes = useStyles();

    if (Object.keys(reportData).length < 1){
        return <div className={classes.root}>
            <div style={{margin:'0 auto', textAlign:'center'}}>
                <div style={{marginBottom:'20px'}}>
                    (No impact dimensions found)
                </div>
                <div>
                    <Button variant="contained" color="primary" size="small" className={classes.UpdateButton} onClick={FetchAIData}>
                        Update report
                    </Button>
                </div>
            </div>
        </div>
    }

    let topScore = 1;

    for (const group in reportData) {
        for (const key in reportData[group]){
            /** find topscore level */
            if (reportData[group][key].pos > topScore) topScore = reportData[group][key].pos
            if (reportData[group][key].neg*-1 > topScore) topScore = reportData[group][key].neg*-1
            topScore.toFixed(1)
        }
    }

    let negBars = []
    let dimensions = []
    let posBars = []

    // console.log('reportData:::',reportData)

    for (const group in reportData) {
        let negative_total = 0
        let positive_total = 0
        for (const key in reportData[group]){
            negative_total += reportData[group][key].neg
            positive_total += reportData[group][key].pos
        }

        let negWidth = {width:((negative_total*-1)/topScore)*100+"%"}
        negBars.push(<div className={classes.contentWrapNegHeader} key={'neg_group_'+group} style={negWidth}><span className={classes.contentNumber}>{negative_total.toFixed(1)}</span></div>)
        dimensions.push(<div key={'group_'+group} className={classes.dimensionContentWrapHeader}>{group}</div>)
        let posWidth = {width:((positive_total)/topScore)*100+"%"}
        posBars.push(<div className={classes.contentWrapPosHeader} key={'pos_group_'+group} style={posWidth}><span className={classes.contentNumber}>+{positive_total.toFixed(1)}</span></div>)

        for (const key in reportData[group]){
            let negWidth = {width:((reportData[group][key].neg*-1)/topScore)*100+"%"}
            negBars.push(<div key={'neg_'+key} className={classes.contentWrapNeg} style={negWidth}><span className={classes.contentNumber}>{reportData[group][key].neg.toFixed(1)}</span></div>)

            dimensions.push(<div key={'dim_'+key} className={classes.dimensionContentWrap}>{key}</div>)

            let posWidth = {width:((reportData[group][key].pos)/topScore)*100+"%"}
            posBars.push(<div key={'pos_'+key} className={classes.contentWrapPos} style={posWidth}><span className={classes.contentNumber}>+{reportData[group][key].pos.toFixed(1)}</span></div>)
        }
    }

    return (
        <div className={classes.root}>
            <div className={classes.negativeColumn}>
                <div className={classes.negativeContent} style={{width:'100%'}}>
                    {negBars}
                </div>
            </div>
            <div className={classes.dimensionColumn}>
                <div className={classes.dimensionContent}>
                    {dimensions}
                </div>
            </div>
            <div className={classes.positiveColumn}>
                <div className={classes.positiveContent} style={{width:'100%'}}>
                    {posBars}
                </div>
            </div>
        </div>
    );
};