import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/features.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Lygg from "../../../img/references/lygg_logo.png";
import UnitedLabs from "../../../img/references/unitelabs_logo.png";
import Panda from "../../../img/references/panda_logo.png";
import DroppX from "../../../img/references/droppx_logo.png";
import Meom from "../../../img/references/meom_logo.png";
import Analysis_mode from "../../../img/references/amode_logo.png";

const HowItWorks = () => {

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const text_color_light = (prefersDarkMode) ? '' : 'text_color_light';

    return (
        <section>
            <div className={'featuresContainer'}>
                <h2>
                    How it Works?
                </h2>
                <div className={'featuresSubtitleText '+text_color_light}>
                    <p>
                        Unlimited analysis, execution and documentation.
                    </p>
                </div>
                <div className={'feature-container'}>
                    <div className={'grid-item'}>
                        <div className={'grid-line'} />
                        <div className={'features-color-dot dot-color-whiteblue'}>1</div>
                        <h4 className={text_color_light}>Continous analysis</h4>
                        <p className={text_color_light}>Securely share (some) of your plans and get our analysis to document what and why something is decided.</p>
                    </div>
                    <div className={'grid-item'}>
                        <div className={'grid-line'} />
                        <div className={'features-color-dot dot-color-whiteblue'}>2</div>
                        <h4 className={text_color_light}>Collect data</h4>
                        <p className={text_color_light}>With your employees, we select and make sure teams continuously document their work in a way it suits them.</p>
                    </div>
                    <div className={'grid-item'}>
                        <div className={'features-color-dot dot-color-whiteblue'}>3</div>
                        <h4 className={text_color_light}>Save the context</h4>
                        <p className={text_color_light}>Turn the company domain knowledge into organised reports with context. Ready to be used for AI or any other team member.</p>
                    </div>
                </div>
                <div className={'call-to-action-container'}>
                    <a className={'button button-red'} href={'/login'}>
                        Get started
                    </a>
                    {/*<a className={'button button-red'} href={'https://form.jotform.com/201062132467041'}>*/}
                    {/*    Book a 30min intro*/}
                    {/*</a>*/}
                </div>

            </div>

        </section>

    );
}

export default HowItWorks;