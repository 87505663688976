import {useLayoutEffect, useState} from "react";

export const useIsMobile = () => {
    const [isMobile, setIsMobile] = useState(false);
    useLayoutEffect(() => {
        function checkIsMobile() {
            setIsMobile(window.innerWidth < 600);
        }
        window.addEventListener('resize', checkIsMobile);
        checkIsMobile();
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);
    return isMobile;
}

export const useIsPad = () => {
    const [useIsPad, setIsPad] = useState(false);
    useLayoutEffect(() => {
        function checkIsMobile() {
            setIsPad(window.innerWidth < 1000);
        }
        window.addEventListener('resize', checkIsMobile);
        checkIsMobile();
        return () => window.removeEventListener('resize', checkIsMobile);
    }, []);
    return useIsPad;
}
