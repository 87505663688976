import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useParams} from "react-router";
import {auth, db} from "../../util/firebase";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Divider from '@material-ui/core/Divider';
import Timeline from '@material-ui/lab/Timeline';
import ActivityTimeline from "../generalComponents/activityTimeline"
import DetailHeaderEditor from "../generalComponents/DetailHeaderEditor";
import FreetextEditor from "../generalComponents/FreetextEditor";
import axios from "axios";
import BASEURL from "../../constants/baseurl";
import SlowLoad from "../slowLoad";
import Team_sideDetails from "./team_sideDetails";
import TeamContent from "../../pages/contentWrappers/teamContent";
import TeamRoleListManager from "./teamRoleListManager";
import TeamActivityTimeline from "../generalComponents/TeamActivityTimeline";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        height: 'calc(100vh - 65px)',
        flexFlow:'row wrap'
    },
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    maindetails: {
        flex: '1 100%',
        // maxWidth: '700px',
        minWidth:'400px',
        maxHeight: '100%',
        overflowY: 'scroll',
        "position":"relative",
        "flexShrink":"0",
        "WebkitBoxFlex":"100",
        "flexGrow":"100",
        "paddingBottom":"82px"
    },
    maindetailsWrapper: {
        "flexGrow":"100",
        "WebkitBoxFlex":"100",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "overflowY":"overlay"
    },
    maindetailsContent:{
        "display":"flex",
        "flexBasis":"initial",
        "flexDirection":"column",
        "position":"relative",
        "width":"calc(100% - 60px)",
        "marginLeft":"auto",
        "marginRight":"auto",
        "WebkitBoxFlex":"1",
        "flexGrow":"1",
        "flexShrink":"0",
        "paddingTop":"20px"
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%",
        "display":"flex",
        "zIndex":"1100",
        "boxSizing":"border-box",
        "flexShrink":"0",
        "flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    textareaStyle: {
        padding: '10px',
        minWidth: '260px',
        width: 'calc(100% - 35px)'
    },
    submitButton: {
        background:theme.custom.MainButton
    },
    activityTitle: {
        fontSize:'1.1rem',
        fontWeight:'500'
    },
    [theme.breakpoints.up("xs")]: {
        // maindetails:{
        //     maxWidth: '350px'
        // },
    },
    [theme.breakpoints.up("sm")]: {
        maindetails:{
            flex:'100 0px',
            order:'2'
        },
    },
    [theme.breakpoints.up("md")]: {
    },
    [theme.breakpoints.down("sm")]: {
        root: {
            flexFlow:'row nowrap',
            overflowX:'clip'
        },
        maindetails:{
            // flex:'100 0px',
            // order:'2',
            minWidth:'100%'
        },
    },
    metadataPopup:{
        border:'1px solid transparent',
        borderColor:theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        boxShadow:'rgb(0 0 0 / 10%) 0px 4px 18px',
        height: 'calc(100vh - 105px)'
    },
    metadataTopBar:{
        height:'40px',
        padding:'10px',
        border:'1px solid transparent',
        borderBottomColor:theme.custom.OutlineButtonBorder
    },
    metadataClose:{
        // padding:'10px',
        color: theme.custom.subtitle,
        borderRadius:'2px',
        "&:hover":{
            background:theme.custom.ListBGhover
        }
    },
    metadataImage: {
        maxHeight:'365px',
        maxWidth:'100%',
        cursor:'zoom-in'
    },
    metadataImageFullContainer: {
        display:'flex',
        width:'100vw',
        height:'100vh',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        background:'rgba(0,0,0,0.628)'
    },
    metadataImageFull: {
        width: undefined,
        height: undefined,
        maxWidth:'100%',
        maxHeight:'100%'
    },
    taskListItem: {
        display:"flex",
        flexDirection:'row',
        paddingBottom:'8px',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }
    },
    menuIcon:{
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
        }
    }


}));

export default function TeamDetails({userData, backToTeams, loadTaskDetails}) {
    const classes = useStyles();
    let { teamId } = useParams();

    dayjs.extend(relativeTime);

    const [teamLoading, setTeamLoading] = useState(true)
    const [AILoading, setAILoading] = useState(false);
    const [taskAILoading, setTaskAILoading] = useState(false);
    const [teamData, setTeamData] = useState({})

    React.useEffect(() => {

        const unsubscribe = db
            .collection('teams')
            .doc(teamId)
            .onSnapshot(async (doc) => {
                if (doc.exists){
                    let observedData = {
                        id: doc.id,
                        ...doc.data()
                    }

                    console.log('teamId',observedData)
                    setTeamData(observedData)
                    setTeamLoading(false)
                } else {
                    setTeamData({
                        id: teamId
                    })
                    setTeamLoading(false)
                }
            });

        return function cleanup() { unsubscribe() }

    }, [teamId]);

    const handleTitleSave = (savePayload) => {
        /** this will save the title to db */

        db
            .collection('teams')
            .doc(teamId)
            .update({
                title: savePayload.value
            })
            .then()
    }

    const handleDescriptionSave = (savePayload) => {
        /** this will save the description to db */

        db
            .collection('teams')
            .doc(teamId)
            .update({
                description: savePayload.value
            })
            .then()

    }

    /** AI stuff might be used? */
    const handleDescriptionFetch = async (selectedLanguage) => {
        setAILoading(true);
        const authToken = await auth().currentUser.getIdToken()

        axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${authToken}` };
        axios
            .post(BASEURL.api+'/AI/getDescription', {
                "seed_title":teamData.title,
                "language":selectedLanguage
            })
            .then((response) => {
                setAILoading(false);

                if (response && response.data && response.data.body) {
                    const responseMessage = JSON.parse(response.data.body)
                    let observedProjectData = {
                        ...teamData,
                        description: responseMessage.message
                    }
                    setTeamData(observedProjectData)
                    // console.log('observedProjectData', observedProjectData)
                }

            })
            .catch(err => {
                console.log('err',err.message)
            })
    }

    const handleTasksFetch = async (selectedLanguage) => {
        setTaskAILoading(true)
        const authToken = await auth().currentUser.getIdToken()

        /** later, configuration of your own prompts */
        const myPrompts = [
            "Create a task list for '"+teamData.description+"'. Each task can be max 8h."
        ]
        const selectedPrompt = 0

        axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${authToken}` };
        axios
            .post(BASEURL.api+'/AI/getTasks', {
                "seed_title":teamData.description,
                "language":selectedLanguage
            })
            .then((response) => {
                setTaskAILoading(false)

                if (response && response.data && response.data.body) {
                    const responseMessage = JSON.parse(response.data.body)
                    // let observedProjectData = {
                    //     ...projectData,
                    //     copilot_task_list: responseMessage.message
                    // }

                    /** parse the incoming message to a list */
                    if (responseMessage.message) handleTaskListParsing(responseMessage.message)

                    // setProjectData(observedProjectData)
                    // console.log('observedProjectData', observedProjectData)
                }
                if (response && response.data && response.data.message) {
                    console.error('response', response.data)
                    handleCopilotError(response.data)
                }

            })
            .catch(err => {
                console.log('err',err.message)
                setTaskAILoading(false)
                setAILoading(false);
                handleCopilotError({message:'AI server failed to respond'})
            })
    }

    const handleTaskListParsing = (message) => {
        /** case one - splitted with \n */
        let message_split = message.split('\n')
        /** how do I test that it makes sense? regex? */


        /** case two - splitted with \\n and \\n\\n
         * \\n\\n means task cut
         * \\n means row cut within the task list
         */



        const message_objects = message_split.map(m => {
            return {
                title : m
            }
        })
        let observedProjectData = {
            ...teamData,
            copilot_task_list: message_objects,
            copilot_error: false
        }
        setTeamData(observedProjectData)
    }

    const handleCopilotError = (error) => {
        let observedProjectData = {
            ...teamData,
            copilot_task_list: [],
            copilot_error: true,
            copilot_error_msg: error.message
        }
        setTeamData(observedProjectData)
    }

    return (
        <div style={{height: 'calc(100vh - 65px)'}}>
            <div className={classes.root}>
                <div className={classes.maindetails}>
                    <div className={classes.maindetailsWrapper}>
                        <div className={classes.maindetailsContent}>
                            <DetailHeaderEditor
                                payload={{key:"title", value:teamData.title}}
                                save={handleTitleSave}
                            />
                            <div>
                                <div className={classes.subtitle}>
                                    <FreetextEditor
                                        payload={{key:"description", value:teamData.description, placeholder:'What should the team build?'}}
                                        save={handleDescriptionSave}
                                    />
                                </div>
                                {AILoading ? <SlowLoad/> : <></>}
                            </div>
                            <div style={{margin:'10px 0'}} />
                            <Divider />


                            {teamLoading ? <></> : <TeamRoleListManager userData={userData} payload={teamData} />}

                            <Divider />
                            <h4 className={classes.activityTitle} style={{marginBottom:'5px'}}>Activity</h4>
                            <Timeline>
                                <TeamActivityTimeline userData={userData} payload={teamData} placeholder={"Leave a comment..."} showCopilot={false} />
                            </Timeline>
                        </div>
                    </div>
                </div>
                <Team_sideDetails payload={teamData} taskAILoading={taskAILoading} writeTasks={handleTasksFetch} writeDescription={handleDescriptionFetch} />
            </div>
        </div>
    );
};