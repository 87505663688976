import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useRouteMatch} from "react-router-dom";

import '../../css/general.css'
import '../../css/toolbar.css'
import '../../css/dashboard.css'
import Button from "@material-ui/core/Button";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import Snackbar from "@material-ui/core/Snackbar";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import SendIcon from "@material-ui/icons/Send";
import {auth, db, firebaseCore} from "../../util/firebase";
import {Divider, Toolbar, Typography} from "@material-ui/core";
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import clsx from "clsx";
import {searchClient} from "../../util/typesense_search";
import axios from "axios";
import BASEURL from "../../constants/baseurl";
import SlowLoad from "../../components/slowLoad";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    activateButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    }
}));

export default function Dashboardcontent({userData, history, loadFeatures, openNewRequest, loadProjects}) {
    const classes = useStyles();
    let { url } = useRouteMatch();

    const [Loading, setLoading] = useState(true)
    const [knowledgeAILoading, setKnowledgeAILoading] = useState(false)
    const [teams, setTeams] = useState([])

    const ToggleWelcomePrompt = () => setWelcomePrompt(!welcomePrompt)
    const [welcomePrompt, setWelcomePrompt] = useState(false)

    const [topTitle, setTopTitle] = useState('');
    const [dashSnackbarOpen, setDashSnackbarOpen] = useState(false)
    const [reportSnackbarOpen, setReportSnackbarOpen] = useState(false)
    const [knowledgeSnackbarOpen, setKnowledgeSnackbarOpen] = useState(false)
    const [chatInputValue, setChatInputValue] = useState('')
    const [chatContext, setChatContext] = useState('')
    const [activeChatFirstMsg, setActiveChatFirstMessage] = useState('')
    const [newChat, setNewChat] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [knowledgeBaseMessage,setKnowledgeBaseMessage] = useState('')
    const ToggleContext = () => setExpanded(!expanded)

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const input_color_dark = (prefersDarkMode) ? 'chat-input-wrap-dark' : '';
    const box_button_color_dark = (prefersDarkMode) ? 'box-button-dark' : '';
    const box_background = (prefersDarkMode) ? 'box-background-dark' : 'box-background-light';

    const [bidValue, setBidValue] = useState('');

    const pagecontext = '';
    const promptheader = 'Knowledge Management 2.0';
    const promptbody = () => {
        return (
            <div>
                <p>
                    We're thrilled to have you.
                </p>
                <p>
                    Sprinthack is a #1 solution analysis service and a community where we fall in love with the problems, not with the solutions.
                </p>
                <p>
                    Such analysis is an foundation for you to start building domain specific knowledge. Teams and generative AI can use that knowledge to know what the company does.
                </p>
                <p>
                    {/*<Button style={{marginTop:'20px'}} className={classes.addNewButtonPrompt} variant="contained" size="small" color="primary" onClick={addNewTeam} >*/}
                    {/*    + Build a new team*/}
                    {/*</Button>*/}
                    <Button style={{marginTop:'20px'}} className={classes.addNewButtonPrompt} variant="contained" size="small" color="primary" onClick={ToggleWelcomePrompt} >
                        Ok
                    </Button>
                </p>
            </div>
        )};

    const closeWelcomePrompt = () => {

    }

    React.useEffect(()=>{
        console.log('userdata',userData)

        const unsubscribe = db.collection('teams')
            .where('members.'+userData.id, '==', true)
            .orderBy('activeAt','desc')
            // .limit(3)
            .onSnapshot(snap => {
                let teams = []
                snap.forEach(doc => {
                    teams.push({...doc.data(), id:doc.id})
                })
                setTeams(teams)
                setLoading(false)
            }, error => {
                setLoading(false)
            })

        return function cleanup() { unsubscribe() }

    }, [userData])


    /** front buttons test hardcoded */
    const examples = [
        'I need to create an authentication to my application →',
        'I need to get my project to 1000 in sales →',
        'Describe me a way to in real-time update master data storage →'
    ]

    /** functionality to fetch service status and data
     * Maybe move to own component eventually
     * Should use mobx here as well...
     *
     * status should also be fetch at the initialisation
     */

    const FetchRolesStatus = () => {
        return <div className={'activateButtonWrap'}>
            <Button variant="contained" color="primary" size="small" className={classes.activateButton} onClick={()=>{setDashSnackbarOpen(true)}}>
                Activate
            </Button>
        </div>
    }

    const FetchDueDiligenceStatus = () => {
        return <div className={'activateButtonWrap'}>
            <Button variant="contained" color="primary" size="small" className={classes.activateButton} onClick={()=>{setDashSnackbarOpen(true)}}>
                Activate
            </Button>
        </div>
    }

    const FetchCostControlStatus = () => {
        return <div className={'activateButtonWrap'}>
            <Button variant="contained" color="primary" size="small" className={classes.activateButton} onClick={()=>{setDashSnackbarOpen(true)}}>
                Activate
            </Button>
        </div>
    }

    const FetchAdvisoryStatus = () => {
        return <div className={'activateButtonWrap'}>
            <Button variant="contained" color="primary" size="small" className={classes.activateButton} onClick={()=>{setDashSnackbarOpen(true)}}>
                Activate
            </Button>
        </div>
    }

    const defaultDCFData = {
        currencyIcon:'$',
        terminalValue: 218265,
        requireRateOfReturn: 0.1,
        presentValueOfPool:270782,
        netPresentCoins:24026,
        coinDCFValue:11.27
    }

    const DefaultCoinsValue = (amount) => {
        return defaultDCFData.currencyIcon+(amount*defaultDCFData.coinDCFValue).toFixed(2)
    }

    const onChangeBidValue = (e) => {
        setBidValue(e.target.value);
    }

    const CalculateBidValue = () => {
        return defaultDCFData.currencyIcon+(bidValue*defaultDCFData.coinDCFValue).toFixed(2)
    }

    const updateInputValue = (e) => {
        setChatInputValue(e)
    }

    const openTeam = (teamId) => {
        history.push("/home/teams/team/"+teamId)
    }

    /** this has to be it's own element to query the workspace members data */
    const RenderTeams = () => {
        if (!teams || teams.length < 1) {
            return <div className={'box'}>
                <div className={'header color-gray'}>
                    <h5 className={'small-header'}>No teams found</h5>
                </div>
            </div>

        }
        return teams.map((team)=>{
            // console.log('team',team)
            /** this should be turned into it's own component that fetches the team meta data like roles etc.. */
            return <div key={team.id} className={'box '+box_background} onClick={()=>{openTeam(team.id)}}>
                <div className={'header box-header-hover color-gray'}>
                    <h5 className={'small-header'}>{team.title}</h5>
                </div>
                <div className={'quest-content'}>
                    <div>{Object.keys(team.members).length} Active Members</div>
                    {/*<div>{team} Roles</div>*/}
                </div>
            </div>

        })
    }

    const RenderKnowledgeBase = () => {
        return <>
            <div className={'box full-box'}>
                <div className={'header color-gray'}>
                    <div className={'small-header'}>Private Knowledge Base</div>
                </div>
                <div className={'content content-box'}>
                    <div className={'chat-input-wrap '+input_color_dark}>
                        <input value={chatInputValue} placeholder={'Search across the teams data'} onChange={(e)=>{setChatInputValue(e.target.value)}} />
                        <Button className={'sendChatButton'} onClick={ async () => {
                            // setKnowledgeSnackbarOpen(true)
                            setChatContext('')
                            if (chatInputValue ==='' && chatInputValue.length < 3) return

                            let searchTeams = teams.map(team => team.id)

                            let searchParameters = {
                                'q': chatInputValue,
                                'query_by': 'reportData.a',
                                'filter_by': 'contextId:= [' + searchTeams + ']',
                                'per_page' : 250
                            }

                            const searchResults = await searchClient.collections('documents').documents().search(searchParameters)

                            const prompt_start = "Answer the question based on the context below. Write the answer in the same language as the context. If the answer is not in the context, return 'No data to answer'.\n\n"
                            let prompt_middle = "Context:"
                            searchResults.hits.forEach(doc=>{
                                doc.document["reportData"].forEach(data=>{
                                    if (data.type === "worknote") prompt_middle += "\n\n---\n\n" + data.a
                                })
                            })
                            const prompt_end = "\n\nQuestion: "+chatInputValue+"\nAnswer:"

                            const finalPrompt = prompt_start + prompt_middle + prompt_end

                            setChatContext(searchResults)
                            await FetchKnowledgeBaseInfo(finalPrompt)

                        }}>
                            <ListItemIcon><SendIcon /></ListItemIcon>
                        </Button>
                    </div>
                </div>
            </div>
            {/*<div className={'box'}>*/}
            {/*    <div className={'header color-gray'}>*/}
            {/*        <h5 className={'small-header'}>Team 1</h5>*/}
            {/*    </div>*/}
            {/*    <div className={'quest-content'}>*/}
            {/*        team content*/}
            {/*    </div>*/}
            {/*</div>*/}
        </>
    }

    const FetchKnowledgeBaseInfo = async (prompt) => {
        setKnowledgeAILoading(true)
        setKnowledgeBaseMessage('')
        const authToken = await auth().currentUser.getIdToken()

        console.log('baseURL',BASEURL.api)

        axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${authToken}` };
        axios
            .post(BASEURL.api+'/AI/answerQuestionWithContext', {
                "prompt":prompt
            })
            .then((response) => {
                setKnowledgeAILoading(false)

                if (response && response.data && response.data.body) {
                    const responseMessage = JSON.parse(response.data.body)

                    setKnowledgeBaseMessage(responseMessage.message)
                }
                if (response && response.data && response.data.message) {
                    setKnowledgeBaseMessage(response.data)
                }

            })
            .catch(err => {
                // console.log('err full',err)
                // console.log('err',err.message)
                setKnowledgeAILoading(false)
            })
    }

    const RenderKnowledgeBaseReply = () => {
        if (chatContext === '' || chatInputValue.length<3) return <></>
        return <div className={'dash-content dash-content-left'}>
            <div className={'ask-question-result'}>
                <div className={'ask-question-result--response'}>
                    {(knowledgeAILoading) ? <SlowLoad/> : knowledgeBaseMessage}
                </div>
                <div className={'ask-question-result--view-context'} >
                    <ChevronRightIcon className={expanded ? "context-icon down" : "context-icon"}/>
                    <div onClick={ToggleContext}>View Context</div>
                </div>
                {(chatContext.hits && expanded) ? chatContext.hits.map(doc=>{
                    return doc.document["reportData"].map((data,index)=>{
                        if (data.type !== "worknote") return ''
                        return <div key={index}><p>---</p><p>{data.a}</p></div>
                    })
                }) : <></>}
            </div>
        </div>
    }

    const addNewTeam = () => {
        history.push(`${url}/newteam`);
    }

    const needSolutionAnalysis = () => {

        const date = new Date().toISOString()
        db.collection('need_analysis')
            .add({user: userData.id, createdAt: date}).then(r => {
                setReportSnackbarOpen(true)
            })
    }

    return (
        <div>
            <div className={'main-content-wrap'}>
                <div>
                    <div style={{paddingTop:'20px', paddingLeft:'18px', paddingRight:'36px', paddingBottom:'60px'}}>

                        {Loading ? (
                            <></>
                        ) : (
                            <>
                                {welcomePrompt ? (
                                        /** welcome message */
                                        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'18px'}}>
                                            <div className={classes.toolbar} />
                                            <div>
                                                <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
                                            </div>
                                        </div>
                                    ) :
                                    (
                                        /** dashboard content */
                                        <>
                                            <div className={'dash-content dash-content-right'}>
                                                <div className={'button'} onClick={addNewTeam}>+ADD TEAM</div>
                                            </div>
                                            <div className={'dash-content dash-content-left'}>
                                                <div className={'header'}>Knowledge Base 2.0</div>
                                            </div>
                                            <div className={'dash-content dash-content-left'}>
                                                {RenderKnowledgeBase()}
                                            </div>
                                            {RenderKnowledgeBaseReply()}
                                            <div className={'dash-content dash-content-left'}>
                                                <div className={'header'}>Analysis Reports</div>
                                            </div>
                                            <p>
                                                <button className={'button'} onClick={needSolutionAnalysis}>Order a Solution Analysis</button>
                                            </p>
                                            <div className={'dash-content dash-content-left'}>
                                                <div className={'header'}>Teams</div>
                                            </div>
                                            <div className={'dash-content dash-content-left'}>
                                                <RenderTeams/>
                                            </div>
                                        </>
                                    )}
                            </>
                        )}
                    </div>

                </div>
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={reportSnackbarOpen}
                autoHideDuration={6000}
                message={'Thanks, Solution Architect will contact you soon via email.'}
                key={'123'}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={knowledgeSnackbarOpen}
                autoHideDuration={6000}
                message={'Knowledge Base is coming soon...'}
                key={'124'}
            />
        </div>

    );
}

