import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/hero.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Female_happy_dev from "../../../img/SPH_vaporwave_a_female_software_developer_that_is_amplified.PNG";
import Kanban_board from "../../../img/SPH_vaporwave_kanban_board_that_has_AI_integrated.PNG";
import Documentation_storage from "../../../img/SPH_waporwave_documentation_board.PNG";


const useStyles = makeStyles((theme) => ({
    [theme.breakpoints.down("xs")]: {
        container:{
            maxWidth:'600px'
        }
    }

}));

const ValueDeck = () => {

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const box_background_light = (prefersDarkMode) ? '' : 'box-background-light';

    return (
        <section>
            <div className={'dash-content'} style={{'maxWidth':'100%'}}>
                <div className={'box '+box_background_light}>
                    <div className={'header color-gray'}>
                        <h5 className={'small-header'}>Amplify the product team</h5>
                    </div>
                    <div className={'quest-content'}>
                        <div className={'grid-img'}>
                            <img alt={'Female_happy_dev'} src={Female_happy_dev} width="540" height="540" />
                        </div>
                    </div>
                </div>
                <div className={'box '+box_background_light}>
                    <div className={'header color-gray'}>
                        <h5 className={'small-header'}>Integrates into your workflow</h5>
                    </div>
                    <div className={'quest-content'}>
                        <div className={'grid-img'}>
                            <img alt={'Kanban_board'} src={Kanban_board} width="540" height="540" />
                        </div>
                    </div>
                </div>
                <div className={'box '+box_background_light}>
                    <div className={'header color-gray'}>
                        <h5 className={'small-header'}>Documentation done automatically</h5>
                    </div>
                    <div className={'quest-content'}>
                        <div className={'grid-img'}>
                            <img alt={'Documentation_storage'} src={Documentation_storage} width="540" height="540" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ValueDeck;