import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

import Chip from '@material-ui/core/Chip';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({

    container: {
        padding:'0 0 20px 0'
    },
    addNewStakeholder: {
        width:'fit-content',
        padding:'5px 10px 5px 10px',
        color:"#64ccd9",
        cursor:'pointer',
        marginBottom:'15px',
        marginLeft:'20px',
        borderRadius:'3px',
        border:'1px solid transparent',


        "&:hover":{
            color:theme.custom.genericHighlightText,
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhoverBlue
        }
    },
    stakeholderContainer: {
        paddingBottom:'20px'
    },
    stakeholderField: {
        marginLeft:'20px',
        marginBottom:'5px',
        backgroundColor:'rgba(0,0,0,0.04)'
    },
    needField: {
        marginLeft:'40px',
        padding:'5px',
        borderRadius:'8px',
        width:'calc(100% - 80px)'
    },
    selectedNeedField: {
        backgroundColor:'rgba(205,242,248,1)'
    },
    addNewNeed: {
        color: theme.custom.SmoothColor,
        marginLeft:'45px',
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    input: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.875em',
        backgroundColor:''
    },
    needChip:{
        'backgroundColor':'#cdf2f8',
        'color':'rgba(0, 0, 0, 0.54)',
        'marginRight':'6px'
    },
    actionButton: {
        background:theme.custom.MainButton
    },


}));


export default function StakeholderNeedManager({payload, saveChanges, readOnly, showVolatilities, colorPops}) {

    const classes = useStyles();

    /** should really create a custom hook for this... */
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const colorPopColor = prefersDarkMode ? 'rgba(255,255,255,0.3)' : 'rgba(205,242,248,1)';
    const completed = 'line-through';
    const notCompleted = 'initial';

    const [needs, setUserNeeds] = useState([])
    const [completedNeeds, setCompletedNeeds] = useState({})
    const [colorPop, setColorPop] = useState({})

    useEffect(() => {
        console.log('payload update');
        if (payload && payload.user_needs){setUserNeeds(payload.user_needs)}

        if (payload && payload.sidedetails && payload.sidedetails["Use cases"] instanceof Array){
            let updatedCompleted = {}

            payload.sidedetails["Use cases"].forEach(useCase=>{
                if (useCase.data){
                    useCase.data.forEach(data=>{
                        if (useCase.completed && !updatedCompleted[data.stakeholder+'_'+data.user_needs]){
                            updatedCompleted[data.stakeholder+'_'+data.user_needs] = completed
                        } else if (useCase.completed && updatedCompleted[data.stakeholder+'_'+data.user_needs] === completed) {
                            updatedCompleted[data.stakeholder+'_'+data.user_needs] = completed
                        } else if (!useCase.completed && updatedCompleted[data.stakeholder+'_'+data.user_needs] === completed) {
                            updatedCompleted[data.stakeholder+'_'+data.user_needs] = notCompleted
                        } else {
                            updatedCompleted[data.stakeholder+'_'+data.user_needs] = notCompleted
                        }
                    })
                }
            })
            setCompletedNeeds(updatedCompleted)
        }
    }, [payload]);

    useEffect(()=>{
        /** update the color of user needs that belong to the use cases */
        let updatedColorPops = {}
        colorPops && colorPops.forEach(pop=>{
            updatedColorPops[pop.stakeholder+'_'+pop.user_needs] = colorPopColor
        });

        setColorPop(updatedColorPops)
    }, [colorPopColor, colorPops])

    const onChangeStakeholder = (e, idx) => {
        if (readOnly) return false
        let newArray = [...needs]
        newArray[idx].stakeholder = e.target.value
        setUserNeeds(newArray)
    }

    const onChangeStakeholderNeed = (e, idx, index) => {
        if (readOnly) return false
        let newArray = [...needs]
        newArray[idx].needs[index].need = e.target.value
        setUserNeeds(newArray)
    }

    const onAddNewStakeholder = () => {
        if (readOnly) return false
        if (!needs) return setUserNeeds([{stakeholder:'', needs:[]}])
        setUserNeeds([...needs, {stakeholder:'', needs:[]}])
    }

    const onAddNewStakeholderNeed = (idx) => {
        if (readOnly) return false
        let newArray = [...needs]
        if (!newArray[idx].needs) newArray[idx].needs = []
        newArray[idx].needs.push({need:'',volatilities:[]})
        setUserNeeds(newArray)
    }

    const onBlurEvent = () => {
        saveChanges(needs)
    }

    const renderNeeds = () => {
        if (!needs) return null
        return needs.map((row, idx)=>{
            return <div key={idx} className={classes.stakeholderContainer}>
                <TextField className={classes.stakeholderField} inputProps={{className:classes.input}} variant="outlined" placeholder="stakeholder" size="small" value={row.stakeholder} onChange={e=>onChangeStakeholder(e, idx)} onBlur={onBlurEvent} />
                {row.needs && row.needs.map((item, index) => {
                    return <div key={index}>
                        <TextField
                            className={classes.needField} style={{'backgroundColor':colorPop[idx+'_'+index], 'textDecoration':completedNeeds[idx+'_'+index]}} inputProps={{className:classes.input}} variant="outlined" placeholder="need / user story" size="small" value={item.need} onChange={e=>onChangeStakeholderNeed(e, idx, index)} onBlur={onBlurEvent} />

                        {showVolatilities && item.volatilities ?
                            <div style={{'marginLeft':'50px', 'marginBottom':'5px'}}>
                                {
                                    item.volatilities.map((volatility, idx2)=>{
                                    return <Chip key={idx2} label={volatility} size="small" className={classes.needChip} />
                                })
                                }
                            </div> : null }
                    </div>
                })}
                {!readOnly? <div style={{"marginTop":"5px"}}>
                    <AddCircleOutlineIcon className={classes.addNewNeed} onClick={() => onAddNewStakeholderNeed(idx)} />
                </div> : null}
            </div>
        })
    }

    return (
        <div className={classes.container}>
            {/*{!readOnly? <Button className={classes.addNewStakeholder} onClick={onAddNewStakeholder}>+ add stakeholder</Button> : null}*/}
            {renderNeeds()}
            {!readOnly?
                <div style={{padding:'0 0 0 20px'}}>
                    <Button color="primary" variant="contained" size="small" className={classes.actionButton} onClick={onAddNewStakeholder}>+ Add a feature story</Button>
                </div>
                : null
            }

        </div>
    )
}
