import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmptyPageInfoPrompt from "../components/emptyPageInfoPrompt";
import Link from "@material-ui/core/Link";
import Button from "@material-ui/core/Button";
import LoadScreen from "../components/loadscreen";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    animatedItem: {
        animation: `$scaleIn 4s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-50%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    }
}));

export default function Welcome() {
    const classes = useStyles();
    // const history = useHistory()
    const [loading, setLoading] = useState(true)

    const pagecontext = '';
    const promptheader = 'Welcome to Sprinthack';
    const promptbody = () => {
        return (
            <div>
                {/*<p>*/}
                {/*    Accounting for your product development.*/}
                {/*</p>*/}
                <p>
                    Lets remove speed limits of your ventures.
                </p>
                <Button variant="contained" size="large" color="primary" onClick={openApp} >
                    Get Started
                </Button>
            </div>
        )};

    const openApp = () => {
        // history.push('/')
    }

    React.useEffect(() => {
        /** eventually should ask user about setups etc... Now this is there so that backend can create the workspace
         * Could also be a heartbeat to backend to check is there workspace is done
         */
        setTimeout(()=>{
            setLoading(false)
        }, 8000)

    }, [])

    return (loading) ? (
        <LoadScreen />
    ) : (
        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
            <div className={classes.toolbar} />
            <Link style={{position:'absolute', top:'20px', right:'25px'}} href="/login">Back to login</Link>
            <div className={classes.animatedItem} >
                <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
            </div>
        </div>
    )
}

