import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Card from "@material-ui/core/Card";
import "../../css/Trello.css"
import FreetextEditor from "./FreetextEditor";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import {MoreHoriz} from "@material-ui/icons";
import Fade from "@material-ui/core/Fade";
import Menu from "@material-ui/core/Menu/Menu";

const useStyles = makeStyles((theme) => ({

    requestTaskButton: {
        fontSize:'0.65rem',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    listItem:{
        width: 'auto',
        overflow: 'hidden',
        fontSize: '0.75rem',
        minHeight:'auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight:'1.5',
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        paddingRight:'10px',
        cursor:'pointer',

        '&:hover':{
            background:'rgba(0, 0, 0, 0.04)'
        }
    },
}));


export default function TaskCard({payload, id, cardUpdated, taskAction}) {

    const classes = useStyles();
    const [displayData, setDisplayData] = useState({})
    const [displayEdit, setDisplayEdit] = useState(false);
    const TextInputRef = useRef(null);
    const [anchorEl,setAnchorEl] = useState();
    const open = Boolean(anchorEl);

    /** should really create a custom hook for this... */
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;

    useEffect(()=>{
        setDisplayData(payload)
    }, [payload])

    const HandleSave = (saveData) => {
        // console.log('card payload',savedata)
        const update_object = {}
        update_object[saveData["key"]] = saveData["value"]
        update_object["z-index"] = id
        cardUpdated(update_object)
    }

    const handleActionClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleActionClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={'taskCardWrap'}>
            <Card
                className={`card-hover card${prefersDarkMode ? " card-dark" : ""}`}
                style={{paddingRight:'15px'}}
            >
                <div id={'title_'+id} className="cardTitleLonely">
                    <FreetextEditor
                        payload={{key:"title", value:displayData.title, placeholder:'Title'}}
                        save={HandleSave}
                    />
                </div>

                <div className={'taskCardActionButton'}>
                    <IconButton size={'small'} onClick={handleActionClick}>
                        <MoreHoriz/>
                    </IconButton>
                    <Menu
                        id="action-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleActionClose}
                        TransitionComponent={Fade}
                        PaperProps={{
                            style: {
                                padding:'5px',
                                marginLeft:'-60px'
                            },
                        }}
                    >
                        <div className={classes.listItem} onClick={()=>{taskAction({action:'delete', index:id})}}>{"Delete"}</div>
                    </Menu>
                </div>
            </Card>
            {/*<div className={'taskCardActionsWrap'}>*/}
            {/*</div>*/}
        </div>

    )
}
