import React, { Component } from 'react';
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Divider} from "@material-ui/core";
import WorkspaceNameUpdate from "./workspaceNameUpdate";
import WorkspaceMembers from "./workspaceMembers";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    root: {},
    subtitle:{
        color:theme.custom.subtitle
    },
    section: {
        paddingTop:'30px'
    },
    settingContent: {
        "display":"flex","flex":"1 0 auto","flexDirection":"column","WebkitBoxAlign":"center","alignItems":"center","margin":"64px 40px"
    },
    settingContentColumn: {
        "display":"flex","flex":"initial","flexDirection":"column","maxWidth":"640px","width":"100%"
    },
    settingBody: {
        color:'rgb(138, 143, 152)'
    }

}));

export default function Settings() {
    const classes = useStyles();
    let history = useHistory();

    return <div style={{paddingLeft:'20px'}}>
        <div className={classes.content}>
            <div className={classes.settingContent}>
                <div className={classes.settingContentColumn}>
                    <h1 style={{marginBottom:'0px'}}>Settings</h1>
                    <div className={classes.subtitle}>Manage the workspace settings</div>
                    <div className={classes.section}>
                        <Divider />
                        <h3>Light / Dark themes</h3>
                        <div className={classes.settingBody}>
                            <p>You can control the Light / Dark color theme from your computer system settings. Sprinthack matches the system settings.</p>
                        </div>
                    </div>
                    <div className={classes.section}>
                        <Divider />
                        <h3>Workspace</h3>
                        <div className={classes.settingBody}>
                            <WorkspaceNameUpdate/>
                        </div>
                    </div>
                    <div className={classes.section}>
                        <Divider />
                        <h3>Manage Members</h3>
                        <div className={classes.settingBody}>
                            <WorkspaceMembers/>
                        </div>
                    </div>
                    <div className={classes.section}>
                        <Divider />
                        <h3>Delete Workspace</h3>
                        <div className={classes.settingBody}>
                            <p>For now, please contact your architect about deleting a workspace</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
}