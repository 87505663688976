import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core";
import {db, auth} from "../../util/firebase"
import clsx from 'clsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import CircularProgress from "@material-ui/core/CircularProgress";
import axios from "axios";
import {authMiddleWare} from "../../util/auth";
import BASEURL from "../../constants/baseurl";
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
    joinRow: {
        "display":"flex","flex":"1 1 auto","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center",
        padding:'10px',
        borderTop:'1px solid rgba(255, 255, 255, 0.12)',
        minWidth:'400px'
    },
    joinData:{
        "display":"flex","flex":"1 1 auto","flexDirection":"column","flexGrow":"4","textAlign":"left"
    },
    joinButton: {
        "display":"flex","flex":"initial","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center"
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
        fontSize:'10px'
    },
    activeUserButton:{
        background:theme.custom.RedButton,
        "&:hover":{background:theme.custom.RedButtonHover},
    },
    genericButton:{
        background:theme.custom.GenericButton,
        "&:hover":{background:theme.custom.GenericButtonHover},
    },
    memberList:{
        backgroundColor:theme.custom.MemberListBG,
            marginBottom:'5px'
    },
    progress: {
        position: 'absolute'
    }
}));

export default React.memo(function WorkspaceMembers() {
    const classes = useStyles();
    const [loadingMemberState, setLoadingMember] = useState(false)
    const [open, setOpen] = useState(false)
    const [members, setMembers] = useState({active:[],inactive:[],suspended:[], invited:[]})
    const [workspaceId, setWorkspaceId] = useState('')

    let wsId = null

    useEffect(()=>{
        if (!auth().currentUser) return null

        fetchMembersData()

        var unsubscribe = db.collection("users").doc(auth().currentUser.uid)
            .onSnapshot((snap) => {
                /** idea is to listen to changes in allowed user workspaces --- changed in cloud function when user removes themself from the workspace */
                let user = snap.data()
                console.log('user.workspaces ',user.workspaces)
                console.log('wsId ',wsId)
                console.log('user.workspaces[wsId] ',user.workspaces[wsId])

                if (user.workspaces && wsId && !user.workspaces[wsId]){
                    window.location.href = "/"
                }
            });

        return () => {
            unsubscribe()
        }
    }, [])

    const fetchMembersData = async () => {
        const userData = await db.collection('users').doc(auth().currentUser.uid).get()
        const userDataParsed = userData.data()
        const workspaceId = userDataParsed.activeWorkspace
        wsId = userDataParsed.activeWorkspace
        setWorkspaceId(workspaceId)

        const workspaceMembers = await db.collection(`/workspaces/${workspaceId}/members`).get()
        let membersData = []
        let inactiveMembers = []
        let suspendedMembers = []
        workspaceMembers.forEach(member=>{
            const thisMember = member.data()
            if (thisMember.suspended) return suspendedMembers.push(thisMember)
            if (thisMember.removed) return inactiveMembers.push(thisMember)
            membersData.push(thisMember)

        });
        const authToken = await authMiddleWare()
        axios.defaults.headers.common = { Authorization: `Bearer ${authToken}`};

        const invitedResp = await axios.get(BASEURL.api+`/workspace/${workspaceId}/invited`)
        const invitedList = invitedResp.data.data || []

        console.log(invitedList)

        setMembers({active:membersData, inactive: inactiveMembers, suspended: suspendedMembers, invited:invitedList});
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setLoadingMember(false)
        setOpen(false);
    };



    const ListActiveMembers = () => {
        var first = auth().currentUser.uid;
        let sortedActiveMembers = members.active.sort(function(x,y){ return x.user === first ? -1 : y.user === first ? 1 : 0; });

        return sortedActiveMembers.map((member, index)=>{
            const styleClass = (member.user === auth().currentUser.uid) ? classes.activeUserButton : classes.genericButton
            const buttonText = (member.user === auth().currentUser.uid) ? 'Leave Workspace' : "Remove access"
            return (
                <ListItem key={index} className={classes.memberList}>
                    <ListItemText primary={member.email} />
                    <ListItemSecondaryAction>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            size="small"
                            className={clsx(classes.submitButton, styleClass)}
                            disabled={loadingMemberState}
                            onClick={()=>removeMembers(member.user)}
                        >
                            {buttonText}
                            {loadingMemberState && <CircularProgress size={30} className={classes.progress} />}
                        </Button>
                    </ListItemSecondaryAction>
                </ListItem>
            )
        })
    }

    const ListInactiveMembers = () => {
        return members.inactive.map((member, index)=>{
            return (
                <ListItem key={index} className={classes.memberList}>
                    <ListItemText style={{ textDecorationLine: 'line-through' }} primary={member.email} />
                    <ListItemSecondaryAction>
                        <Chip
                            variant="outlined"
                            size="small"
                            label="Inactive - suspend access"
                            disabled={loadingMemberState}
                            onDelete={()=>removeMembers(member.user)}
                        />
                    </ListItemSecondaryAction>
                </ListItem>
            )
        })
    }

    const ListSuspendedMembers = () => {
        return members.suspended.map((member, index)=>{
            return (
                <ListItem key={index} className={classes.memberList}>
                    <ListItemText style={{ textDecorationLine: 'line-through' }} primary={member.email} />
                    <ListItemSecondaryAction>
                        <ListItemSecondaryAction>
                            <Chip
                                variant="outlined"
                                size="small"
                                label="Suspended"
                                disabled={loadingMemberState}
                            />
                        </ListItemSecondaryAction>
                    </ListItemSecondaryAction>
                </ListItem>
            )
        })
    }

    const ListInvitedMembers = () => {
        return members.invited.map((member, index)=>{
            return (
                <ListItem key={index} className={classes.memberList}>
                    <ListItemText primary={member} />
                    <ListItemSecondaryAction>
                    </ListItemSecondaryAction>

                </ListItem>
            )
        })
    }

    const removeMembers = async (member_userId) => {
        const isSelfRemoval = (member_userId === auth().currentUser.uid)
        // eslint-disable-next-line no-restricted-globals
        var shouldDelete = confirm('Are you sure?');
        if (shouldDelete) {
            setLoadingMember(true)

            if (!isSelfRemoval) return handleOpen()

            /** turn this into a event driven logic in backend. */

            await db.collection('events').add({
                type: 'action',
                name: 'remove_self_from_ws',
                user: member_userId,
                workspace: workspaceId
            })
        }
    }

    return (
        <div style={{marginTop:'15px'}}>
            <List>
                <ListActiveMembers/>
                <ListInactiveMembers/>
                <ListSuspendedMembers/>
            </List>
            <p>Invited:</p>
            <List>
                <ListInvitedMembers/>
            </List>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={6000}
                message={"Suspending users coming up soon..."}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={'123'}
            />

        </div>
    )

});
