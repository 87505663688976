import React, {useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import * as ROUTES from "../../constants/routes";
import {BrowserRouter as Router, Route, useRouteMatch} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import ListHeader from "../../components/listHeader";
import dayjs from "dayjs";
import {ViewWeekSharp} from "@material-ui/icons";
import FeatureLogicSteps from "../../components/features/featureLogicSteps";
import Menu from "@material-ui/core/Menu/Menu";
import Fade from "@material-ui/core/Fade";
import AddNewFeature from "../../components/addNewFeature";

const useStyles = makeStyles((theme) => ({
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRow:{
        "&:hover": {
            backgroundColor: theme.custom.TableRowHover
        }
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:theme.custom.textColor
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 0px 0px 0px'
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },

    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    columnWrap: {
        display:'flex',
    },
    column1: {
        flex:'1 100%',
        borderRight: '1px solid #000',
    },
    column2: {
        flex:'1 100%',
    },
    listItem:{
        width: 'auto',
        overflow: 'hidden',
        fontSize: '0.75rem',
        minHeight:'auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight:'1.5',
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        paddingRight:'10px',
        cursor:'pointer',

        '&:hover':{
            background:'rgba(0, 0, 0, 0.04)'
        }
    },
    [theme.breakpoints.down("sm")]: {
        columnWrap:{
            flexFlow:'row wrap'
        }
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

let topTitle = 'Jobs-to-be-done'
export default function FeaturesList2({userData, history, isMobile, triggerRows, scheduledRows, loadTaskDetails}) {
    let { url } = useRouteMatch();
    let route = useRouteMatch();
    const classes = useStyles();
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const [selectedPop, setSelectedPop] = useState({});
    const [selectedRow, setSelectedRow] = useState({});

    const [anchorEl,setAnchorEl] = useState();
    const open = Boolean(anchorEl);
    const volInputRef = useRef();

    const pagecontext = 'Jobs-to-be-done';
    const promptheader = <div style={{textAlign: 'left'}}>The core of it all</div>;
    const promptbody = () => {
        return (
            <div>
                <p>
                    Jobs-to-be-done are your list of actions that the service or product has to do constantly to deliver some value.
                </p>
                <p>
                    Scheduled are a list of tasks on planned schedules. For example, managing a weekly meeting.
                </p>
                <p>
                    Triggers are a list of tasks that are triggered on-demand. Like for example a page load.
                </p>
            </div>
        )
    };

    const RenderTable = ({tableRows}) => {
        return (
            <div>
                {/*<ListHeader title={'Confirmed'} />*/}
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody>
                            {tableRows.map((feature, index) => {
                                return (
                                    <TableRow key={index} className={classes.tableRow} style={{height:'44px'}} onClick={()=>{loadFeatureDetails(feature)}}>
                                        <TableCell align="left" style={{ width: "1px", paddingRight:'10px'}}><ViewWeekSharp className={classes.tableIcon} /></TableCell>
                                        <TableCell align="left" scope="row" className={classes.rowText}>
                                            <div className={classes.rowTitle}>{feature.title}</div>
                                        </TableCell>
                                        <TableCell align="right" className={classes.dateColumn}>{dayjs(feature.createdAt).format('MMM DD')}</TableCell>
                                    </TableRow>
                                )
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer>
                {/*<ListHeader title={'Completed'} />*/}
            </div>
        )
    }

    const addButtonClick = ({type}) => {
        console.log('type',type)
    }

    const RenderTableWrap = () => {
        /** two row table render here */
        // return <div className={classes.animatedItemFade}>
        return <div>

            {(triggerRows.length < 1 && scheduledRows.length < 1) ? (
                    <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
                        <div className={classes.toolbar} />
                        <div>
                            <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
                        </div>
                    </div>
                ) :
                (
                    <div className={classes.columnWrap}>

                        <div className={classes.column1}>
                            <ListHeader title={'Scheduled'}/>

                            <RenderTable tableRows={scheduledRows} />
                        </div>

                        <div className={classes.column2}>
                            <ListHeader title={'Triggers'}/>

                            <RenderTable tableRows={triggerRows} />
                        </div>

                    </div>
                )}

        </div>
    }

    const handleOpen = (event, stakeholder_need) => {
        setAnchorEl(event.currentTarget);

        /** for some reason the focus has to be on setTimeout */
        setTimeout(() => {
            volInputRef.current.focus()
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleListItemClick = (type) => {
        setAnchorEl(null);
        if (type === 'scheduled'){
            history.push(`${url}/new/scheduled`);
        }
        if (type === 'triggers'){
            history.push(`${url}/new/triggers`);
        }
    };

    const loadFeaturesPage = () => {
        history.push(`${url}`);
    }
    const loadAddFeature = () => {
        history.push(`${url}/new`);
    }
    const loadFeatureDetails = (payload) => {
        history.push(`${url}/feature/${payload.id}`);
    }

    const RenderFeaturesLists = () => {
        return (
            <Router>
                <Route exact path={`${url}`} render={() => RenderTableWrap()} />
                <Route path={`${url}/new/triggers`} render={() => <AddNewFeature userData={userData} backToFeatures={loadFeaturesPage} type={'trigger'} />} />
                <Route path={`${url}/new/scheduled`} render={() => <AddNewFeature userData={userData} backToFeatures={loadFeaturesPage} type={'scheduled'} />} />
                <Route path={`${url}${ROUTES.FEATURE_DETAILS}`} render={() => <FeatureLogicSteps userData={userData} loadCardDetails={loadTaskDetails} />} />
            </Router>
        )
    };

    const handleBackButton = () => {
        loadFeaturesPage();
        return false
    };


    /** the topBar has to be own component, Toolbar within it and toolbar always customised to view */
    return <div>
        <div
            className={clsx(classes.topBar, {
                [classes.topBarShift]: isMobile,
            })}
        >
            <Toolbar>
                {!route.isExact ? <IconButton onClick={handleBackButton} className={clsx(classes.menuButton)}>
                    <ChevronLeftIcon />
                </IconButton> : <div />}
                <Typography variant="button" style={{flexGrow:'1', textTransform:'capitalize'}}>
                    {topTitle}
                </Typography>
                {route.isExact ? <Button variant="contained" color="primary" size="small" className={classes.addNewButton} onClick={handleOpen}>
                    <AddIcon />
                </Button> : <div/>}
            </Toolbar>
        </div>
        {(triggerRows.length < 1 && scheduledRows.length < 1) ? (
                <div>
                    <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()}/>
                </div>
            ) :
            <RenderFeaturesLists/>
        }
        <Menu
            id="add-feature-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
            PaperProps={{
                style: {
                    padding:'0',
                    marginTop:'35px',
                    marginLeft:'-50px'
                },
            }}
        >
            <div
                className={classes.listItem}
                onClick={(event) => handleListItemClick('scheduled')}
            >
                Scheduled +
            </div>
            <div
                className={classes.listItem}
                onClick={(event) => handleListItemClick('triggers')}
            >
                Triggers +
            </div>
        </Menu>
    </div>
}


