import {types, flow, applySnapshot} from 'mobx-state-tree'
import {WishList} from "./WishList";
import {CycleModel} from "./Cycle";
import {db} from "../util/firebase";


/** define worklistItem model */
const WorkListItemModel = types.model({
    id:types.string,
    freetext:types.string
})

/** define worklist model */
const WorkListModel = types.model({
    items: types.optional(types.array(WorkListItemModel), [])
})


/** define user model */
export const UserModel = types.model({
    id: types.identifier,
    name: types.string,
    gender: types.enumeration('gender', ["m","f"]),
    email: types.optional(types.string, ""),
    cycles:types.optional(types.array(types.reference(CycleModel)), []),
    wishlist: types.optional(WishList, {}),
    workList: types.optional(WorkListModel,{})
}).views(self => ({
    get getCyclesList() {
        return self.cycles.map(c => {console.log('c',c.toJSON()); return c.toJSON()})
    }
})).actions(self => ({
    afterCreate() {
        self.loadAdminWorkList()
    },
    loadAdminWorkList: flow(function* loadAdminWorkList() {
        console.log('loadAdminWorkList')
        const cycles = yield db.collection('cycles').get()

        const worklistdata = {
            items: []
        }
        cycles.forEach(cycle => {
            console.log('cycle',cycle.id)
            worklistdata.items.push({
                id:cycle.id,
                freetext:cycle.data().freetext
            })
        })
        console.log('worklistdata',worklistdata)
        applySnapshot(self.workList, worklistdata)
    })
}))

export const User = types.snapshotProcessor(UserModel, {
    postProcessor(snapshot) {
        snapshot.email = 'generated2@email.com'
        return snapshot
    }
})
