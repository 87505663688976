import React, {useState} from 'react';
import {makeStyles, TextField} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Chip from "@material-ui/core/Chip";
import dayjs from "dayjs";
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import ListHeader from '../../components/listHeader';
import IterationsList from '../../components/documentArchitecture/iterationsList'
import ImpactDataList from "../../components/documentArchitecture/impactDataList";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {db, firebaseCore} from "../../util/firebase";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import FilterCenterFocusIcon from "@material-ui/icons/FilterCenterFocus";
import docs_promo from "../../img/SPH_impact_timeline.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRow:{
        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 0px 0px 0px'
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },

    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

}));

let topTitle = 'Feature Lifecycle'

const sampleIterationData = [
    {
        name:'Iteration #1',
        createdAt:'2021-09-27T15:52:54.238Z',
    },
    {
        name:'Iteration #2',
        createdAt:'2021-09-28T15:52:54.238Z'
    },
    {
        name:'Iteration #3',
        createdAt:'2021-10-07T15:52:54.238Z'
    },
    {
        name:'Iteration #4',
        createdAt:'2021-10-22T15:52:54.238Z'
    },
    {
        name:'Iteration #5',
        createdAt:'2021-10-23T15:52:54.238Z'
    },
    {
        name:'Iteration #6',
        createdAt:'2021-11-09T15:52:54.238Z'
    }
]



export default function DocsContent({userData, history, isMobile, rows}) {
    const classes = useStyles();
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const [dataPanelOpen, setDataPanelOpen] = useState(false);
    const [selectedPop, setSelectedPop] = useState({});
    const [rowsData, setRowsData] = useState([]);
    const [selectedRow, setSelectedRow] = useState({});
    const [newImpactDataSet, setNewImpactDataSet] = useState({});

    const [newImpactDataOpen, setNewImpactDataOpen] = useState(false)
    const [showEmptyDatapoint, setShowEmptyDatapoint] = useState(false);
    const [newImpactDataKey, setNewImpactDataKey] = useState('');
    const [newImpactDataValue, setNewImpactDataValue] = useState('');

    const pagecontext = 'Feature Lifecycles';
    const promptheader = 'Track the lifecycles of your features';
    const promptbody = () => {
        return (
            <div>
                <p>
                    This is where you can manually or automatically track the lifecycles of the confirmed features data.
                </p>
                <p>
                    For now, your architect will set up the documentation timeline.
                </p>
            </div>
        )};

    const randomIterations = () => {
        return Math.floor((Math.random() * 6) + 1);
    }

    React.useEffect(() => {

        const rowsDataFilled = rows.map((row, idx) => {
            // const iterationCount = randomIterations();
            // let iterations = []
            // for(let i = 0; i < iterationCount; i++){
            //     iterations.push(sampleIterationData[i])
            // }
            // row.iterations = iterations

            return row
        })

        /** also load the impact data the user has added */

        setRowsData(rowsDataFilled);


    }, [rows])

    const onDocumentRowClick = (docData, index) => {
        let updateSelectedPop = {}
        updateSelectedPop[index] = prefersDarkMode ? 'rgba(255,255,255,0.1)' : 'rgba(205,242,248,1)';
        setSelectedPop(updateSelectedPop);
        setSelectedRow(rowsData[index]);
        setDataPanelOpen(true);
    }

    const onDocumentDetailsClick = (docData) => {
        history.push(`/report/${docData.requestId}`);
    }

    const handleImpactDataOpen = () => {
        /** load prev datasets data */

        const startTime = dayjs().subtract(1, 'year').toISOString()
        console.log('startTime',startTime)

        db
            .collection('lifecycle_data')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            .where('createdAt', '>', startTime)
            .orderBy('createdAt', 'desc')
            .get()
            .then(impactSnapshot => {
                /** get last 3 data sets */
                let impactData = impactSnapshot.docs.map(doc => doc.data()).slice(0,3);

                let baselineData = {data:{}}
                impactData.forEach((impact) => {
                    Object.keys(impact.data).forEach(k => {
                        if (!baselineData.data[k]) {baselineData.data[k] = {}; baselineData.data[k].value = impact.data[k].value}
                    })
                });

                // console.log('baselineData',baselineData)
                // console.log('impactData',impactData)
                setNewImpactDataSet(baselineData)

            })

        setNewImpactDataOpen(true)
    }

    const handleAddNewDataPoint = () => {
        setShowEmptyDatapoint(true)
        /** add to data set */
        if (newImpactDataKey && newImpactDataValue){
            newImpactDataSet.data[newImpactDataKey] =  {}
            newImpactDataSet.data[newImpactDataKey].value = newImpactDataValue
            setNewImpactDataSet({...newImpactDataSet})
        }
        /** clear the previous data from the empty datapoints */
        setNewImpactDataKey('');
        setNewImpactDataValue('');

    }
    const handleSaveNewData = () => {
        setNewImpactDataOpen(false)

        /** get the currently typed new stuff */
        if (newImpactDataKey && newImpactDataValue){
            newImpactDataSet.data[newImpactDataKey] =  {}
            newImpactDataSet.data[newImpactDataKey].value = newImpactDataValue
            setNewImpactDataKey('');
            setNewImpactDataValue('');
        }

        /** should save the data set here */
        const updateObject = {
            data: newImpactDataSet.data,
            workspace_id:userData.activeWorkspace.id,
            createdAt: dayjs().toISOString()
        }

        db
            .collection('lifecycle_data')
            .add(updateObject)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

    }

    const handleImpactDataClose = () => {
        setNewImpactDataOpen(false)
    }

    const updateImpactDataPoint = (e, key) => {
        let updatedDataSet = newImpactDataSet
        // console.log('e.target.value::',e.target.value)
        updatedDataSet.data[key].value = e.target.value
        // console.log('updatedDataSet::',updatedDataSet)
        setNewImpactDataSet({...updatedDataSet})
    }

    /** the topBar has to be own component, Toolbar within it and toolbar always customised to view */
    return (rows && rows.length < 1) ? (
        <div>
            <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} image />
        </div>
    ) : (

        <div>
            <div
                className={clsx(classes.topBar, {
                    [classes.topBarShift]: isMobile,
                })}
            >
                <Toolbar>
                    <Typography variant="button" style={{flexGrow:'1', textTransform:'capitalize'}}>
                        {topTitle}
                    </Typography>
                </Toolbar>
            </div>
            <div className={classes.docsActionBar}>
                <Button variant="contained" color="primary" className={classes.fullDocsButton} onClick={handleImpactDataOpen}>
                    Update the impact data
                </Button>
            </div>
            {dataPanelOpen ? (
                    <div style={{padding:'0px 20px'}}>
                        <Paper elevation={5} className={classes.dataPanel}>
                            <div style={{padding:'5px 10px 20px 0px'}} className={classes.subtitle}><span className={classes.title}>Impact</span> since {dayjs(selectedRow.createdAt).format('MMM DD')}</div>
                            <ImpactDataList row={selectedRow} userData={userData}/>
                        </Paper>
                        {selectedRow.iterations && selectedRow.iterations.length > 0 &&
                            <Paper elevation={5} className={classes.iterationPanel}>
                                <ListHeader title={"Iterations"} backgroundColor={'transparent'}/>
                                <IterationsList row={selectedRow} />
                            </Paper>
                        }
                    </div>
            ) : null}
            <div>
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody>
                            {rowsData && rowsData.map((doc, index) => {
                                return (
                                    <TableRow className={classes.tableRow} style={{height:'44px', 'backgroundColor':selectedPop[index]}} key={index} hover onClick={() => {onDocumentRowClick(doc, index)}}>
                                        <TableCell align="left" style={{ width: "1px", paddingRight:'10px'}}><CheckCircleIcon className={classes.tableIcon} /></TableCell>
                                        <TableCell align="left" className={classes.dateColumn}>{dayjs(doc.createdAt).format('MMM DD')}</TableCell>
                                        <TableCell className={classes.stateChip} align="center">{doc.iterations ? <Chip variant="outlined" color="primary" size="small" label={doc.iterations.length } /> : <FilterCenterFocusIcon color="primary" />}</TableCell>
                                        <TableCell align="left" scope="row" className={classes.rowText}>
                                            <div className={classes.rowTitle}>{doc.freetext} <ChevronRight className={classes.menuIcon} onClick={()=>onDocumentDetailsClick(doc)} /> </div>
                                        </TableCell>
                                        <TableCell className={classes.stateChip} align="right"><Chip variant="outlined" color="primary" size="small" label={doc.state} /></TableCell>
                                    </TableRow>
                                )
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer>

            </div>

            <Modal
                className={classes.modal}
                open={newImpactDataOpen}
                onClose={handleImpactDataClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={newImpactDataOpen}>
                    <div className={classes.paper}>
                        <h2>Set new impact data set</h2>
                        {newImpactDataSet && newImpactDataSet.data && Object.keys(newImpactDataSet.data).map((k,idx) => {
                            return (
                                <div style={{marginBottom:'10px'}} key={idx}>
                                    <span>{k}</span> : <span>
                                        <TextField placeholder={''}
                                               size="small"
                                               value={newImpactDataSet.data[k].value}
                                               onChange={e=>{updateImpactDataPoint(e, k)}}
                                        />
                                    </span>
                                </div>
                            )
                        })}
                        <div className={classes.newDataWrap}>
                            {showEmptyDatapoint && (
                                <div><span>
                                    <TextField placeholder=""
                                                  size="small"
                                                  value={newImpactDataKey}
                                                  onChange={e=>{setNewImpactDataKey(e.target.value)}}
                                    />
                                </span> : <span>
                                    <TextField placeholder=""
                                               size="small"
                                               value={newImpactDataValue}
                                               onChange={e=>{setNewImpactDataValue(e.target.value)}}
                                    />
                                </span></div>
                            )}
                            <div style={{"marginTop":"5px"}}>
                                <AddCircleOutlineIcon className={classes.addNewData} onClick={() => handleAddNewDataPoint()} />
                            </div>
                            <div className={classes.buttonsHolder}>
                                <Button
                                    color="primary"
                                    onClick={handleImpactDataClose}
                                >Cancel</Button>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    className={classes.submitButton}
                                    onClick={(e) => {handleSaveNewData(e)}}
                                >Save</Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
        </div>
    )

}
