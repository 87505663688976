import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/hero.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    [theme.breakpoints.down("xs")]: {
        container:{
            maxWidth:'600px'
        }
    }

}));

const ValueDeck = () => {

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const box_background_light = (prefersDarkMode) ? '' : 'box-background-light';

    return (
        <section>
            <div className={'dash-content'} style={{'maxWidth':'100%'}}>
                <div className={'box '+box_background_light}>
                    <div className={'header color-gray'}>
                        <h5 className={'large-header'}>In a nutshell</h5>
                    </div>
                    <div className={'quest-content'}>
                        <div className={'box-button disabled'}>
                            AI tools really can increase productivity a lot. It is not a hype.
                            However, getting an AI model trained to a level that it has depth and it does not
                            hallucinate false positives is not an easy task. Sprinthack strongly believes that the
                            key is by companies focusing in organising their domain knowledge to structured documentation
                            that can used with any AI service you choose.
                        </div>
                    </div>
                </div>
                <div className={'box '+box_background_light}>
                    <div className={'header color-gray'}>
                        <h5 className={'large-header'}>Knowledge for the AI</h5>
                    </div>
                    <div className={'quest-content'}>
                        <div className={'box-button disabled'}>
                            Continuous monthly knowledge reports, are your best ways to get started quickly.
                            No need for hiring or training, and will have an array to methods to start from. These reports when
                            done in continuous manner will get you great knowledge base of domain insights with 1 year.
                            At that point your company is ready to start implement AI tools if needed.
                        </div>
                    </div>
                </div>
                <div className={'box '+box_background_light}>
                    <div className={'header color-gray'}>
                        <h5 className={'large-header'}>How?</h5>
                    </div>
                    <div className={'quest-content'}>
                        <div className={'box-button disabled'}>Analysis reports service</div>
                        <p></p>
                        <div className={'box-button disabled'}>Micro-documentation</div>
                        <p></p>
                        <div className={'box-button disabled'}>Vector databases ready to be used with AI</div>
                        <p></p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default ValueDeck;