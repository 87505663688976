import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/functions'

const config = {
    apiKey: "AIzaSyBt7LoJkBEgWzHOjtMbL3KHDBB9PFxO3Co",
    authDomain: "sprinthack.com",
    projectId: "sprinthack",
    storageBucket: "sprinthack.appspot.com",
    messagingSenderId: "194123942015",
    appId: "1:194123942015:web:d09e0fde87b29353a9d74a",
    measurementId: "G-PBFC2KFS3V"
};
firebase.initializeApp(config);

export const auth = firebase.auth;

var googleProvider = new firebase.auth.GoogleAuthProvider();
export const signInWithGoogle = () => new Promise((resolve, reject)=>{
    auth().signInWithPopup(googleProvider).then((res) => {
        // console.log(res.user)
        resolve(res)
    }).catch((error) => {
        // console.log(error.message)
        reject(error)
    })

})

// export const db = firebase.database();
export const db = firebase.firestore()
db.settings({ ignoreUndefinedProperties: true, merge:true });
export const firebaseCore = firebase;
export const firebaseFunctions = firebase.app().functions('europe-west1');
export const firebaseStorageRef = firebase.storage().ref();

/** emulators */
// const env = process.env.NODE_ENV || 'development';

// firebaseFunctions.useEmulator("localhost", 5001);
// db.useEmulator("localhost", 8080);
