import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu/Menu";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import SlowLoad from "../slowLoad";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {db} from "../../util/firebase";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import activityUpdateManager from "../activityUpdates/activityUpdateManager";
import CategoryIcon from "@material-ui/icons/Category";
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime";

const useStyles = makeStyles((theme) => ({
    a_button: {
        textDecoration:'none'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    actionButton: {
        background:theme.custom.MainButton
    },
    feedbackLinks:{
        backgroundColor:theme.custom.MemberListBG,
        marginBottom:'5px',
        color:theme.custom.genericHighlightText,
    },
    menuInput: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.8em'
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'15px',
        minHeight:'35px'
    },
    sidedetailsRowHeader: {
        color:'rgb(107, 111, 118)',
        fontWeight: 500,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'100px',
        flexShrink: '0'
    },
    sidedetailValue: {
        "verticalAlign":"top",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "minWidth":'0',
        "userSelect":'none',

        // "whiteSpace":"nowrap",
        // "overflow":"hidden !important",
        // "textOverflow":"ellipsis"
    },
    sideDetailValueFullWith:{
        "marginLeft": '-145px'
    },
    stateWrap: {
        padding:'6px 8px 8px 8px',
        border:'1px solid transparent',
        borderRadius:'4px',

        textAlign: 'left',
        "-webkitBoxPack":'start',
        justifyContent:'flex-start',
        marginLeft:'-8px',
        marginRight:'32px',
        marginBottom:'0px',
        marginTop:'-6px',
        flexShrink: 1,
        overflow:'hidden',
        width:'fit-content',

        "&:hover":{
            border:'1px solid rgba(217, 224, 231, 1)'
        }
    },
    sideDetailGuidance: {
        // color:theme.custom.textColor,
        // background:'rgb(205,242,245)',
        background: theme.custom.genericHighlightArea,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        // border:'1px solid rgb(205,242,245)',
        // boxShadow: theme.custom.boxShadowGlow
    },
    listItem:{
        width: 'auto',
        overflow: 'hidden',
        fontSize: '0.75rem',
        minHeight:'auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight:'1.5',
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        paddingRight:'10px',
        cursor:'pointer',

        '&:hover':{
            background:'rgba(0, 0, 0, 0.04)'
        }
    },
    slowloadWrap: {
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        width:'fit-content',
        marginTop:'0px',
        marginBottom:'-35px',
        right:'0'
    },
    approveIcon: {
        marginRight: '10px',
        color:theme.custom.GreenButton
    },
}));


const menuItems_full = [
    'project 1',
    'project 2',
    'project 3'
]

export default function LinkWithAssumptions({payload}) {
    const classes = useStyles()
    dayjs.extend(relativeTime);

    const [anchorEl,setAnchorEl] = useState();
    const open = Boolean(anchorEl);
    const [filter, setFilter] = useState('')

    const volInputRef = useRef();

    const handleOpen = (event, stakeholder_need) => {
        setAnchorEl(event.currentTarget);

        /** for some reason the focus has to be on setTimeout */
        setTimeout(() => {
            volInputRef.current.focus()
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleListItemClick = (assumption, index) => {
        setAnchorEl(null);

        /** save assumption linking to db */
        db
            .collection('cycle_requests')
            .doc(payload.requestId)
            .update({
                assumption: assumption.id
            })
            .then()

    };

    return (
        <div>
            <div className={classes.sidedetailsRow}>
                <div className={classes.sidedetailsRowHeader}>Project: </div>
                <div className={classes.sidedetailValue} style={{position:'relative'}}>
                    <div className={classes.stateWrap} onClick={handleOpen}>
                        {payload.assumptionTitle || <span className={classes.subtitle}>add...</span>}
                    </div>
                </div>
            </div>
            <div>
            </div>
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                PaperProps={{
                    style: {
                        padding:'0',
                        marginLeft:'-145px'
                    },
                }}
            >
                <TextField inputProps={{className:classes.menuInput}} placeholder="Filter projects..." size="small"
                           style={{padding:'0 10px', marginBottom:'5px'}}
                           inputRef={volInputRef}
                           value={filter}
                           onChange={e=>{setFilter(e.target.value)}}
                />
                {/*{payload && payload.assumptionList && payload.assumptionList.filter(f => f.idea.includes(filter) || filter === '').map((assumption, index) => (*/}
                {/*    <div*/}
                {/*        className={classes.listItem}*/}
                {/*        key={assumption.id}*/}
                {/*        onClick={(event) => handleListItemClick(assumption, index)}*/}
                {/*    >*/}
                {/*        {assumption.idea}*/}
                {/*    </div>*/}
                {/*))}*/}
            </Menu>
        </div>
    )
}
