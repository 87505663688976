import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/references.css'

/** images */
import Lygg from '../../../img/references/lygg_logo.png'
import UnitedLabs from '../../../img/references/unitelabs_logo.png'
import Panda from '../../../img/references/panda_logo.png'
import DroppX from '../../../img/references/droppx_logo.png'
import Meom from '../../../img/references/meom_logo.png'
import Analysis_mode from '../../../img/references/amode_logo.png'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Velocity_map_dark from "../../../img/velocity_map_dark.png";
import Velocity_map_light from "../../../img/velocity_map.png";


const useStyles = makeStyles((theme) => ({

}));

const LatestResearch = ({
                         ...props
                     }) => {

    const classes = useStyles();

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const backgroundColor = (prefersDarkMode) ? 'whiteline dark' : 'whiteline';

    return (
        <section>
            <div className={'latest-research-content'}>
                <div>
                    <h1 className={'latest-research-title'}>Latest Research Report</h1>
                    {/*<div style={{marginBottom:'20px'}}>*/}
                    {/*    <a className={'button'} href={'https://sprinthack.substack.com'}>*/}
                    {/*        Read Solution Analysis Reports*/}
                    {/*    </a>*/}
                    {/*</div>*/}
                    <div>
                        <a href={'https://open.substack.com/pub/sprinthack/p/kempower-challenges-in-communicating?r=twvam&utm_campaign=post&utm_medium=web'}>
                            <div className={'research-title'} >• Kempower: Challenges in communicating the reliability that the solution has.</div>
                            <div className={'research-subtitle'}>Solution Analysis of the EV charging solution of Kempower Plc.</div>
                        </a>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default LatestResearch;