import React, {useReducer, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {db} from "../../util/firebase";
import IdeasList from "../listWrappers/ideasList";
import ArchFeedbackList from "../listWrappers/archFeedbackList";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function ArchFeedbackContent({userData, history, isMobile}) {
    const classes = useStyles();
    const [feedbackState, setFeedbackState] = useState({validationTemplateData:{}, loading:true});

    React.useEffect(() => {
        const unsubscribe = db
            .collection('validation_template_object')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            .orderBy('createdAt', 'desc')
            .onSnapshot((querySnapshot) => {
                let validation_template = {}
                querySnapshot.forEach((doc) => {
                    validation_template = doc.data()
                });

                // console.log('validation_template',validation_template)
                setFeedbackState({validationTemplateData:validation_template, loading:false})
            })

        return function cleanup() {
            unsubscribe()
        }
    }, [userData.activeWorkspace.id])

    return (feedbackState.loading) ? <></> : (
        <ArchFeedbackList userData={userData} history={history} templateLink={feedbackState.validationTemplateData.link} linkDisplay={feedbackState.validationTemplateData.link_display} feedbacks={feedbackState.validationTemplateData.arch_feedback} isMobile={isMobile} />
    )
}

