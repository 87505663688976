import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import LogoDivider from "../../components/logoDivider";
import Paper from '@material-ui/core/Paper';
import {Button, Divider} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SendIcon from '@material-ui/icons/Send';
import {db, auth, firebaseFunctions} from '../../util/firebase'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import RequestDetailsEditor from "../../components/activeExperiments/requestDetailsEditor";
import axios from "axios";
import BASEURL from "../../constants/baseurl";
import LoadScreen from "../../components/loadscreen";
import Chip from "@material-ui/core/Chip";
import Menu from "@material-ui/core/Menu/Menu";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import SPH_logo from "../../img/sprinthack_logo_white_dark_small.png";

const useStyles = makeStyles((theme) => ({
    a:{
        all: 'unset',
        cursor:'pointer',
        textAlign:'center'
    },
    logowrap:{
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        marginBottom:'40px',
    },
    logo:{
        border: '1px solid transparent',
        padding:'20px',
        borderRadius:'4px',
        transitionProperty:'border, background, color',
        transitionDuration:'150ms',
        "&:hover":{
            border:'1px solid rgb(239, 241, 244)',
            borderColor: theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover,
            textDecoration:'none',
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    root: {},
    subtitle:{
        color:theme.custom.subtitle
    },
    section: {
        paddingTop:'30px'
    },
    settingContent: {
        "display":"flex","flex":"1 0 auto","flexDirection":"column","WebkitBoxAlign":"center","alignItems":"center","margin":"64px 40px"
    },
    settingContentColumn: {
        "display":"flex","flex":"initial","flexDirection":"column","maxWidth":"640px","width":"100%"
    },
    settingBody: {
        color:'rgb(138, 143, 152)'
    },
    boldedLine: {
        maxWidth:'560px',
        padding:'20px',
        position:'relative',
        overflow:'hidden',

    },
    textareaStyle: {
        backgroundColor:theme.custom.MemberListBG,
        color:theme.custom.genericHighlightText,
        padding: '10px',
        width: 'calc(100% - 35px)'
    },
    sendButtonWrap: {
        textAlign:'center',
        margin:'20px 0',
    },
    sendButton:{
        color:"#fff",
        background:theme.custom.MainButton,

        "&:hover":{
            color:theme.custom.textColorInverted
        }
    },
    volChip: {
        marginRight:'5px'
    },
    addChip: {

        // border: '1px solid rgba(0, 0, 0, 0.43)',
        // "&:hover": {
        //     borderColor: 'rgba(0, 0, 0, 0.23)'
        // }

    },
    menuInput: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.8em'
    },
    questionWrap: {
        // fontSize:'0.8em',

        // background:'rgb(205,242,245)',
        // boxShadow: theme.custom.boxShadowGlow,
        // borderRadius:'4px'

    }

}));

export default function Template() {
    const classes = useStyles();
    const history = useHistory();
    const { hash } = useParams();
    const [loading, setLoading] = useState(true);
    const [loadingQuestion, setLoadingQuestion] = useState(true);
    const [noFeedbackNeeded, setNoFeedbackNeeded] = useState(false);
    const [isAnonymous, setAnonymous] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('')
    const [detailsData, setDetailsData] = useState({})
    const [anchorEl, setAnchorEl] = useState(null);
    const [newVolatility, setNewVolatility] = useState([])
    const volatility_open = Boolean(anchorEl);
    const [addingVolatility, setAddingVolatility] = useState('');
    const volInputRef = useRef();
    const [inviteSnackbarOpen, setSnackbarOpen] = useState(false)

    /** should probably be in own class function eventually */
    const [scenarioDiscovery, setScenarioDiscovery] = useState('');

    useEffect(()=>{
        fetchDetails()
    }, [])


    const fetchDetails = async () => {
        /** here we could also set a remote loading of the questions */

        /** get request data based on url hash */
        let responseData = {}
        try {
            // const snapshot = await db.collection('cycle_requests').doc(hash).get()
            const snapshot = await axios.get(BASEURL.api+'/templates/active/'+hash).then()
            responseData = snapshot.data
            // /** get custom question template */
            // const questionsSnapShot = await db.collection('feedback_templates').where('requestId', '==', hash).get()
            // let questionsSnapData = []
            // questionsSnapShot.forEach(snap=>{
            //     questionsSnapData = snap.data()
            // })
            // if (questionsSnapData.questions && questionsSnapData.questions.length > 0) setQuestionsData(questionsSnapData.questions)
        } catch (err){
            console.log('error',err)
            setNoFeedbackNeeded(true)
        }
        console.log('loaded...',responseData)
        if (responseData.status !== 0 && responseData.status !== 1) setNoFeedbackNeeded(true)
        setDetailsData(responseData)
        setNotificationMessage('')
        setLoading(false)
    }

    const SendButton = () => {
        return <div>
            {/*<Divider />*/}

            <div className={classes.sendButtonWrap}>
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.sendButton}
                    onClick={saveFeedback}
                    disabled={notificationMessage !== ''}
                    endIcon={<SendIcon/>}
                >
                    Send
                </Button>
                {(auth().currentUser) ? <div>
                    <FormGroup row >
                        <FormControlLabel
                            style={{margin:'15px auto 0 auto'}}
                            control={
                                <Switch
                                    checked={isAnonymous}
                                    onChange={()=>{setAnonymous(isAnonymous => !isAnonymous)}}
                                    color="primary"
                                />
                            }
                            label="Anonymous"
                        />
                    </FormGroup>
                </div> : null}

            </div>
        </div>
    }

    const RenderRandomItem = () => {
        /** lets get random question */

        return <div>
            <div className={classes.boldedLine}>
                <h2>As a {detailsData.stakeholder}</h2>
                <h3>{detailsData.need}</h3>
            </div>

        </div>

    }

    const RenderQuestions = () => {
        return (notificationMessage === '') ? (

            <>
                {detailsData.need && detailsData.need !== '' ?
                    <div>
                        <Paper elevation={3} style={{padding:"20px 0", margin:'20px 0 5px 0'}}>
                            <div className={classes.subtitle} style={{marginLeft:'20px'}}>User story</div>
                            <RenderRandomItem/>
                        </Paper>
                    </div>
                    :
                    <div>
                        <Paper elevation={3} style={{padding:"20px 0", margin:'20px 0 5px 0'}}>
                            <div className={classes.subtitle} style={{marginLeft:'20px'}}>Feature Description</div>
                            <div className={classes.boldedLine}>
                                {detailsData.freetext_details}
                            </div>
                        </Paper>
                    </div>
                }
            </>

            ) : (
            <div>
                <h1>{notificationMessage}</h1>
            </div>
        )
    }

    const saveFeedback = async () => {
        setSnackbarOpen(true)
        if (detailsData.status.toString() === '0' || detailsData.status.toString() === '1') {
            await saveScenario()
        }

        if (detailsData.status.toString() === '2') {

        }
    }

    const saveScenario = async () => {
        // await setNotificationMessage('Thank you!')

        setAnchorEl(null)
        setScenarioDiscovery('')
        setNewVolatility([])
        setNotificationMessage('Loading next...')

        let questionDataObject = [
            {
                q: detailsData.stakeholder + " - " + detailsData.need,
                a: scenarioDiscovery
            }
        ]

        const postfeedback = firebaseFunctions.httpsCallable('comments_api-comments-postFeedback')
        postfeedback({reply: questionDataObject, contextId:hash, isAnonymous:isAnonymous})
            .then(response => {
                fetchDetails()

            })
    }

    const handleVolatilityOpen = (event) => {
        setAnchorEl(event.currentTarget);

        /** for some reason the focus has to be on setTimeout */
        setTimeout(() => {
            volInputRef.current.focus()
        }, 100);
    };

    const handleVolatilityClose = () => {
        setAnchorEl(null);
    };

    const saveVolatility = (ev) => {
        if (ev.key === 'Enter') {
            // Do code here
            ev.preventDefault();

            setNewVolatility(prev=>[...prev, addingVolatility])

            handleVolatilityClose()
            setAddingVolatility('')
        }
    }

    const deleteVolatility = (index) => {
        const updatedVolatility = newVolatility.filter((item,filterIdx) => index !== filterIdx)
        setNewVolatility(updatedVolatility)
    }

    const handleInviteSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    const handleStatus0Text = (event) => {
        event.preventDefault();

        setScenarioDiscovery(event.target.value)
    }

    const RenderLogo = () => {
        return <div className={classes.logowrap}>
            <a href={"/"} className={classes.a}>
                <div className={classes.logo}>
                    <div>
                        <img src={SPH_logo} style={{height:'30px'}} />
                    </div>
                    <strong>Sprinthack</strong>
                    <div>Unleash your potential</div>
                </div>
            </a>
        </div>

    }

    return (loading) ?
        <LoadScreen/>
        :
        (noFeedbackNeeded) ? (
            <div style={{paddingLeft:'20px'}}>
                <div className={classes.content}>
                    <RenderLogo/>
                    <div style={{textAlign:'center'}}>Thank you. All feedback completed.</div>
                </div>
            </div>
        ):(
            <div style={{paddingLeft:'20px'}}>
                <div className={classes.content}>
                    <div className={classes.logowrap}>
                        <a href={"/"} className={classes.a}>
                            <div className={classes.logo}>
                                <div>
                                    <img src={SPH_logo} style={{height:'30px'}} />
                                </div>
                                <strong>Sprinthack</strong>
                                <div>Unleash your product</div>
                            </div>
                        </a>
                    </div>

                    <div className={classes.settingContent}>
                        <div className={classes.settingContentColumn}>
                            {/*<LogoDivider/>*/}
                            {/*<RenderTemplateBasedOnState/>*/}
                            <div>
                                {/*<h1 style={{marginBottom:'0px'}}>Your opinion is needed!</h1>*/}
                                {/*<div className={classes.subtitle}>Please, provide your opinion below. All is anonymous {(auth().currentUser) ? 'if you choose so.' : '.'}</div>*/}
                                <RenderQuestions/>

                                <div className={classes.questionWrap} style={{padding:'20px 0', margin:'20px 0 40px 0'}}>
                                    <h4 style={{marginBottom:'10px'}}>Based on the description, what kind of scenarios the system needs to handle?</h4>

                                    <div>
                                        <TextareaAutosize
                                            aria-label="minimum height"
                                            minRows={3}
                                            className={classes.textareaStyle}
                                            value={scenarioDiscovery}
                                            placeholder="Write your free flowing thoughts."
                                            onChange={handleStatus0Text}
                                        />
                                    </div>
                                </div>

                                <SendButton/>

                                <Divider />
                            </div>
                            {(detailsData.status.toString() === "2") ? (
                                <div>
                                    <h1 style={{marginBottom:'0px'}}>Your opinion is needed!</h1>
                                    <div className={classes.subtitle}>Please, provide your opinion below. All is anonymous {(auth().currentUser) ? 'if you choose so.' : '.'}</div>
                                    <RenderQuestions/>

                                    <Paper elevation={3} className={classes.questionWrap} style={{padding:'20px', margin:'20px 0 40px 0'}}>
                                        <div>What things might change in this overtime?</div>
                                        <div style={{paddingTop:'10px'}}>
                                            {newVolatility.map((vol, idx2)=>{
                                                return <Chip key={vol} className={classes.volChip} label={vol} variant="outlined" size="small" onDelete={()=>deleteVolatility(idx2)} />
                                            })}
                                            <Chip className={classes.addChip} label="+ add" variant="outlined" size="small" onClick={e=>handleVolatilityOpen(e)} />
                                        </div>
                                    </Paper>

                                    <SendButton/>

                                    <Divider />
                                    <Menu
                                        id="vol"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={volatility_open}
                                        onClose={handleVolatilityClose}
                                        TransitionComponent={Fade}
                                        PaperProps={{
                                            style: {
                                                padding:'0 10px'
                                            },
                                        }}
                                    >
                                        <TextField inputProps={{className:classes.menuInput}} placeholder="set volatility" size="small"
                                                   inputRef={volInputRef}
                                                   value={addingVolatility}
                                                   onChange={e=>{setAddingVolatility(e.target.value)}}
                                                   onKeyPress={(ev) => {saveVolatility(ev)}}
                                        />
                                    </Menu>

                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={inviteSnackbarOpen}
                    onClose={handleInviteSnack}
                    autoHideDuration={6000}
                    message={'Saved. Thank you.'}
                    action={
                        <React.Fragment>
                            <IconButton size="small" aria-label="close" color="inherit" onClick={handleInviteSnack}>
                                <CloseIcon fontSize="small" />
                            </IconButton>
                        </React.Fragment>
                    }
                    key={'123'}
                />
            </div>
        )
}

