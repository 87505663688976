import React from "react";
import {makeStyles} from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Logo from "../img/sprinthack_logo_white_dark_small.png"
import AccessDenied from "../pages/accessDenied";


const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: 'calc(50% - 5px)',
        top: 'calc(35% - 5px)'
    },
    logo: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    name: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: 'calc(50% - 24px)',
        top: '40%'
    }
}));

export default function LoadScreen({authorized}) {
    const classes = useStyles();

    /** this first check if authorised exists and then if it is true/false */
    // console.log('authorized',authorized)
    const isAuthorised = (authorized !== undefined || null) ? authorized : true;
    // console.log('authorized', isAuthorised);
    return isAuthorised ? (
        <main className={classes.content}>
            <img  src={Logo} className={classes.logo} alt="Sprinthack"/>
            <CircularProgress size={41} className={classes.uiProgess} />
            <h5 className={classes.name}>SPRINTHACK</h5>
        </main>
    ) : (
        <AccessDenied/>
    )
}
