import React, {useReducer, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import DocsContent from "../contentWrappers/docscontent"
import {db} from "../../util/firebase";
import {isMapType} from "mobx-state-tree";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function DocumentsList({userData, isMobile, history}) {
    const classes = useStyles();
    const [docsState, setDocsState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {docsData:[], loading:true}
    );

    React.useLayoutEffect(() => {

        const unsubscribe = db
            .collection('cycle_requests')
            .where('status', '==', 5)
            .where('workspace_id', '==', userData.activeWorkspace.id)
            .orderBy('createdAt', 'desc')
            .limit(100)
            .onSnapshot((querySnapshot) => {
                let cycles = [];
                querySnapshot.forEach((doc) => {
                    // console.log(`${doc.id}`);
                    cycles.push({
                        requestId: doc.id,
                        title: doc.data().title,
                        freetext: doc.data().freetext,
                        user_needs: doc.data().user_needs || [],
                        createdAt: doc.data().createdAt,
                        ai_analysis_stage: doc.data().ai_analysis_stage || -1,
                        state: doc.data().state || "Pending Architect Review",
                        status: doc.data().status || 0,
                        readyToCycle: doc.data().readyToCycle || false
                    });
                });

                setDocsState({docsData:cycles, loading:false});
            },error => {
                setDocsState({docsData:[], loading:false});
            });

        return function cleanup() {
            unsubscribe()
        }
    }, [])

    return (docsState.loading) ? null : (
        <div>
            <DocsContent userData={userData} history={history} isMobile={isMobile} rows={docsState.docsData} />
        </div>
    );
}
