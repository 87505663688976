import React, {useReducer, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useHistory, useRouteMatch} from "react-router-dom";
import {useParams} from "react-router";
import {db} from "../../util/firebase";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Divider from '@material-ui/core/Divider';
import Timeline from '@material-ui/lab/Timeline';
import SideDetails from "./sideDetails";
import ActivityTimeline from "../generalComponents/activityTimeline"
import RequestDetailsEditor from "./requestDetailsEditor"
import ComponentStructure from "./componentStructure"
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Close from '@material-ui/icons/Close';
import DetailHeaderEditor from "../generalComponents/DetailHeaderEditor";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        height: 'calc(100vh - 65px)',
        flexFlow:'row wrap'
    },
    maindetails: {
        flex: '1 100%',
        maxWidth: '800px',
        minWidth:'400px',
        maxHeight: '100%',
        overflowY: 'scroll',
        "position":"relative",
        "flexShrink":"0",
        "WebkitBoxFlex":"100",
        "flexGrow":"100",
        "paddingBottom":"82px"
    },
    maindetailsWrapper: {
        "flexGrow":"100",
        "WebkitBoxFlex":"100",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "overflowY":"overlay"
    },
    maindetailsContent:{
        "display":"flex",
        "flexBasis":"initial",
        "flexDirection":"column",
        "position":"relative",
        "width":"calc(100% - 60px)",
        "marginLeft":"auto",
        "marginRight":"auto",
        "WebkitBoxFlex":"1",
        "flexGrow":"1",
        "flexShrink":"0",
        "paddingTop":"20px"
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%",
        "display":"flex",
        "zIndex":"1100",
        "boxSizing":"border-box",
        "flexShrink":"0",
        "flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    textareaStyle: {
        padding: '10px',
        minWidth: '260px',
        width: 'calc(100% - 35px)'
    },
    submitButton: {
        background:theme.custom.MainButton
    },
    [theme.breakpoints.up("xs")]: {
    },
    [theme.breakpoints.up("sm")]: {
        maindetails:{
            flex:'100 0px',
            order:'2'
        },
    },
    [theme.breakpoints.up("md")]: {
    },
    metadataPopup:{
        border:'1px solid transparent',
        borderColor:theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        boxShadow:'rgb(0 0 0 / 10%) 0px 4px 18px',
        height: 'calc(100vh - 105px)'
    },
    metadataTopBar:{
        height:'40px',
        padding:'10px',
        border:'1px solid transparent',
        borderBottomColor:theme.custom.OutlineButtonBorder
    },
    metadataClose:{
        // padding:'10px',
        color: theme.custom.subtitle,
        borderRadius:'2px',
        "&:hover":{
            background:theme.custom.ListBGhover
        }
    },
    metadataImage: {
        maxHeight:'365px',
        maxWidth:'100%',
        cursor:'zoom-in'
    },
    metadataImageFullContainer: {
        display:'flex',
        width:'100vw',
        height:'100vh',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        background:'rgba(0,0,0,0.628)'
    },
    metadataImageFull: {
        width: undefined,
        height: undefined,
        maxWidth:'100%',
        maxHeight:'100%'
    }


}));

export default function CycleRequestDetails({userData, detailsPayload, history}) {
    const classes = useStyles();
    let { requestId } = useParams();
    let { url } = useRouteMatch();

    dayjs.extend(relativeTime);

    const [showMetadataPage, setShowMetadataPage] = useState(false);
    const [requestData, setRequestData] = useState(detailsPayload)
    // const [displayRequestEdit, setDisplayEdit] = useState(false);
    const [focusedImage, setFocusedImage] = useState('');
    const [imageOpen, setImageOpen] = useState(false);
    const [componentStructureOpen, setComponentStructureOpen] = useState(false);
    const [colorPopInUserNeeds, setColorPopInUserNeeds] = useState([]);

    const [state, setState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {activity: [], payload:detailsPayload}
    );

    const [showVolatilities, setShowVolatilities] = useState(false)

    React.useEffect(() => {

        // console.log('detailsPayload:::',requestData)

        const unsubscribe = db
            .collection('cycle_requests')
            .doc(requestId)
            .onSnapshot(async (doc) => {
                if (doc.exists){
                    let observedData = {
                        requestId: doc.id,
                        freetext_details: doc.data().freetext_details || '',
                        user_needs: doc.data().user_needs || [],
                        sidedetails: doc.data().sidedetails || {},
                        ai_analysis_stage: doc.data().ai_analysis_stage || 0,
                        state: doc.data().state || "Pending",
                        status: doc.data().status || 0,
                        success: doc.data().success || '',
                        ping_count: doc.data().ping_count || 0,
                        ping_timestamp: doc.data().ping_timestamp || null,
                        scenarios: doc.data().scenarios || [],
                        ...doc.data()
                    }

                    db.collection('idea_validation')
                        .where('workspace_id', '==', userData.activeWorkspace.id)
                        .orderBy('createdAt', 'desc')
                        .get()
                        .then(assumptions => {
                            let assumptionList = []
                            let linkedAssumptionTitle = null

                            assumptions.forEach(assumption=>{
                                assumptionList.push({id:assumption.id,...assumption.data()})

                                if (doc.data().assumption === assumption.id && assumption.data().idea) {
                                    linkedAssumptionTitle = assumption.data().idea
                                }

                            })

                            observedData = {
                                ...observedData,
                                assumptionList:assumptionList,
                                assumptionTitle: linkedAssumptionTitle
                            }
                            setRequestData(observedData)
                        })

                    console.log('observedData',observedData);
                    setRequestData(observedData)
                } else {
                    setRequestData({
                        requestId: requestId
                    })
                }
            });

        return function cleanup() { unsubscribe() }

    }, []);


    const toggleSidedetails = (sideDetail) => {

        if (sideDetail === 'volatilities') {
            setShowVolatilities(!showVolatilities);
        }
    }

    const toggleMetadata = (requestId) => {
        console.log('metadata ',requestId)
        setShowMetadataPage(!showMetadataPage)
    }

    const showComponentModal = () => {
        setComponentStructureOpen(true);
    }

    const showImageModal = (imageURL) => {
        setFocusedImage(imageURL);
        setImageOpen(true);
    }

    const showUseCaseLink = (colorPops) => {
        setColorPopInUserNeeds(colorPops);
    }

    const useCaseCompleteClick = (payloadIndex, updateToggle) => {
        let newRequestData = requestData
        newRequestData.sidedetails["Use cases"][payloadIndex].completed = updateToggle
        setRequestData({...newRequestData})
        db.collection('cycle_requests').doc(newRequestData.requestId)
            .update({sidedetails:newRequestData.sidedetails})
            .then(()=>{
            })
            .catch(()=>{
            })
    }

    const scenarioComplete = (payloadId, isCompleted) => {
        console.log('payloadIndex',payloadId)
        console.log('isCompleted',isCompleted)
        // let newRequestData = requestData
        // newRequestData["scenarios"][payloadIndex].completed = isCompleted
        // setRequestData({...newRequestData})
        // db.collection('cycle_requests').doc(newRequestData.requestId)
        //     .update({scenarios:newRequestData.scenarios})
        //     .then(()=>{
        //     })
        //     .catch(()=>{
        //     })
        // if (!payloadId) return null
        // db.collection('scenarios_bdd').doc(payloadId)
        //     .update({completed:isCompleted})
        //     .then(()=>{
        //     })
        //     .catch(()=>{
        //     })
    }

    const demoImage_actionDiagram = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Faction_diagrams%2FSPH_action_diagram_sample.png?alt=media&token=76f3b256-a44f-4d7f-8a35-b7555dca1676";
    const demoImage_callChain = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Fcall_chains%2FSPH_call_chain_sample.png?alt=media&token=e05eb18e-238a-47bf-af64-5b38c1841b69";
    const demoImage_sequence = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Fsequence%2FSPH_sequence_sample.png?alt=media&token=12b4b4d6-cc31-4811-88d7-b5785f12df0b";

    const handleTitleSave = (savePayload) => {
        /** this will save the title to db */
        db.collection('cycle_requests').doc(requestId).update({freetext: savePayload.value}).then()
    }

    return (
        <div style={{height: 'calc(100vh - 65px)'}}>
            {showMetadataPage ? (
                <div style={{padding:'20px', height: 'calc(100vh - 65px)'}} >
                    <div className={classes.metadataPopup}>
                        <div className={classes.metadataTopBar}>
                            <Close className={classes.metadataClose} onClick={toggleMetadata}/>
                        </div>
                        <div className={classes.root} style={{padding:'20px', height: 'calc(100vh - 145px)'}}>
                            <div className={classes.maindetails}>
                                <div className={classes.maindetailsContent}>
                                    <h2 style={{fontWeight:'700'}}>Action diagram</h2>
                                    <div>
                                        <img
                                            className={classes.metadataImage}
                                            onClick={()=>{showImageModal(demoImage_actionDiagram)}}
                                            src={`${demoImage_actionDiagram}`}
                                            srcSet={`${demoImage_actionDiagram} 2x`}
                                            alt='action_diagram_img'
                                            loading="lazy"
                                        />
                                    </div>
                                    <h2 style={{fontWeight:'700'}}>Call chains</h2>
                                    <div>
                                        <img
                                            className={classes.metadataImage}
                                            onClick={()=>{showImageModal(demoImage_callChain)}}
                                            src={`${demoImage_callChain}`}
                                            srcSet={`${demoImage_callChain} 2x`}
                                            alt='action_diagram_img'
                                            loading="lazy"
                                        />
                                    </div>
                                    <h2 style={{fontWeight:'700'}}>Sequence</h2>
                                    <div>
                                        <img
                                            className={classes.metadataImage}
                                            onClick={()=>{showImageModal(demoImage_sequence)}}
                                            src={`${demoImage_sequence}`}
                                            srcSet={`${demoImage_sequence} 2x`}
                                            alt='action_diagram_img'
                                            loading="lazy"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={classes.root}>
                    <div className={classes.maindetails}>
                        <div className={classes.maindetailsWrapper}>
                            <div className={classes.maindetailsContent}>
                                {/*<div className={classes.subtitle}>*/}
                                {/*    Created {dayjs(requestData.createdAt).format('MMMM DD, YYYY')}*/}
                                {/*</div>*/}
                                <DetailHeaderEditor payload={{key:"freetext", value:requestData.freetext}} save={handleTitleSave}/>
                                <RequestDetailsEditor payload={requestData} showVolatilities={showVolatilities} colorPops={colorPopInUserNeeds} />
                                <Divider />
                                <h4 className={'activityTitle'}>Activity</h4>
                                <Timeline>
                                    <ActivityTimeline payload={requestData} />
                                </Timeline>
                            </div>
                        </div>
                    </div>
                    <SideDetails payload={requestData} history={history} toggleFunc={toggleSidedetails} toggleMetadata={toggleMetadata} showVolatilities={showVolatilities} showImage={showImageModal} drawComponentMap={showComponentModal} showUseCaseLink={showUseCaseLink} useCaseComplete={useCaseCompleteClick} scenarioComplete={scenarioComplete}/>
                </div>
            )}
            <Modal
                open={imageOpen}
                onClick={()=>{setImageOpen(false)}}
                aria-labelledby="modal-image-zoom"
                aria-describedby="modal-image-zoom"
            >
                <Fade in={imageOpen}>
                    <div className={classes.metadataImageFullContainer}>
                        <img
                            className={classes.metadataImageFull}
                            src={focusedImage}
                            srcSet={focusedImage}
                            alt='action_diagram_img'
                            loading="lazy"
                        />
                    </div>
                </Fade>
            </Modal>
            <Modal
                open={componentStructureOpen}
                onClick={()=>{setComponentStructureOpen(false)}}
                aria-labelledby="modal-image-zoom"
                aria-describedby="modal-image-zoom"
            >
                <Fade in={componentStructureOpen}>
                    <div className={classes.metadataImageFullContainer}>
                        <ComponentStructure payload={requestData} />
                    </div>
                </Fade>
            </Modal>

        </div>
    );
};