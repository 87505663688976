import React from 'react';
import clsx from 'clsx'
import Header from '../components/landing/layout/Header'
import Footer from '../components/landing/layout/Footer'
import Hero from '../components/landing/sections/Hero'
import Hero_new from '../components/landing/sections/Hero_new'
import Hero_3 from "../components/landing/sections/Hero_3";
import ValueDeck from '../components/landing/sections/ValueDeck'
import References from '../components/landing/sections/References'
import Quote from '../components/landing/sections/Quote'
import LatestResearch from '../components/landing/sections/LatestResearch'
import HowItWorks from '../components/landing/sections/HowItWorks'
import Features from '../components/landing/sections/Features'
import TargetUsers from '../components/landing/sections/TargetUsers'
import ContactUs from '../components/landing/sections/ContactUs'
import {makeStyles} from "@material-ui/core/styles";
import Features_3 from "../components/landing/sections/Features_3";
import WhatWeDo from "../components/landing/sections/WhatWeDo";

const useStyles = makeStyles((theme) => ({
    mainBackground:{
        // backgroundColor:'#060606'
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

export default function Landing() {
    const classes = useStyles();

    return<div>
        <Header/>
        <div className={clsx(classes.animatedItemFade, classes.mainBackground)}>
            <Hero_3/>
            {/*<Quote/>*/}
            <LatestResearch/>
            {/*<WhatWeDo/>*/}
            <ValueDeck/>
            {/*<Features_3/>*/}
            <HowItWorks/>
            {/*<TargetUsers/>*/}
            <ContactUs/>
        </div>
        <Footer/>
    </div>

}
