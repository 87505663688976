import React, {useEffect} from 'react';
import PropTypes from 'prop-types'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';

const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)'
    },
    active: {
        '& $line': {
            borderColor: '#00bcd4',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#00bcd4',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center'
    },
    active: {
        color: '#00bcd4',
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: '#00bcd4',
        zIndex: 1,
        fontSize: 18,
    },
});

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
            })}
        >
            {completed || active ? <Check className={classes.completed} /> : <div className={classes.circle} />}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'transparent',
        maxWidth:'540px !important'
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

function getSteps() {
    return ['Requested','In Develop', 'Delivered', '3', '4', '5', '6'];
}

export default function SideDetailsAnalysisStage({payload}) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(payload.ai_analysis_stage || -1);
    const steps = getSteps();

    useEffect(() => {

        let activestate = -1
        const sideDetails = payload.sidedetails
        if (sideDetails){
            // if (sideDetails["Volatilities"] && sideDetails["Volatilities"] !== '' ) activestate = 1
            // if (sideDetails["Use cases"] && sideDetails["Use cases"] !== '' && activestate === 1) activestate = 2
            // if (sideDetails["Estimates"] && sideDetails["Estimates"] !== '' && activestate === 2) activestate = 3
            // if (sideDetails["Priority list"] && sideDetails["Priority list"] !== '' && activestate === 3) activestate = 4
        }

        if (payload.ai_analysis_stage > activestate) activestate = payload.ai_analysis_stage

        setActiveStep(activestate)
    }, [payload])

    const handleNextStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBackStep = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleResetStep = () => {
        setActiveStep(-1);
    };

    return (
        <div className={classes.root}>
            <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}