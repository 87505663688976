import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/references.css'

/** images */
import Lygg from '../../../img/references/lygg_logo.png'
import UnitedLabs from '../../../img/references/unitelabs_logo.png'
import Panda from '../../../img/references/panda_logo.png'
import DroppX from '../../../img/references/droppx_logo.png'
import Meom from '../../../img/references/meom_logo.png'
import Analysis_mode from '../../../img/references/amode_logo.png'


const useStyles = makeStyles((theme) => ({

}));

const References = ({
                         ...props
                     }) => {

    const classes = useStyles();

    return (
        <section>
            <div className={'whiteline'}>
                <div className={'reference_content'}>
                    <div className={'subtitleHeader'}>
                        Trusted by
                    </div>
                    <div className={'row'}>
                        <div className={'col-4 col-lg-2'}>
                            <img alt={'Lygg'} src={Lygg} />
                        </div>
                        <div className={'col-4 col-lg-2'}>
                            <img alt={'UnitedLabs'} src={UnitedLabs} />
                        </div>
                        <div className={'col-4 col-lg-2'}>
                            <img alt={'Panda'} src={Panda} />
                        </div>
                        <div className={'col-4 col-lg-2'}>
                            <img alt={'DroppX'} src={DroppX} />
                        </div>
                        <div className={'col-4 col-lg-2'}>
                            <img alt={'Meom'} src={Meom} />
                        </div>
                        <div className={'col-4 col-lg-2'}>
                            <img alt={'Analysis_mode'} src={Analysis_mode} />
                        </div>
                    </div>
                    <div className={'subtitleHeader quote'}>
                        "Fast learning loop with small tasks is important. Early in the product-market-fit search, we really needed a plan how are we going to get this delivered to the customer today, this week, this month? Focus has to be in the speed of the cycles to learn more."
                    </div>
                    <div className={'subtitleHeader quote'}>
                        -- Dima Syrotkin, CEO & Founder, Pandatron Ltd.
                    </div>
                </div>
            </div>
        </section>
    );
}

export default References;