import React from "react";
import {makeStyles} from "@material-ui/core";
import "../css/slowLoad.css"

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: 'calc(50% - 5px)',
        top: 'calc(35% - 5px)'
    },
    logo: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    name: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: 'calc(50% - 24px)',
        top: '40%'
    }
}));

export default function SlowLoad({show}) {

    /** this first check if authorised exists and then if it is true/false */
    return (
        <div className={'b'}>
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
            <div className={'c'} />
        </div>
    )

}
