import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/references.css'

/** images */
import Lygg from '../../../img/references/lygg_logo.png'
import UnitedLabs from '../../../img/references/unitelabs_logo.png'
import Panda from '../../../img/references/panda_logo.png'
import DroppX from '../../../img/references/droppx_logo.png'
import Meom from '../../../img/references/meom_logo.png'
import Analysis_mode from '../../../img/references/amode_logo.png'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Velocity_map_dark from "../../../img/velocity_map_dark.png";
import Velocity_map_light from "../../../img/velocity_map.png";


const useStyles = makeStyles((theme) => ({

}));

const Quote = ({
                         ...props
                     }) => {

    const classes = useStyles();

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const backgroundColor = (prefersDarkMode) ? 'whiteline dark' : 'whiteline';

    return (
        <section style={{'padding':'0 50px'}}>
            <div className={backgroundColor}>
                <div className={'quote_content'}>

                    "Solution Analysis studies an organization’s current challenges, analyzes them, and design solutions to remove or improve the handling of the challenges."

                </div>
            </div>
        </section>
    );
}

export default Quote;