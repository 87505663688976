import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/features.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Lygg from "../../../img/references/lygg_logo.png";
import UnitedLabs from "../../../img/references/unitelabs_logo.png";
import Panda from "../../../img/references/panda_logo.png";
import DroppX from "../../../img/references/droppx_logo.png";
import Meom from "../../../img/references/meom_logo.png";
import Analysis_mode from "../../../img/references/amode_logo.png";

const ContactUs = () => {

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const text_color_light = (prefersDarkMode) ? '' : 'text_color_light';

    return (
        <section>
            {/** temp contact info **/}
            <div>
                <div className={'contact_us'}>
                    <div className={'subtitleHeader'}>
                        <div>***</div>
                        <div><br/>Reach out to us:</div>
                        <div>team@sprinthack.com</div>
                        <div><br/>***</div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default ContactUs;