import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {
    BrowserRouter as Router,
    Route, useRouteMatch
} from "react-router-dom";

import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import {auth, db, firebaseCore} from '../util/firebase'
import Account from "../components/SettingComponents/account";
import Settings from "../components/SettingComponents/settings";
import LoadScreen from "../components/loadscreen";
import Pricing from "./pricing";
import TeamContent from "./contentWrappers/teamContent";
import PlayBook from "./playbook";
import {useIsMobile} from "../util/useIsMobile";
import SprintsContent_individual from "./contentWrappers/sprintsContent_individual";
import SideDrawer_individual from "../components/dashboardComponents/sideDrawer_individual";
import SideDrawer_team from "../components/dashboardComponents/sideDrawer_team";
import axios from "axios";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        backgroundColor: theme.custom.root,
        transition:'opacity 1020ms ease 0s'
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000'

    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    content: {
        padding: '0px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        "display":"flex","flexShrink":"initial","flexBasis":"initial","flexDirection":"column","WebkitBoxFlex":"1","flexGrow":"1","position":"relative","overflow":"auto","WebkitBoxAlign":"stretch","placeItems":"stretch"
    },
    drawerShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    avatarHoverArrow: {
        position: 'absolute',
        right:'10px',
        top:'10px'
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    menuButton:{
        "position":"fixed",
        "left":"12px",
        "top":"0px",
        "width":"52px",
        "height":"52px",
        "paddingLeft":"8px",
        "zIndex":"10"
    },
    menuButtonShift: {
        marginLeft: `calc(${drawerWidth}px - 10px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButtonHide: {
        display:'none'
    },

}));

// function useIsMobile() {
//     const [isMobile, setIsMobile] = useState(false);
//     useLayoutEffect(() => {
//         function checkIsMobile() {
//             setIsMobile(window.innerWidth < 600);
//         }
//         window.addEventListener('resize', checkIsMobile);
//         checkIsMobile();
//         return () => window.removeEventListener('resize', checkIsMobile);
//     }, []);
//     return isMobile;
// }


const TRACKING_ID = "G-PBFC2KFS3V"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Dashboard_new(props) {
    const history = props.history;
    const classes = useStyles();
    let { url, params } = useRouteMatch();
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [opacity, setOpacity] = useState('0');

    const [userData, setUserData] = useState({loading: true, email: '', username: null, profilePicture: '', isAuthorised: true})

    useEffect( () => {
        // if (!userData.loading) setOpacity('1');
        setTimeout(() => {setOpacity('1');}, 1500)

    }, [userData.loading]);

    useEffect( () => {
        setOpen(!isMobile)
    }, [isMobile]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "dashboard_new page" });

        db.collection(`/users/`)
            .doc(auth().currentUser.uid)
            .get()
            .then((userdata) => {
                userdata = {
                    data:userdata.data()
                }
                // console.log('userdata: ',user);
                // console.log('userdata   .data: ',userdata);
                if (!userdata.data || !userdata.data.activeWorkspace) {
                    setUserData({
                        email: userdata.data.email,
                        username: userdata.data.username,
                        profilePicture: userdata.data.imageUrl,
                        isAuthorised: false,
                        loading: false
                    });
                    return null;
                }

                db.collection('workspaces').doc(userdata.data.activeWorkspace)
                    .get()
                    .then((workspace)=>{
                        let activeWorkspace = workspace.data()
                        // console.log('dashboard activeWorkspace:::::',activeWorkspace)
                        activeWorkspace['id'] = workspace.id
                        const loading = (params.workspace.toLowerCase() !== activeWorkspace.namespace.toLowerCase());

                        if (params.workspace.toLowerCase() !== activeWorkspace.namespace.toLowerCase()){
                            history.push('/join')
                            return null
                        }

                        let is_authorised_workspace = true;
                        console.log('is_authorised_workspace: ',is_authorised_workspace, params.workspace, activeWorkspace.namespace);

                        /** Should check if the user is a member of the params.workspace -->> otherwise correct_workspace is false truth */
                        db.collection('workspaces').doc(workspace.id)
                            .collection('members').doc(auth().currentUser.uid)
                            .get()
                            .then(async doc=>{
                                let member = doc.data()
                                // console.log('member',member);
                                if (!member.user || member.suspended || member.removed){
                                    is_authorised_workspace = false
                                    setUserData({
                                        isAuthorised: is_authorised_workspace
                                    });
                                    return null
                                }

                                /** approved to access the active ws, but params workspace might still be different. But do this later */
                                let active_is_different = (params.workspace.toLowerCase() === activeWorkspace.namespace.toLowerCase());

                                const spacesArr = Object.keys(userdata.data.workspaces).map((space) => {
                                    return space
                                })
                                const arrayChunks = []
                                while (spacesArr.length > 0) arrayChunks.push(spacesArr.splice(0, 10));

                                /** this breaks if more than 10 workspaces, so for now show first 10 --- then some option to show all workspaces */
                                db.collection('workspaces')
                                    .where(firebaseCore.firestore.FieldPath.documentId(),'in', arrayChunks[0])
                                    .get()
                                    .then((workspaces) => {
                                        let spaces = []
                                        let setNewActiveWorkspace = null
                                        workspaces.forEach((doc) => {
                                            let workspacedata = doc.data()
                                            workspacedata['id'] = doc.id
                                            if (!active_is_different && workspacedata.namespace === params.workspace.toLowerCase()) {
                                                setNewActiveWorkspace = doc.id
                                            }
                                            spaces.push(workspacedata)
                                        })

                                        // console.log('spaces',spaces, is_authorised_workspace, active_is_different, activeWorkspace)

                                        /** check that allowed collections, match param collection. reroutes to root if so */
                                        if (setNewActiveWorkspace) {
                                            db.collection('users').doc(auth().currentUser.uid)
                                                .set({activeWorkspace:setNewActiveWorkspace}, {merge:true})
                                                .then(()=>{
                                                    history.push('/');
                                                })
                                                .catch(err=>{
                                                    console.log('err',err)
                                                })
                                        } else {
                                            setUserData({
                                                email: userdata.data.email,
                                                username: userdata.data.username,
                                                profilePicture: userdata.data.imageUrl,
                                                activeWorkspace: setNewActiveWorkspace || activeWorkspace,
                                                workspaces: spaces,
                                                isAuthorised: is_authorised_workspace,
                                                loading: loading
                                            });
                                        }
                                    }).catch((err)=>{
                                    console.log('err',err);
                                    setUserData({
                                        isAuthorised: false,
                                    });
                                })
                            }).catch((err)=>{
                            console.log('err',err);
                            setUserData({
                                isAuthorised: false,
                            });
                        })

                    })
                    .catch((err)=>{
                        console.log('err',err);
                        setUserData({
                            isAuthorised: false,
                        });
                        // history.push('/error')
                    })
            })
            .catch((error) => {
            });


    }, [])

    const loadProjects = () => {
        history.push(`${url}/projects`);
    }

    const RenderMainComponent = () => {
        // console.log('USERDATA:::',userData)
        return (
            <Router>
                <Route path={`${url}`} render={() => <SprintsContent_individual userData={userData} history={history} isMobile={isMobile}/>}>
                </Route>
                <Route path={`${url}/playbook`} render={() => <PlayBook userData={userData} history={history}/>}>
                </Route>

                <Route exact path={`${url}/team`} render={() => <TeamContent userData={userData} />}>
                </Route>
                <Route exact path={`${url}/settings`} render={() => <Settings />}>
                    {/*<RoadmapList history={history} />*/}
                </Route>
                <Route exact path={`${url}/settings/profile`} render={() => <Account />}>
                    {/*<RoadmapList history={history} />*/}
                </Route>
                <Route exact path={`${url}/settings/pricing`} render={() => <Pricing userData={userData} showButtons />}>
                    {/*<RoadmapList history={history} />*/}
                </Route>

            </Router>
        )

    };

    return userData.loading || (opacity !== '1') ? (
        <LoadScreen authorized={true}/>
    ) : (
        <div className={classes.root} style={{opacity:opacity}}>
            <CssBaseline />
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={()=>{setOpen(!open)}}
                edge="start"
                className={clsx(classes.menuButton, {[classes.menuButtonShift]: open}, {[classes.menuButtonHide]: !isMobile})}
            >
                <MenuIcon />
            </IconButton>
            <SideDrawer_team className={clsx({[classes.drawerShift]: open || !isMobile})} history={history} userData={userData} isMobile={isMobile} open={open} closeDrawer={()=>setOpen(!open)} />
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open || !isMobile,
                })}
            >
                <RenderMainComponent/>
            </main>
        </div>
   );
}

export default Dashboard_new