import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import EmptyPageInfoPrompt from "../components/emptyPageInfoPrompt";
import {auth, db, firebaseCore} from '../util/firebase'
import axios from "axios";
import BASEURL from "../constants/baseurl";
import LoadScreen from "../components/loadscreen";
import Link from "@material-ui/core/Link";
import JoinList from "../components/joinList";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    createSpaceButton:{
        marginTop:'20px'
    },
    animatedItem: {
        animation: `$scaleIn 6s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

export default function Finishsetup() {
    const classes = useStyles();
    const history = useHistory()

    const [loading, setLoading] = useState(true)
    const [joinSpaces, setJoinSpaces] = useState([])

    const [workspaceName, setWorkspaceName] = useState('')
    const [namespace, setNamespace] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(()=>{
        if (namespace.length >0 && namespace.length <3) {setErrorMsg('url must be at least 3 characters')}
        else {setErrorMsg('')}
    }, [namespace])

    const pagecontext = '';
    const promptheader = 'Create a workspace';
    const promptbody = () => {
        return (
            <div style={{maxWidth:'400px'}}>
                <p>
                    Workspaces are shared environments where teams can work and receive Virtual Scrum Master expertise on product development.
                </p>
                <div>
                    <TextField
                        style={{marginTop:'0px'}}
                        id="outlined-workspace"
                        label="Workspace Name"
                        min-width="400"
                        fullWidth
                        defaultValue={workspaceName}
                        variant="outlined"
                        onChange={(e)=>setWorkspaceName(e.target.value)}
                    />
                    <div style={{position:'relative', marginTop:'30px'}}>
                        <TextField
                            id="outlined-workspaceurl"
                            label="sprinthack.com/"
                            width="400"
                            helperText="Workspace URL"
                            fullWidth
                            defaultValue={namespace}
                            variant="outlined"
                            onChange={(e)=>setNamespace(e.target.value)}
                        />
                    </div>
                    <p style={{textAlign:'left', color:'#ff6d6d', height:'16px'}}>{errorMsg}</p>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.createSpaceButton}
                        disabled={namespace.length <3}
                        onClick={createNewWorkspace}
                    >
                        <span>Create workspace</span>
                    </Button>
                </div>
            </div>
        )};

    const createNewWorkspace = () => {
        setLoading(true);
        axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*' };
        console.log('log0',namespace)
        axios
            .get(BASEURL.api+'/workspaces/available?w='+namespace+'&id=200')
            .then((response) => {
                console.log('log1',response)
                if (!response.data.data.availability) {setErrorMsg(response.data.data.message); setLoading(false);}
                if (response.data.data.availability) {
                    /** all good, go and save the data namespace */
                    const user = auth().currentUser
                    db.collection('/workspaces')
                        .add({
                            workspaceName:workspaceName,
                            namespace:namespace,
                            owner:user.uid
                        })
                        .then((docRef)=>{
                            db.collection('/events')
                                .add({
                                    "type": 'action',
                                    "name": 'add_new_ws_member',
                                    "user": user.uid,
                                    "email": auth().currentUser.email,
                                    "workspace": docRef.id
                                })
                                .then(()=>{
                                    const workspaceMap = {}
                                    workspaceMap[docRef.id] = true
                                    db.collection('/users').doc(user.uid)
                                        .set({
                                            activeWorkspace:docRef.id,
                                            workspaces: workspaceMap
                                        },{merge:true})
                                        .then(()=>{
                                            history.replace('/welcome')
                                        }).catch((error)=>{
                                            console.log('error1',error)
                                            setErrorMsg('error')
                                            setLoading(false);
                                    });
                                }).catch((error)=>{
                                console.log('error2',error)
                                setErrorMsg('error')
                                setLoading(false);
                            })
                        }).catch((error)=>{
                        console.log('error2',error)
                        setErrorMsg('error')
                        setLoading(false);
                    });
                }
            })
            .catch((error) => {
                console.log('error3',error)
                setErrorMsg('error')
                setLoading(false);
            });
    }

    const checkUserWorkspace = async () => {

        const user = auth().currentUser
        if (!user) history.replace('/')

        /** get currentuser -> get doc with uid -> get activeWorkspace */
        // console.log('log0',user)

        db.doc(`/users/${user.uid}`).get()
            .then((doc)=>{
                /** get activeWorkspace */
                if (doc.exists && doc.data().activeWorkspace){
                    history.replace('/welcome')
                } else {
                    db.doc(`/join/${user.email}`)
                        .get()
                        .then(async (doc)=>{
                            let docData = doc.data()
                            if (!docData) docData = {}
                            const availableIdList = Object.keys(docData).map(key => key)
                            console.log('availableIdList',availableIdList)
                            let availableSpaces = []
                            if (availableIdList.length > 0){
                                const workspaces = await db.collection('workspaces')
                                    .where(firebaseCore.firestore.FieldPath.documentId(),'in', availableIdList)
                                    .get()
                                for(const doc of workspaces.docs){
                                    const spacesData = doc.data()
                                    spacesData.id = doc.id
                                    availableSpaces.push(spacesData)
                                }
                                console.log('workspaces',workspaces.docs)
                                setJoinSpaces(availableSpaces)
                                setLoading(false);
                            } else {
                                setLoading(false);
                            }
                        })
                        .catch(err=>{
                            console.log('err',err)
                            setLoading(false);
                        })
                }
            })
            .catch(err=>{
                console.log('err',err)
                setLoading(false);
            })
    }

    React.useLayoutEffect(() => {

        // Confirm the link is a sign-in with email link.
        if (auth().isSignInWithEmailLink(window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            var email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            auth().signInWithEmailLink(email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                    checkUserWorkspace()
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                    checkUserWorkspace()
                });
        } else {
            checkUserWorkspace()
        }

        return () => {}

    }, [])

    const joinheader = () => <JoinList rows={joinSpaces} showWelcome />
    const RenderJoinSpaces = () => {
        if (joinSpaces.length > 0) return (<EmptyPageInfoPrompt header={"Invitations to join"} body={joinheader()} />)
        return null
     }

    return (loading) ? (
        <LoadScreen/>
    ) : (
        <div style={{paddingTop:'0px', paddingLeft:'18px', paddingRight:'18px'}}>
            <div className={classes.toolbar} />
            <Link style={{position:'absolute', top:'20px', right:'25px'}} href="/logout">Back to login</Link>
            <div className={classes.animatedItemFade}>
                <RenderJoinSpaces/>
                <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
            </div>
        </div>
    )
}

