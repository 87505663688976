import {makeStyles, useTheme} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import AddIcon from "@material-ui/icons/Add";
import React from "react";


const useStyles = makeStyles((theme) => ({
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    tableIcon: {
        float:'left',
        margin:'2px 15px 0 0',
        fontSize:theme.custom.tableiconsize,
        color:'#64ccd9'
    },

}));

export default function ListHeader({title, icon, backgroundColor, color, addButtonClick, buttonType}) {
        const classes = useStyles();
        const theme = useTheme();

        return (
            <div className={classes.title} style={{backgroundColor:backgroundColor}}>
                {icon ? <PlayCircleOutlineIcon className={classes.tableIcon}/> : null} {title}
            </div>
        )
};