import React, { useCallback, useRef } from 'react';
import ReactFlow, {
    Background,
    useNodesState,
    useEdgesState,
    addEdge,
    useReactFlow,
    ReactFlowProvider
} from 'reactflow';

import 'reactflow/dist/style.css';
import '../../css/reactFlow.css'

const initialNodes_simple = [
    {
        id: '0',
        sourcePosition: 'right',
        type: 'input',
        data: { label: 'Logic flow' },
        position: { x: 0, y: 50 },
    }
];

const initialNodes = [
    {
        id: 'horizontal-1',
        sourcePosition: 'right',
        type: 'input',
        data: { label: 'User is logging in for a new sprint' },
        position: { x: 0, y: 50 },
    },
    {
        id: 'horizontal-2',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'A Node' },
        position: { x: 250, y: 0 },
    },
    {
        id: 'horizontal-3',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 3' },
        position: { x: 250, y: 160 },
    },
    {
        id: 'horizontal-4',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 4' },
        position: { x: 500, y: 0 },
    },
    {
        id: 'horizontal-5',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 5' },
        position: { x: 500, y: 100 },
    },
    {
        id: 'horizontal-6',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 6' },
        position: { x: 500, y: 230 },
    },
    {
        id: 'horizontal-7',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 7' },
        position: { x: 750, y: 50 },
    },
    {
        id: 'horizontal-8',
        sourcePosition: 'right',
        targetPosition: 'left',
        data: { label: 'Node 8' },
        position: { x: 750, y: 300 },
    },
];

const initialEdges = [
    {
        id: 'horizontal-e1-2',
        source: 'horizontal-1',
        type: 'smoothstep',
        target: 'horizontal-2',
        animated: true,
    },
    {
        id: 'horizontal-e1-3',
        source: 'horizontal-1',
        type: 'smoothstep',
        target: 'horizontal-3',
        animated: true,
    },
    {
        id: 'horizontal-e1-42',
        source: 'horizontal-2',
        type: 'smoothstep',
        target: 'horizontal-4',
        animated: true,
    },
    {
        id: 'horizontal-e1-4',
        source: 'horizontal-2',
        type: 'smoothstep',
        target: 'horizontal-5',
        animated: true,
    },
    {
        id: 'horizontal-e3-5',
        source: 'horizontal-3',
        type: 'smoothstep',
        target: 'horizontal-5',
        animated: true,
    },
    {
        id: 'horizontal-e3-6',
        source: 'horizontal-3',
        type: 'smoothstep',
        target: 'horizontal-6',
        animated: true,
    },
    {
        id: 'horizontal-e5-7',
        source: 'horizontal-5',
        type: 'smoothstep',
        target: 'horizontal-7',
        animated: true,
    },
    {
        id: 'horizontal-e6-8',
        source: 'horizontal-6',
        type: 'smoothstep',
        target: 'horizontal-8',
        animated: true,
    },
];

let id = 1;
const getId = () => `${id++}`;

const fitViewOptions = {
    padding: 3,
};
const HorizontalFlow = () => {
    const reactFlowWrapper = useRef(null);
    const connectingNodeId = useRef(null);
    const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes_simple);
    const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
    const { project } = useReactFlow();
    const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);

    const onConnectStart = useCallback((_, { nodeId }) => {
        connectingNodeId.current = nodeId;
    }, []);

    const onConnectEnd = useCallback(
        (event) => {
            const targetIsPane = event.target.classList.contains('react-flow__pane');

            if (targetIsPane) {
                // we need to remove the wrapper bounds, in order to get the correct position
                const { top, left } = reactFlowWrapper.current.getBoundingClientRect();
                const id = getId();
                const newNode = {
                    id,
                    sourcePosition: 'right',
                    targetPosition: 'left',
                    // we are removing the half of the node width (75) to center the new node
                    position: project({ x: event.clientX - left, y: event.clientY - top - 10 }),
                    data: { label: `Node ${id}` },
                };

                setNodes((nds) => nds.concat(newNode));
                setEdges((eds) => eds.concat({
                    id,
                    source: connectingNodeId.current,
                    type: 'smoothstep',
                    target: id,
                    animated: true
                }));
            }
        },
        [project, setEdges, setNodes]
    );

    /** load nodes from db */



    /**
     * This example demonstrates how you can remove the attribution from the React Flow renderer.
     * Please only hide the attribution if you are subscribed to React Flow Pro: https://pro.reactflow.dev
     */
    const proOptions = { hideAttribution: true };

    return (
        <div className="wrapper" ref={reactFlowWrapper}>
            <ReactFlow
                nodes={nodes}
                edges={edges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                onConnect={onConnect}
                onConnectStart={onConnectStart}
                onConnectEnd={onConnectEnd}
                fitView
                fitViewOptions={fitViewOptions}
                proOptions={proOptions}
            >
                <Background />
            </ReactFlow>
        </div>
    );
};

export default () => (
    <ReactFlowProvider>
        <HorizontalFlow />
    </ReactFlowProvider>
);