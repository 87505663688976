import React from 'react';
import clsx from 'clsx'
import Header from '../components/landing/layout/Header'
import Footer from '../components/landing/layout/Footer'
import '../css/blogpage.css'
import {makeStyles} from "@material-ui/core/styles";
import Blogarticle from "./blogarticle";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    mainBackground:{
        // backgroundColor:'#060606'
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

export default function Blog() {
    const classes = useStyles();
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const background_light = (prefersDarkMode) ? '' : 'background-light';

    return<div>
        <Header/>
        <div className={clsx(classes.animatedItemFade, classes.mainBackground)}>
            <div className={'pagewrap '+background_light}>
                <div className={'pagetitle'}>
                    <div className={'sidespace'}></div>
                    <div className={'blog-header '+background_light}>
                        <h1>
                            Solution Analysis research
                        </h1>
                        <p>
                            Connecting teams and developers to create solutions efficiently and with less stress. <br/>We call it Solution Analysis.
                        </p>
                    </div>

                </div>
                <div className={'bloglist-wrap'}>
                    <hr/>
                    <div className={'blogarticle'}>
                        <div className={'sidespace'}>
                            <div>1 December 2022</div>
                            <div>Podcast</div>
                        </div>
                        <div className={'main'}>
                            <h3 className={'blogtitle'}><a href={'/blog/sprinthack_ep3'}>Sprinthack EP3 - Jonas Bjurhult, Safeture</a></h3>
                            <div className={'blogcontent'}>
                                <div className={'contentwrap'}>
                                    <p>
                                        What kind of challenges a public company has in their product development and transformation?
                                    </p>
                                    <p>
                                        Jonas Bjurhult from <a href={'http://www.safeture.com'}>Safeture</a>
                                    </p>
                                    <li>How the product operations organise information from customers to dev epics?</li>
                                    <li>How the product feature is estimated?</li>
                                    <li>What is the relevance of technical knowledge in the product team?</li>
                                    <p></p>
                                </div>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/2-ttKPwnLsE"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className={'blogarticle'}>
                        <div className={'sidespace'}>
                            <div>2 November 2022</div>
                            <div>Podcast</div>
                        </div>
                        <div className={'main'}>
                            <h3 className={'blogtitle'}><a href={'/blog/sprinthack_ep2'}>Sprinthack EP2 - Magnus Hultman, Safeture</a></h3>
                            <div className={'blogcontent'}>
                                <div className={'contentwrap'}>
                                    <p>
                                        What kind of challenges a public company has in their transformation to new business model?
                                    </p>
                                    <p>
                                        Magnus Hultman from <a href={'http://www.safeture.com'}>Safeture</a>
                                    </p>
                                    <li>How the company switched from project based service to SaaS product?</li>
                                    <li>How the product leadership is done in this new product organisation?</li>
                                    <li>What are the product team responsibilities?</li>
                                    <p></p>
                                </div>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/6c1JqFF8QlM"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className={'blogarticle'}>
                        <div className={'sidespace'}>
                            <div>4 October 2022</div>
                            <div>Podcast</div>
                        </div>
                        <div className={'main'}>
                            <h3 className={'blogtitle'}><a href={'/blog/sprinthack_ep1'}>Sprinthack EP1 - Otto Hilska, Swarmia</a></h3>
                            <div className={'blogcontent'}>
                                <div className={'contentwrap'}>
                                    <p>
                                        Interesting episode coming up with <a href={'http://www.swarmia.com'}>Swarmia</a> CEO & Founder Otto Hilska. In the first episode of Sprinthack we talk about:
                                    </p>
                                    <li>What is good for developer life in product teams?</li>
                                    <li>How should you work daily in development?</li>
                                    <li>How do the business and dev teams communicate about features?</li>
                                    <p></p>
                                </div>
                                <iframe width="560" height="315" src="https://www.youtube.com/embed/bOWZE0oSPDw"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen>
                                </iframe>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

}
