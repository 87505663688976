import React, {useLayoutEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import EmptyPageInfoPrompt from "../components/emptyPageInfoPrompt";
import JoinList from "../components/joinList";
import {auth, db, firebaseCore} from "../util/firebase";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

});

export default function AccessDenied() {
    const classes = useStyles();
    let history = useHistory();
    const [loading, setLoading] = useState(true)
    const [availableWorkspaces, setAvailableWorkspaces] = useState([])

    useLayoutEffect(()=>{
        if (auth().currentUser){
            db.collection("join")
                .doc(auth().currentUser.email)
                .get()
                .then((doc)=>{
                    let docData = doc.data()
                    if (!docData) docData = {}
                    const availableIdList = Object.keys(docData).map(key => key)

                    db.collection("rejoin")
                        .doc(auth().currentUser.uid)
                        .get()
                        .then((doc2)=>{
                            let docData2 = doc2.data()
                            if (!docData2) docData2 = {}
                            const availableIdList2 = Object.keys(docData2).map(key => key)
                            const concatAvailableList = availableIdList.concat(availableIdList2)

                            db.collection("users")
                                .doc(auth().currentUser.uid)
                                .get()
                                .then((user) =>{
                                    const userdata = user.data()
                                    console.log('userdata.workspaces',userdata)
                                    let listOfAvailableSpaces = Object.keys(userdata.workspaces).map(spaceId=>{
                                        if (userdata.workspaces[spaceId]) return spaceId
                                    })

                                    console.log('concatAvailableList',concatAvailableList)
                                    console.log('listOfAvailableSpaces',listOfAvailableSpaces)

                                    const concatAvailableListWithActive = concatAvailableList.concat(listOfAvailableSpaces)
                                    console.log('concatAvailableListWithActive',concatAvailableListWithActive)
                                    if (concatAvailableListWithActive.length > 0){
                                        db.collection('workspaces')
                                            .where(firebaseCore.firestore.FieldPath.documentId(),'in', concatAvailableListWithActive)
                                            .get()
                                            .then((workspaces) => {
                                                let availableSpaces = []
                                                workspaces.forEach((doc) => {
                                                    const spacesData = doc.data()
                                                    spacesData.id = doc.id
                                                    availableSpaces.push(spacesData)
                                                })
                                                setLoading(false)
                                                setAvailableWorkspaces(availableSpaces)
                                            }).catch(err=>{
                                            console.log('err',err);
                                        })
                                    } else {setLoading(false)}
                                })
                        })
                })
                .catch(error=>{
                    console.log('data join err',error)

                })
        } else {
            setLoading(false)
        }

    }, [])

    const pagecontext = 'Unauthorized';
    const promptheader = 'You need access to this workspace';
    const promptbody = () => {
        return (
            <div>
                <p>
                    Please contact the workspace admin to gain access
                </p>
                <p>
                    <Link href="/">
                        Back to home
                    </Link>
                </p>
            </div>
        )};

    const WorkspaceAvailableList = () => {
        if (loading) return null
        return (
            <div>
                <Typography variant="h5" component="h2" style={{textAlign:'center'}}>
                    Workspaces you have access to
                </Typography>
                <JoinList rows={availableWorkspaces} />
            </div>
        )
    }

    return (
        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
            <div className={classes.toolbar} />
            <div>
                <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
            </div>
            <div>
                <EmptyPageInfoPrompt body={<WorkspaceAvailableList/>} />
            </div>
        </div>
    );
}

