import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import CircularProgress from '@material-ui/core/CircularProgress';
import MuiAlert from "@material-ui/lab/Alert";
import Snackbar from "@material-ui/core/Snackbar";

import {auth, signInWithGoogle} from '../util/firebase'

class Signup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            confirmPassword: '',
            errors: [],
            loading: false,
            errorOpen: false,
            templinkHelp:false
        };
    }

    // UNSAFE_componentWillReceiveProps(nextProps) {
    //     if (nextProps.UI.errors) {
    //         this.setState({
    //             errors: nextProps.UI.errors
    //         });
    //     }
    // }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });
        // const newUserData = {
        //     email: this.state.email,
        //     password: this.state.password,
        //     confirmPassword: this.state.confirmPassword
        // };
        // axios
        //     .post(BASEURL.api+'/signup', newUserData)
        //     .then((response) => {
        //         // localStorage.setItem('AuthToken', `${response.data.token}`);
        //         this.setState({
        //             loading: false,
        //         });
        //         this.props.history.push('/');
        //     })
        //     .catch((error) => {
        //         console.log('error',error.response.data)
        //         this.setState({
        //             errors: error.response.data.message,
        //             loading: false,
        //             errorOpen: true
        //
        //         });
        //     });

        var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: 'https://sprinthack.com/finishsetup',
            // This must be true.
            handleCodeInApp: true
        };
        auth().sendSignInLinkToEmail(this.state.email, actionCodeSettings)
            .then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', this.state.email);
                this.setState({
                    templinkHelp:true
                })
                // ...
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                this.setState({
                    errors: error.message,
                    loading: false,
                    errorOpen: true
                })
            });
    };

    handleGoogleSignin = () => {
        signInWithGoogle()
            .then(()=>{
                this.props.history.push('/finishsetup');}
            )
            .catch(

            )
    }

    render() {
        const { classes } = this.props;
        const { errors, loading, errorOpen } = this.state;
        const handleError = () => this.setState({errorOpen: !this.state.errorOpen});
        const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

        return (!this.state.templinkHelp) ? (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Create your Sprinthack account
                    </Typography>
                    <div style={{padding: "40px 0"}}>
                        <div className="login-buttons">
                            <button className={classes.loginProviderButton} onClick={this.handleGoogleSignin}>
                                <img style={{height:'15px', margin:'0 15px'}} src="https://img.icons8.com/ios-filled/50/ffffff/google-logo.png" alt="google icon"/>
                                <span> Continue with Google</span>
                            </button>
                        </div>
                    </div>
                    <form className={classes.form} noValidate>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    id="email"
                                    label="Enter your email address..."
                                    name="email"
                                    autoComplete="email"
                                    error={!!errors.message}
                                    onChange={this.handleChange}
                                />
                            </Grid>

                            {/*<Grid item xs={12}>*/}
                            {/*    <TextField*/}
                            {/*        variant="outlined"*/}
                            {/*        required*/}
                            {/*        fullWidth*/}
                            {/*        name="password"*/}
                            {/*        label="Password"*/}
                            {/*        type="password"*/}
                            {/*        id="password"*/}
                            {/*        autoComplete="current-password"*/}
                            {/*        helperText={errors.password}*/}
                            {/*        error={errors.password ? true : false}*/}
                            {/*        onChange={this.handleChange}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                            {/*<Grid item xs={12}>*/}
                            {/*    <TextField*/}
                            {/*        variant="outlined"*/}
                            {/*        required*/}
                            {/*        fullWidth*/}
                            {/*        name="confirmPassword"*/}
                            {/*        label="Confirm Password"*/}
                            {/*        type="password"*/}
                            {/*        id="confirmPassword"*/}
                            {/*        autoComplete="current-password"*/}
                            {/*        onChange={this.handleChange}*/}
                            {/*    />*/}
                            {/*</Grid>*/}
                        </Grid>
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleSubmit}
                            disabled={loading ||
                            !this.state.email}
                        >
                            Sign Up
                            {loading && <CircularProgress size={30} className={classes.progess} />}
                        </Button>
                        <Grid container justify="flex-end">
                            <Grid item>
                                <Link href="login" variant="body2">
                                    Already have an account? Log in
                                </Link>
                            </Grid>
                        </Grid>
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={errorOpen}
                            onClose={handleError}
                            message={<Alert severity="error">{errors}</Alert>}
                            key={'123'}
                        />
                        {errors && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors}
                            </Typography>

                        )}
                    </form>
                </div>
            </Container>
        ) : (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Check your email
                    </Typography>
                    <p>
                        We've sent a temporary login link.
                    </p>
                    <p>
                        Please check your inbox at <span className={classes.bold}>{this.state.email}</span>
                    </p>
                </div>
            </Container>
        )
    }
}

const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(3)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#1595a5",

        "&:hover":{
            backgroundColor: "#19bed2"
        }
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    },
    progess: {
        position: 'absolute'
    },
    loginProviderButton: {
        "height":"48px",
        "fontSize":"14px",
        "color":"#fff",
        "background":"#1595a5",
        "width":"336px",
        "display":"inline-flex",
        "WebkitBoxAlign":"center",
        "alignItems":"center",
        "WebkitBoxPack":"center",
        "justifyContent":"center",
        "whiteSpace":"nowrap",
        "borderRadius":"4px",
        "flexShrink":"0",
        "margin":"0px",
        "fontWeight":"500",
        "minWidth":"32px",
        "padding":"0px 21px",
        "border":"none",

        "&:hover":{
            "background":"#19bed2",

        }
    },
    bold:{
        fontWeight:700
    }
});

export default withStyles(styles)(Signup);
