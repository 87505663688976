import {types} from 'mobx-state-tree'

import {User} from "./user"
import {CycleModel} from "./Cycle";


export const Workspace = types.model({
    members: types.map(User),
    cycles: types.map(CycleModel),
    name: types.string
}).actions(self=>{
    return {
    }
})
