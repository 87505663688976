import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {db, auth, firebaseFunctions} from "../../util/firebase";
import dayjs from "dayjs";
import TimelineItem from "@material-ui/lab/TimelineItem";
import TimelineSeparator from "@material-ui/lab/TimelineSeparator";
import TimelineConnector from "@material-ui/lab/TimelineConnector";
import TimelineContent from "@material-ui/lab/TimelineContent";
import Paper from "@material-ui/core/Paper";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import ReplyIcon from '@material-ui/icons/Reply';
import SmsIcon from '@material-ui/icons/Sms';
import {Menu, TextareaAutosize} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import axios from "axios";
import BASEURL from "../../constants/baseurl";
import SlowLoad from "../slowLoad";
import IconButton from "@material-ui/core/IconButton";
import {Clear, MoreHoriz} from "@material-ui/icons";
import Fade from "@material-ui/core/Fade";
import GeneralDropdown from "../../util/generalDropdown";
import MoreMenuDropdown from "../../util/moreMenuDropdown";

const useStyles = makeStyles((theme) => ({
    timeline_author:{
        fontWeight: 500
    },
    timeline_action: {
        color:theme.custom.subtitle

    },
    timeline_createdAt: {
        color:theme.custom.subtitle

    },
    timeline_question:{
        fontWeight: 500,
        color:theme.custom.timelineQuestion
    },
    timeline_connector:{
        backgroundColor:theme.custom.timelineConnector
    },
    timeline_icon:{
        margin:'5px 0',
        fontSize:'16px'
    },
    largeTimeNumber: {
        fontSize: '20px'
    },
    timeline_comment: {
        position: 'relative',
        margin: '10px 0',
        "boxShadow":"rgb(0 0 0 / 9%) 0px 3px 12px",
        "borderRadius":"4px",
        "padding":"12px 16px",
        whiteSpace:'pre-line',

        "&:hover":{
            "& $taskCardActionButton":{
                display:'block'
            }
        }
    },
    textareaStyle: {
        padding: '10px',
        minWidth: '260px',
        width: 'calc(100% - 35px)'
    },
    submitButton: {
        background:theme.custom.MainButton,
        marginRight:'10px',
        "&:hover":{background:theme.custom.MainButtonHover}
    },
    copilotButtonComment: {
        marginTop:'5px',
        color:theme.custom.MainButton,
        borderColor:theme.custom.MainButton,
        marginRight:'10px',
    },
    textWrap:{
        whiteSpace: 'pre-line'
    },
    taskCardActionButton: {
        display: 'none',
        position: 'absolute',
        right: 0,
        top:0
    },
    listItem: {
        width: 'auto',
        overflow: 'hidden',
        fontSize: '0.75rem',
        minHeight:'auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight:'1.5',
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        paddingRight:'10px',
        cursor:'pointer',

        '&:hover':{
            background:'rgba(0, 0, 0, 0.04)'
        }
    }

}))

const menuItems_full = [
    {display:'Edit'},
    {display:'Delete'}
]

export default function TeamActivityTimeline({userData, payload, placeholder, showCopilot, readOnly, teamsActivity}) {
    const classes = useStyles()
    const [activity, setActivity] = useState([])
    const [commentFocus, setCommentFocus] = useState(false);
    const [commentInput, setCommentInput] = useState('')
    const [loading, setLoading] = useState(true)

    useEffect(()=>{
        /** note: comments database table has multiple types of activity
         * - type: comment
         * - type: bot_activity
         * - type: user_activity
         * - type: user_update_doc
         */

        if (readOnly){
            // console.log('readonly activity',payload.activity)
            setActivity(payload.activity)
        }

        if (payload.createdAt && !readOnly) {
            const unsubscribe_comments = db
                .collection('team_activity')
                .where('team', '==', payload.id)
                .orderBy('createdAt', 'asc')
                .onSnapshot((querySnapshot) => {
                    db.collection('comments')
                        .where('contextId','==',payload.id)
                        .orderBy('createdAt', 'asc')
                        .onSnapshot((querySnapshot2) => {
                            let itemType = ""
                            let allActivity = [{type:'created', action: `${itemType} created`, createdAt:payload.createdAt}];
                            const activity_data1 = querySnapshot.docs.map(doc => ({
                                id:doc.id,
                                ...doc.data()
                            }))
                            const activity_data2 = querySnapshot2.docs.map(doc => ({
                                id:doc.id,
                                ...doc.data()
                            }))

                            allActivity = [...allActivity, ...activity_data1, ...activity_data2]

                            setActivity(allActivity)
                            setLoading(false)
                        });
                });

            return function cleanup(){
                unsubscribe_comments()
            }
        }
    }, [payload, readOnly])

    const getSortedArrbyKey = (arr, key1, key2) => {
        if (!arr) return [];
        return arr.sort(function(a, b) {
            return b[key1] - a[key2] || b[key2] - a[key2];
        });
    };

    const TimeLineContentData = ({activity, timesince}) => {
        // console.log('activity::',activity)
        const author = (activity.author && typeof activity.author === 'string') ? activity.author : 'Someone'
        if (activity.type === 'comment'){
            return (
                <div className={classes.textWrap}>
                    <div style={{fontSize:'12px'}}><span className={classes.timeline_author}>{author}</span> <span className={classes.timeline_action}>{activity.action}.</span> <span className={classes.timeline_createdAt}>{timesince}</span>  </div>
                    {(activity.text) ? <Paper className={classes.timeline_comment} elevation={3}>
                        {activity.text}
                        {activity.AI_created || activity.authorRef === auth().currentUser.uid ? <div className={classes.taskCardActionButton}>
                            <MoreMenuDropdown contextId={activity.id} list={menuItems_full} selected={handleCommentMenuClick}/>
                        </div> : <></>}
                    </Paper> :  <></>}
                </div>
            )
        }
        if (activity.type === 'user_update_doc' || activity.type === 'Task update'){
            return (
                <div className={classes.textWrap}>
                    <div style={{fontSize:'12px'}}><span className={classes.timeline_author}>{author}</span> <span className={classes.timeline_action}>{activity.action}</span> with <span className={classes.timeline_action}>{activity.role} role.</span> <span className={classes.timeline_createdAt}>{timesince}</span>  </div>
                </div>
            )
        }

        if (activity.type === 'user_activity'){
            return (
                <div className={classes.textWrap}>
                    <div style={{fontSize:'12px'}}><span className={classes.timeline_author}>{author}</span> <span className={classes.timeline_action}>{activity.action}</span> <span className={classes.timeline_action}>{activity.text}.</span> <span className={classes.timeline_createdAt}>{timesince}</span>  </div>
                </div>
            )
        }
        if (activity.type === 'general_activity'){
            return (
                <div className={classes.textWrap}>
                    <div style={{fontSize:'12px'}}><span className={classes.timeline_action}>{activity.action}.</span> <span className={classes.timeline_createdAt}>{timesince}</span>  </div>
                </div>
            )
        }
        if (activity.type === 'created'){
            return (
                <div className={classes.textWrap}>
                    <div style={{fontSize:'12px'}}><span className={classes.timeline_action}>{activity.action}</span><span className={classes.timeline_createdAt}>{timesince}</span>  </div>
                </div>
            )
        }

        return false

    }

    const handleCommentMenuClick = (payload) => {
        if (payload.index === 1){
            /** we handle delete */
            db.collection('comments')
                .doc(payload.contextId)
                .delete()
                .then(()=>{
                    console.log('delete ok')
                })
                .catch(error=>{
                    console.log('error in comment delete',error)
                })
        }
    }

    const handleCommentFocus = () => {
        setCommentFocus((prev) => !prev);
    }

    const handleCommentSave = (commentSource, copilotMessage) => {
        const postcomment = firebaseFunctions.httpsCallable('comments_api-comments-postComment')
        if (commentSource === 'input') {
            /** this will set sync code first, which will trigger useEffect function to send the data to backend async. */
            setActivity([...activity, {type:'comment', action: 'commented', text:commentInput, author: userData.username || userData.email}]);
            setCommentInput('')

            if (commentInput !== '') {
                return postcomment({author:userData.username || userData.email, text: commentInput, contextId:payload.requestId || payload.id})
            }
        }
        if (commentSource === 'copilot') {
            console.log('copilot trigger')
            if (copilotMessage !== '') {
                return postcomment({author:"Copilot", AI_created:true, type:"bot_activity", text: copilotMessage, contextId:payload.requestId || payload.id})
            }
        }
    }

    const ActivityTimeline = () => {
        /** move to own react function and manage the render state there. */
        const sortedActivity = getSortedArrbyKey(activity, 'createdAt');
        // console.log('sortedActivity',sortedActivity);
        const availableActivities = [
            "created","comment","bot_activity","user_activity","feedback", "general_activity", "user_update_doc", "Task update"
        ]

        return sortedActivity.map((activity, index) => {
            // console.log('activity',activity);
            if (availableActivities.indexOf(activity.type) < 0 ) return null

            const now = dayjs()
            const diff_days = now.diff(activity.createdAt, 'day')
            const diff_hours = now.diff(activity.createdAt, 'hour')
            const diff_minutes = now.diff(activity.createdAt, 'minute')
            let ago_text = `${diff_days} days ago.`;
            if (diff_days > 30) ago_text = dayjs(activity.createdAt).format('MMM DD, YYYY, h:mm:ss A')
            if (diff_days < 1) ago_text = `${diff_hours} hours ago.`;
            if (diff_hours < 1) ago_text = `${diff_minutes} minutes ago.`;
            const time_since_activity_created = '  '+ ago_text;

            /** timeline icons */
            let timelineIcon = <RadioButtonUncheckedIcon className={classes.timeline_icon} />
            if (activity.type === 'feedback') timelineIcon = <ReplyIcon  className={classes.timeline_icon}/>
            if (activity.type === 'comment') timelineIcon = <SmsIcon  className={classes.timeline_icon}/>

            return <TimelineItem key={index}>
                <TimelineSeparator>
                    {timelineIcon}
                    <TimelineConnector className={classes.timeline_connector} />
                </TimelineSeparator>
                <TimelineContent>
                    <TimeLineContentData activity={activity} timesince={time_since_activity_created} />
                </TimelineContent>
            </TimelineItem>

            }
        );
    }

    return loading ? null :
        <div>
            <ActivityTimeline/>
            {(!readOnly) ? (
            <div style={{paddingLeft:'35px'}}>
                <div>
                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={1}
                        className={classes.textareaStyle}
                        placeholder= {placeholder || "Write a comment..."}
                        value={commentInput}
                        onChange={(e) => {setCommentInput(e.target.value)}}
                        onFocus={handleCommentFocus}
                        onBlur={handleCommentFocus}
                    />
                </div>
                <div style={{marginBottom:'5px'}}>
                    <Collapse in={commentFocus}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            size="small"
                            className={clsx(classes.submitButton)}
                            onClick={()=>handleCommentSave('input')}
                        >Comment</Button>
                    </Collapse>
                </div>
            </div>
            ) : null }
        </div>
}
