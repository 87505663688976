import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Chip from "@material-ui/core/Chip";
import dayjs from "dayjs";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ChevronRight from "@material-ui/icons/ChevronRight";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRow:{
        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "55px",
        padding:'0px 0px 0px 0px'
    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        }
    },
    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

}));

export default function IterationsList({row}) {
    const classes = useStyles();

    return (
        <div>
            <TableContainer>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableBody>
                        {row.iterations && row.iterations.map((doc, index) => {
                            return (
                                <TableRow className={classes.tableRow} style={{height:'44px'}} key={index} hover onClick={() => {}}>
                                    <TableCell align="left" style={{ width: "1px", paddingRight:'10px'}}><CheckCircleIcon className={classes.tableIcon} /></TableCell>
                                    <TableCell align="left" className={classes.dateColumn}>{dayjs(doc.createdAt).format('MMM DD')}</TableCell>
                                    <TableCell align="left" scope="row" className={classes.rowText}>
                                        <div className={classes.rowTitle}>{doc.name} <ChevronRight className={classes.menuIcon} /></div>
                                    </TableCell>
                                </TableRow>
                            )
                        } )}
                    </TableBody>
                </Table>
            </TableContainer>

        </div>
    )
}
