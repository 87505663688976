import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {TextareaAutosize} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    freeTextContent: {
        color:theme.custom.genericLongformText
    },
    valueRow: {
        minWidth:'auto',
    },
    textareaStyleTransparent:{
        fontSize:'22px',
        fontWeight:500,
        fontFamily:'Inter,Roboto,Helvetica,Arial,sans-serif',
        border:'1px solid transparent',
        backgroundColor:"transparent",
        color: theme.custom.genericLongformText,
        resize:'none',
        overflow:'auto',
        outline:'none',
        "WebkitBoxShadow":"none",
        "MozBoxShadow":"none",
        "boxShadow":"none",
        minWidth:'350px',
        width:'100%',
        padding:'10px',
        marginTop:'0px',
        marginLeft:'-10px',
        borderRadius:'5px',
        "&:focus":{
            border:'1px solid rgb(22, 138, 156)'
        }

    },
    hide: {
        display:'none !important'
    },

}));


export default function DetailHeaderEditor({payload, save}) {

    const classes = useStyles();
    const [displayData, setDisplayData] = useState({})
    const [displayEdit, setDisplayEdit] = useState(false);
    const TextInputRef = useRef(null);

    /** should really create a custom hook for this... */
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;

    useEffect(()=>{
        setDisplayData(payload)
    }, [payload])

    const toggleHide = () => {
        setDisplayEdit(!displayEdit)

        setTimeout(() => {
            TextInputRef.current.focus()
        }, 100);

    }

    const handleSave = () => {
        save(displayData)
    }

    const handleKeyUp = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault()
            handleSave()
            return false
        }
    }

    return (
        <>
            <div className={clsx(classes.valueRow, {})}>
                <TextareaAutosize
                    ref={TextInputRef}
                    className={clsx(classes.textareaStyleTransparent)}
                    placeholder={payload.placeholder}
                    value={displayData.value}
                    onChange={(e)=>{setDisplayData({...displayData, ...{value: e.target.value}})}}
                    onKeyDown={(e) => handleKeyUp(e)}
                    onBlur={handleSave}
                />
            </div>
        </>

    )
}
