import React, {useEffect } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Velocity_map_light from '../../../img/velocity_map.png'
import Velocity_map_dark from '../../../img/velocity_map_dark.png'
import '../../../css/general.css'
import '../../../css/hero.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HeroIMG from "../../../img/undraw_Business_decisions.png";
import HeroIMG_white from "../../../img/midjourney_SPH_white_text.png";
import HeroIMG_dark from "../../../img/midjourney_SPH_dark_text.png";
import SlowLoad from "../../slowLoad";

const useStyles = makeStyles((theme) => ({
    textpop:{
        color:'#ffffff',
        backgroundColor:'rgb(6, 141, 153)',
        padding: '10px 5px',
        margin:'15px 5px',
        borderRadius:'10px',
        maxWidth:'200px'
    },
    [theme.breakpoints.down("xs")]: {
        container:{
            maxWidth:'600px'
        }
    },
    heroContainer:{
        display:'flex'
    }

}));

const Hero = ({
                  ...props
              }) => {

    const classes = useStyles();
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const velocity_map = (prefersDarkMode) ? Velocity_map_dark : Velocity_map_light;
    const text_color_light = (prefersDarkMode) ? '' : 'text_color_light';
    const heroImage = (prefersDarkMode) ? HeroIMG_dark : HeroIMG_white;

    useEffect(() => {
        document.title = "Sprinthack - Connect the dots"
        document.description = "Sprinthack is a community for to have a stress free product development"
    }, []);

    return (
        <section>
            <div className={'container'}>
                <div className={'maingrid'}>
                    <div className={'gridwrap'}>
                        <div className={'grid-hero'}>
                            <h1>
                                {/*Fall in love with the problem, not the solution*/}
                                Unlock Evidence-guided Company
                            </h1>
                            <div style={{marginLeft: 'calc(50% - 20px)'}}><SlowLoad  /></div>
                            <div className={'heroSubtitleText '+text_color_light}>
                                <p>
                                    Hey CEO 👋, to unlock productivity boost focus on evidence-guided process.
                                </p>
                                <p>
                                    We enable that capability for companies.
                                </p>

                                {/*<div className={'featurebox'}>*/}
                                {/*    <div className={'featureboxcolumn'}>*/}
                                {/*        <p className={'textpop dot-color-rose'}>Blockchain based contribution tool</p>*/}
                                {/*        <p className={'textpop dot-color-purple'}>Jobs-to-be-done coaching</p>*/}
                                {/*        <p className={'textpop dot-color-light_blue'}>Impact measuring with an AI</p>*/}
                                {/*    </div>*/}
                                {/*    /!*<div className={'featureboxcolumn'}>*!/*/}
                                {/*    /!*    <p className={'textpop dot-color-light_blue'}>Interim Roles Service</p>*!/*/}
                                {/*    /!*    <p className={'textpop dot-color-cyan'}>Development Cost Control</p>*!/*/}
                                {/*    /!*    <p className={'textpop dot-color-pinky'}>Curated Tech Advisory Board</p>*!/*/}
                                {/*    /!**!/*/}
                                {/*    /!*</div>*!/*/}

                                {/*</div>*/}

                                <div>
                                    {/*<a className={'button button-red'} href={'/login'}>*/}
                                    {/*    Get started*/}
                                    {/*</a>*/}
                                </div>
                                <div>
                                    <a className={'button'} href={'https://sprinthack.substack.com'}>
                                        Read Free Published Reports
                                    </a>
                                </div>

                                {/*<a className={'button button-red'} href={'https://form.jotform.com/201062132467041'}>*/}
                                {/*    Book a 30min intro*/}
                                {/*</a>*/}
                            </div>

                            {/*<h3 className={'padding-top-20'}>Starting at only €190/mo</h3>*/}
                        </div>
                        <div className={'grid-img'}>
                            <img style={{'borderRadius':'20px'}} alt={'hero'} src={heroImage} width="540" height="540" />
                        </div>
                    </div>
                </div>

{/*
                <div className={'heroSubtitleText '+text_color_light}>
                    <p>
                        1. Product operations for smooth impactful discovery and integration<br/>
                        2. On-demand technical development to enable your growth.<br/>
                        3. Upfront cash flow to fund your growth.
                    </p>
                    <p className={'small-quote'}>
                        What you get, and should aim for, is a smooth constant completion of tasks.
                    </p>
                    <img alt={"velocity_map"} src={velocity_map} />
                </div>
*/}

            </div>
            <div className={'footerspace'} />
        </section>
    );
}

export default Hero;