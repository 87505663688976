import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {TextareaAutosize, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {db} from "../../util/firebase";

const useStyles = makeStyles((theme) => ({
    freeTextContent: {
        color:theme.custom.genericLongformText
    },
    valueRow: {
        minWidth:'auto',
    },
    textareaStyleTransparent:{
        fontSize:'22px',
        fontWeight:700,
        fontFamily:'Inter,Roboto,Helvetica,Arial,sans-serif',
        border:'1px solid transparent',
        backgroundColor:"transparent",
        color: theme.custom.genericLongformText,
        resize:'none',
        overflow:'auto',
        outline:'none',
        "WebkitBoxShadow":"none",
        "MozBoxShadow":"none",
        "boxShadow":"none",
        minWidth:'350px',
        width:'100%',
        padding:'10px',
        marginTop:'0px',
        marginLeft:'-8px',
        borderRadius:'5px',
        "&:focus":{
            border:'1px solid rgb(22, 138, 156)'
        }

    },
    hide: {
        display:'none !important'
    },
    input:{
        fontSize:'22px',
        fontWeight:700,

    }

}));


export default function BoardHeaderEditor({payload, save}) {

    const classes = useStyles();
    const [displayData, setDisplayData] = useState({})
    const TextInputRef = useRef(null);

    useEffect(()=>{
        setDisplayData(payload)
    }, [payload])

    const handleKeyPress = (e) => {
        if (e.keyCode === 13 && !e.shiftKey) {
            e.preventDefault()
            handleSave()
            setTimeout(() => {
                TextInputRef.current.blur()
            }, 100);

        }
    }

    const handleSave = () => {
        save(displayData)
        // db.collection('features_confirmed/'+featureData.id+'/listName')
        //     .update(newObject)
        //     .then()

    }

    return (
        <div className={clsx(classes.valueRow, {})}>
            <TextareaAutosize
                ref={TextInputRef}
                // inputProps={{className:classes.input}}
                onKeyDown={handleKeyPress}
                className={clsx(classes.textareaStyleTransparent)}
                placeholder={payload.placeholder}
                value={displayData.value}
                onChange={(e)=>{setDisplayData({...displayData, ...{value: e.target.value}})}}
                onBlur={handleSave}
            />
        </div>
    )
}
