export const HOME = '/';
export const COMMUNITY = '/community';
export const DASHBOARD = '/home';
export const ROLE_REPORT = '/log/:team/:role'
export const FEEDBACK = '/f/:hash'
export const SHARE = '/s/:hash'
export const REPORT = '/report/:hash'
export const TEMPLATE = '/t/:hash';
export const ERROR = '/error';
export const SIGN_UP = '/signup';
export const LOG_IN = '/login';
export const LOG_OUT = '/logout';
export const PRICING_HOMEPAGE = '/pricing';
export const SETUP = '/finishsetup';
export const JOIN = '/join';
export const DENIED = '/denied';
export const CREATE_WS = '/create';
export const SPRINTS = '/sprints';

export const WORKSPACE = '/:workspace';
export const PROFILE = '/home/settings/profile';

export const WELCOME = '/welcome';
export const BOARD = '/w/b';
export const PLAYBOOK = '/:workspace/playbook';
export const FEATURES = '/:workspace/features';
export const IDEAS = '/:workspace/ideas';
export const IDEA_DETAILS = '/task/:ideaId';
export const FEATURE_DETAILS = '/feature/:featureId';
export const PROJECT_DETAILS = '/project/:projectId';
export const PROJECT_LIST_TASKS = '/tasks/:selectedParam';
export const SPRINT_DETAILS = '/sprint/:sprintId';
export const VALIDATION_FEEDBACK_DETAILS = '/validation_feedback/:feedbackId';
export const CYCLES = '/:workspace/cycles';
export const CYCLES_REQUEST = '/:workspace/cycles/request/:requestId';
export const CYCLES_REQUEST_IN_CYCLES = '/request/:requestId';
export const CYCLE_DETAILS = '/cycle/:cycleId';
export const ISSUE_DETAILS = '/issue/:issueId';
export const SUBTASK_DETAILS = '/subtask/:subtaskId';
export const REQUEST_CHAT_DETAILS = '/:requestChatId';
export const FUNDING = '/funding';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const PASSWORD_FORGET = '/pw-forget';
export const BLOG = '/blog';
export const BLOGARTICLE = '/blog/:articleid';
export const ABOUTUS = '/aboutus';
export const HUB = '/hub'
export const QUIZ = '/quiz'

export const SPRINTHACK = '/sprinthack'
export const TERMS = '/sprinthack/terms_of_service'


export const DRAWTEST = '/drawtest'
