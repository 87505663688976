import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Velocity_map_light from '../../../img/velocity_map.png'
import Velocity_map_dark from '../../../img/velocity_map_dark.png'
import '../../../css/general.css'
import '../../../css/hero.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HeroIMG from "../../../img/undraw_Collaboration.png";

const useStyles = makeStyles((theme) => ({
    textpop:{
        color:theme.custom.textColorInverted,
        backgroundColor:  theme.custom.textPop,
        padding: '0px 5px',
        margin:'0px 5px',
    },
    [theme.breakpoints.down("xs")]: {
        container:{
            maxWidth:'600px'
        }
    },
    heroContainer:{
        display:'flex'
    }

}));

const Hero = ({
                  ...props
              }) => {

    const classes = useStyles();
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const velocity_map = (prefersDarkMode) ? Velocity_map_dark : Velocity_map_light;
    const text_color_light = (prefersDarkMode) ? '' : 'text_color_light';

    return (
        <section>
            <div className={'container'}>
                <div className={'maingrid'}>
                    <div className={'gridwrap'}>
                        <div className={'grid-hero'}>
                            <h1>
                                Build Remote Development Team Risk-Free
                            </h1>
                            <div className={'heroSubtitleText '+text_color_light}>
                                <p>
                                    Click. Match. Hire. We make it risk-free and easy to get remote developers vetted and eventually hired to your team.
                                </p>
                                <p>
                                    At the same time, everything is documented to product board. You have a remote tool to drive development.
                                </p>
                                <a className={'button button-red'} href={'https://form.jotform.com/201062132467041'}>
                                    Book a 30min intro
                                </a>
                            </div>

                            <h3 className={'padding-top-20'}>Get everything you need to run efficient development starting at only €1900/mo</h3>
                        </div>
                        <div className={'grid-img'}>
                            <img alt={'hero'} src={HeroIMG} width="540" height="540" />
                        </div>
                    </div>
                </div>

{/*
                <div className={'heroSubtitleText '+text_color_light}>
                    <p>
                        1. Product operations for smooth impactful discovery and integration<br/>
                        2. On-demand technical development to enable your growth.<br/>
                        3. Upfront cash flow to fund your growth.
                    </p>
                    <p className={'small-quote'}>
                        What you get, and should aim for, is a smooth constant completion of tasks.
                    </p>
                    <img alt={"velocity_map"} src={velocity_map} />
                </div>
*/}

            </div>
            <div className={'footerspace'} />
        </section>
    );
}

export default Hero;