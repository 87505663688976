import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import {TextareaAutosize} from "@material-ui/core";
import {authMiddleWare} from "../util/auth";
import BASEURL from "../constants/baseurl";

import StakeholderNeedManager from "./generalComponents/stakeholderNeedManager";
import {db} from "../util/firebase";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    newTitle:{
        textTransform:'capitalize',
        padding:'0 20px'
    },
    requestBoxWrap:{
        padding:"40px"
    },
    requestBox: {
        boxShadow:"rgb(0 0 0 / 50%) 0px 16px 70px",
        borderRadius:'10px',
        paddingTop:'20px',
        paddingLeft:'18px',
        paddingRight:'36px',
        paddingBottom:'20px',
        margin:'0px auto',
        maxWidth:'900px'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function AddNewFeature({userData, backToFeatures, type}) {
    const classes = useStyles();

    const [feature, setFeature] = useState('')

    const handleFeatureChange = (event) => {
        setFeature(event.target.value)
    }

    const handleSaveNewFeature = async (event) => {
        event.preventDefault();
        const updateObject = {
            title: feature,
            type:type,
            workspace_id: userData.activeWorkspace.id
        }

        db
            .collection('features_confirmed')
            .add(updateObject)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                backToFeatures()
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

    }

    return (
        <div>
            <h2 className={classes.newTitle} style={{}}>New {type} Job</h2>
            <div className={classes.requestBoxWrap}>
                <div className={classes.requestBox}>
                    <div style={{marginBottom:'20px'}}>
                        <input
                            aria-label="minimum height"
                            className={classes.textareaStyle}
                            defaultValue=""
                            placeholder="Name the new Job-To-Be-Done"
                            onChange={handleFeatureChange}
                        />

                    </div>
                    <div>
                        <Button
                            color="primary"
                            onClick={backToFeatures}
                        >Cancel</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.submitButton}
                            onClick={(e) => {handleSaveNewFeature(e).then(r => {})}}
                        >Save</Button>
                    </div>

                </div>
            </div>
        </div>
    );
}

