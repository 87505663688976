import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import {TextareaAutosize} from "@material-ui/core";
import {authMiddleWare} from "../util/auth";
import BASEURL from "../constants/baseurl";

import StakeholderNeedManager from "./generalComponents/stakeholderNeedManager";
import {db} from "../util/firebase";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    requestBoxWrap:{
        padding:"40px"
    },
    requestBox: {
        boxShadow:"rgb(0 0 0 / 50%) 0px 16px 70px",
        borderRadius:'10px',
        paddingTop:'20px',
        paddingLeft:'18px',
        paddingRight:'36px',
        paddingBottom:'20px',
        margin:'0px auto',
        maxWidth:'900px'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function AddNewRequest({history, backToSprintCycleList}) {
    const classes = useStyles();

    const [freetextField, setFreetext] = useState('')
    const [isSaving, setIsSaving] = useState(false)
    let user_needs = []

    const handleFreetextChange = (event) => {
        setFreetext(event.target.value)
    }

    const handleSaveNewCycle = async (event) => {
        event.preventDefault();
        setIsSaving(true);
        const newCycleRequest = {
            freetext: freetextField,
            user_needs: user_needs
        };
        let options = {
            url: BASEURL.api+'/cycles/request',
            method: 'post',
            data: newCycleRequest
        };
        const authToken = await authMiddleWare(history);
        axios.defaults.headers.common = { Authorization: `Bearer ${authToken}`, 'Access-Control-Allow-Origin': '*' };
        axios(options)
            .then(response => {
                console.log('response',response);
                setIsSaving(false);
                backToSprintCycleList()
            })
            .catch((error) => {
                setIsSaving(false);
                console.log('new request err',error);
            });
    }

    const createNewStakeholderData = (needs) => {
        user_needs = needs
    }

    return (
        <div className={classes.requestBoxWrap}>
            <div className={classes.requestBox}>
                <div style={{marginBottom:'15px'}}>
                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        className={classes.textareaStyle}
                        defaultValue=""
                        placeholder="Title of the experiment..."
                        onChange={handleFreetextChange}
                    />
                    {/*<p className={classes.subtitle}>Describe a functionality, feature or bug you need.</p>*/}

                </div>
                {/*<StakeholderNeedManager saveChanges={createNewStakeholderData} />*/}
                {(isSaving) ?
                    <div>
                        saving...
                    </div>
                    :
                    <div>
                        <Button
                            color="primary"
                            onClick={backToSprintCycleList}
                        >Cancel</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.submitButton}
                            onClick={(e) => {
                                handleSaveNewCycle(e)
                            }}
                        >Save</Button>
                    </div>
                }
            </div>
        </div>
    );
}

