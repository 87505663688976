import React, {Component, useEffect, useReducer} from 'react';
import {auth, db, firebaseStorageRef} from "../../util/firebase";

import withStyles from '@material-ui/core/styles/withStyles';
import Avatar from '@material-ui/core/avatar';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { Card, CardContent, Divider, Button, Grid, TextField } from '@material-ui/core';

import clsx from 'clsx';

import axios from 'axios';
import { authMiddleWare } from '../../util/auth';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    toolbar: theme.mixins.toolbar,
    root: {},
    details: {
        display: 'flex'
    },
    avatar: {
        height: 110,
        width: 110,
        flexShrink: 0,
        flexGrow: 0
    },
    locationText: {
        paddingLeft: '15px'
    },
    buttonProperty: {
        position: 'absolute',
        top: '50%'
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    progess: {
        position: 'absolute'
    },
    uploadButton: {
        marginLeft: '8px',
        margin: theme.spacing(1)
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    },
    submitButton: {
        marginTop: '10px',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    imageInput: {
        marginTop: '25px',
        marginBottom: '25px'
    },
    section: {
        paddingTop:'30px'
    },
    settingContent: {
        "display":"flex","flex":"1 0 auto","flexDirection":"column","WebkitBoxAlign":"center","alignItems":"center","margin":"64px 40px"
    },
    settingContentColumn: {
        "display":"flex","flex":"initial","flexDirection":"column","maxWidth":"640px","width":"100%"
    }
}));

function Account(props) {
    const classes = useStyles();

    const [userData, setUserData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            fullName: '',
            username: '',
            email: '',
            phoneNumber: '',
            country: '',
            picture: '',
            uiLoading: true,
            buttonLoading: false,
            imageError: ''
        }
    )

    useEffect(()=>{

        const history = props.history;
        async function fetchToken() {
            return await authMiddleWare(history)
        }


        const user = auth().currentUser;
        db.collection('/users')
            .doc(user.uid)
            .get()
            .then((doc)=>{
                console.log('user response',doc.data())
                console.log('user',user)
                const userData = doc.data()
                setUserData({
                    fullName: userData.fullName || user.displayName,
                    username: userData.username,
                    email: userData.email || user.email,
                    picture: userData.imageUrl || user.photoURL,
                    uiLoading: false
                });

            }).catch(err => {
            setUserData({ errorMsg: 'Error in retrieving the data' });
        })


        /* old */
    //     fetchToken()
    //         .then((authToken) => {
    //             axios.defaults.headers.common = { Authorization: `Bearer ${authToken}`, 'Access-Control-Allow-Origin': '*' };
    //             axios
    //                 .get('/user')
    //                 .then((response) => {
    //                     console.log('user response',response)
    //                     setUserData({
    //                         fullName: response.data.fullName,
    //                         username: response.data.username,
    //                         email: response.data.email,
    //                         picture: response.data.imageUrl,
    //                         uiLoading: false
    //                     });
    //                 })
    //                 .catch((error) => {
    //                     if (error.response.status === 403) {
    //                         props.history.push('/login');
    //                     }
    //                     console.log(error);
    //                     setUserData({ errorMsg: 'Error in retrieving the data' });
    //                 });
    //         })


    }, [])

    const handleChange = (event) => {
        setUserData({
            [event.target.name]: event.target.value
        });
    };

    const handleImageChange = (event) => {
        setUserData({
            image: event.target.files[0]
        });
    };

    const updateState = ({uiLoading, picture}) => {
        setUserData({uiLoading:uiLoading, picture:picture})
    };

    const pictureHandler = async (event) => {
        // Create a root reference
        event.stopPropagation();
        event.preventDefault();
        var file = userData.image

        var metadata = {
            'contentType': file.type
        };

        /** createFileName */
        const imageExtension = file.name.split('.')[file.name.split('.').length - 1];
        const filename = `user_${auth().currentUser.uid}.${imageExtension}`

        // console.log('filename',filename)

        const uploadSnap = await firebaseStorageRef.child('users/' + filename).put(file, metadata);

        // console.log('Uploaded', uploadSnap.totalBytes, 'bytes.');
        // console.log('File metadata:', uploadSnap.metadata);
        // Let's get a download URL for the file.
        const imageUrl = await uploadSnap.ref.getDownloadURL();
        console.log('File available at', imageUrl);
        setUserData({uiLoading:false, picture:imageUrl})

        db.doc(`/users/${auth().currentUser.uid}`).update({
            imageUrl:imageUrl
        });

    };

    const updateFormValues = async (event) => {
        event.preventDefault();
        setUserData({ buttonLoading: true });

        const user = auth().currentUser;
        db.collection('/users')
            .doc(user.uid)
            .update({
                fullName: userData.fullName,
                username: userData.username
            })
            .then(()=>{
                setUserData({ buttonLoading: false });
            }).catch(error => {
                console.log(error);
                setUserData({buttonLoading: false});
            })


        /* old */
        // const authToken = await authMiddleWare(props.history);
        // axios.defaults.headers.common = { Authorization: `Bearer ${authToken}`, 'Access-Control-Allow-Origin': '*' };
        // const formRequest = {
        //     fullName: userData.fullName,
        //     username: userData.username
        // };
        // axios
        //     .put('/user', formRequest)
        //     .then((response) => {
        //         console.log('response',response)
        //         setUserData({ buttonLoading: false });
        //     })
        //     .catch((error) => {
        //         if (error.response.status === 403) {
        //             props.history.push('/login');
        //         }
        //         console.log(error);
        //         setUserData({
        //             buttonLoading: false
        //         });
        //     });
    };

    return (userData.uiLoading) ? (
        <main className={classes.content}>
            <div className={classes.toolbar} />
            {userData.uiLoading && <CircularProgress size={30} className={classes.uiProgess} />}
        </main>
    ) : (
        <div style={{paddingLeft:'20px'}}>
            <div className={classes.content}>
                <div className={classes.settingContent}>
                    <div className={classes.settingContentColumn}>
                        <div>
                            <div className={classes.details}>
                                <div>
                                    <Avatar src={userData.picture} className={classes.avatar} />
                                    <Typography className={classes.locationText} gutterBottom variant="h4">
                                        {userData.firstName} {userData.lastName}
                                    </Typography>
                                    <div className={classes.imageInput}>
                                        <input type="file" onChange={handleImageChange} />
                                    </div>
                                    <div>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            type="submit"
                                            size="small"
                                            startIcon={<CloudUploadIcon />}
                                            className={classes.uploadButton}
                                            onClick={pictureHandler}
                                        >
                                            Upload Photo
                                        </Button>
                                    </div>

                                    {userData.imageError && (
                                        <div className={classes.customError}>
                                            {userData.imageError}
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className={classes.progress} />
                        </div>
                        <div className={classes.section}>
                            <Divider />

                            <form autoComplete="off" noValidate>
                                <Divider />
                                <CardContent>
                                    <Grid container spacing={3}>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Full name"
                                                margin="dense"
                                                name="fullName"
                                                variant="outlined"
                                                value={userData.fullName || ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Username - used within Sprinthack"
                                                margin="dense"
                                                name="username"
                                                variant="outlined"
                                                value={userData.username || ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                        <Grid item md={12} xs={12}>
                                            <TextField
                                                fullWidth
                                                label="Email"
                                                margin="dense"
                                                name="email"
                                                variant="outlined"
                                                disabled={true}
                                                value={userData.email || ''}
                                                onChange={handleChange}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </form>
                            <Button
                                color="primary"
                                variant="contained"
                                type="submit"
                                className={classes.submitButton}
                                onClick={updateFormValues}
                                disabled={
                                    userData.buttonLoading
                                }
                            >
                                Update
                                {userData.buttonLoading && <CircularProgress size={30} className={classes.progess} />}
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Account;
