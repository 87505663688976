import React from 'react';
import clsx from 'clsx'
import Header from '../components/landing/layout/Header'
import Footer from '../components/landing/layout/Footer'
import '../css/blogpage.css'
import {makeStyles} from "@material-ui/core/styles";
import Blogarticle from "./blogarticle";

const useStyles = makeStyles((theme) => ({
    mainBackground:{
        // backgroundColor:'#060606'
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

export default function AboutUs() {
    const classes = useStyles();

    return<div>
        <Header/>
        <div className={clsx(classes.animatedItemFade, classes.mainBackground)}>
            <div className={'pagewrap'}>
                <div className={'pagetitle'}>
                    <div>
                        <h1>
                            About us
                        </h1>
                    </div>

                </div>
                <div className={"pagebody"}>
                    <p>
                        Sprinthack is developed in Helsinki, Finland by two senior developers <a className={'intext_link'} href={'https://www.linkedin.com/in/hudsson/'} target={'_blank'}>Teppo Hudsson</a> and <a className={'intext_link'} href={'https://www.linkedin.com/in/trsampaio/'} target={'_blank'}>Tiago Sampaio</a>.
                        We both have seen the pain that projects have when task planning is problematic and have since 2016 worked
                        as on-demand talent on 15+ projects.
                    </p>
                    <p>
                        Again and again we see the problem when startups and corporations ventures cannot explain in clear
                        terms and in details what are they doing next.
                    </p>
                    <p>
                        We aim to fix that.
                    </p>
                    <p>
                        Since 2016 we've developed several AI-tools currently used on the market (<a href={'www.pandatron.com'}>Pandatron</a> & <a href={'www.analysismode.com'}>Analysismode</a>) and we aim to continue applying
                        this knowledge of human-machine interfaces. Aiming for quality, by sometimes sacrificing the speed. This
                        means that our "AI" has humans checking the output in outlier cases.
                    </p>
                </div>
            </div>
        </div>
    </div>

}
