const {firebaseFunctions} = require("../../util/firebase");


exports.updateStatus = (contextId, stepIndex, text) => {
    let completeText = text
    if (!completeText) completeText = ""

    const postStatusUpdate = firebaseFunctions.httpsCallable('comments_api-comments-postStatusUpdate')
    postStatusUpdate({text: completeText, stepIndex: stepIndex, isSPH_architect:false, contextId:contextId}).then(()=>{
        return "ok"
    }).catch()

}