import React from "react";
import {makeStyles, useTheme} from "@material-ui/core";
import Logo from "../img/sprinthack_logo_white_dark_small.png"

const useStyles = makeStyles((theme) => ({
    content: {

        paddingLeft:'0px',
        paddingRight:'0px',
        position:'relative',
        overflow:'visible',
        display:'flex',
        flex:'1 1 auto',
        flexDirection:'row',
        "WebkitBoxAlign":"center","alignItems":"center","WebkitBoxPack":"center","justifyContent":"center","overflowY":"auto",

        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    logo: {
        position: 'absolute',
        zIndex: '1001',
        height: '31px',
        width: '31px',
        left: 'calc(50% - 16px)',
        top: '10px'
    }
}));

export default function LogoDivider() {
    const classes = useStyles();

    return (
        <main className={classes.content}>
            <div style={{height:'2px', width:'100%', backgroundColor:'rgba(0,0,0,0.12)'}} />
            <img  src={Logo} className={classes.logo} alt="fireSpot"/>
        </main>
    )
}
