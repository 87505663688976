import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { getSnapshot, destroy, onSnapshot } from "mobx-state-tree"
import {Workspace} from "./models/Workspace"

const localStorageKey = "SPH-storage-workspace";

const initialState = {
    "members": {
        "123": { id: "123", name: "user 1", gender: "f", cycles:["MdQFApkp2JEA7s4WocBw", "MdQFApkp2JEA7s4WocBw2"]},
        "124": { id: "124", name: "user 2", gender: "m", cycles:["MdQFApkp2JEA7s4WocBw", "MdQFApkp2JEA7s4WocBw2"]}
    },
    "cycles":{
        "MdQFApkp2JEA7s4WocBw2":{id:"MdQFApkp2JEA7s4WocBw2", freetext:'freetext sample 2'},
        "MdQFApkp2JEA7s4WocBw":{id:"MdQFApkp2JEA7s4WocBw", freetext:'freetext sample'}
    },
    "name": "test"
}

let store
let snapshotListenerDestroyer

function createWorkspaceStore(snapshot) {
    // clean up snapshot listener
    if (snapshotListenerDestroyer) snapshotListenerDestroyer()
    // kill old store to prevent accidental use and run clean up hooks
    if (store) destroy(store)

    // create new one
    window.workspace = store = Workspace.create(snapshot)

    // connect local storage
    snapshotListenerDestroyer = onSnapshot(store, (snapshot) =>
        localStorage.setItem(localStorageKey, JSON.stringify(snapshot))
    )

    return store
}

function renderApp(App, store) {
    ReactDOM.render(
        <React.StrictMode>
            <App store={store}/>
        </React.StrictMode>,
        document.getElementById('root')
    )
}

// Initial render
renderApp(App, createWorkspaceStore(initialState))


// Connect HMR
if (module.hot) {
    module.hot.accept(["./models/Workspace"], () => {
        // Store definition changed, recreate a new one from old state
        renderApp(App, createWorkspaceStore(getSnapshot(store)))
    })

    module.hot.accept(["./App"], () => {
        // Componenent definition changed, re-render app
        renderApp(App, store)
    })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
