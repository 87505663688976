import React, {useReducer, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {db} from "../../util/firebase";
import IdeasList from "../listWrappers/ideasList";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }
}));

export default function IdeasContent({userData, history, isMobile, loadExperimentDetails}) {
    const classes = useStyles();
    const [docsState, setDocsState] = useState(
        {ideasData:[], loading:true}
    );

    React.useEffect(() => {
        const unsubscribe = db
            .collection('idea_validation')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            .orderBy('createdAt', 'desc')
            .get()
            .then(docs => {
                let ideas = [];
                docs.forEach((doc) => {
                    ideas.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setDocsState({ideasData:ideas, loading:false});

            })
            .catch(err => {
                setDocsState({ideasData:[], loading:false});
            })

            // .onSnapshot((querySnapshot) => {
            //     let ideas = [];
            //     querySnapshot.forEach((doc) => {
            //         ideas.push({
            //             id: doc.id,
            //             idea: doc.data().idea,
            //             feedbacks: doc.data().feedbacks,
            //             createdAt: doc.data().createdAt
            //         });
            //     });
            //     setDocsState({ideasData:ideas, loading:false});
            // },error => {
            //     console.log('error',error)
            //     setDocsState({ideasData:[], loading:false});
            // });

        return function cleanup() {}
    }, [])

    return (docsState.loading) ? <></> : (
        <IdeasList userData={userData} history={history} ideas={docsState.ideasData} isMobile={isMobile} loadExperimentDetails={loadExperimentDetails} />
    )
}

