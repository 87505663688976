import React from 'react';
import clsx from 'clsx'
import Header from '../components/landing/layout/Header'
import Footer from '../components/landing/layout/Footer'

import {makeStyles} from "@material-ui/core/styles";
import Pricing from "./pricing";

const useStyles = makeStyles((theme) => ({
    mainBackground:{
        // backgroundColor:'#060606'
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

export default function Homepage_subpage_pricing() {
    const classes = useStyles();

    return<div>
        <Header/>
        <div className={clsx(classes.animatedItemFade, classes.mainBackground)}>
            <Pricing showButtons />
        </div>
        <Footer/>
    </div>

}
