const env = process.env.NODE_ENV || 'development';

const BASEURL = {
    development: {
        api: '', // we use proxy in package.json due to CORS
    },
    staging: {
        api: 'https://europe-west1-sprinthack.cloudfunctions.net/api',
    },
    production: {
        api: 'https://europe-west1-sprinthack.cloudfunctions.net/api',
    },
}[env];

export default BASEURL