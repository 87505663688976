import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ProjectsList from "../listWrappers/projectsList";
import {db} from "../../util/firebase";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function ProjectsContent({userData, history, isMobile, loadTaskDetails}) {
    const classes = useStyles();
    const [docsState, setDocsState] = useState(
        {projectsData:[], loading:true}
    );

    React.useEffect(() => {
        /** load data here */

        const unsubscribe = db
            .collection('projects')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            .orderBy('createdAt', 'desc')
            .onSnapshot((querySnapshot) => {
                let projects = [];
                querySnapshot.forEach((doc) => {
                    projects.push({
                        id: doc.id,
                        ...doc.data()
                    });
                });
                setDocsState({projectsData:projects, loading:false});
            },error => {
                setDocsState({projectsData:[], loading:false});
            });

        return function cleanup() {
            unsubscribe()
        }

    }, [userData])

    return (docsState.loading)
        ?
        <></>
        :
        <ProjectsList userData={userData} history={history} projects={docsState.projectsData} isMobile={isMobile} loadTaskDetails={loadTaskDetails} />

}