import React, {useState} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router-dom";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Button from "@material-ui/core/Button";
import {useRouteMatch} from "react-router";

const useStyles = makeStyles((theme) => ({
    sidebarListPadding: {
        margin:'0px 10px 3px 10px',
        border:"1px solid rgb(239, 241, 244)",
        boxShadow:"rgb(0 0 0 / 10%) 0px 2px 4px"
    },
    sidebarListItem: {
        borderRadius:'8px',
        padding:'5px',
        fontSize:'12px',
    },
    sidebarListItemText: {
        margin:'0px'
    },
    drawerBottom: {
        // position:'absolute',
        // bottom:'30px',
        // left:'0',
        width:'100%'
    },
}));

const UpgradeButton = withStyles((theme) => ({
    root: {
        fontSize: '0.75em',
    },
}))(Button);

export default function PricingTab() {
    const classes = useStyles();
    let history = useHistory();
    let { url } = useRouteMatch();

    return (
        <List className={classes.drawerBottom}>
            <div className={classes.sidebarListPadding}>
                <ListItem>
                    <ListItemText className={classes.sidebarListItemText} secondary={'Free plan'} />
                    <ListItemSecondaryAction>
                        <UpgradeButton
                            size="small"
                            onClick={()=>{history.push(`/home/settings/pricing`)}}
                        >Upgrade</UpgradeButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </div>
        </List>
    );
}