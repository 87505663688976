import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 375,
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    listLink:{
        cursor:'pointer'
    },
    rowText: {
        cursor:'default'
    },

    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    }
}));

function createData(title, context, freetext, createdAt) {
    return { title, context, freetext, createdAt };
}

const rows = [
    // createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    // createData('Eclair', 262, 16.0, 24, 6.0),
    // createData('Cupcake', 305, 3.7, 67, 4.3),
    // createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CycleReadyToDevelopList({history}) {
    const classes = useStyles();
    const theme = useTheme();

    const [rows, setRows] = React.useState([
    //     {
    //     "cycleId": "QSOJIsYnxIkXTR9yrHQP",
    //     "context": 'cycle context',
    //     "title": 'cycle title',
    //     "freetext": "test with auth",
    //     "createdAt": "2021-03-06T12:40:45.719Z",
    //     "tasks": [{},{},{}]
    // }
    ]);

    React.useEffect(() => {
        // async function fetchToken() {
        //     return await authMiddleWare(history)
        // }
        // fetchToken()
        //     .then((authToken) => {
                // axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
                // axios
                //     .get(BASEURL.api+'/cycles/request')
                //     .then((response) => {
                //         console.log('response.data: ',response.data);
                //         console.log('dayjs', dayjs(response.data[0].createdAt).format('MMM DD'))
                //         setRows(response.data)
                //         // setRows(response.data.map((cyclerequest) => {
                //         //     console.log(createData(cyclerequest.title, cyclerequest.context, cyclerequest.freetext, cyclerequest.createdAt))
                //         //     return createData(cyclerequest.title, cyclerequest.context, cyclerequest.freetext, cyclerequest.createdAt)
                //         // }))
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         if(error.response && error.response.status === 403) {
                //             history.push('/login')
                //         }
                //     });
            // })

    }, [])

    return (
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableBody>
                    {(rows.length < 1) ?
                        <TableRow>
                            <TableCell align="left" style={{color:theme.custom.subtitle, paddingLeft:'25px'}}>All completed</TableCell>
                        </TableRow>
                        :
                        rows.map((row, index) => (
                        <TableRow key={index} hover className={classes.listLink}>
                            <TableCell align="left" style={{ width: "1px", paddingRight:'0px' }}><CheckCircleIcon className={classes.tableIcon} /></TableCell>
                            <TableCell component="th" scope="row" className={classes.rowText}>
                                <div className={classes.cycleTitle}>{row.title} </div>
                            </TableCell>
                            <TableCell align="right"><Chip variant="outlined" color="primary" size="small" label={row.context} /></TableCell>
                            <TableCell align="right" style={{ width: "70px", paddingLeft:'0px' }} className={classes.dateColumn}>{dayjs(row.createdAt).format('MMM DD')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

