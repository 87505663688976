import React, {useEffect, useReducer, useState, useRef} from 'react';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {auth} from '../util/firebase'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs';

import { observer } from "mobx-react";
import { clone, getSnapshot, applySnapshot } from "mobx-state-tree";

import {AdminSpace} from "../models/Adminspace";
import BASEURL from "../constants/baseurl";
import AdminTaskDetails from "../components/admin/adminTaskDetails";

const useStyles = makeStyles((theme) => ({
    // root:{
    //     height:'1000px',
    //     [theme.breakpoints.up("xs")]: {
    //         backgroundColor: "yellow",
    //     },
    //     [theme.breakpoints.up("sm")]: {
    //         backgroundColor: theme.palette.secondary.main,
    //     },
    //     [theme.breakpoints.up("md")]: {
    //         backgroundColor: theme.palette.primary.main,
    //     },
    //     [theme.breakpoints.up("lg")]: {
    //         backgroundColor: "green",
    //     },
    // },
    level1:{
        minHeight:'min-height',
        display:'flex',
        // flex:'1 1 0%'
        flexFlow:'row wrap'
    },
    main:{
        flex: '1 100%',
        background:'deepskyblue',
        maxWidth:'960px'
    },
    main2:{
        padding: '20px',
        flex: '1 100%',
        flexShrink:'0',
        "WebkitBoxFlex":"100","flexGrow":"100","maxWidth":"1460px","paddingBottom":"48px",
        borderLeft:'1px solid rgba(0, 0, 0, 0.12)'
    },
    main3:{
        padding: '20px',
        flex:'100 0px',
        flexShrink:'0',
        "WebkitBoxFlex":"100","flexGrow":"100","maxWidth":"960px","paddingBottom":"48px",
        borderLeft:'1px solid rgba(0, 0, 0, 0.12)',
    },
    aside1:{
        flex: '1 100%',
        background:'hotpink',
    },
    aside2:{
        flex: '1 100%',
        background:'hotpink',
        "minWidth":"320px","WebkitBoxFlex":"1","flexGrow":"1"

    },
    [theme.breakpoints.up("xs")]: {
        aside:{
            flex: '1 auto'
        }
    },
    [theme.breakpoints.up("sm")]: {
        main:{
            flex:'100 0px',
            order:'2'
        },
        main2:{
            flex:'100 0px',
            order:'2',
        },
        aside2:{
            order:'3'
        }
    },
    [theme.breakpoints.up("md")]: {
        main2:{
            // marginLeft:'240px'
        }
    },
    savebutton: {
        float:"right",
        background:'#64ccd9'
    },
    listLink:{
        cursor:'pointer',

        "&:hover":{
            background:'#f8f8f8'
        }
    },
    selectField: {
        marginTop:'10px',
        marginBottom:'10px',
    },
    textField: {
        width:'100%',
        marginTop:'20px',
        marginBottom:'20px',
    }


}));

const localStorageKey = "SPH-storage-adminspace";

const initialState = {}
let adminSpace = (window.adminspace = AdminSpace.create(initialState))

/** this should be on backend */
 const allowedEmails = [
    'teppo@fibo.io',
    'tiago@fibo.io',
    'teppo@sprinthack.com',
    'teppo.hudsson@gmail.com'
]

function Admin({props, user}) {
    const theme = useTheme();
    const classes = useStyles();
    // const [invitations, setInvitations] = useState([])
    // const [readyRequests, setReadyRequests] = useState([])
    // const [upgrades, setUpgrades] = useState([])

    const token = useRef();
    const [hasAuth, setHasAuth] = useState(
        false
    );
    const [activityData, setActivityData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {pings: [], invitations: [], readyRequests: [], upgrades: [], loading:true}
    );

    useEffect( () => {
        async function loadData() {
            token.current = await auth().currentUser.getIdToken()

            const user = auth().currentUser

            // console.log('user',allowedEmails.includes(user.email))
            if (!allowedEmails.includes(user.email)) {setHasAuth(false); return null}
            if (allowedEmails.includes(user.email)) {setHasAuth(true)}


            /** load the request data */
            adminSpace.setAdminUser()
            adminSpace.loadAdminChatTasks(token.current).then(()=>{
                console.log('admin chat tasks loaded')

            })
            // adminSpace.loadAdminRequests().then(()=>
            //     console.log('admin request loaded')
            // )


            /** load the invitation from 'join' document through ADMIN api */
            let updateObject = {
                invitations:[],
                readyRequests:[],
                upgrades:[],
                loading: false
            }

            fetch(BASEURL.api+'/admin/pings')
                .then(response => response.json())
                .then(data => {

                    setActivityData({pings:data})

                });


            fetch(BASEURL.api+'/admin/invitations')
                .then(response => response.json())
                .then(data1 => {
                    updateObject.invitations = data1
                    fetch(BASEURL.api+'/admin/readyrequests')
                        .then(response => response.json())
                        .then(data2 => {
                            updateObject.readyRequests = data2
                            fetch(BASEURL.api+'/admin/upgrades')
                                .then(response => response.json())
                                .then(data3 => {
                                    updateObject.upgrades = data3

                                    setActivityData(updateObject)
                                });
                        });
                });

        }
        loadData()

    }, []);

    return (!hasAuth) ? <div>401</div> :
        <div className={classes.root}>
            <div className={classes.level1}>
                <div className={classes.main2} style={{maxWidth:'340px'}}>
                    {adminSpace.taskOpen ? (
                        <div>activity list here </div>
                    ) :
                        <>
                            {activityData.loading
                                ?
                                <div>loading...</div>
                                :
                                <div>
                                    {/*<div>*/}
                                    {/*    Prio 1 - PINGS:*/}
                                    {/*    {activityData.pings.map((inv,idx) => {*/}
                                    {/*        return <div key={idx} style={{marginLeft:'20px'}}>{inv.freetext} : {inv.ping_count}</div>*/}
                                    {/*    })}*/}
                                    {/*</div>*/}
                                    {/*<hr/>*/}
                                    <div>
                                        Prio - invitation:
                                        {activityData.invitations.map((inv,idx) => {
                                            return <div key={idx} style={{marginLeft:'20px'}}>{inv.email} : {inv.length}</div>
                                        })}
                                    </div>
                                    <hr/>
                                    {/*<div>*/}
                                    {/*    Prio 3 - request ready:*/}
                                    {/*    {activityData.readyRequests.map((request,idx) => {*/}
                                    {/*        return <div key={idx} style={{marginBottom:'10px'}}>*/}
                                    {/*            <div style={{marginLeft:'20px'}}>{request.id}</div>*/}
                                    {/*            <div style={{marginLeft:'20px'}}>{request.freetext} : {dayjs(request.createdAt).format('MMM DD')}</div>*/}
                                    {/*        </div>*/}
                                    {/**/}
                                    {/*    })}*/}
                                    {/**/}
                                    {/*</div>*/}
                                    {/*<hr/>*/}
                                    <div>
                                        Prio 4 - plan upgrades:
                                        {activityData.upgrades.map((upgrade,idx) => {
                                            return <div key={idx} style={{marginLeft:'20px'}}>{upgrade.plan} : {dayjs(upgrade.createdAt).format('MMM DD')}</div>

                                        })}
                                    </div>
                                    <hr/>
                                </div>

                            }
                        </>
                    }
                </div>
                <div className={classes.main2}>
                    {<AdminUser adminspace={adminSpace} authToken={token.current} />}
                    {/*{selectedUser &&  <User user={props.store.members.get(selectedUser)} />}*/}
                </div>

            </div>
        </div>
}

const AdminUser = observer(({adminspace, authToken})=>{
    const [stateClone, setClone] = useState(null)
    const [activeTask, setActiveTask] = useState(null)
    const [loadingChats, setLoadingChats] = useState(false)
    const classes = useStyles();

    const onTaskClick = (task) => {
        setActiveTask(task)
        setClone(task)
        adminspace.toggleTaskOpen()
        task.messageList.fetchChatMessages(authToken, task.id).then(()=>{
            setClone(clone(task))
        }).catch(e=>{
            console.log('task error!!',e)
        })

        // task.scenarioList.fetchScenarioDetails(task.scenarios).then(()=>{
        //     setClone(clone(task))
        // }).catch(e=>{
        //     console.log('task error!!',e)
        // })
    }

    const chatTaskRows = adminspace.chatList.data.map(wl => {
        console.log('wl data fetch', wl)
        return <TableRow key={wl.id} hover className={classes.listLink} onClick={()=>onTaskClick(wl)}>
            <TableCell component="th" scope="row">
                {(!wl.title || wl.title === '') ? <div>(no title)</div> : wl.title}
            </TableCell>
            <TableCell align='right' style={{width:'90px'}}>
                {dayjs(wl.createdAt).format('MMM DD YYYY')}
            </TableCell>
        </TableRow>
    })

    // const projectRows = adminspace.projectList.data.map(wl => {
    //     // console.log('wl data fetch', wl)
    //     return (
    //         <TableRow key={wl.id} hover className={classes.listLink} onClick={()=>onTaskClick(wl)}>
    //             <TableCell component="th" scope="row">
    //                 {wl.title}
    //             </TableCell>
    //             <TableCell align='right' style={{width:'90px'}}>
    //                 {dayjs(wl.createdAt).format('MMM DD')}
    //             </TableCell>
    //         </TableRow>
    //     )
    // })
    //
    // const requestRows = adminspace.requestList.data.map(wl => {
    //     // console.log('wl data fetch', wl)
    //     return (
    //         <TableRow key={wl.id} hover className={classes.listLink} onClick={()=>onTaskClick(wl)}>
    //             <TableCell component="th" scope="row">
    //                 {wl.freetext} {(wl.ping_count > 0) && <span>({wl.ping_count})</span>}
    //             </TableCell>
    //             <TableCell align='right' style={{width:'90px'}}>
    //                 {dayjs(wl.createdAt).format('MMM DD')}
    //             </TableCell>
    //         </TableRow>
    //     )
    // })

    const saveChanges = () => {

        const updateObject = getSnapshot(stateClone)

        // console.log('updateObject',updateObject)
        applySnapshot(activeTask, getSnapshot(stateClone))

        /** call model.save function here to save changes */
        // activeTask.scenarioList.save()
        // activeTask.save()
        adminSpace.toggleTaskOpen()
    }

    const toggleTaskOpen = () => {
        adminSpace.toggleTaskOpen()
    }

    const saveReply = () => {
        applySnapshot(activeTask, getSnapshot(stateClone))
    }

    return (adminspace.taskOpen) ? (

        <AdminTaskDetails authToken={authToken} stateClone={stateClone} toggleTaskOpen={toggleTaskOpen} saveChanges={saveChanges} saveReply={saveReply} />

    ) : (
        <div>
            <div>admin: {adminspace.adminInfo.email}</div>
            <div>admin id: {adminspace.adminInfo.id}</div>
            <hr/>
            <div className={classes.root}>
                <div className={classes.level1}>
                    <div className={classes.main3}>
                        <div>Chats</div>
                        { loadingChats ?
                            <div>loading...</div>
                            :
                            <TableContainer>
                                <Table size="small">
                                    <TableBody>
                                        {chatTaskRows}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        }
                    </div>
                </div>
            </div>
        </div>
    )

})

export default observer(Admin)