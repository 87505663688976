import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu/Menu";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import GeneralDropdown from "../../util/generalDropdown";
import {db} from "../../util/firebase";
import activityUpdateManager from "../activityUpdates/activityUpdateManager";

const useStyles = makeStyles((theme) => ({
    sidedetailsRow: {
        display: 'flex',
        flex: 'initial',
        flexDirection: 'row',
        marginBottom: '18px'
    },
    sidedetailsRowHeader: {
        color:'rgb(107, 111, 118)',
        fontWeight: 600,
        display: 'flex',
        flexGrow: 'initial',
        flexBasis: 'initial',
        flexDirection: 'row',
        width: '80px',
        flexShrink: '0',
        paddingTop:'10px'
    },
    sidedetailValue: {
        "verticalAlign": "top",
        "display": "flex",
        "flex": "initial",
        "flexDirection": "column",
        "minWidth": '0',
        "userSelect": 'none',

        // "whiteSpace":"nowrap",
        // "overflow":"hidden !important",
        // "textOverflow":"ellipsis"
    },
    sideDetailValueFullWith: {
        "marginLeft": '-145px'
    },
    stateWrap: {
        padding: '6px 8px 8px 8px',
        border: '1px solid transparent',
        borderRadius: '4px',

        textAlign: 'left',
        "-webkitBoxPack": 'start',
        justifyContent: 'flex-start',
        marginLeft: '-8px',
        marginRight: '32px',
        // marginBottom: '18px',
        marginTop: '-6px',
        flexShrink: 1,
        overflow: 'hidden',
        width: 'fit-content',

        "&:hover": {
            border: '1px solid rgba(217, 224, 231, 1)'
        }
    },

}))

const availableStates = [
    {key: "Specs", display:"Specs"},
    {key: "Gathering", display:"Gathering"},
    {key: "Proposals", display:"Proposals"},
    {key: "Confirmation", display:"Confirmation"},
    {key: "Active", display:"Active"}
]

export default function TeamStatus({payload}) {
    const classes = useStyles()
    const [selectedState, setSelectedState] = useState('Exploring')

    useEffect(()=>{
        setSelectedState(payload.state)
    }, [payload])

    const updateStateChange = (updateObject) => {
        /** update the state change of the sprint */
        const update_object = {
            state : updateObject.newState.key
        }
        // console.log('update_object',update_object)
        db
            .collection('teams')
            .doc(payload.id)
            .update(update_object)
            .then(()=>{
                activityUpdateManager.updateStatus(payload.id, update_object.index, update_object.state)
            })
    }

    return <div className={classes.sidedetailsRow} style={{marginBottom:'0'}}>
        <div className={classes.sidedetailsRowHeader}>Status </div>
        <div className={classes.sidedetailValue} style={{position:'relative'}}>
            <GeneralDropdown
                state={selectedState || "Specs"}
                list={availableStates}
                updateStateChange={updateStateChange}
                showFilter={true}
            />
        </div>
    </div>

}
