import React, {useReducer, useState} from 'react'
import LoopIcon from '@material-ui/icons/Loop';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@material-ui/core/Typography';

import ListHeader from '../../components/listHeader';

import CycleSuggestionList from "../../components/activeCycles/cycleSuggestionList";
import CycleReadyToDevelopList from "../../components/backlogLists/cycleReadyToDevelopList";
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import {db} from "../../util/firebase";
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";

// const useStyles = makeStyles((theme) => ({
const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    toolbar: {
        height: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    submitButton: {
        display: 'block',
        color: 'white',
        textAlign: 'center',
        position: 'absolute',
        top: 14,
        right: 10
    },
    floatingButton: {
        position: 'fixed',
        bottom: 0,
        right: 0
    },
    form: {
        width: '98%',
        marginLeft: 13,
        marginTop: theme.spacing(3)
    },
    root: {
        minWidth: 470
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    pos: {
        marginBottom: 12
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    dialogeStyle: {
        maxWidth: '50%'
    },
    viewRoot: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    headerRow: {
        paddingLeft: '18px',
        paddingRight: '16px'
    },
    dateColumn: {
        textTransform: 'uppercase',
        color:'rgba(0,0,0,0.4)'
    },
    addNewButton: {
        float: 'right',
        background: 'linear-gradient(180deg,#34444b,#262f36)'
    },
    listLink:{
        cursor:'pointer'
    },
    cycleListWrapper: {
        height: 'calc(100vh - 65px)',
        overflowY:'scroll'
    },
    animatedItem: {
        animation: `$scaleIn 6s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }
}))

/** note: the props parameters acts as an interface for the function/class */

export default function CycleList ({userData, history, cycleDetails, cycleGroups}) {
    const classes = useStyles();
    const [cyclesState, setCyclesState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {cycleGroups:[], cycles: [], loading:true}
    );

    React.useEffect(() => {

        /** demostate */

        const cycledemo = [
            {
                requestRef: 'XTW45qv0affjFdmizmeY',
                freetext: 'NLP to classify incoming backlog requests (demo3)',
                createdAt:'2021-09-18T07:46:10.699Z',
                cycles: [
                    {
                        cycleId:'c125',
                        createdAt:'2021-09-18T07:46:10.699Z',
                        cycleNumber : 3,
                        name : 'cycle 3',
                        workspace_id:'MdTzlrkJNpiPofez8MVC',
                        issues: [
                            {
                                issueId:'iss125',
                                createdAt:'2021-09-17T07:46:10.699Z',
                                type:'Story',
                                title: 'story with subtasks' ,
                                description:'',
                                dod:'',
                                changelog:'',
                                subtasks: [
                                    {
                                        issueId:'iss127',
                                        createdAt:'2021-09-17T07:46:10.699Z',
                                        type:'Task',
                                        title:'subtask1',
                                        description:'',
                                        dod:'',
                                        changelog:''
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        cycleId:'c124',
                        createdAt:'2021-09-17T07:46:10.699Z',
                        cycleNumber : 2,
                        name : 'cycle 2',
                        workspace_id:'MdTzlrkJNpiPofez8MVC',
                        issues: [
                            {
                                issueId:'iss124',
                                createdAt:'2021-09-17T07:46:10.699Z',
                                type:'Task',
                                title: 'plain task',
                                description:'',
                                dod:'',
                                changelog:'',
                                subtasks:[]
                            }
                        ]

                    },
                    {
                        cycleId:'c123',
                        createdAt:'2021-09-16T07:46:10.699Z',
                        cycleNumber : 1,
                        name : 'cycle 1',
                        workspace_id:'MdTzlrkJNpiPofez8MVC',
                        issues: [
                            {
                                issueId:'iss123',
                                createdAt:'2021-09-17T07:46:10.699Z',
                                type:'Task',
                                title: 'plain task',
                                description:'',
                                dod:'',
                                changelog:'',
                                subtasks:[]
                            }
                        ]
                    }
                ]
            },
        ]

        const unsubscribe = db
            .collection('cycles')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            // .orderBy('createdAt', 'desc')
            .onSnapshot((querySnapshot) => {
                let cycles = {};
                querySnapshot.forEach((doc) => {
                    cycles[doc.id] = {
                        cycleId: doc.id,
                        createdAt: doc.data().createdAt,
                        cycleNumber:doc.data().cycleNumber,
                        name:doc.data().name,
                        issues:doc.data().issues,
                        scenarios: doc.data().scenarios
                    };
                });

                let cycleGroupsTemp = cycleGroups
                cycleGroupsTemp.forEach(group=>{
                    group.cycles.forEach((cycleId, index)=>{
                        if (cycles[cycleId]){
                            group.cycles[index] = cycles[cycleId]
                        } else {
                            group.cycles.splice(index, 1)
                        }
                    })
                })

                setCyclesState({cycleGroups:cycleGroupsTemp, cycles:cycles, loading:false});
            });

        return function cleanup() {
            unsubscribe()
        }
    // eslint-disable-next-line
    }, [cycleGroups])

    dayjs.extend(relativeTime);
    const pagecontext = <LoopIcon/>;
    const promptheader = <div style={{textAlign: 'left'}}>Cycles</div>;
    const promptbody = () => {
        return (
            <div style={{textAlign: 'left'}}>
                <p>
                    Cycles are a collection of tasks that focus the priorities over a certain period of time.
                </p>
                <p>
                    Sprinthack provides you a process to define and document cycle content and then push it to
                    your preferred places for development issue tracking tool.
                </p>
                <p>
                    It all starts from the backlog requests.
                </p>
            </div>
        )};

    return (cycleGroups.length < 1) ? (
        <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
    ) : (
        <div className={clsx(classes.cycleListWrapper, classes.animatedItemFade)}>

            {cycleGroups && cycleGroups.map((data, index)=>{
                return ( <div key={index}>
                        <ListHeader title={data.freetext} />
                        <CycleSuggestionList history={history} details={cycleDetails} rows={data.cycles} isTableFormat />
                    </div>

                )})}

            {/*<ListHeader title={'Tasks Ready to Develop'} />*/}
            {/*<CycleReadyToDevelopList history={history} />*/}

            {/*<ListHeader title={'Wetlab'} />*/}
            {/*<CycleValidatedList history={history} />*/}

        </div>
    )
}

