import React, {useEffect, useReducer, useState} from "react";
import { Redirect } from 'react-router-dom';
import {makeStyles, TextField} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {db, auth, firebaseCore} from "../../util/firebase"
import axios from "axios";
import BASEURL from "../../constants/baseurl";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    joinRow: {
        "display":"flex","flex":"1 1 auto","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center",
        padding:'10px',
        borderTop:'1px solid rgba(255, 255, 255, 0.12)',
        minWidth:'400px'
    },
    joinData:{
        "display":"flex","flex":"1 1 auto","flexDirection":"column","flexGrow":"4","textAlign":"left"
    },
    joinButton: {
        "display":"flex","flex":"initial","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center"
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
        marginTop:'20px'

    },


}));

export default React.memo(function WorkspaceNameUpdate({location}) {
    const classes = useStyles();
    const [shouldRedirect, setShouldRedirect] = useState(false)
    const [open, setOpen] = useState((location && location.shoudShowNotification))
    const [errorMsg, setErrorMsg] = useState('test')
    const [workspace, setWorkspace] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {namespace:'', workspaceName:''})

    useEffect(()=>{
        if (!auth().currentUser) return null

        fetchWorkspaceData()

    }, [])

    useEffect(()=>{
        if (workspace.namespace.length >0 && workspace.namespace.length <3) {setErrorMsg('url must be at least 3 characters')}
        else {setErrorMsg('')}
    }, [workspace])


    const fetchWorkspaceData = async () => {
        const userData = await db.collection('users').doc(auth().currentUser.uid).get()
        const userDataParsed = userData.data()
        const workspaceId = userDataParsed.activeWorkspace

        const workspaceData = await db.collection("workspaces").doc(workspaceId).get()
        let workspaceParsed = workspaceData.data()
        workspaceParsed['id']=workspaceId

        setWorkspace(workspaceParsed);
    }

    const updateWorkspace = () => {
        console.log('update',workspace)
        axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*' };
        axios
            .get(BASEURL.api+'/workspaces/available?w='+workspace.namespace+'?id='+workspace.id)
            .then((response) => {
                if (!response.data.data.availability) {setErrorMsg(response.data.data.message)}

                if (response.data.data.availability) {
                    db.collection('/workspaces').doc(workspace.id)
                        .update({
                            workspaceName:workspace.workspaceName,
                            namespace:workspace.namespace
                        })
                        .then(()=>{
                            setShouldRedirect(true)
                        }).catch((err)=>{
                            setErrorMsg(err)
                        })
                }
            })
    }

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (shouldRedirect) ? (
        window.location.href=`/${workspace.namespace}/settings/`
    ) : (
        <div style={{marginTop:'15px'}}>
            <TextField
                style={{width:'100%'}}
                label="Workspace name"
                id="workspacename"
                variant="outlined"
                size="small"
                value={workspace.workspaceName}
                onChange = {e => setWorkspace({workspaceName: e.target.value})}
            />
            <TextField
                style={{width:'100%', marginTop:'20px'}}
                label="www.sprinthack.com/"
                id="namespace"
                variant="outlined"
                size="small"
                value={workspace.namespace}
                onChange = {e => setWorkspace({namespace:e.target.value})}
            />
            <p style={{textAlign:'left', color:'#ff6d6d', paddingLeft:'12px'}}>{errorMsg}</p>
            <Button
                color="primary"
                variant="contained"
                type="submit"
                size="small"
                className={classes.submitButton}
                disabled={workspace.namespace.length <3}
                onClick={updateWorkspace}
            >Update</Button>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={open}
                onClose={handleClose}
                autoHideDuration={6000}
                message={"Workspace Updated"}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={'123'}
            />

        </div>
    )

});
