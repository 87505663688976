import React, { Component } from 'react';
import {makeStyles, useTheme} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import Box from "@material-ui/core/Box";
import Drawer from "@material-ui/core/Drawer/Drawer";

const useStyles = makeStyles((theme) => ({
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        // justifyContent: 'flex-end'
    },
    profileBox: {
        borderBottom: '1px solid #000',
        marginTop:'1px',
        cursor:'pointer',

        "&:hover": {
            background: "rgba(0,0,0,0.04)"
        }
    },
    smallAvatar: {
        // marginTop:'8px',
        width: '30px',
        height: '30px'
    },
    avatarInfo: {
        // marginTop:'5px'
    },
    subtitle: {
        color:'#888',
        lineHeight: '14px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    workspace_container: {
        alignItems:'center'
    }

}));

export default function DrawerHeader({history, userData,  openSettings}) {
    const classes = useStyles();
    const theme = useTheme();

    let activeWorkspace = (userData.activeWorkspace) ? userData.activeWorkspace.workspaceName || userData.activeWorkspace.namespace : 'My workspace';
    if (!activeWorkspace) activeWorkspace = userData.username
    return (
        <div className={[classes.drawerHeader, classes.profileBox].join(' ')} aria-controls="fade-menu" aria-haspopup="true" onClick={openSettings}>
            <Grid container spacing={5} className={classes.workspace_container}>
                <Grid item xs={2}>
                    <Avatar src={''} className={classes.smallAvatar}>{activeWorkspace.charAt(0)}</Avatar>
                </Grid>
                <Grid item xs={10} className={classes.avatarInfo}>
                    <Grid container spacing={0}>
                        <Grid item xs={12}>
                            <Box fontSize={14} fontWeight={500}>{activeWorkspace}</Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box className={classes.subtitle} fontSize={12}>{userData.email}</Box>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>

            {/*<IconButton onClick={handleDrawerClose} className={classes.avatarHoverArrow}>*/}
            {/*    {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}*/}
            {/*</IconButton>*/}

            {/*<IconButton className={classes.avatarHoverArrow}>*/}
            {/*    <KeyboardArrowDownIcon />*/}
            {/*</IconButton>*/}
        </div>
    )
}
