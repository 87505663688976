import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu/Menu";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import SlowLoad from "../slowLoad";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {db} from "../../util/firebase";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import activityUpdateManager from "../activityUpdates/activityUpdateManager";
import CategoryIcon from "@material-ui/icons/Category";
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime";

const useStyles = makeStyles((theme) => ({
    a_button: {
        textDecoration:'none'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    actionButton: {
        background:theme.custom.MainButton
    },
    feedbackLinks:{
        backgroundColor:theme.custom.MemberListBG,
        marginBottom:'5px',
        color:theme.custom.genericHighlightText,
    },
    menuInput: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.8em'
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'28px'
    },
    sidedetailsRowHeader: {
        fontWeight: 600,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'150px',
        flexShrink: '0'
    },
    sidedetailValue: {
        "verticalAlign":"top",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "minWidth":'0',
        "userSelect":'none',

        // "whiteSpace":"nowrap",
        // "overflow":"hidden !important",
        // "textOverflow":"ellipsis"
    },
    sideDetailValueFullWith:{
        "marginLeft": '-145px'
    },
    stateWrap: {
        padding:'6px 8px 8px 8px',
        border:'1px solid transparent',
        borderRadius:'4px',

        textAlign: 'left',
        "-webkitBoxPack":'start',
        justifyContent:'flex-start',
        marginLeft:'-8px',
        marginRight:'32px',
        marginBottom:'38px',
        marginTop:'-6px',
        flexShrink: 1,
        overflow:'hidden',
        width:'fit-content',

        "&:hover":{
            border:'1px solid rgba(217, 224, 231, 1)'
        }
    },
    sideDetailGuidance: {
        // color:theme.custom.textColor,
        // background:'rgb(205,242,245)',
        background: theme.custom.genericHighlightArea,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        // border:'1px solid rgb(205,242,245)',
        // boxShadow: theme.custom.boxShadowGlow
    },
    listItem:{
        width: 'auto',
        overflow: 'hidden',
        fontSize: '0.75rem',
        minHeight:'auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight:'1.5',
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        cursor:'pointer',

        '&:hover':{
            background:'rgba(0, 0, 0, 0.04)'
        }
    },
    slowloadWrap: {
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        width:'fit-content',
        marginTop:'0px',
        marginBottom:'-35px',
        right:'0'
    },
    approveIcon: {
        marginRight: '10px',
        color:theme.custom.GreenButton
    },
}));

const menuItems_basic = [
    'Stories',
    'Scenario discovery',
    'Scenario writing',
    'Done'
]

const menuItems_full = [
    'Todo',
    'Development',
    'Test',
    'Learn',
    'Document',
    'Done'
]

export default function SideDetailsNextActionDropdown({payload, updateStateChange}) {
    const classes = useStyles()
    const history = useHistory()
    dayjs.extend(relativeTime);

    const [menuItems, setMenuItems] = useState([]);
    const [anchorEl,setAnchorEl] = useState();
    const open = Boolean(anchorEl);
    const [customState, setCustomState] = useState('');
    const [templateCopyClicked, setTemplateCopyClicked] = useState(false);

    const volInputRef = useRef();
    const templateRef = useRef(null);

    const [pingCount, setPingCount] = useState(payload.ping_count || 0)

    useLayoutEffect(() => {
        setMenuItems(menuItems_full)
    }, [payload]);

    const handleOpen = (event, stakeholder_need) => {
        setAnchorEl(event.currentTarget);

        /** for some reason the focus has to be on setTimeout */
        setTimeout(() => {
            volInputRef.current.focus()
        }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const updateCustom = (ev) => {
        if (ev.key === 'Enter') {
            // Do code here
            ev.preventDefault();

            /** add to model object */
            setAnchorEl(null);
            updateStateChange({newState: customState})
            setCustomState('')
        } else {

        }
    }

    const handleListItemClick = (event, index) => {
        setAnchorEl(null);
        updateStateChange({newState: menuItems[index], newStatus:index-1})

        const updateObject = {
            ai_analysis_stage:index-1,
            status:index-1,
            state:menuItems[index]
        }
        if (menuItems[index] === 'Done') {
            updateObject.ai_analysis_stage = 6
            updateObject.status = 6
        }

        /** save status update to db */
        db.collection('cycle_requests').doc(payload.requestId)
            .update(updateObject)
            .then(()=>{
                // setApproveLoading(false);

                let completeText = menuItems[index]
                activityUpdateManager.updateStatus(payload.requestId, index, completeText)

            })
            .catch(()=>{
                // setApproveLoading(false);
            })
    };

    const RenderPingArchitectButton = () => {
        const now = dayjs()
        const diff_days = now.diff(payload.ping_timestamp, 'day')
        const diff_hours = now.diff(payload.ping_timestamp, 'hour')
        const diff_minutes = now.diff(payload.ping_timestamp, 'minute')
        let ago_text = `${diff_days} days ago.`;
        if (diff_days < 1) ago_text = `${diff_hours} hours ago.`;
        if (diff_hours < 1) ago_text = `${diff_minutes} minutes ago.`;
        const time_since_last_ping = ago_text;

        return <>
            <Button
                color="primary"
                variant="contained"
                size="small"
                className={clsx(classes.actionButton)}
                onClick={()=>{
                    setPingCount(pingCount + 1)
                    const updateObject = {
                        ping_count: pingCount + 1,
                        ping_timestamp: new Date().toISOString()
                    }
                    db.collection('cycle_requests').doc(payload.requestId)
                        .update(updateObject)
                        .then(()=>{
                            // setApproveLoading(false);
                        })
                        .catch(()=>{
                            // setApproveLoading(false);
                        })
                }}
            >Ping Architect {(pingCount > 0) && "("+pingCount+")"}
            </Button>
            {payload.ping_timestamp && <div className={classes.subtitle} style={{fontSize:'12px', marginTop:'5px'}}>last ping: {time_since_last_ping}</div>}

        </>
    }

    const RenderOpenReportButton = () => {
        return <a className={classes.a_button} href={'/report/'+payload.requestId}><Button
            variant="outlined"
            size="small"
            onClick={()=>{

            }}
        >Open Report
        </Button></a>
    }
    const RenderMoveToFeaturesButton = () => {
        return <Button
            color="primary"
            variant="contained"
            size="small"
            className={clsx(classes.actionButton)}
            onClick={()=>{}}
        ><CategoryIcon className={classes.approveIcon} /> Archive</Button>
    }

    const RenderTemplateGuidance = () => {
        if (payload.status <= -1){
            return <div>
                <Paper elevation={5} className={classes.sideDetailGuidance}>
                    <div>
                        Define the experiment. Focus on what should be developed with "If X, then Y". Use the feedback link to get opinions on experimentation readiness.
                    </div>
                </Paper>

                {/** note: this should be a feedback template, always based on the state of the request **/}
                <Paper elevation={5} className={classes.sideDetailGuidance}>
                    <div className={classes.subtitle}>
                        {menuItems[payload.status+1]} feedback link
                    </div>
                    <div>
                        <List>
                            <ListItem className={classes.feedbackLinks}>
                                <ListItemText style={{"userSelect":"text"}} primary={`sprinthack.com/f/${payload.requestId}`} ref={templateRef} />
                            </ListItem>
                        </List>

                        <div style={{display:'flex', flexDirection:'row'}}>
                            <Button
                                color="primary"
                                variant="outlined"
                                size="small"
                                onClick={(e)=>{
                                    navigator.clipboard.writeText(`sprinthack.com/f/${payload.requestId}`);
                                    setTemplateCopyClicked(true)
                                }}> {(templateCopyClicked) ? "Copied" : "Copy link!"} </Button>
                        </div>
                    </div>
                </Paper>
            </div>
        }
        if (payload.status === 0){
            return <div>
                <Paper elevation={5} className={classes.sideDetailGuidance}>
                    <div>
                        Prototype the feature with development. Get to the state where the description and success definition can be tested. All the possible usage scenarios should be out of scope as you are not validating them.
                    </div>
                </Paper>
            </div>
        }

        if (payload.status === 1){
            return <div>
                <Paper elevation={5} className={classes.sideDetailGuidance}>
                    <div>
                        Test with a small group of some users or other stakeholders. 20 feedbacks should be enough to see that the prototype delivers value.
                    </div>
                </Paper>
            </div>
        }

        if (payload.status === 2){
            return <div>
                <Paper elevation={5} className={classes.sideDetailGuidance}>
                    <div>
                        Write down some of the learnings found out at the tests. Now you should have a hunch what metrics to set to the hypothesis to follow up in the long run that the feature has value.
                    </div>
                </Paper>
            </div>
        }

        if (payload.status === 3){
            return <div>
                <Paper elevation={5} className={classes.sideDetailGuidance}>
                    <div>
                        Document your your learning on this experimentation. What was said about it and how many times? Push that to list on the assumptions of the project.
                    </div>
                </Paper>
            </div>
        }


        if (payload.status >= 6){
            /** basic case */
            console.log('payload.scenario::',payload.scenarios)
            // if (!payload.include_architecture && (!payload.scenarios || payload.scenarios.length < 1) ) {
            //     return <div style={{paddingTop:'10px'}}>
            //         <Paper elevation={5} className={classes.sideDetailGuidance}>
            //             <div>
            //                 Good job. However, looks like there are no scenarios included.
            //             </div>
            //         </Paper>
            //     </div>
            // }

            /** advanced case */
            return <div style={{paddingTop:'10px'}}>
                <Paper elevation={5} className={classes.sideDetailGuidance}>
                    <div>
                        Good job! Experiment completed. The data is part of the assumption now.
                        <p></p>
                        <div><RenderOpenReportButton/></div>
                        <div style={{'marginTop':'10px'}}><RenderMoveToFeaturesButton/></div>

                    </div>
                </Paper>
            </div>
        }

    }

    return (
        <div>
            <div className={classes.sidedetailsRow} style={{marginBottom:'0'}}>
                <div className={classes.sidedetailsRowHeader}>Status: </div>
                <div className={classes.sidedetailValue} style={{position:'relative'}}>
                    {/*{(payload.status >= 0 && payload.status < 4) ? (<div className={classes.slowloadWrap} style={{position:'absolute'}}>*/}
                    {/*    <SlowLoad show={true} />*/}
                    {/*    <span style={{fontSize:'10px'}}>in analysis mode...</span>*/}
                    {/*</div>) : null}*/}
                    <div className={classes.stateWrap} onClick={handleOpen}>
                        {payload.state || customState}
                    </div>
                    <div className={classes.sideDetailValueFullWith}>
                        <RenderTemplateGuidance/>
                    </div>

                </div>
            </div>
            <div>
            </div>
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                PaperProps={{
                    style: {
                        padding:'0',
                        marginLeft:'-145px'
                    },
                }}
            >
                <TextField inputProps={{className:classes.menuInput}} placeholder="Change status..." size="small"
                           style={{padding:'0 10px', marginBottom:'5px'}}
                           inputRef={volInputRef}
                           value={customState}
                           onChange={e=>{console.log('value',e.target.value);  setCustomState(e.target.value)}}
                           onKeyPress={(ev) => {updateCustom(ev)}}
                />
                {menuItems.map((option, index) => (
                    <div
                        className={classes.listItem}
                        key={option}
                        onClick={(event) => handleListItemClick(event, index)}
                    >
                        {option}
                    </div>
                ))}
            </Menu>
        </div>
    )
}
