import React, {useState, useEffect} from 'react';
import ReactGA from 'react-ga4';
import {
    BrowserRouter as Router,
    Route, useRouteMatch
} from "react-router-dom";

import clsx from 'clsx';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

import {auth, db, firebaseCore} from '../util/firebase'
import Account from "../components/SettingComponents/account";
import Settings from "../components/SettingComponents/settings";
import LoadScreen from "../components/loadscreen";
import Pricing from "./pricing";
import TeamContent from "./contentWrappers/teamContent";
import PlayBook from "./playbook";
import {useIsMobile} from "../util/useIsMobile";
import SprintsContent_individual from "./contentWrappers/sprintsContent_individual";
import SideDrawer_individual from "../components/dashboardComponents/sideDrawer_individual";
import SideDrawer_team from "../components/dashboardComponents/sideDrawer_team";
import TeamsContent_individual from "./contentWrappers/teamsContent_individual";
import Dashboardcontent from "./contentWrappers/dashboardcontent";
import AddNewTeam from "../components/addNewTeam";
import TeamDetails from "../components/activeTeams/teamDetails";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
        height: '100vh',
        backgroundColor: theme.custom.root,
        transition:'opacity 1020ms ease 0s'
    },
    toolbar: theme.mixins.toolbar,
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000'

    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    hide: {
        display: 'none',
    },
    content: {
        padding: '0px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
        "display":"flex","flexShrink":"initial","flexBasis":"initial","flexDirection":"column","WebkitBoxFlex":"1","flexGrow":"1","position":"relative","overflow":"auto","WebkitBoxAlign":"stretch","placeItems":"stretch"
    },
    drawerShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
    avatarHoverArrow: {
        position: 'absolute',
        right:'10px',
        top:'10px'
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    menuButton:{
        "position":"fixed",
        "left":"12px",
        "top":"0px",
        "width":"52px",
        "height":"52px",
        "paddingLeft":"8px",
        "zIndex":"10"
    },
    menuButtonShift: {
        marginLeft: `calc(${drawerWidth}px - 10px)`,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButtonHide: {
        display:'none'
    },

}));

// function useIsMobile() {
//     const [isMobile, setIsMobile] = useState(false);
//     useLayoutEffect(() => {
//         function checkIsMobile() {
//             setIsMobile(window.innerWidth < 600);
//         }
//         window.addEventListener('resize', checkIsMobile);
//         checkIsMobile();
//         return () => window.removeEventListener('resize', checkIsMobile);
//     }, []);
//     return isMobile;
// }


const TRACKING_ID = "G-PBFC2KFS3V"; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function Dashboard_individual(props) {
    const history = props.history;
    const classes = useStyles();
    let { url, params } = useRouteMatch();
    const isMobile = useIsMobile();
    const [open, setOpen] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [opacity, setOpacity] = useState('0');

    const [userData, setUserData] = useState({loading: true, email: '', username: null, profilePicture: '', isAuthorised: true})

    useEffect( () => {
        // if (!userData.loading) setOpacity('1');
        setTimeout(() => {setOpacity('1');}, 1500)

    }, [userData.loading]);

    useEffect( () => {
        setOpen(!isMobile)
    }, [isMobile]);

    useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "dashboard_individual page" });

        db.collection(`/users/`)
            .doc(auth().currentUser.uid)
            .get()
            .then((response_userdata) => {
                setUserData({
                    ...userData,
                    ...response_userdata.data(),
                    ...{loading:false, id:response_userdata.id}
                })
            })


    }, [])

    const loadDashboard = () => {
        history.push(`${url}`);
    }
    const loadProjects = () => {
        history.push(`${url}/projects`);
    }

    const RenderMainComponent = () => {
        // console.log('USERDATA:::',userData)
        return (
            <Router>
                <Route exact path={`${url}`} render={() => <Dashboardcontent userData={userData} history={history} isMobile={isMobile} />}>
                </Route>
                <Route path={`${url}/teams`} render={() => <TeamsContent_individual userData={userData} history={history}  />}>
                </Route>
                <Route path={`${url}/team/:teamId`} render={() => <TeamDetails userData={userData} history={history}  />}>
                </Route>
                <Route path={`${url}/newteam`} render={() => <AddNewTeam userData={userData} backToTeams={loadDashboard} />}>
                </Route>
                <Route path={`${url}/diary`} render={() => <PlayBook userData={userData} history={history}/>}>
                </Route>

                <Route exact path={`${url}/settings`} render={() => <Settings />}>
                    {/*<RoadmapList history={history} />*/}
                </Route>
                <Route exact path={`${url}/settings/profile`} render={() => <Account />}>
                    {/*<RoadmapList history={history} />*/}
                </Route>
                <Route exact path={`${url}/settings/pricing`} render={() => <Pricing userData={userData} showButtons />}>
                    {/*<RoadmapList history={history} />*/}
                </Route>


            </Router>
        )

    };

    return userData.loading || (opacity !== '1') ? (
        <LoadScreen authorized={true}/>
    ) : (
        <div className={classes.root} style={{opacity:opacity}}>
            <CssBaseline />
            <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={()=>{setOpen(!open)}}
                edge="start"
                className={clsx(classes.menuButton, {[classes.menuButtonShift]: open}, {[classes.menuButtonHide]: !isMobile})}
            >
                <MenuIcon />
            </IconButton>
            <SideDrawer_individual className={clsx({[classes.drawerShift]: open || !isMobile})} history={history} userData={userData} isMobile={isMobile} open={open} closeDrawer={()=>setOpen(!open)} />
            <main
                className={clsx(classes.content, {
                    [classes.contentShift]: open || !isMobile,
                })}
            >
                <RenderMainComponent/>
            </main>
        </div>
   );
}

export default Dashboard_individual