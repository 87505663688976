import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import {auth, db} from '../util/firebase'
import AccessDenied from "../pages/accessDenied";
import Landing from "./landing"
import {set} from "mobx";
import LoadScreen from "../components/loadscreen";
import Dashboard_individual from "./dashboard_individual";

export default function Home() {
    const history = useHistory()
    const [workspaceAuth, setWorkspaceAuth] = useState(true)
    const [showLanding, setShowLanding] = useState(false)
    const [loading, setLoading] = useState(true)

    React.useEffect(()=>{
        /** leadfeeder tracker */
        // const script = document.createElement("script")
        // script.innerHTML = '(function(ss,ex){ window.ldfdr=window.ldfdr||function(){(ldfdr._q=ldfdr._q||[]).push([].slice.call(arguments));}; (function(d,s){ fs=d.getElementsByTagName(s)[0]; function ce(src){ var cs=d.createElement(s); cs.src=src; cs.async=1; fs.parentNode.insertBefore(cs,fs); }; ce(\'https://sc.lfeeder.com/lftracker_v1_\'+ss+(ex?\'_\'+ex:\'\')+\'.js\'); })(document,\'script\'); })(\'bElvO73pnLP4ZMqj\');'
        // document.body.appendChild(script)
        //
        // return () => {
        //     clean up the script when the component in unmounted
        // document.body.removeChild(script)
        // }
    })

    React.useLayoutEffect(() => {
        const user = auth().currentUser;
        if (user) {
            // console.log('user at home',user.uid)
            db.collection('/users')
                .doc(user.uid)
                .get()
                .then((doc)=>{
                    if (doc.exists){
                        let workspaceId = doc.data().activeWorkspace;
                        const workspaces_access = doc.data().workspaces;
                        if (!workspaceId) {
                            Object.keys(workspaces_access).forEach(ws_id=>{
                                if (workspaces_access[ws_id]) workspaceId=ws_id
                            })
                        }

                        // console.log('user at home',workspaceId)
                        db.collection('/workspaces')
                            .doc(workspaceId)
                            .get()
                            .then((workspaceDoc)=>{
                                if (workspaceDoc.exists){
                                    const workspace = workspaceDoc.data().namespace.toLowerCase()
                                    // history.push(`/${workspace}`)
                                    history.push(`/home`)
                                    // setLoading(false)
                                } else {
                                    history.push(`/finishsetup`)
                                }
                            }).catch(err=>{
                            console.log('user at home',err)
                            /** home needs a logic to change activeWorkspace */
                            setWorkspaceAuth(false)
                            // history.push(`/join`)
                        })
                    } else {
                        history.push(`/finishsetup`)
                    }
                }).catch((error)=>{
                history.push('/error');
            })
        } else {
            setShowLanding(true)
        }

    }, [])

    return (showLanding) ? (
            <Landing />
        ) : (
            (loading) ? (
                <LoadScreen authorized={workspaceAuth}/>
            ) : (
                <Dashboard_individual />
            )
        );
}
