import React, {useReducer, useState} from 'react';
import {
    BrowserRouter as Router,
    Route,
    useRouteMatch
} from "react-router-dom";
import {makeStyles, useTheme} from "@material-ui/core";
import BacklogList from "../listWrappers/backlogList";
import AddNewBacklog from "../../components/addNewRequest";
import CycleRequestDetails from "../../components/activeExperiments/cycleRequestDetails";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import AddIcon from '@material-ui/icons/Add';
import {db} from "../../util/firebase";
import * as ROUTES from '../../constants/routes';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    }

}));

let topTitle = 'Active Experiments'
export default function BacklogContent({userData, history, isMobile, activeView}) {
    let { url } = useRouteMatch();
    let route = useRouteMatch();
    const classes = useStyles();
    const [render, setRender] = useState(activeView);
    const [backlogState, setBacklogState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {backlogData: {
                requests:[],
                inprogress:[],
                complete:[]
            }, loading:true}
    );

    React.useLayoutEffect(() => {
        if (!route.isExact) {
            setBacklogState({loading:false});
            return function cleanup() {
            }
        }

        const unsubscribe = db
            .collection('cycle_requests')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            .orderBy('createdAt', 'desc')
            .onSnapshot((querySnapshot) => {
                let cycle_requests = [];
                querySnapshot.forEach((doc) => {
                    // console.log(`${doc.id}`);
                    cycle_requests.push({
                        requestId: doc.id,
                        freetext_details: doc.data().freetext_details || '',
                        user_needs: doc.data().user_needs || [],
                        sidedetails: doc.data().sidedetails || {},
                        ai_analysis_stage: doc.data().ai_analysis_stage || 0,
                        state: doc.data().state || "Pending",
                        status: doc.data().status || 0,
                        success: doc.data().success || '',
                        ping_count: doc.data().ping_count || 0,
                        ping_timestamp: doc.data().ping_timestamp || null,
                        scenarios: doc.data().scenarios || [],
                        ...doc.data()

                    });
                });

                let organised_backlog = {
                    requests:[],
                    development:[],
                    test:[],
                    learn:[],
                    finalise:[],
                    published:[],
                    document:[],
                    complete:[]
                }
                cycle_requests.forEach((item) => {

                    if (item.status === 0){
                        /** development */
                        organised_backlog.development.push(item)
                        return false
                    }
                    if (item.status === 1){
                        /** test */
                        organised_backlog.test.push(item)
                        return false
                    }
                    if (item.status === 2){
                        /** learn */
                        organised_backlog.learn.push(item)
                        return false
                    }
                    if (item.status === 3){
                        /** document */
                        organised_backlog.document.push(item)
                        return false
                    }
                    // if (item.status === 4){
                    //     organised_backlog.published.push(item)
                    //     return false
                    // }
                    // if (item.status === 5){
                    //     organised_backlog.document.push(item)
                    //     return false
                    // }
                    if (item.status >= 6){
                        /** done */
                        organised_backlog.complete.push(item)
                        return false
                    }
                    organised_backlog.requests.push(item)
                })

                console.log('organised_backlog',organised_backlog)
                setBacklogState({backlogData:organised_backlog, loading:false});
            })

        return function cleanup() {
            unsubscribe()
        }
    }, []);

    const loadCyclesPage = () => {
        history.push(`${url}`);
        // activeViewStateChange({selected:'Sprint Cycles', appbarTitle:'Active cycles'})
        // setRender({selected:'Sprint Cycles', appbarTitle:'Active cycles'})
    }
    const loadAddNewCyclePage = () => {
        history.push(`${url}/new`);
        // setRender({selected:'New Cycle', appbarTitle:'Active Cycles > New Cycle', })
    }
    const loadCycleRequestDetails = (payload) => {
        history.push(`${url}/request/${payload.requestId}`);
        // activeViewStateChange({selected:'Cycle Request Details', appbarTitle:'Active Cycles > Request Details', payload:payload});
        // setRender({selected:'Cycle Request Details', appbarTitle:'Active Cycles > Request Details', payload:payload})
    }

    const RenderBacklogLists = () => {
        return (!backlogState.loading) ? (
            <Router>
                <Route exact path={`${url}`} render={() => <BacklogList history={history} loadAddNewRequest={loadAddNewCyclePage} requestDetails={loadCycleRequestDetails} rows={backlogState.backlogData} rowsLoading={backlogState.loading} />} />
                <Route path={`${url}/new`} render={() => <AddNewBacklog history={history} backToSprintCycleList={loadCyclesPage} />} />
                <Route path={`${url}${ROUTES.CYCLES_REQUEST_IN_CYCLES}`} render={() => <CycleRequestDetails userData={userData} detailsPayload={render.payload} history={history} backToSprintCycleList={loadCyclesPage} />} />
            </Router>
        ) : null

        // if (render.selected === 'Sprint Cycles') { return <SprintCycleList history={history} requestDetails={loadCycleRequestDetails} requestrows={cycleRequestData} cyclerows={cycleData} />}
        // if (render.selected === 'New Cycle') return <AddNewCycle history={history} backToSprintCycleList={loadCyclesPage} />
        // if (render.selected === 'Cycle Request Details') return <CycleRequestDetails backToSprintCycleList={loadCyclesPage} detailsPayload={render.payload} />
        // return false
    };

    const handleBackButton = () => {
        history.goBack()
        // loadCyclesPage();
        return false
    };

    /** the topBar has to be own component, Toolbar within it and toolbar always customised to view */
    return (
        <div>
            <div
                position="fixed"
                className={clsx(classes.topBar, {
                    [classes.topBarShift]: isMobile,
                })}
            >
                <Toolbar>
                    {!route.isExact ? <IconButton onClick={handleBackButton} className={clsx(classes.menuButton)}>
                        <ChevronLeftIcon />
                    </IconButton> : <div />}
                    <Typography variant="button" style={{flexGrow:'1', textTransform:'capitalize'}}>
                        {topTitle}
                    </Typography>
                    {route.isExact ? <Button variant="contained" color="primary" size="small" className={classes.addNewButton} onClick={loadAddNewCyclePage}>
                        <AddIcon />
                    </Button> : <div/>}


                </Toolbar>
            </div>
            <RenderBacklogLists/>
        </div>

    )

}
