import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import dayjs from "dayjs";
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ListHeader from "../../components/listHeader";
import {BrowserRouter as Router, Route, useRouteMatch} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import AddNewIdea from "../../components/addNewIdea";
import TasksDetails from "../../components/activeTasks/TasksDetails"
import AdjustIcon from "@material-ui/icons/Adjust";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRow:{
        "&:hover": {
            backgroundColor: theme.custom.TableRowHover
        }
    },
    tableIconDone: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    tableIconTodo: {
        fontSize:theme.custom.tableiconsize,
        color:'#BEBEBE'
    },
    tableIconInProgress: {
        fontSize:theme.custom.tableiconsize,
        color:'rgb(242, 201, 76)'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 0px 0px 0px'
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        }
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },
    emptyRow:{
        color:theme.custom.subtitle,
        marginLeft:'25px'
    },

    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

let topTitle = 'Tasks'

export default function IdeasList({userData, history, isMobile, ideas, loadExperimentDetails}) {
    let { url } = useRouteMatch();
    let route = useRouteMatch();
    const classes = useStyles();
    const [todoIdeas, setTodoIdeas] = useState([])
    const [inProgressIdeas, setInprogressIdeas] = useState([])
    const [doneIdeas, setDoneIdeas] = useState([])

    useEffect(() => {

        console.log('ideas',ideas)
        setTodoIdeas(ideas.filter(p => p.status === 0 || !p.status )) /** need status filter here */
        setInprogressIdeas(ideas.filter(p => p.status === 1))
        setDoneIdeas(ideas.filter(p => p.status === 2))

    }, [ideas])

    const pagecontext = 'Tasks';
    const promptheader = 'A task or assumption what should be done next in a project';
    const promptbody = () => {
        return (
            <div style={{textAlign: 'left'}}>
                <p>
                    Within tasks you can define experiments, define the task logically and most important together plan it.
                </p>
                <p>
                    <Button style={{marginTop:'20px'}} variant="contained" size="small" color="primary" onClick={loadAddNewIdea} >
                        + New Task
                    </Button>
                </p>
            </div>
        )};

    const GetIcon = ({title}) => {
        if (title === 'Done') return <CheckCircleIcon className={classes.tableIconDone}/>
        if (title === 'In progress') return <AdjustIcon className={classes.tableIconInProgress}/>
        return <RadioButtonUncheckedIcon className={classes.tableIconTodo}/>
    }

    const GetTable = ({title, tasks}) => {
        if (!tasks || !Array.isArray(tasks)) return null
        return (
            <div>
                <ListHeader title={title} />
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody>
                            {(tasks.length < 1)
                                ? <TableRow style={{height:'44px'}}><TableCell align="left" className={classes.emptyRow}>All done</TableCell></TableRow>
                                : tasks.map((idea, index) => {
                                    return (
                                        <TableRow key={index} className={classes.tableRow} style={{height:'44px'}} onClick={()=>{loadIdeaDetails(idea)}}>
                                            <TableCell align="left" style={{ width: "1px", paddingRight:'10px'}}><GetIcon title={title} /></TableCell>
                                            <TableCell align="left" scope="row" className={classes.rowText}>
                                                <div className={classes.rowTitle}>{idea.idea}</div>
                                            </TableCell>
                                            <TableCell align="right" className={classes.dateColumn}>{dayjs(idea.createdAt).format('MMM DD')}</TableCell>
                                        </TableRow>
                                    )
                                } )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const RenderTable = () => {
        return (
            <div>
                <GetTable title={'In progress'} tasks={inProgressIdeas} />
                <GetTable title={'Todo'} tasks={todoIdeas} />
                <GetTable title={'Done'} tasks={doneIdeas} />
            </div>
        )
    }

    const RenderTableWrap = () => {
        return <div className={classes.animatedItemFade}>
            {(ideas.length < 1) ? (
                    <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
                        <div className={classes.toolbar} />
                        <div>
                            <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
                        </div>
                    </div>
                ) :
                (
                    <div>
                        <RenderTable/>
                    </div>
                )}
        </div>
    }

    const loadIdeasPage = () => {
        history.push(`${url}`);
    }
    const loadAddNewIdea = () => {
        history.push(`${url}/new`);
    }
    const loadIdeaDetails = (payload) => {
        history.push(`${url}/task/${payload.id}`);
    }

    const RenderIdeasLists = () => {
        return (
            <Router>
                <Route exact path={`${url}`} render={() => RenderTableWrap()} />
                <Route path={`${url}/new`} render={() => <AddNewIdea userData={userData} history={history} backToIdeas={loadIdeasPage} />} />
                <Route path={`${url}${ROUTES.IDEA_DETAILS}`} render={() => <TasksDetails userData={userData} backToIdeas={loadIdeasPage} detailsPayload={ideas} loadExperimentDetails={loadExperimentDetails} />} />
            </Router>
        )
    };

    const handleBackButton = () => {
        history.goBack()
        // loadIdeasPage();
        return false
    };

    /** the topBar has to be own component, Toolbar within it and toolbar always customised to view */
    return <div>
        <div
            className={clsx(classes.topBar, {
                [classes.topBarShift]: isMobile,
            })}
        >
            <Toolbar>
                {!route.isExact ? <IconButton onClick={handleBackButton} className={clsx(classes.menuButton)}>
                    <ChevronLeftIcon />
                </IconButton> : <div />}
                <Typography variant="button" style={{flexGrow:'1', textTransform:'capitalize'}}>
                    {topTitle}
                </Typography>
                {route.isExact ? <Button variant="contained" color="primary" size="small" className={classes.addNewButton} onClick={loadAddNewIdea}>
                    <AddIcon />
                </Button> : <div/>}
            </Toolbar>
        </div>
        <RenderIdeasLists/>
    </div>

}
