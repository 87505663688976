import React, {useState, useEffect} from "react";
import {db} from "../../util/firebase";
import clsx from "clsx";
import {TextareaAutosize} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles";
import StakeholderNeedManager from "../generalComponents/stakeholderNeedManager";
import RequestFreetextEditor from "../generalComponents/RequestFreetextEditor";

const useStyles = makeStyles((theme) => ({
    freeTextContent: {
        color:theme.custom.genericLongformText,
    },
    textareaStyleTransparent:{
        padding: '10px',
        marginBottom:'10px',
        fontSize:'15px',
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',
        minWidth: '260px',
        width: 'calc(100% - 35px)',
        border:'none',
        backgroundColor:theme.custom.MemberListBG,
        resize:'none',
        overflow:'auto',
        outline:'none',
        "WebkitBoxShadow":"none",
        "MozBoxShadow":"none",
        "boxShadow":"none"
    },
    hide: {
        display:'none !important'
    },
    requestDetailText:{
        "fontStyle":"normal","fontWeight":"normal","textAlign":"left","color":"rgb(98, 102, 109)",
        "lineHeight":"23px","fontSize":"15px","display":"block","marginBottom":"50px","cursor":"default"
    },
    requestDetailGuide:{
        color:"rgb(98, 102, 109)",
        marginBottom:'15px',
        fontSize:'14px',
        fontStyle:'italic'
    },
    whiteColor:{
        color:'#fff'
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover}
    }
}))

export default React.memo(function RequestDetailEditor({payload, readOnly, showVolatilities, colorPops}) {
    const classes = useStyles();
    const [requestData, setRequestData] = useState({})
    const [displayRequestEdit, setDisplayEdit] = useState(false);

    let isSavingCurrently = false

    useEffect(()=>{
        setRequestData(payload)
    }, [payload])

    const toggleHide = () => {
        setDisplayEdit(!displayRequestEdit)
    }

    const saveRequestFreetextDetails = async (text) => {
        await db.collection('cycle_requests').doc(requestData.requestId).update({freetext_details:text}, {merge:true})
        toggleHide()
    }

    const RenderRequestStepDetails = ({detailsString}) => {
        if (!detailsString) return null
        const detailsArr = detailsString.split(',')
        const detailsList = detailsArr.map((detail, index) => {return <li className={classes.freeTextContent} key={index} value={index+1}>{detail}</li>})
        return <ol>{detailsList}</ol>
    }

    const saveStakeholderDetailChanges = (needs) => {
        console.log('save changes', needs)
        if (!isSavingCurrently){
            isSavingCurrently = true
            db.collection('cycle_requests').doc(requestData.requestId)
                .update({user_needs:needs})
                .then(()=>{
                    isSavingCurrently = false
                })
                .catch(()=>{
                    isSavingCurrently = false
                })
        }
    }

    return (readOnly) ? (
        // <RenderRequestStepDetails detailsString={requestData.freetext_details} />
        <div>{requestData.freetext_details}</div>
            // <StakeholderNeedManager payload={requestData} readOnly saveChanges={()=>{}} showVolatilities={showVolatilities} colorPops={colorPops} />
    ) : (<div>
            <>
                <RequestFreetextEditor payload={payload} save={saveRequestFreetextDetails}/>
                {/*<StakeholderNeedManager payload={requestData} saveChanges={saveStakeholderDetailChanges} showVolatilities={showVolatilities} colorPops={colorPops} />*/}
            </>
    </div>
    )
})
