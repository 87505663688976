import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs';
import AdjustIcon from '@material-ui/icons/Adjust';
import Chip from '@material-ui/core/Chip';

import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import clsx from "clsx";
import {Divider} from "@material-ui/core";

import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 375,
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {

    },
    listLink:{
        cursor:'pointer'
    },
    rowText: {
        cursor:'default'
    },
    heading: {
        fontSize: '15px'
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize
    },
    isRunning:{
        color:'#64ccd9'
    },
    isComplete:{
        color:'#93c47e'
    },
    accordionList: {
        width:'100%',

        '&:hover': {
            background: theme.custom.AccordionListBGhover
        }
    },


}));

function createData(title, context, freetext, createdAt) {
    return { title, context, freetext, createdAt };
}

const rows = [
    // createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    // createData('Eclair', 262, 16.0, 24, 6.0),
    // createData('Cupcake', 305, 3.7, 67, 4.3),
    // createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function CycleSuggestions({history, details, rows, isTableFormat}) {
    const classes = useStyles();
    const theme = useTheme();

    const [rowstest, setRowstest] = React.useState([
    //     {
    //     "cycleId": "QSOJIsYnxIkXTR9yrHQP",
    //     "context": 'cycle context',
    //     "title": 'cycle title',
    //     "freetext": "test with auth",
    //     "createdAt": "2021-03-06T12:40:45.719Z"
    // }
    ]);

    React.useEffect(() => {

        console.log('rows ::::: ',rows)

        // async function fetchToken() {
        //     return await authMiddleWare(history)
        // }
        // fetchToken()
        //     .then((authToken) => {
                // axios.defaults.headers.common = { Authorization: `Bearer ${authToken}` };
                // axios
                //     .get(BASEURL.api+'/cycles/request')
                //     .then((response) => {
                //         console.log('response.data: ',response.data);
                //         console.log('dayjs', dayjs(response.data[0].createdAt).format('MMM DD'))
                //         setRows(response.data)
                //         // setRows(response.data.map((cyclerequest) => {
                //         //     console.log(createData(cyclerequest.title, cyclerequest.context, cyclerequest.freetext, cyclerequest.createdAt))
                //         //     return createData(cyclerequest.title, cyclerequest.context, cyclerequest.freetext, cyclerequest.createdAt)
                //         // }))
                //     })
                //     .catch((error) => {
                //         console.log(error);
                //         if(error.response && error.response.status === 403) {
                //             history.push('/login')
                //         }
                //     });
            // })

    }, [])

    const getCycleIssueTitle = (issue) => {
        if (issue.subtasks.length > 0) return issue.title + ' (' + issue.subtasks.length + ')'
        return issue.title
    }
    const getCycleScenarioTitle = (scenario) => {
        return scenario
    }
    const countIssues = (row) => {
        let count = 0
        row.issues && row.issues.forEach(issue=> {
            count++
            issue.subtasks && issue.subtasks.forEach(subtask => {
                count++
            })
        })
        row.scenarios && row.scenarios.forEach(scenario=> {
            count++
        })

        return count
    }

    return (isTableFormat) ? (
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableBody>
                    {(rows && rows.length < 1) ?
                        <TableRow>
                            <TableCell align="left" style={{color:theme.custom.subtitle, paddingLeft:'25px'}}>All completed</TableCell>
                        </TableRow>
                        :
                        rows && rows.map((row, index) => (
                            <TableRow key={index} hover className={classes.listLink} onClick={()=>details(row)}>
                                <TableCell align="left" style={{ width: "1px", paddingRight:'0px' }}> {row.isComplete ? <CheckCircleIcon className={clsx(classes.tableIcon, classes.isComplete)} /> : <PlayCircleOutlineIcon className={clsx(classes.tableIcon, classes.isRunning)} />}</TableCell>
                                <TableCell component="th" scope="row" className={classes.rowText}>
                                    <div className={classes.rowTitle}>{row.name}</div>
                                </TableCell>
                                <TableCell align="right"><Chip variant="outlined" color="primary" size="small" label={countIssues(row)} /></TableCell>
                                <TableCell align="right" style={{ width: "70px", paddingLeft:'0px' }} className={classes.dateColumn}>{dayjs(row.createdAt).format('MMM DD')}</TableCell>
                            </TableRow>
                        ))}
                </TableBody>
            </Table>
        </TableContainer>
    ) : (
        <div>
            {(rows && rows.length < 1) ?
                <div>
                    All completed
                </div>
                :
                <TreeView
                    style={{padding:'15px'}}
                    aria-label="cycles"
                    defaultCollapseIcon={<ExpandMoreIcon />}
                    defaultExpandIcon={<ChevronRightIcon />}
                    sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                >
                    {rows && rows.map((row, index) => (
                        <TreeItem nodeId={index} label={row.name} key={index}>
                            {row.scenarios && row.scenarios.map((scenario, idx) => (
                                <TreeItem
                                    nodeId={idx}
                                    label={getCycleScenarioTitle(scenario)}
                                    key={idx}
                                    onClick={()=>details(row)}
                                />
                            ))}
                            {row.issues && row.issues.map((issue, idx) => (
                                <TreeItem
                                    nodeId={idx}
                                    label={getCycleIssueTitle(issue)}
                                    key={idx}
                                    onClick={()=>details(row)}
                                />
                            ))}
                        </TreeItem>
                        ))}
                </TreeView>
            }
        </div>
    );
}

