import React, {useState} from "react";
import {makeStyles, TextareaAutosize} from "@material-ui/core";
import {useRouteMatch} from "react-router-dom";
import Button from "@material-ui/core/Button";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import FreetextEditor from "../generalComponents/FreetextEditor";
import {db} from "../../util/firebase";
import SetProjectPriority from "./SetProjectPriority";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: 'rgba(0, 0, 0, 0.54)'
    },
    textareaStyle: {
        padding: '10px',
        width: 'calc(100% - 35px)'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    subtitleLight: {
        color:theme.custom.subtitleLight
    },
    submitButton: {
        background:theme.custom.MainButton,
    },
    createCycleButton: {
        background:theme.custom.MainButton
    },
    cycleTextDetail: {
        lineHeight:'20px'
    },
    approveButton: {
        background:theme.custom.GreenButton,
    },
    approveChip: {
        background:'transparent',
        fontWeight:'700',
        border:'1px solid transparent',
        transition:'200ms',
        '&:hover':{
            // background:'#fff',
            // border:'1px solid rgba(0, 0, 0, 0.12)'
        },
    },
    sidedetails: {
        marginTop:'-65px',
        flex: '1 100%',
        background: theme.custom.sidedetailBackground,
        padding:'0px 0px 82px 25px',
        position:"relative",
        flexShrink:"0",
        WebkitBoxFlex:"1",
        flexGrow:"1",
        minWidth: '320px',
        maxHeight: 'calc(100% + 65px)',
        overflowY: 'scroll',
    },
    sideTopMenu: {
        minHeight:'64px',
        // width:'300px',
        display:'flex',
        position:'relative',
        alignItems:'center'
    },
    sidedetailData: {
        padding: '20px 0 20px 0',
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'15px',
        // paddingLeft: '5px',
    },
    sidedetailsRowHeader: {
        color:'rgb(107, 111, 118)',
        fontWeight: 500,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'100px',
        flexShrink: '0',
        textTransform: 'capitalize',
    },
    sidedetailValue:{
        fontWeight: 400,
        width:'100%',
        display: 'flex',
        flex:'initial',
        flexDirection:'row',
    },
    sideDetailHoverField:{
        flexGrow: '1',
        maxWidth:'100%',
        padding:'8px 8px',
        textAlign:'left',
        justifyContent:'flex-start',
        marginLeft:'-8px',
        marginRight:'32px',
        flexShrink:'1',
        overflow:'hidden',
        border:'1px solid transparent',
        borderRadius:'5px',
        background:'transparent',
        marginTop:'-8px',
        "&:hover":{
            border:'1px solid #c7c7c7'
        }
    },
    link:{
        display:'flex',
        flexDirection:'row',
        transition:'150ms',
        "-webkit-box-align":'center',
        alignItems:'center',
        color:'inherit',
        textDecoration: 'none',
        cursor: 'default',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }

    },
    whiteColorButton:{
        color:'#a2a2a2',
        borderColor:'#a2a2a2'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    nextActionToggle: {
        position:'absolute',
        top:'0px',
        right:'0px'
    },
    requestTaskButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    listButton: {
        padding:'10px',
        marginBottom:'10px',
        border:'1px solid #e0e2e1',
        borderRadius:'5px',

        "&:hover": {
            background: 'hsla(150,3%,88%,.3)',
        }

    },
    [theme.breakpoints.up("xs")]: {
        sidedetails:{
            flex: '10 auto'
        }
    },
    [theme.breakpoints.up("sm")]: {
        maindetails:{
            flex:'100 0px',
            order:'2'
        },
        sidedetails:{
            order:'3'
        }
    },
    [theme.breakpoints.up("md")]: {
        maindetails:{
            marginLeft:'240px'
        }
    },

}));

function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

function getPlaceholders() {
    return {
        "problem":"(Describes the user going through a domain-level process to achieve some valuable outcome)",
        "Why":'Add...',
    }
}

export default function Project_sideDetails({payload}) {
    const classes = useStyles();

    const [requestTaskOpen, setRequestTaskOpen] = useState(false);
    const [newRequestSnackbarOpen, setNewRequestSnackbarOpen] = useState(false);

    const saveSideDetailChange = (save_object) => {
        console.log('save payload', save_object)
        const update_object = {}
        update_object[save_object["key"]] = save_object["value"]
        db
            .collection('projects')
            .doc(payload.id)
            .update(update_object)
            .then()

    }

    const RenderSideDetails = () => {
        const sidedetailData = [
            "version",
            "priority",
            "problem",
            "why",
            "success",
            "what",
            "how"
        ]

        let elements = []

        console.log('payload payload', payload)

        sidedetailData.forEach((detail, index)=>{

            if (detail === "priority") {
                elements.push(
                    <SetProjectPriority payload={payload} />
                )
            } else {
                elements.push(
                    <div className={classes.sidedetailsRow} key={index}>
                        <div className={classes.sidedetailsRowHeader}>{detail} </div>
                        <div className={classes.sidedetailValue}>
                            <div className={classes.sideDetailHoverField}>
                                <FreetextEditor payload={{key:detail, value:payload[detail]}} save={saveSideDetailChange}/>
                            </div>
                        </div>
                    </div>
                )
            }
        })

        return elements
    }

    const handleSelectRequestType = (event, reason) => {
        setNewRequestSnackbarOpen(true)
        setRequestTaskOpen(false)
    };

    const handleRequestSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNewRequestSnackbarOpen(false);
    };

    return (
        <div className={classes.sidedetails}>
            <div className={classes.sideTopMenu}>
                <div>
                    <Button variant="contained" color="primary" size="small" className={classes.requestTaskButton} onClick={()=>{setRequestTaskOpen(true)}}>
                        {"Request Service"}
                    </Button>
                </div>
            </div>
            <hr style={{border:'1px solid #000', borderBottom:'none', marginTop: '0px', marginLeft:'-25px', marginBottom:'30px'}} />
            <div style={{maxWidth:'450px'}}>
                <RenderSideDetails />
            </div>

            <Modal
                open={requestTaskOpen}
                // onClick={()=>{setConfirmFeatureOpen(false)}}
                onClose={(_, reason) => {
                    if (reason === "backdropClick") {
                        setRequestTaskOpen(false);
                    }
                }}
                className={classes.modal}
                aria-labelledby="modal-confirm-feature"
                aria-describedby="modal-confirm-feature"
                closeAfterTransition

            >
                <div className={classes.paper}>
                    <h2 style={{fontSize:'20px'}} id="transition-modal-title">What are you looking for?</h2>
                    <div className={classes.listButton} onClick={handleSelectRequestType}>Tasks written</div>
                    <div className={classes.listButton} onClick={handleSelectRequestType}>Architect feedback</div>
                </div>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={newRequestSnackbarOpen}
                onClose={handleRequestSnack}
                autoHideDuration={6000}
                message={'Architect notified about the request. You will get an email about this.'}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleRequestSnack}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={'123'}
            />


        </div>
    )
}
