import React, {useState, useRef, useEffect, useLayoutEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu/Menu";
import Fade from "@material-ui/core/Fade";
import TextField from "@material-ui/core/TextField";
import SlowLoad from "../slowLoad";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import {List} from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import {db} from "../../util/firebase";
import clsx from "clsx";
import {useHistory} from "react-router-dom";
import activityUpdateManager from "../activityUpdates/activityUpdateManager";
import CategoryIcon from "@material-ui/icons/Category";
import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime";
import CircularProgress from "@material-ui/core/CircularProgress";
import {cleanup} from "@testing-library/react";

const useStyles = makeStyles((theme) => ({
    a_button: {
        textDecoration:'none'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    actionButton: {
        background:theme.custom.MainButton
    },
    feedbackLinks:{
        backgroundColor:theme.custom.MemberListBG,
        marginBottom:'5px',
        color:theme.custom.genericHighlightText,
    },
    featList:{
        marginTop:'15px'
    },
    menuInput: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.875em'
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'15px',
        minHeight:'35px'
    },
    sidedetailsRowHeader: {
        color:'rgb(107, 111, 118)',
        fontWeight: 500,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'100px',
        flexShrink: '0'
    },
    sidedetailValue: {
        "verticalAlign":"top",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "minWidth":'0',
        "userSelect":'none',

        // "whiteSpace":"nowrap",
        // "overflow":"hidden !important",
        // "textOverflow":"ellipsis"
    },
    sideDetailValueFullWith:{
        "marginLeft": '-145px'
    },
    loadWrap:{
        height:'36px'
    },
    stateWrap: {
        padding:'6px 8px 8px 8px',
        border:'1px solid transparent',
        borderRadius:'4px',

        textAlign: 'left',
        "-webkitBoxPack":'start',
        justifyContent:'flex-start',
        marginLeft:'-8px',
        marginRight:'32px',
        marginBottom:'0px',
        marginTop:'0px',
        flexShrink: 1,
        overflow:'hidden',
        width:'fit-content',

        "&:hover":{
            border:'1px solid rgba(217, 224, 231, 1)'
        }
    },
    sideDetailGuidance: {
        // color:theme.custom.textColor,
        // background:'rgb(205,242,245)',
        background: theme.custom.genericHighlightArea,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        // border:'1px solid rgb(205,242,245)',
        // boxShadow: theme.custom.boxShadowGlow
    },
    listItem:{
        width: 'auto',
        overflow: 'hidden',
        fontSize: '0.875rem',
        minHeight:'auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight:'1.5',
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        paddingRight:'10px',
        cursor:'pointer',

        '&:hover':{
            background:'rgba(0, 0, 0, 0.04)'
        }
    },
    slowloadWrap: {
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        width:'fit-content',
        marginTop:'0px',
        marginBottom:'-35px',
        right:'0'
    },
    approveIcon: {
        marginRight: '10px',
        color:theme.custom.GreenButton
    },
}));


const menuItems_full = [
    {title:'project 1', id:'1234'},
    {title:'project 2', id:'2234'},
    {title:'project 3', id:'3234'}
]

export default function PromoteToFeatures({payload, closeModal}) {
    const classes = useStyles()

    const [filter, setFilter] = useState('')
    const [allFeatures, setAllFeatures] = useState([])
    const [loadingFeatures, setLoadingFeatures ] = useState(true);

    const volInputRef = useRef();

    React.useEffect(()=> {
        if (payload){
            db.collection('features_confirmed')
                .where('workspace_id','==',payload.workspace_id)
                .get()
                .then((features) => {
                    const allLoadedFeatures = []
                    features.forEach(f => {
                        allLoadedFeatures.push({id:f.id, ...f.data()})
                    })
                    setAllFeatures(allLoadedFeatures)
                    setLoadingFeatures(false)
                })
        }
    }, [payload])

    const handlePromoteSave = (feature, index) => {
        closeModal()

        /** save project linking to db */
        if (feature){
            db
                .collection('idea_validation')
                .doc(payload.id)
                .update({
                    feature:feature.id,
                    workspace_id:payload.workspace_id
                })
                .then(()=>{
                })
        } else {
            /** create a new one */

            db.collection('features_confirmed')
                .add({
                    title:filter,
                    workspace_id:payload.workspace_id,
                })
                .then(doc => {
                    console.log('doc',doc)
                    db
                        .collection('idea_validation')
                        .doc(payload.id)
                        .update({
                            feature:doc.id,
                            feature_order:0
                        })
                        .then(()=>{
                        })
                })
        }

        return function cleanup(){}

    };

    return (
        (loadingFeatures) ? <CircularProgress/> :
        <div>
            <div>
                <TextField inputProps={{className:classes.menuInput}} placeholder="Add to feature..." size="small"
                           style={{padding:'0 10px', marginBottom:'5px'}}
                           inputRef={volInputRef}
                           value={filter}
                           onChange={e=>{setFilter(e.target.value)}}
                />
            </div>
            <div className={classes.featList}>
                {allFeatures.filter(f => f.title.includes(filter) || filter === '').map((feature, index) => (
                    <div
                        className={classes.listItem}
                        key={feature.id}
                        onClick={(event) => handlePromoteSave(feature, index)}
                    >
                        {feature.title}
                    </div>
                ))}
                {(filter && filter.length > 0) ?
                    <div
                        className={classes.listItem}
                        onClick={(event) => handlePromoteSave(null)}
                    >
                        + add feature "{filter}"
                    </div>
                    : <div></div>
                }
            </div>
        </div>
    )
}
