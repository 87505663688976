import React, {useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import EmptyPageInfoPrompt from "../components/emptyPageInfoPrompt";
import {auth, db} from '../util/firebase'
import axios from "axios";
import BASEURL from "../constants/baseurl";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import LoadScreen from "../components/loadscreen";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    createSpaceButton:{
        marginTop:'20px'
    },
    animatedItem: {
        animation: `$scaleIn 6s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

export default function CreateWS() {
    const classes = useStyles();
    const history = useHistory()

    const [loading, setLoading] = useState(false)
    const [waitingSetup, setWaitingSetup] = useState(false)

    const [workspaceName, setWorkspaceName] = useState('')
    const [namespace, setNamespace] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    useEffect(()=>{
        if (namespace.length >0 && namespace.length <3) {setErrorMsg('url must be at least 3 characters')}
        else {setErrorMsg('')}
    }, [namespace])

    let setupUnsubscription;

    const pagecontext = '';
    const promptheader = 'Create a workspace';
    const promptbody = () => {
        return (
            <div style={{width:'400px'}}>
                <p>
                    Workspaces are shared environments where teams can work and receive Sprinthack Architects
                    expertise on backlog items and development cycles.
                </p>
                <div>
                    <TextField
                        style={{marginTop:'0px'}}
                        id="outlined-workspace"
                        label="Workspace Name"
                        width="400"
                        fullWidth
                        defaultValue={workspaceName}
                        variant="outlined"
                        onChange={(e)=>setWorkspaceName(e.target.value)}
                    />
                    <div style={{position:'relative', marginTop:'30px'}}>
                        <TextField
                            id="outlined-workspaceurl"
                            label="sprinthack.com/"
                            width="400"
                            helperText="Workspace URL"
                            fullWidth
                            defaultValue={namespace}
                            variant="outlined"
                            onChange={(e)=>setNamespace(e.target.value)}
                        />
                    </div>
                    <p style={{textAlign:'left', color:'#ff6d6d', height:'16px'}}>{errorMsg}</p>
                    <Button
                        variant="contained"
                        size="large"
                        color="primary"
                        className={classes.createSpaceButton}
                        disabled={namespace.length <3}
                        onClick={createNewWorkspace}
                    >
                        <span>Create workspace</span>
                    </Button>
                </div>
            </div>
        )};

    const createNewWorkspace = () => {
        axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*' };
        axios
            .get(BASEURL.api+'/workspaces/available?w='+namespace)
            .then((response) => {
                if (!response.data.data.availability) {setErrorMsg(response.data.data.message); setLoading(false); return false}
                if (response.data.data.availability) {
                    setLoading(true);
                    /** all good, go and save the data namespace */
                    const user = auth().currentUser
                    db.collection('/workspaces')
                        .add({
                            workspaceName:workspaceName,
                            namespace:namespace,
                            owner:user.uid
                        })
                        .then((docRef)=>{
                            const workspaceid = docRef.id

                            db.collection('/events')
                                .add({
                                    "type": 'action',
                                    "name": 'add_new_ws_member',
                                    "user": user.uid,
                                    "email": auth().currentUser.email,
                                    "workspace": workspaceid
                                })
                                .then(()=>{
                                    db.collection('/users').doc(user.uid)
                                        .update({
                                            activeWorkspace:docRef.id,
                                            [`workspaces.${workspaceid}`]: true
                                        })
                                        .then(()=>{
                                            console.log('doc workspaceid',workspaceid)
                                            setWaitingSetup(true)
                                            setWaitingForWorkspaceSetup(workspaceid)

                                        }).catch((error)=>{
                                        console.log('error1',error)
                                        setErrorMsg('error')
                                        setLoading(false);
                                    });
                                }).catch((error)=>{
                                console.log('error2',error)
                                setErrorMsg('error')
                                setLoading(false);
                            })
                        }).catch((error)=>{
                        console.log('error2',error)
                        setErrorMsg('error')
                        setLoading(false);
                    });
                }
            })
            .catch((error) => {
                console.log('error3',error)
                setErrorMsg('error')
                setLoading(false);
            });
    }

    const setWaitingForWorkspaceSetup = (workspaceid) => {
        console.log('doc set up start',)
        setLoading(false);
        setupUnsubscription = db
            .collection('events')
            .where('workspace','==', workspaceid)
            .where('user','==', auth().currentUser.uid)
            .where('name','==', 'add_new_ws_member')
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    console.log('doc completed?',doc.data())
                    const document = doc.data()
                    if (document.status === 'completed') {
                        cleanWaitingListener()
                        setTimeout(()=>{
                            history.replace('/')
                        }, 2000)
                    }
                });
            });
    }

    const cleanWaitingListener = () => {
        setupUnsubscription()
    }

    return (loading)?(<LoadScreen />):(
        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
            <div className={classes.toolbar} />
            <Button size="small" style={{position:'absolute', top:'20px', left:'25px', cursor:'pointer'}} onClick={history.goBack}><ArrowBackIosIcon/> Back</Button>
            <div className={classes.animatedItemFade}>
                {(waitingSetup) ? (
                    <EmptyPageInfoPrompt header={'Workspace setup in progress...'} body={<div><p>Please wait...this might take about 30s</p></div>} />
                ) : (
                    <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
                )}
            </div>
        </div>
    )
}

