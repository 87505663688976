import React, {useEffect, useLayoutEffect, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import LogoDivider from "../../components/logoDivider";
import {Button, Divider} from "@material-ui/core";
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import SendIcon from '@material-ui/icons/Send';
import {db, auth, firebaseFunctions} from '../../util/firebase'
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import RequestDetailsEditor from "../../components/activeExperiments/requestDetailsEditor";
import axios from "axios";
import BASEURL from "../../constants/baseurl";
import LoadScreen from "../../components/loadscreen";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar,
    root: {},
    subtitle:{
        color:theme.custom.subtitle
    },
    section: {
        paddingTop:'30px'
    },
    settingContent: {
        "display":"flex","flex":"1 0 auto","flexDirection":"column","WebkitBoxAlign":"center","alignItems":"center","margin":"64px 40px"
    },
    settingContentColumn: {
        "display":"flex","flex":"initial","flexDirection":"column","maxWidth":"640px","width":"100%"
    },
    settingBody: {
        color:'rgb(138, 143, 152)'
    },
    boldedLine: {
        backgroundColor:theme.custom.genericHighlightArea,
        color:'rgba(0, 0, 0, 0.87)',
        marginBottom:'5px',
        padding:'20px 48px 20px 20px',
        fontWeight:'700',
        fontSize:'16px'
    },
    textareaStyle: {
        backgroundColor:theme.custom.MemberListBG,
        color:theme.custom.genericHighlightText,
        padding: '10px',
        width: 'calc(100% - 35px)'
    },
    sendButtonWrap: {
        textAlign:'center',
        margin:'20px 0',
    },
    sendButton:{
        color:'#fff',
        background:theme.custom.MainButton,
    }


}));

const testPhaseQuestions = [
    {
        q:'Is this prototype valuable to you?',
        a:'',
        pl:'anything unclear?'
    },

]


export default function Feedbacks() {
    const classes = useStyles();
    const history = useHistory();
    const { hash } = useParams();
    const [loading, setLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [isAnonymous, setAnonymous] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('')
    const [detailsData, setDetailsData] = useState({})
    const [questionsData, setQuestionsData] = useState([
        {
            q:'Is it clear what should happen here?',
            a:'',
            pl:'anything unclear?'
        },
        {
            q:'Is it clear what is the value of this feature?',
            a:'',
            pl:'anything unclear?'
        },
        {
            q:'Do you know a solution somewhat similar? Link it.',
            a:'',
            pl:'Any examples?'
        },
    ])

    useEffect(()=>{
        fetchDetails()
    }, [])


    const fetchDetails = async () => {
        /** here we could also set a remote loading of the questions */

        /** get request data based on url hash */
        let responseData = {}
        try {
            // const snapshot = await db.collection('cycle_requests').doc(hash).get()
            const snapshot = await axios.get(BASEURL.api+'/cycles/shared/backlog/'+hash).then()
            responseData = snapshot.data
            /** get custom question template */
            const questionsSnapShot = await db.collection('feedback_templates').where('requestId', '==', hash).get()
            let questionsSnapData = []
            questionsSnapShot.forEach(snap=>{
                questionsSnapData = snap.data()
            })
            if (questionsSnapData.questions && questionsSnapData.questions.length > 0) setQuestionsData(questionsSnapData.questions)
        } catch (err){
            console.log('error',err)
            setHasError(true)
        }
        setDetailsData(responseData)
        setLoading(false)
    }

    const updateQuestionsdata = (index, e) => {
        let qData = [...questionsData]
        qData[index].a = e.target.value
        setQuestionsData(qData)
    }

    const RenderQuestions = () => {
        return (notificationMessage === '') ? (
                questionsData.map((qdata,index)=> {
                    return <div className={classes.section} key={index}>
                        {/*<Divider />*/}
                        <h4>{qdata.q}</h4>
                        <div className={classes.settingBody}>
                            <TextareaAutosize
                                className={classes.textareaStyle}
                                rowsMin={2}
                                value={qdata.a}
                                onChange={e => {
                                    updateQuestionsdata(index, e)
                                }}
                                placeholder={qdata.pl}
                            />
                        </div>
                    </div>
                })
            ) : (
            <div>
                <h1>{notificationMessage}</h1>
            </div>
        )

    }

    const saveFeedback = async () => {
        await setNotificationMessage('Thank you!')

        let questionDataObject = questionsData.map(data => {delete data.pl; return data})
        const postfeedback = firebaseFunctions.httpsCallable('comments_api-comments-postFeedback')
        postfeedback({reply: questionDataObject, contextId:hash, isAnonymous:isAnonymous})
            .then(response => {
            })
    }

    const RenderFeedback = () => {
        return (hasError)
            ?
            <div style={{paddingLeft:'20px'}}> <div className={classes.content}>Nothing Found</div></div>
            :
            <div style={{paddingLeft:'20px'}}>
            <div className={classes.content}>
                <div className={classes.settingContent}>
                    <div className={classes.settingContentColumn}>
                        <div>Your feedback is needed!</div>
                        <LogoDivider/>
                        {/*<h3 style={{marginBottom:'0px'}}>Your feedback is needed!</h3>*/}
                        {/*<div className={classes.subtitle}>This backlog item requires your opinion.</div>*/}
                        {/*<div className={classes.subtitle}>Please, provide your opinion below. All is anonymous {(auth().currentUser) ? 'if you choose so.' : '.'}</div>*/}
                        <h3>{detailsData.freetext}</h3>
                        <p style={{color:"rgb(60, 65, 73)"}}>{detailsData.freetext_details}</p>
                        {RenderQuestions ()}
                        <div className={classes.sendButtonWrap}>
                            <Button
                                variant="outlined"
                                color="primary"
                                className={classes.sendButton}
                                onClick={saveFeedback}
                                disabled={notificationMessage !== ''}
                                endIcon={<SendIcon/>}
                            >
                                Send
                            </Button>
                            {(auth().currentUser) ? <div>
                                <FormGroup row >
                                    <FormControlLabel
                                        style={{margin:'15px auto 0 auto'}}
                                        control={
                                            <Switch
                                                checked={isAnonymous}
                                                onChange={()=>{setAnonymous(isAnonymous => !isAnonymous)}}
                                                color="primary"
                                            />
                                        }
                                        label="Anonymous"
                                    />
                                </FormGroup>
                            </div> : null}

                        </div>
                        <Divider />

                    </div>
                </div>
            </div>
        </div>
    }

    return (loading) ? <LoadScreen/> : <RenderFeedback />
}

