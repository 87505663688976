import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import {TextareaAutosize} from "@material-ui/core";
import {authMiddleWare} from "../util/auth";
import BASEURL from "../constants/baseurl";

import StakeholderNeedManager from "./generalComponents/stakeholderNeedManager";
import {db} from "../util/firebase";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    boxHeader:{
        padding:'0 0 20px 0',
        fontWeight:700,
    },
    boxFooter:{
        padding:'20px 0 0 0',
    },
    boxTextField:{
        maxWidth:'350px'
    },
    requestBoxWrap:{
        padding:"40px"
    },
    requestBox: {
        boxShadow:"rgb(0 0 0 / 50%) 0px 16px 70px",
        borderRadius:'10px',
        paddingTop:'20px',
        paddingLeft:'18px',
        paddingRight:'36px',
        paddingBottom:'20px',
        margin:'0px auto',
        maxWidth:'900px'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function AddNewImpact({history, userData, backToImpact}) {
    const classes = useStyles();

    const [dimension, setDimension] = useState('')
    const [group, setGroup] = useState('')
    const [errorMsg, setErrorMsg] = useState('')

    const handleImpactChange = (event) => {
        setDimension(event.target.value)
    }
    const handleGroupChange = (event) => {
        setGroup(event.target.value)
    }

    const handleSaveNewImpact = async (event) => {
        event.preventDefault();
        if (dimension === '' || !dimension) {setErrorMsg('Dimension required'); return}
        if (group === '' || !group) {setErrorMsg('Dimension group required'); return}

        const updateObject = {
            dimension: dimension,
            group: group,
            workspace_id:userData.activeWorkspace.id,
            createdAt: dayjs().toISOString()
        }

        db
            .collection('impact_dimensions')
            .add(updateObject)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                backToImpact()
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                setErrorMsg(error)
            });
    }

    return (
        <div className={classes.requestBoxWrap}>
            <div className={classes.requestBox}>
                <div className={classes.boxHeader}>Add your custom impact dimension </div>
                <div className={classes.boxTextField}>
                    <TextField
                        fullWidth={true}
                        helperText={'Dimensions are grouped together for a group impact'}
                        label="Impact Group"
                        margin="dense"
                        variant="outlined"
                        value={group || ''}
                        onChange={handleGroupChange} />
                    <TextField
                        fullWidth={true}
                        label="Impact Dimension"
                        margin="dense"
                        variant="outlined"
                        value={dimension || ''}
                        onChange={handleImpactChange} />
                </div>
                <p style={{textAlign:'left', fontSize:'12px', color:'#ff6d6d', paddingLeft:'12px'}}>{errorMsg}</p>

                <div className={classes.boxFooter}>
                    <Button
                        color="primary"
                        onClick={backToImpact}
                    >Cancel</Button>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={classes.submitButton}
                        onClick={(e) => {handleSaveNewImpact(e)}}
                    >Save</Button>
                </div>

            </div>
        </div>
    );
}

