import React, {useState, useRef, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu/Menu";
import Fade from "@material-ui/core/Fade";
import IconButton from "@material-ui/core/IconButton";
import {MoreHoriz} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    a_button: {
        textDecoration:'none'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    actionButton: {
        background:theme.custom.MainButton
    },
    feedbackLinks:{
        backgroundColor:theme.custom.MemberListBG,
        marginBottom:'5px',
        color:theme.custom.genericHighlightText,
    },
    menuInput: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.8em'
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'28px'
    },
    sidedetailsRowHeader: {
        fontWeight: 600,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'150px',
        flexShrink: '0'
    },
    sidedetailValue: {
        "verticalAlign":"top",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "minWidth":'0',
        "userSelect":'none',

        // "whiteSpace":"nowrap",
        // "overflow":"hidden !important",
        // "textOverflow":"ellipsis"
    },
    sideDetailValueFullWith:{
        "marginLeft": '-45px'
    },
    stateWrap: {
        padding:'10px',
        border:'1px solid transparent',
        borderRadius:'4px',
        textAlign: 'left',
        overflow:'hidden',
        width:'fit-content',

        "&:hover":{
            border:'1px solid rgba(217, 224, 231, 1)'
        }
    },
    sideDetailGuidance: {
        // color:theme.custom.textColor,
        // background:'rgb(205,242,245)',
        background: theme.custom.genericHighlightArea,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        // border:'1px solid rgb(205,242,245)',
        // boxShadow: theme.custom.boxShadowGlow
    },
    listItem:{
        width: 'auto',
        overflow: 'hidden',
        fontSize: '0.75rem',
        minHeight:'auto',
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
        fontWeight: '400',
        lineHeight:'1.5',
        paddingTop:'6px',
        paddingBottom:'6px',
        paddingLeft:'10px',
        paddingRight:'10px',
        cursor:'pointer',

        '&:hover':{
            background:'rgba(0, 0, 0, 0.04)'
        }
    },
    slowloadWrap: {
        display:'flex',
        flexDirection:'column',
        alignItems:'flex-end',
        width:'fit-content',
        marginTop:'0px',
        marginBottom:'-35px',
        right:'0'
    },
    approveIcon: {
        marginRight: '10px',
        color:theme.custom.GreenButton
    },
}));


export default function MoreMenuDropdown({contextId, list,  selected}) {
    const classes = useStyles()

    const [menuItems, setMenuItems] = useState([]);
    const [anchorEl,setAnchorEl] = useState();
    const open = Boolean(anchorEl);

    const volInputRef = useRef();

    useEffect(() => {
        setMenuItems(list)
    }, [list]);

    const handleOpen = (event, stakeholder_need) => {
        setAnchorEl(event.currentTarget);

        /** for some reason the focus has to be on setTimeout */
        // setTimeout(() => {
            // volInputRef.current.focus()
        // }, 100);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleListItemClick = (display, index) => {
        setAnchorEl(null);
        selected({contextId, display, index})
    };

    return (
        <div>
            <div>
                <IconButton size={'small'} onClick={handleOpen}>
                    <MoreHoriz/>
                </IconButton>
            </div>
            <Menu
                id="status-menu"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
                PaperProps={{
                    style: {
                        padding:'0',
                        marginLeft:'-55px'
                    },
                }}
            >
                {menuItems.map((option, index) => (
                    // console.log('option',option)
                    <div
                        className={classes.listItem}
                        key={index}
                        onClick={(event) => handleListItemClick(option.display, index)}
                    >
                        {option.display}
                    </div>
                ))}
            </Menu>
        </div>
    )
}
