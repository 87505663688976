import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useRouteMatch} from "react-router-dom";

import '../../css/general.css'
import '../../css/toolbar.css'
import '../../css/dashboard.css'
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Toolbar from "@material-ui/core/Toolbar";

import Snackbar from "@material-ui/core/Snackbar";
import EditIcon from "@material-ui/icons/Edit";
import {Input} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import SendIcon from "@material-ui/icons/Send";
import {db, firebaseFunctions} from "../../util/firebase";

const useStyles = makeStyles((theme) => ({
    activateButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    }
}));

export default function ChatDashboard({userData, history, loadFeatures, openNewRequest, loadProjects}) {
    const classes = useStyles();
    let { url } = useRouteMatch();

    const [topTitle, setTopTitle] = useState('');
    const [dashSnackbarOpen, setDashSnackbarOpen] = useState(false)
    const [chatInputValue, setChatInputValue] = useState('')
    const [activeChatFirstMsg, setActiveChatFirstMessage] = useState('')
    const [newChat, setNewChat] = useState(false)

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const input_color_dark = (prefersDarkMode) ? 'chat-input-wrap-dark' : '';
    const box_button_color_dark = (prefersDarkMode) ? 'box-button-dark' : '';
    const box_background_light = (prefersDarkMode) ? '' : 'box-background-light';

    const [bidValue, setBidValue] = useState('');

    /** front buttons test hardcoded */
    const examples = [
        'I need a paywall to the reports generated by authors',
        'I need to get my project to 1000 in sales',
        'Describe a way to develop IoT device and an application for it'
    ]

    /** functionality to fetch service status and data
     * Maybe move to own component eventually
     * Should use mobx here as well...
     *
     * status should also be fetch at the initialisation
     */

    const FetchRolesStatus = () => {
        return <div className={'activateButtonWrap'}>
            <Button variant="contained" color="primary" size="small" className={classes.activateButton} onClick={()=>{setDashSnackbarOpen(true)}}>
                Activate
            </Button>
        </div>
    }

    const FetchDueDiligenceStatus = () => {
        return <div className={'activateButtonWrap'}>
            <Button variant="contained" color="primary" size="small" className={classes.activateButton} onClick={()=>{setDashSnackbarOpen(true)}}>
                Activate
            </Button>
        </div>
    }

    const FetchCostControlStatus = () => {
        return <div className={'activateButtonWrap'}>
            <Button variant="contained" color="primary" size="small" className={classes.activateButton} onClick={()=>{setDashSnackbarOpen(true)}}>
                Activate
            </Button>
        </div>
    }

    const FetchAdvisoryStatus = () => {
        return <div className={'activateButtonWrap'}>
            <Button variant="contained" color="primary" size="small" className={classes.activateButton} onClick={()=>{setDashSnackbarOpen(true)}}>
                Activate
            </Button>
        </div>
    }

    const defaultDCFData = {
        currencyIcon:'$',
        terminalValue: 218265,
        requireRateOfReturn: 0.1,
        presentValueOfPool:270782,
        netPresentCoins:24026,
        coinDCFValue:11.27
    }

    const DefaultCoinsValue = (amount) => {
        return defaultDCFData.currencyIcon+(amount*defaultDCFData.coinDCFValue).toFixed(2)
    }

    const onChangeBidValue = (e) => {
        setBidValue(e.target.value);
    }

    const CalculateBidValue = () => {
        console.log('bidValue::',bidValue)
        return defaultDCFData.currencyIcon+(bidValue*defaultDCFData.coinDCFValue).toFixed(2)
    }

    const updateInputValue = (e) => {
        setChatInputValue(e)
    }

    const sendNewChatMessage = () => {
        /** should get a saved object from backend, and then forward to that ID */
        /** this has to add author & authorRef at the backend */
        let trimInputValue = chatInputValue.trim()
        if (trimInputValue === '') return

        db
            .collection('chat_requests')
            .add({
                // messages:[{
                //     author:userData.username || userData.email,
                //     text: chatInputValue,
                //     type:'text'
                // }],
                title:chatInputValue,
                metaTags:[],
                showCursor:true,
                systemStatus:'Waiting for Sprinthack...',
                updated:true,
                workspace_id:userData.activeWorkspace.id,
            })
            .then((newChatObject)=>{

                /** add a new message to the chat */
                const newMessage = {
                    contextId:newChatObject.id,
                    author:userData.username || userData.email,
                    text: chatInputValue,
                    type:'text',
                    workspace_id:userData.activeWorkspace.id
                };
                const postChatMsg = firebaseFunctions.httpsCallable('chats_api-chats-postChatMsg')
                postChatMsg(newMessage).then(msgObject => {
                }).catch(error => {
                    console.log('chat save error', error)
                })
                setChatInputValue('')
                history.push(`${url}/`+newChatObject.id)

            })

    }

    const _handleKeyUp = (e) => {
        if (e.key === 'Enter') {
            sendNewChatMessage()
        }
    }

    return (
        <div>
            <div className={'main-content-wrap'}>
                <div>
                    <div style={{paddingTop:'20px', paddingLeft:'18px', paddingRight:'36px', paddingBottom:'60px'}}>
                        {/*<Toolbar>*/}
                        {/*    <Typography variant="button">*/}
                        {/*        {topTitle}*/}
                        {/*    </Typography>*/}
                        {/*</Toolbar>*/}
                        {/*<div className={'title-content'}>*/}
                        {/*    <h4>What to do?</h4>*/}
                        {/*</div>*/}

                        <div className={'dash-content'}>
                            <div className={'box '}>
                                <div className={'header color-gray'}>
                                    <h5 className={'small-header'}>Examples</h5>
                                </div>
                                <div className={'quest-content'}>
                                    <div className={'box-button '+box_button_color_dark} onClick={()=>updateInputValue(examples[0])}> {examples[0]}</div>
                                    <p></p>
                                    <div className={'box-button '+box_button_color_dark} onClick={()=>updateInputValue(examples[1])}> {examples[1]}</div>
                                    <p></p>
                                    <div className={'box-button '+box_button_color_dark} onClick={()=>updateInputValue(examples[2])}> {examples[2]}</div>
                                </div>
                            </div>
                            <div className={'box '}>
                                <div className={'header color-gray'}>
                                    <h5 className={'small-header'}>Capabilities</h5>
                                </div>
                                <div className={'quest-content'}>
                                    <div className={'box-button disabled'}>Senior level scrum master will reply and breakdown your tasks</div>
                                    <p></p>
                                    <div className={'box-button disabled'}>You can review and reply for follow-up questions</div>
                                    <p></p>
                                    <div className={'box-button disabled'}>Has high level security and audit logs</div>
                                    <p></p>
                                </div>
                            </div>
                            <div className={'box '}>
                                <div className={'header color-gray'}>
                                    <h5 className={'small-header'}>Limitations</h5>
                                </div>
                                <div className={'quest-content'}>
                                    <div className={'box-button disabled'}>Might take up to few days to reply</div>
                                    <p></p>
                                    <div className={'box-button disabled'}>Limited knowledge of the context unless you fill the project data</div>
                                    <p></p>
                                    <div className={'box-button disabled'}>Might occasionally produce off context suggestions</div>
                                    <p></p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={'chat-input-wrap '+input_color_dark}>
                <input value={chatInputValue} placeholder={'What do you need done?'} onChange={(e)=>updateInputValue(e.target.value)} onKeyUp={(e)=>_handleKeyUp(e)} />
                <Button className={'sendChatButton'} onClick={(e)=>sendNewChatMessage(e)}>
                    <ListItemIcon><SendIcon /></ListItemIcon>
                </Button>
            </div>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={dashSnackbarOpen}
                autoHideDuration={6000}
                message={'Thanks, Product Architect will contact you soon via email.'}
                key={'123'}
            />

        </div>

    );
}

