import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useRouteMatch} from "react-router-dom";
import {useParams} from "react-router";
import {db} from "../../util/firebase";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Divider from '@material-ui/core/Divider';
import Timeline from '@material-ui/lab/Timeline';
import ActivityTimeline from "../generalComponents/activityTimeline"
import Project_sideDetails from "./projects_sideDetails";
import DetailHeaderEditor from "../generalComponents/DetailHeaderEditor";
import ChevronRight from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        height: 'calc(100vh - 65px)',
        flexFlow:'row wrap'
    },
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    maindetails: {
        flex: '1 100%',
        // maxWidth: '700px',
        minWidth:'400px',
        maxHeight: '100%',
        overflowY: 'scroll',
        "position":"relative",
        "flexShrink":"0",
        "WebkitBoxFlex":"100",
        "flexGrow":"100",
        "paddingBottom":"82px"
    },
    maindetailsWrapper: {
        "flexGrow":"100",
        "WebkitBoxFlex":"100",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "overflowY":"overlay"
    },
    maindetailsContent:{
        "display":"flex",
        "flexBasis":"initial",
        "flexDirection":"column",
        "position":"relative",
        "width":"calc(100% - 60px)",
        "marginLeft":"auto",
        "marginRight":"auto",
        "WebkitBoxFlex":"1",
        "flexGrow":"1",
        "flexShrink":"0",
        "paddingTop":"20px"
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%",
        "display":"flex",
        "zIndex":"1100",
        "boxSizing":"border-box",
        "flexShrink":"0",
        "flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    textareaStyle: {
        padding: '10px',
        minWidth: '260px',
        width: 'calc(100% - 35px)'
    },
    submitButton: {
        background:theme.custom.MainButton
    },
    [theme.breakpoints.up("xs")]: {
    },
    [theme.breakpoints.up("sm")]: {
        maindetails:{
            flex:'100 0px',
            order:'2'
        },
    },
    [theme.breakpoints.up("md")]: {
    },
    metadataPopup:{
        border:'1px solid transparent',
        borderColor:theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        boxShadow:'rgb(0 0 0 / 10%) 0px 4px 18px',
        height: 'calc(100vh - 105px)'
    },
    metadataTopBar:{
        height:'40px',
        padding:'10px',
        border:'1px solid transparent',
        borderBottomColor:theme.custom.OutlineButtonBorder
    },
    metadataClose:{
        // padding:'10px',
        color: theme.custom.subtitle,
        borderRadius:'2px',
        "&:hover":{
            background:theme.custom.ListBGhover
        }
    },
    metadataImage: {
        maxHeight:'365px',
        maxWidth:'100%',
        cursor:'zoom-in'
    },
    metadataImageFullContainer: {
        display:'flex',
        width:'100vw',
        height:'100vh',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        background:'rgba(0,0,0,0.628)'
    },
    metadataImageFull: {
        width: undefined,
        height: undefined,
        maxWidth:'100%',
        maxHeight:'100%'
    },
    taskListItem: {
        display:"flex",
        flexDirection:'row',
        paddingBottom:'8px',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }
    },
    menuIcon:{
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
        }
    }


}));

export default function ProjectDetails({userData, backToProjects, loadTaskDetails}) {
    const classes = useStyles();
    let { projectId } = useParams();

    dayjs.extend(relativeTime);

    const [projectData, setProjectData] = useState({})

    React.useEffect(() => {

        const unsubscribe = db
            .collection('projects')
            .doc(projectId)
            .onSnapshot(async (doc) => {
                if (doc.exists){
                    let observedData = {
                        id: doc.id,
                        ...doc.data()
                    }


                    db.collection('idea_validation')
                        .where('workspace_id', '==', userData.activeWorkspace.id)
                        .where('project', '==', doc.id)
                        .get()
                        .then(assumptions => {
                            let foundAssumptions = []
                            assumptions.forEach(assumption=>{
                                foundAssumptions.push({id: assumption.id, ...assumption.data()})
                            })

                            observedData = {
                                assumptionList : foundAssumptions,
                                ...observedData
                            }
                            setProjectData(observedData)
                        })
                    setProjectData(observedData)
                } else {
                    setProjectData({
                        id: projectId
                    })
                }
            });

        return function cleanup() { unsubscribe() }

    }, [projectId]);

    const RenderAssumptionList = () => {
        if (!projectData.assumptionList) return null
        if (projectData.assumptionList.length < 1) return <div>Tasks linked to this project appear here...</div>
        return projectData.assumptionList.map((assumption,index) => {
            // return <div key={index}>{assumption.idea}</div>
            return <div key={index} className={classes.taskListItem}><div>{assumption.idea}</div><ChevronRight onClick={()=>{loadTaskDetails(assumption)}} className={classes.menuIcon} /></div>
        })
    }

    const handleTitleSave = (savePayload) => {
        /** this will save the title to db */

        db
            .collection('projects')
            .doc(projectId)
            .update({
                title: savePayload.value
            })
            .then()
    }

    return (
        <div style={{height: 'calc(100vh - 65px)'}}>
            <div className={classes.root}>
                <div className={classes.maindetails}>
                    <div className={classes.maindetailsWrapper}>
                        <div className={classes.maindetailsContent}>
                                {/*<div className={classes.subtitle} style={{display:'flex'}}>*/}
                                {/*    <span>*/}
                                {/*        Created {dayjs(projectData.createdAt).format('MMMM DD, YYYY')}*/}
                                {/*    </span>*/}
                                {/*    <span style={{marginLeft:'auto'}}>*/}
                                {/*        /!*<Button variant="contained" color="primary" size="small" className={classes.addNewButton} onClick={readyForBacklog}>*!/*/}
                                {/*        /!*    Ready for prototyping*!/*/}
                                {/*        /!*</Button>*!/*/}
                                {/*    </span>*/}
                                {/*</div>*/}
                            {/*<h2 style={{fontWeight:'700'}}>{projectData.title}</h2>*/}
                            <DetailHeaderEditor
                                payload={{key:"title", value:projectData.title}}
                                save={handleTitleSave}
                            />
                            <div>
                                <div className={classes.subtitle}>
                                    <RenderAssumptionList />
                                </div>
                            </div>
                            <div style={{margin:'10px 0'}} />

                            <Divider />
                            <h4 style={{marginBottom:'5px'}}>Activity</h4>
                            {/*<div className={classes.subtitle}>*/}
                            {/*    <div>Gather insights. Define Problems. Set assumptions. List all the data, feedbacks and design thoughts, so that development is able to do a prototype.</div>*/}
                            {/*    <div>That means usually some UI designs, explanation of the UX, what happens where and when.</div>*/}
                            {/*</div>*/}
                            <Timeline>
                                <ActivityTimeline payload={projectData} placeholder={"Write a comment..."} />
                            </Timeline>
                        </div>
                    </div>
                </div>
                <Project_sideDetails payload={projectData}  />
            </div>
        </div>
    );
};