import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/targetusers.css'

import TargetUser from '../../../img/targetuser-02-image.png'
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    textpop:{
        color:'#ffffff',
        backgroundColor:'rgb(6, 141, 153)',
        padding: '0px 5px',
        margin:'0px 5px',
    },
    [theme.breakpoints.down("xs")]: {
        container:{
            maxWidth:'600px'
        }
    }

}));

const TargetUsers = ({
                      ...props
                  }) => {

    const classes = useStyles();

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const text_color_light = (prefersDarkMode) ? '' : 'text_color_light';

    return (
        <section>
            <div className={'container'}>
                <h2 className={'title'}>
                    Perfected for startups
                </h2>
                <div className={'maingrid'}>
                    <div className={'gridwrap'}>
                        <div className={'grid-img'}>
                            <img alt={'Targetuser'} src={TargetUser} width="540" height="540" />
                        </div>
                        <div className={'grid-list'}>
                            <div className={'textwrap'}>
                                <div className={'small-quote'}>Slowly, one jobs-to-be-done at a time, you create something new.</div>
                                <div className={'small-quote'}>Something pure and ... that is understood by all the ones that play it.</div>
                                <div className={'targetuser-row'}>
                                    <h4 className={'textTitle'}><span className={'text-purple'}>.</span>For founders</h4>
                                    <p className={'textBody '+text_color_light}>Save critical amount in costs when identifying the smartest path forward.</p>
                                </div>
                                {/*<div className={'targetuser-row'}>*/}
                                {/*    <h4 className={'textTitle'}><span className={'text-cyan'}>.</span>For talents</h4>*/}
                                {/*    <p className={'textBody '+text_color_light}>You want be part of projects without full-time work? Get ownership, or payments, based on your contribution automatically.</p>*/}
                                {/*</div>*/}
                                {/*<div className={'targetuser-row'}>*/}
                                {/*    <h4 className={'textTitle'}><span className={'text-red'}>.</span>For developers</h4>*/}
                                {/*    <p className={'textBody '+text_color_light}>You are looking for interesting projects and need to vet the team you work with.</p>*/}
                                {/*</div>*/}
                                <div className={'targetuser-row'}>
                                    <h4 className={'textTitle'}><span className={'text-blue'}>.</span>For investors</h4>
                                    <p className={'textBody '+text_color_light}>Wish your portfolio would communicate clearly what are they working on? We will fix that for you.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default TargetUsers;
