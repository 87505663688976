import React, { useState } from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import ChevronRight from "@material-ui/icons/ChevronRight";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    taskListItem: {
        display:"flex",
        flexDirection:'row',
        paddingBottom:'8px',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }
    },
    menuIcon:{
        marginLeft:'10px',
        opacity:'1',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
        }
    },

}));

const tempColors = { "Todo": 0, "In Progress": 1, "Done": 2, "Cancelled": 3 };
const stateLabels = ['Todo', 'In Progress', 'Done', 'Cancelled']

export default function TrelloCard({ id, status, title, orderId, label, cardLink, onCardHold, dragUpdate, loadCardDetails }) {
    const classes = useStyles();
    const [onHold, setOnHold] = useState(false);
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;

    const dragStartHandler = (e) => {
        e.dataTransfer.setData("cardInfo", JSON.stringify({ id, status }));
        onCardHold({id:id, status:status})
        e.target.className += " onhold";
        setTimeout(() => {
            setOnHold(true);
        }, 0);
    };
    const dragEndHandler = (e) => {
        setOnHold(false);
    };
    const onDragOverHandler = (e) => {
        e.preventDefault();
        dragUpdate(e.target.id, status)
        if (e.currentTarget.className === "card" || (e.target.className !== "cardTitle" && e.target.className !== "cardFooter")) {
            e.currentTarget.className = "card anotherCardOnTop";
            // setTimeout(() => {
            //     e.currentTarget.className = "card anotherCardOnTop";
            // }, 0);
        }
    };
    const onDragLeaveHandler = (e) => {
        resetClassName(e);
    };
    const onDropHandler = (e) => {
        e.dataTransfer.setData("cardInfo", JSON.stringify({ id, status }));
        resetClassName(e);
        /**
         Remove all anotherCardOnTop classnames
         from DOM after drop complete.
         */
        const allWithCardOnTop = Array.from(
            document.getElementsByClassName('anotherCardOnTop')
        );
        allWithCardOnTop.forEach((el)=>{
            el.className = "card";
        })
    };

    const resetClassName = (e) => {
        e.preventDefault();
        let isCard =
            e.target.className === "card" ||
            e.target.className === "card anotherCardOnTop";
        if (isCard) {
            e.target.className = `card${prefersDarkMode ? " card-dark" : ""}`;
            setTimeout(() => {
                e.target.className = `card${prefersDarkMode ? " card-dark" : ""}`;
            }, 0);
        }
    };

    return (
        <div
            id={'wrap1_'+id}
            className={`card${onHold ? " hidden" : ""}${prefersDarkMode ? " card-dark" : ""}`}
            draggable="true"
            onDragStart={dragStartHandler}
            onDragEnd={dragEndHandler}
            onDragOver={onDragOverHandler}
            onDragLeave={onDragLeaveHandler}
            onDrop={onDropHandler}
        >
            <div id={'foote_'+id} className="cardFooter">
                {/*{console.log('item::',orderId)}*/}
                {orderId ? (
                    <div className={`orderId`}>Step {orderId}</div>
                ) : (
                    <></>
                )}
                {cardLink ? (
                    <div><ChevronRight onClick={()=>{loadCardDetails(cardLink)}} className={classes.menuIcon} /></div>
                ) : (
                    <></>
                )}
            </div>
            <div id={'title_'+id} className="cardTitle">{title || '< If I do X, then Y will happen >'}</div>
            <div id={'foote_'+id} className="cardFooter">
                {label ? (
                    <div className={`label color${tempColors[stateLabels[label]]}`}>{stateLabels[label]}</div>
                ) : (
                    <div></div>
                )}

                {/*<div className="collab">*/}
                {/*    <a href="" className="collabPerson">*/}
                {/*        <i className="fas fa-user"></i>*/}
                {/*    </a>*/}
                {/*    <a href="" className="collabPerson">*/}
                {/*        <i className="fas fa-user"></i>*/}
                {/*    </a>*/}
                {/*</div>*/}
            </div>
        </div>
    );
}
