import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import axios from 'axios';
import {TextareaAutosize} from "@material-ui/core";
import {authMiddleWare} from "../util/auth";
import BASEURL from "../constants/baseurl";

import StakeholderNeedManager from "./generalComponents/stakeholderNeedManager";
import {db} from "../util/firebase";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    requestBoxWrap:{
        padding:"40px"
    },
    requestBox: {
        boxShadow:"rgb(0 0 0 / 50%) 0px 16px 70px",
        borderRadius:'10px',
        paddingTop:'20px',
        paddingLeft:'18px',
        paddingRight:'36px',
        paddingBottom:'20px',
        margin:'0px auto',
        maxWidth:'900px'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function AddNewIdea({history, userData, backToIdeas}) {
    const classes = useStyles();

    const [idea, setIdea] = useState('')

    const handleIdeaChange = (event) => {
        setIdea(event.target.value)
    }

    const handleSaveNewIdea = async (event) => {
        event.preventDefault();
        const updateObject = {
            idea: idea,
            feedbacks:[],
            workspace_id:userData.activeWorkspace.id,
            createdAt: dayjs().toISOString()
        }

        db
            .collection('idea_validation')
            .add(updateObject)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                backToIdeas()
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

    }

    return (
        <div className={classes.requestBoxWrap}>
            <div className={classes.requestBox}>
                <div>
                    <TextareaAutosize
                        aria-label="minimum height"
                        minRows={3}
                        className={classes.textareaStyle}
                        defaultValue=""
                        placeholder="Title of your assumption"
                        onChange={handleIdeaChange}
                    />

                </div>
                <div>
                    <Button
                        color="primary"
                        onClick={backToIdeas}
                    >Cancel</Button>
                    <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        className={classes.submitButton}
                        onClick={(e) => {handleSaveNewIdea(e)}}
                    >Save</Button>
                </div>

            </div>
        </div>
    );
}

