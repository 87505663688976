import React, {useState} from 'react';
import {makeStyles, TextField} from "@material-ui/core";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import dayjs from "dayjs";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ListHeader from "../../components/listHeader";
import {useRouteMatch} from "react-router-dom";
import {db} from "../../util/firebase";

const useStyles = makeStyles((theme) => ({
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRow:{
        "&:hover": {
            backgroundColor: theme.custom.TableRowHover,
            "& $taskIcon": {
                opacity: "1",
            }
        }
    },
    addNewTask: {
        padding:'12px',
        height:'44px'
    },
    tableIconBlack: {
        fontSize:theme.custom.tableiconsize,
        color:theme.custom.textColor
    },
    tableBorders:{
        margin:'10px',
        padding:'5px',
        borderLeft:'1px solid #000',
        borderRadius:'3px'
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 0px 0px 0px'
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    taskIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        padding:'3px 5px',
        fontSize:'11px',
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        },
    },
    taskChevron: {
        fontSize:'12px',
        verticalAlign:'middle',
        marginBottom:'2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },
    root:{
        minHeight:'min-height',
        display:'flex',
        // flex:'1 1 0%'
        flexFlow:'row wrap'
    },
    wrap:{
        // padding: '20px',
        flex:'100 0px',
        flexShrink:'0',
        "WebkitBoxFlex":"100","flexGrow":"100","paddingBottom":"10px",
        borderLeft:'1px solid rgba(0, 0, 0, 0.12)',
    },


    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

export default function ProjectsListTasks({userData, project, saveNewProject, loadTaskDetails}) {
    const classes = useStyles();
    const [allTasks, setAllTasks] = useState([])
    const [tasksTodo, setTasksTodo] = useState([])
    const [tasksInProgress, setTasksInProgress] = useState([])
    const [tasksDone, setTasksDone] = useState([])
    const [newTask, setNewTask] = useState('')

    React.useEffect(() => {
        if (!project) return

        /** load tasks of the project ID */
        /** set them in different brackets */

        const unsubscribe = db.collection('idea_validation')
            .where('project','==', project)
            .where('workspace_id', '==', userData.activeWorkspace.id)
            .orderBy('createdAt','asc')
            .onSnapshot(taskSnap => {
                const taskList = []
                taskSnap.forEach(task => {
                    taskList.push({
                        id: task.id,
                        ...task.data()
                    })
                })
                setAllTasks(taskList)
            })

        return function cleanup(){
            unsubscribe()
        }
    }, [project, userData.activeWorkspace.id])

    const handleNewTaskChange = (e) => {
        setNewTask(e.target.value)
    }

    const handleAddTaskSave = (e) => {
        if (e.key === 'Enter' && newTask !== '') {

            saveNewProject(newTask)
            setNewTask('')
        }
    }

    const GetTable = ({title, tasks}) => {
        return (
            <div>
                {/*<ListHeader title={'Project: '+ title} />*/}
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody>
                            {tasks.map((task, index) => {
                                return (
                                    <TableRow key={index} className={classes.tableRow} style={{height:'44px'}} onClick={(e)=>{loadTaskDetails(task)}}>
                                        <TableCell align="left" style={{ width: "1px", paddingRight:'10px'}}><RadioButtonUncheckedIcon className={classes.tableIcon} /></TableCell>
                                        <TableCell align="left" scope="row" className={classes.rowText}>
                                            <div className={classes.rowTitle}>
                                                {task.idea}
                                            </div>
                                        </TableCell>
                                        <TableCell align="right" className={classes.dateColumn}>{dayjs(project.createdAt).format('MMM DD')}</TableCell>
                                    </TableRow>
                                )
                            } )}
                            {/*<TableRow key={'-1'} className={classes.tableRow} style={{height:'44px'}} onClick={(e)=>{}}>*/}
                            {/*    <TableCell align="left" style={{ width: "1px", paddingRight:'10px'}}><AddCircleOutline className={classes.tableIconBlack} /></TableCell>*/}
                            {/*    <TableCell align="left" scope="row" className={classes.rowText}>*/}
                            {/*        <TextField*/}
                            {/*            fullWidth*/}
                            {/*            placeholder={'Add task...'}*/}
                            {/*            InputProps={{ disableUnderline: true }}*/}
                            {/*            // onKeyDown={handleAddTaskSave}*/}
                            {/*            value={newTask}*/}
                            {/*            onChange={handleNewTaskChange}*/}
                            {/*        />*/}
                            {/*    </TableCell>*/}
                            {/*</TableRow>*/}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const RenderTable = () => {
        return (
            <div>
                <GetTable title={project.title} tasks={allTasks} />
                {/*<GetTable title={'In Progress'} tasks={tasksInProgress} />*/}
                {/*<GetTable title={'Done'} tasks={tasksDone} />*/}
            </div>
        )
    }

    return <div className={classes.tableBorders}>
        <RenderTable/>
        <div>
            {/*<span style={{padding:'10px'}}><AddCircleOutline className={classes.tableIconBlack} /></span>*/}
            <TextField
                className={classes.addNewTask}
                fullWidth
                placeholder={'+ Add task...'}
                InputProps={{ disableUnderline: true }}
                onKeyDown={handleAddTaskSave}
                value={newTask}
                onChange={handleNewTaskChange}
            />
        </div>
    </div>


}
