import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function Funding({backToSprintCycleList}) {
    const classes = useStyles();
    let history = useHistory();

    const pagecontext = '';
    const promptheader = 'Turn recurring revenue into flexible technical financing';
    const promptbody = () => {
        return (
            <div>
                <p>
                    How it works?
                </p>
                <p>
                    Sync your data. Provide last 12 months revenue data, easiest is to integrate with bank accounts.
                </p>
                <p>
                    Get your tech pool value. We perform data-driven analysis, to determine the best financing for your tech.
                </p>
                <p>
                    Use the pool to get tasks done. Once all is set up, start creating requests and sending them through us to get modules developed.
                </p>
                <p>
                    Contact your architect for more. Meanwhile, you can start by creating drafts of the modules / features you need to develop.
                </p>
            </div>
        )};

    React.useEffect(() => {

    }, [])

    return (
        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
            <div className={classes.toolbar} />
            <div>
                <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
            </div>
        </div>
    );
}

