import {applyPatch, applySnapshot, flow, getSnapshot, onSnapshot, types} from 'mobx-state-tree'
import {AdminModel} from "./Admin";
import {auth, db} from "../util/firebase"
import BASEURL from "../constants/baseurl";

import {ScenarioItem, ScenarioList} from "./Scenario"
import {ProjectItem, ProjectList} from "./Project";
import {ChatList} from "./Chats";

/** example of use cases as an interactive object model
 * How to turn this into mobx ?
 *
 * in data the object is numbered items relating to
 * [stakeholder, user_need]
 */

const use_cases_object = [
    {
        name:'Send message',
        data: [
            {
                'stakeholder':0,
                'user_needs':2
            },
            {
                'stakeholder':1,
                'user_needs':4
            }
        ]
    },
    {
        name:'Save orders',
        data: [
            {
                'stakeholder':0,
                'user_needs':1
            }
        ]
    }
]



/** concept: Adminspace has root in the state model from the admin user
 * as opposite to the workspace, which has root in the space of shared users
 */

const UseCasesData = types.model({
    "stakeholder":types.number,
    "user_needs":types.number
})

const UseCases = types.model({
    "name":types.string,
    "data":types.array(UseCasesData)
})

/** use cases as references */
const UseCases2 = types.model({
    "id":types.identifier,
    "name":types.string,
    "data":types.array(UseCasesData)
})


const RequestSideDetails = types.model({
    // "Problem story":types.optional(types.string, ""),
    // "Volatilities":types.optional(types.string, ""), /* deprecated, gained from user_needs now */
    // "Components":types.optional(types.string, ""),
    // "Use cases":types.optional(types.array(UseCases), []),
    // "Activities":types.optional(types.string, ""),
    // "Roadmap chart":types.optional(types.string, ""),
    // "Component scoring":types.optional(types.string, ""),
    // "Priority list":types.optional(types.string, "")
})

const needItem = types.model({
    need:types.string,
    volatilities: types.array(types.string)
}).actions(self=>({
    addVolatility(volatility){
        self.volatilities.push(volatility)
    },
    removeVolatility(index){
        self.volatilities = self.volatilities.filter((item,filteridx) => index !== filteridx)
    }
}))

const userNeedsItem = types.model({
    stakeholder:types.string,
    values: types.optional(types.array(types.string), []), /** deprecated */
    needs: types.optional(types.array(needItem), [])
})

const RequestListItem = types.model({
    id:types.identifier,
    workspace_id:types.string,
    createdAt:types.optional(types.string,""),
    freetext: "...",
    freetext_details: "...",
    user_needs: types.array(userNeedsItem),
    status:types.number,
    state:types.string,
    ai_analysis_stage:types.maybe(types.number),
    scenarios:types.optional(types.array(types.string), []),
    scenarioList: types.optional(ScenarioList, {}),
    ping_count: types.optional(types.number, 0)

}).actions(self=>({
    loadDetails(id){
        // console.log('detail load', id)
        self.fetchItemDetails(id)
    },
    // loadScenarios(ids){
    //     self.fetchScenarioDetails(ids)
    // },
    fetchItemDetails: flow(function* fetchItemDetails(id){
        // console.log('fetchItemDetails(1)')
        const response = yield fetch(BASEURL.api+'/admin/requestdetails/'+id)
        // console.log('response', response)
        const details = yield response.json()

        // console.log('fetchItemDetails(2)')
        console.log('details',details)

        const stringDetails = JSON.stringify(details)
        // console.log('JSON.stringify(details)',stringDetails)

        const requestDetailString = RequestSideDetails.create(details)
        // applySnapshot(self.sidedetails, requestDetailString)

    }),
    saveDetails: flow(function* save() {

        /** little inconsistency, have to turn sidedetails back to map for saving */
        /** needs to be with a applySnapshot */

        let updateObject = getSnapshot(self)
        // updateObject.sidedetails = JSON.parse(updateObject.sidedetails)
        try {
            yield window.fetch(BASEURL.api+`/admin/requestdetails/${self.id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updateObject)
            })
            console.log("saved successfully")
        } catch (e) {
            console.error("Uh oh, failed to save: ", e)
        }
    }),
    updateScenarios: flow(function* updateScenario(scenarioId) {
        // console.log('scenarioId:::',scenarioId)
        if (!scenarioId) return
        self?.scenarios.push(scenarioId)
        const currentObject = getSnapshot(self)
        const updateObject = {
            scenarios : currentObject.scenarios
        }
        console.log('updateObject:::',updateObject)
        try {
            yield window.fetch(BASEURL.api+`/admin/requests/${self?.id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updateObject)
            })
            return {ok:true, message:"saved successfully"}
        } catch (e) {
            console.error("Uh oh, failed to save: ", e)
        }
    }),

    // afterCreate() {
    //     onSnapshot(self,self.save)
    // },
    updateData(target, newData) {
        self[target] = newData
    },
    updateSideDetail(target, newData){
        self.sidedetails[target] = newData
    }

})).views(self=>({
    get parsedSideDetails() {
        console.log('parsed get', self.sidedetails)
        return self.sidedetails
    }
}))

/*** all above should be on its own files */
/*** chatList is on a file of its own */

const AdminInfo = types.model({
    email: types.optional(types.string, ""),
    id: types.optional(types.string, "")
})

const RequestList = types.model({
    data:types.optional(types.array(RequestListItem), [])
})

export const AdminSpace = types.model({
    adminInfo: types.optional(AdminInfo, {}),
    chatList: types.optional(ChatList, {}),
    // projectList: types.optional(ProjectList, {}),
    // requestList:types.optional(RequestList, {}),
    taskOpen: types.optional(types.boolean, false)
}).actions(self => ({
    afterCreate() {
        /** should load admininfo, then using that load adminrequests and other stuff... */

        // self.loadAdminRequests()
    },

    setAdminUser: () => {
        const user = auth().currentUser;
        if (user){
            applySnapshot(self.adminInfo, {email: auth().currentUser.email, id:auth().currentUser.uid})
        }
    },

    loadAdminChatTasks: flow(function* loadAdminChats(authToken) {
        console.log('loadAdminChats()')

        try {
            const response = yield fetch(BASEURL.api+'/admin/chats', {
                method: "GET",
                headers: {'Authorization': `Bearer ${authToken}`}
            })
            const requests = yield response.json()

            // console.log('requests', requests)
            //
            // console.log('before snapshot', requests)
            applySnapshot(self.chatList, requests)

        } catch (error){

            console.log('error in admin request load', error)
        }
    }),

    // loadAdminProjects: flow(function* loadAdminProjects(authToken) {
    //     console.log('loadAdminProjects()')
    //
    //     try {
    //         const response = yield fetch(BASEURL.api+'/admin/projects')
    //         const requests = yield response.json()
    //
    //         console.log('requests', requests)
    //         //
    //         console.log('before snapshot', requests)
    //         applySnapshot(self.projectList, requests)
    //
    //     } catch (error){
    //
    //         console.log('error in admin request load', error)
    //     }
    // }),
    //
    // loadAdminRequests: flow(function* loadAdminRequests(authToken) {
    //     console.log('loadAdminRequests()')
    //
    //     try {
    //         const response = yield fetch(BASEURL.api+'/admin/requests')
    //         const requests = yield response.json()
    //
    //         console.log('requests', requests)
    //         //
    //         console.log('before snapshot', requests)
    //         applySnapshot(self.requestList, requests)
    //
    //     } catch (error){
    //
    //         console.log('error in admin request load', error)
    //     }
    // }),

    toggleTaskOpen: () => {
        self.taskOpen = !self.taskOpen
    }
}))