import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import EmptyPageInfoPrompt from "../components/emptyPageInfoPrompt";
import {Button, Divider, Input} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import {auth, db} from "../util/firebase";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Snackbar from "@material-ui/core/Snackbar";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Slider from '@material-ui/core/Slider';
import {validateNumber} from "../util/validateNumber";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    checkIcon:{
        color:"#93c47e"
    },
    pricingWrap:{
        display:'flex',
        flex:'1 0 auto',
        flexDirection:'column',
        WebkitBoxAlign:"center",
        alignItems:"center",
        margin:"64px 40px"
    },
    pricingTable: {
        display:'flex',
        flex:'initial',
        flexDirection:'column',
        maxWidth:'800px',
        width:'100%'
    },
    pricingRowGeneral: {
        "display":"flex",
        "flex":"initial",
        "flexDirection":"row",
        "WebkitBoxAlign":"center",
        "alignItems":"center"
    },
    pricingGrid: {
        "display":"grid",
        "width":"100%",
        "gridTemplateColumns":"192px repeat(3, 150px)",
        "gridTemplateRows":"30px 222px 12px",
        "gridAutoRows":"min-content",
        "columnGap":"50px"
    },
    pricingGridItem: {
        // "display":"flex",
        "flexShrink":"initial",
        "flexBasis":"initial",
        "flexDirection":"column",
        "WebkitBoxPack":"end",
        "justifyContent":"flex-end",
        "WebkitBoxFlex":"1",
        "flexGrow":"1"
    },
    pricingGridHeaderRowItem: {
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "WebkitBoxFlex":"1",
        "flexGrow":"1"
    },
    pricingGridHeaderRowItemPlan: {
        "flexShrink":"initial",
        "flexBasis":"initial",
        "flexDirection":"column",
        "WebkitBoxPack":"end",
        "justifyContent":"flex-end",
        "WebkitBoxFlex":"1",
        "flexGrow":"1",
        "backgroundColor":"rgb(255, 255, 255)",
        "paddingLeft":"29px",
        "paddingRight":"29px",
        "marginLeft":"-29px",
        "marginRight":"-29px"
    },
    pricingHeader: {
        "fontStyle":"normal",
        "lineHeight":"normal",
        "textAlign":"left",
        "color":theme.custom.textColor,
        "fontWeight":"500",
        "fontSize":"18px",
        "display":"flex",
        "WebkitBoxAlign":"start",
        "alignItems":"start",
        "marginBottom":"16px",
        "minHeight":"44px"
    },
    pricePoint: {
        "fontStyle":"normal",
        "textAlign":"left",
        "color":theme.custom.textColor,
        "fontWeight":"500",
        "lineHeight":"32px",
        "fontSize":"24px",
        "letterSpacing":"-0.01em",
        "display":"flex"
    },
    pricePointDetail: {
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "marginLeft":"10px",
        "marginTop":"3px"
    },
    featureDetail: {
        "fontStyle":"normal",
        "lineHeight":"normal",
        "fontWeight":"normal",
        "textAlign":"left",
        "color":theme.custom.textColor,
        "fontSize":"13px",
        "display":"flex",
        "WebkitBoxAlign":"center",
        "alignItems":"center",
        "height":"42px",
        "position":"relative",
        "borderBottom":"1px solid rgb(239, 241, 244)",
    },
    planButton: {
        paddingTop:'20px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    calcWrapper: {
        padding: '20px 0'
    },
    calcBolded: {
        fontSize:'24px',
        fontStyle:'normal',
        fontWeight:'500'
    },
    sliderWrap: {
        paddingTop:'40px'
    }
}));

export default function Pricing({userData, showButtons}) {
    const classes = useStyles();
    const history = useHistory()
    const [subsSnackbarOpen, setSubsSnackbarOpen] = useState(false)
    const [userCalcModalOpen, setUserCalcModalOpen] = useState(false);
    const [prioCalcModalOpen, setPrioCalcModalOpen] = useState(false);
    const [totalUsers, setTotalUsers] = useState(10)
    const [budget, setBudget] = React.useState(800);
    const [allocations, setAllocations] = React.useState([0,0])

    const pageContext = '';
    const promptHeader = 'Subscription';
    const promptBody = () => {
        return (
            <div>
                <p>
                    Flexible Ondemand resourcing
                </p>
                <p>
                    Unused hours will rollover to the next month.
                </p>
                {/*<p>*/}
                {/*    Includes hands-on work on planning the next tasks, writing the tasks and development of the tasks in the manner you prefer.*/}
                {/*</p>*/}
                <div className={classes.sliderWrap}>
                    <Slider
                        aria-label="Budget"
                        value={budget}
                        defaultValue={1800}
                        // getAriaValueText={valuetext}
                        valueLabelDisplay="on"
                        step={100}
                        marks
                        min={100}
                        max={9000}
                        onChange={handleBudgetChange}
                    />
                </div>
                <p>
                    Example Monthly Budget: {budget}€
                </p>
                <p>
                    Solution Analysis: {allocations[0]}h<br/>
                    Development: {allocations[1]}h
                </p>
                <p>
                    The actual usage will be decided by you. We will be in touch soon.
                </p>
                {/*<p>*/}
                {/*    The service is billed annually in advance.*/}
                {/*</p>*/}
                <Button
                    color="primary"
                    variant="contained"
                    size='small'
                    onClick={handleModalPrioButton}
                >Request</Button>

            </div>
        )};

    React.useEffect(() => {
        /** load current status of the workspace account */

    }, [])

    const handleBudgetChange = (event, newValue) => {
        if (typeof newValue === 'number') {
            setBudget(newValue);
            setAllocations(calculateMonthlyBudget(newValue))
        }
    };

    function calculateMonthlyBudget(value) {
        /** quick calc hack */
        let analysis = 0
        let devs = 0

        if (value < 300) {
            analysis = value / 100
        } else {
            analysis = 3
            // value = value

            let rest_value = value / 80

            analysis = (rest_value*0.2).toFixed(0)
            devs = (rest_value*0.8).toFixed(0)
        }

        return [analysis, devs]
    }


    const savePlan = ({plan, users}) => {
        db.collection('plan_subs')
            .add({
                workspace:userData.activeWorkspace,
                isAuthorised:userData.isAuthorised,
                userRef:auth().currentUser.uid,
                plan:plan,
                users:users
            }).then(()=>{
            setSubsSnackbarOpen(true)
        }).catch((err) => {
            console.log('err',err)
        })
    }

    const handleFreePlanButton = () => {
        const user = auth().currentUser
        if (user) {
            savePlan({plan:'free'})
        }
    }

    const handlePayGoButton = () => {
        const user = auth().currentUser
        if (user) {
            setUserCalcModalOpen(true)
        }
    }
    const handlePrioCalcButton = () => {
        const user = auth().currentUser
        if (user) {
            setPrioCalcModalOpen(true)
        }
    }

    const handleModalPayGoButton = () => {
        const user = auth().currentUser
        if (user) {
            savePlan({plan:'normalSub', users:totalUsers})
        }
    }

    const handleModalPrioButton = () => {
        const user = auth().currentUser
        if (user) {
            savePlan({plan:'prioSub', users:totalUsers})
        }
    }

    return (
        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px', paddingBottom:'150px'}}>
            <div className={classes.toolbar} />
            {/*<EmptyPageInfoPrompt pagecontext={pageContext} header={promptHeader} body={promptBody()} />*/}
            <div className={classes.pricingWrap}>
                <div className={classes.pricingTable}>
                    <div className={classes.pricingRowGeneral}>
                    </div>
                    <Divider />
                    <div className={classes.pricingGrid}>
                        <div />
                        <div />
                        <div />
                        <div />
                        <div className={classes.pricingGridItem} style={{color:'rgb(107, 111, 118)'}}>
                            <span className={classes.pricingHeader}>
                            </span>
                            <div className={classes.pricingGridHeaderRowItem}>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    {/*Platform Service*/}
                                </div>
                            </div>
                        </div>
                        <div className={classes.pricingGridItem}>
                            <span className={classes.pricingHeader}>
                                Free
                            </span>
                            <div className={classes.pricingGridHeaderRowItem}>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <span className={classes.pricePoint}>
                                        €0
                                    </span>
                                    <div className={classes.pricePointDetail}>
                                    </div>
                                </div>
                                <div style={{color:'rgb(107, 111, 118)'}}>
                                    Document work using our platform yourself
                                </div>
                                {showButtons ? (
                                    <div className={classes.planButton}>
                                        <Button
                                            color="primary"
                                            variant="outlined"
                                            size='small'
                                            disabled
                                            onClick={handleFreePlanButton}
                                        >Current plan</Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className={classes.pricingGridItem}>
                            <span className={classes.pricingHeader}>
                                1 Analysis Monthly
                            </span>
                            <div className={classes.pricingGridHeaderRowItem}>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <span className={classes.pricePoint}>
                                        €500
                                    </span>
                                    <div className={classes.pricePointDetail}>
                                        / mo
                                    </div>
                                </div>
                                <div style={{color:'rgb(107, 111, 118)'}}>
                                    Easy planning & documentation solution
                                </div>
                                {showButtons ? (
                                    <div className={classes.planButton}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size='small'
                                            onClick={handlePayGoButton}
                                        >Select</Button>
                                    </div>
                                ) : null }
                            </div>
                        </div>
                        <div className={classes.pricingGridItem}>
                            <span className={classes.pricingHeader}>
                                Full Knowledge Management
                            </span>
                            <div className={classes.pricingGridHeaderRowItem}>
                                <div style={{display:'flex', flexDirection:'row'}}>
                                    <span className={classes.pricePoint}>
                                        €750
                                    </span>
                                    <div className={classes.pricePointDetail}>
                                        / team
                                    </div>
                                </div>
                                <div style={{color:'rgb(107, 111, 118)'}}>
                                    Managed service to document & analyse company work
                                </div>
                                {showButtons ? (
                                    <div className={classes.planButton}>
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            size='small'
                                            onClick={handlePrioCalcButton}
                                        >Select</Button>
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div />
                        <div />
                        <div />
                        <div />
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Users</span>
                        <span className={classes.featureDetail}>Unlimited</span>
                        <span className={classes.featureDetail}>Unlimited</span>
                        <span className={classes.featureDetail}>Unlimited</span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Teams</span>
                        <span className={classes.featureDetail}>2</span>
                        <span className={classes.featureDetail}>Unlimited</span>
                        <span className={classes.featureDetail}>Unlimited</span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Product workspaces</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}>Unlimited</span>
                        <span className={classes.featureDetail}>Unlimited</span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Architect 1-1 calls / mo</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}>4</span>
                        <span className={classes.featureDetail}>16</span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Solution Analysis</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Sales Letter summary</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Due Diligence</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Sprint planning</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Sprint retro</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Data Designing</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Templates for documentation</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>AI-powered universal search</span>
                        <span className={classes.featureDetail}>-</span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>
                        <span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>

                        {/*<span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Priority queue</span>*/}
                        {/*<span className={classes.featureDetail}>-</span>*/}
                        {/*<span className={classes.featureDetail}>-</span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Integrations</span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>API + Webhook</span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Import + Export</span>*/}
                        {/*<span className={classes.featureDetail}>-</span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                        {/*<span className={classes.featureDetail} style={{color:'rgb(107, 111, 118)'}}>Admin tools</span>*/}
                        {/*<span className={classes.featureDetail}>-</span>*/}
                        {/*<span className={classes.featureDetail}>-</span>*/}
                        {/*<span className={classes.featureDetail}><CheckIcon className={classes.checkIcon} /></span>*/}
                    </div>
                </div>
            </div>

            <Modal
                className={classes.modal}
                open={userCalcModalOpen}
                onClose={()=>{setUserCalcModalOpen(false)}}
                closeAfterTransition
                aria-labelledby="modal-usercalc"
                aria-describedby="modal-usercalc"
            >
                <Fade in={userCalcModalOpen}>
                    <div className={classes.paper}>
                        <div>
                            <Input placeholder={'How many users?'} value={totalUsers} onChange={(e)=>{setTotalUsers(validateNumber(e))}} />
                        </div>
                        <div className={classes.calcWrapper}>
                                {totalUsers < 11 ? (
                                    <div>
                                        <div> <span className={classes.calcBolded}>€190</span> / mo </div>
                                        <div>Flat rate for 1-10 users</div>
                                    </div>
                                ) : (
                                    <div>
                                        <div> <span className={classes.calcBolded}>€{19 * totalUsers}</span> / mo </div>
                                        <div>€19 per user</div>
                                    </div>
                                )}
                        </div>
                        <Button
                            color="primary"
                            variant="contained"
                            size='small'
                            onClick={handleModalPayGoButton}
                        >Select</Button>
                    </div>
                </Fade>
            </Modal>

            <Modal
                className={classes.modal}
                open={prioCalcModalOpen}
                onClose={()=>{setPrioCalcModalOpen(false)}}
                closeAfterTransition
                aria-labelledby="modal-usercalc"
                aria-describedby="modal-usercalc"
            >
                <Fade in={prioCalcModalOpen}>
                    <div className={classes.paper}>
                        <div>
                            {/*<Input placeholder={'How many users?'} value={totalUsers} onChange={(e)=>{setTotalUsers(validateNumber(e))}} />*/}
                        </div>
                        <div className={classes.calcWrapper}>
                            {/*{totalUsers < 11 ? (*/}
                            {/*    <div>*/}
                            {/*        <div> <span className={classes.calcBolded}>€290</span> / mo </div>*/}
                            {/*        <div>Flat rate for 1-10 users</div>*/}
                            {/*    </div>*/}
                            {/*) : (*/}
                            {/*    <div>*/}
                            {/*        <div> <span className={classes.calcBolded}>€{29 * totalUsers}</span> / mo </div>*/}
                            {/*        <div>€29 per user</div>*/}
                            {/*    </div>*/}
                            {/*)}*/}
                            <div>
                                <div> <span className={classes.calcBolded}>€5900 / year</span> </div>
                            </div>

                        </div>
                        <Button
                            color="primary"
                            variant="contained"
                            size='small'
                            onClick={handleModalPrioButton}
                        >Select</Button>
                    </div>
                </Fade>
            </Modal>

            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={subsSnackbarOpen}
                autoHideDuration={6000}
                message={'Subscription request sent. An architect will get in touch through your email to set this up.'}
                key={'123'}
            />
        </div>
    );
}

