import React, {useEffect, useLayoutEffect, useState} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import EmptyPageInfoPrompt from "../components/emptyPageInfoPrompt";
import {auth, db, firebaseCore} from "../util/firebase";
import Button from "@material-ui/core/Button";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import {Divider} from "@material-ui/core";
import JoinList from "../components/joinList";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    createSpaceButton:{
        marginTop:'20px'
    },
    animatedItem: {
        animation: `$scaleIn 6s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },

    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }
}));

export default function Join() {
    const classes = useStyles();
    const history = useHistory();

    const [loading, setLoading] = useState(true)
    const [availableWorkspaces, setAvailableWorkspaces] = useState(null)

    let header = 'Available workspaces to join';
    const pageContext = (auth().currentUser) ? auth().currentUser.email : ''
    const promptHeader = <div style={{textAlign:'left'}}>{header}</div>;
    const promptBody = () => {
        return (
            <>
                <div style={{marginTop:'30px'}}>
                    <WorkspaceAvailableList/>
                    <Divider/>
                </div>
                <div style={{marginTop:'30px'}}>
                    <div>- OR -</div>
                    <br/>
                    <Button
                        variant="contained"
                        size="small"
                        color="default"
                        onClick={()=>{history.push('/')}}
                    >
                        <span>Go to your active workspace</span>
                    </Button>
                </div>
            </>
        )};

    useLayoutEffect(()=>{
        if (auth().currentUser){
            db.collection("join")
                .doc(auth().currentUser.email)
                .get()
                .then((doc)=>{
                    let docData = doc.data()
                    if (!docData) docData = {}
                    const availableIdList = Object.keys(docData).map(key => key)

                    db.collection("rejoin")
                        .doc(auth().currentUser.uid)
                        .get()
                        .then((doc2)=>{
                            let docData2 = doc2.data()
                            if (!docData2) docData2 = {}
                            const availableIdList2 = Object.keys(docData2).map(key => key)
                            const concatAvailableList = availableIdList.concat(availableIdList2)
                            if (concatAvailableList.length > 0){
                                db.collection('workspaces')
                                    .where(firebaseCore.firestore.FieldPath.documentId(),'in', concatAvailableList)
                                    .get()
                                    .then((workspaces) => {
                                        let availableSpaces = []
                                        workspaces.forEach((doc) => {
                                            const spacesData = doc.data()
                                            spacesData.id = doc.id
                                            availableSpaces.push(spacesData)
                                        })
                                        setLoading(false)
                                        setAvailableWorkspaces(availableSpaces)
                                    }).catch(err=>{
                                    console.log('err',err);
                                })
                            } else {setLoading(false)}
                        })
                })
                .catch(error=>{
                    console.log('data join err',error)

                })
        } else {
            setLoading(false)
        }

    }, [])

    const RenderContent = () => {
        if (loading) return null
        return (
            <div className={classes.animatedItemFade}>
                <EmptyPageInfoPrompt pagecontext={pageContext} header={promptHeader} body={promptBody()} />
            </div>
        )
    }

    const WorkspaceAvailableList = () => {
        if (loading) return null
        if (!availableWorkspaces) return (<p>No invitations found. Contact the workspace admin for invitation.</p>)
        return <JoinList rows={availableWorkspaces} />
    }

    return (
        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
            <div className={classes.toolbar} />
            <Button size="small" style={{position:'absolute', top:'20px', left:'25px', cursor:'pointer'}} onClick={history.goBack}><ArrowBackIosIcon/> Back</Button>
            <RenderContent/>
        </div>
    );
}

