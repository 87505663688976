import React, {useState, useEffect, useReducer} from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
// import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CssBaseline from '@material-ui/core/CssBaseline';
import cyan from '@material-ui/core/colors/cyan';

import * as ROUTES from './constants/routes';
import { auth } from './util/firebase';

import Home from './pages/home';
import Home_new from './pages/home_new';
import Hub from './pages/hub';
import Quiz from './pages/quiz';
import Feedbacks from './pages/templates/feedback';
import Share from './pages/share';
// import ShareReport from './pages/shareReport';
import ShareReport from './pages/shareReport_scenario_only';
import Template from './pages/templates/template';
import Login from './pages/login';
import Signup from './pages/signup';
import Logout from './pages/logout';
import Error from './pages/error';
import Finishsetup from './pages/finishsetup';
import Dashboard from './pages/dashboard';
import Account from './components/SettingComponents/account';
import Dashboard_new from './pages/dashboard_new';
import Dashboard_individual from './pages/dashboard_individual';
import Welcome from "./pages/welcome";
import Admin from "./pages/admin";
import LoadScreen from './components/loadscreen';
import Join from "./pages/join";
import Denied from "./pages/accessDenied";
import CreateWS from "./pages/createWS";
import Homepage_subpage_pricing from "./pages/homepage_subpage_pricing";
import Blog from "./pages/blog";
import AboutUs from "./pages/aboutus";
import Terms from "./pages/terms_of_service"
import ReactGA from "react-ga4";
import RoleWorkReport from "./pages/templates/roleWorkReport";
// import {TEAMS, PRIVATE_PLAYBOOK} from "./constants/routes";

/** Google Analytics */
// import ReactGA from 'react-ga4';
// const TRACKING_ID = "G-PBFC2KFS3V"; // OUR_TRACKING_ID
// ReactGA.initialize(TRACKING_ID);



function PrivateRoute({ component: Component, authenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authenticated === true
                ? <Component {...props} />
                : window.location.href="https://www.sprinthack.com"}
        />
    )
}

function CommunityRoute({ component: Component, authenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => window.location.href="https://sprinthack.squarespace.com"}
        />
    )
}


function AdminRoute({ component: Component, authorised, ...rest }) {
    console.log('authorised',authorised)
    return (
        <Route
            {...rest}
            render={(props) => authorised === true
                ? <Component {...props} />
                : <Component {...props} />}
        />
    )
}

function PublicRoute({ component: Component, authenticated, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) => authenticated === false
                ? <Component {...props} />
                : <Redirect to='/welcome' />}
        />
    )
}

function App(props) {
    const [state, setState] = useState({
        auth: false,
        loading: true
    });
    const [userData, setUserData] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {loading: true, email: '', username: null, profilePicture: ''}
    );

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
// prefersDarkMode = false;
    const theme = React.useMemo(
        () =>

            createMuiTheme({
                typography: {
                    fontFamily: [
                        "Inter",
                        "Roboto",
                        "Helvetica",
                        "Arial",
                        "sans-serif"
                    ].join(','),
                },
                overrides: {
                    MuiPaper:{
                        elevation5: {
                            boxShadow:'rgb(0 0 0 / 19%) 0px 3px 12px'
                        },
                        root:{
                            backgroundColor: prefersDarkMode ? 'rgb(41, 63, 68)' : '#ffffff',
                        }
                    },
                    MuiAppBar: {
                        colorPrimary: {
                            backgroundColor: "transparent"
                        }
                    },
                    MuiMenuItem: {
                        root:{
                            fontSize:'0.75rem'
                        }
                    },
                    MuiListItemText: {
                        root:{
                            fontSize:'13px'
                        },
                        secondary:{
                            fontSize:'13px'
                        }
                    },
                    MuiListItemIcon: {
                        root:{
                            minWidth:'36px'
                        }
                    },
                    MuiSvgIcon: {
                        root:{
                            fontSize:'1.3rem'
                        }
                    },
                    MuiTimelineItem: {
                        root: {
                            minHeight: '40px',
                        },
                        missingOppositeContent: {
                            "&:before": {
                                display: "none"
                            }
                        }
                    },
                    MuiStepper: {
                        root: {
                            backgroundColor: 'transparent',
                            padding:'0px'
                        }
                    },
                    MuiAccordion: {
                        root: {
                            backgroundColor: 'transparent'
                        }
                    },
                },
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                    primary: {
                        light: cyan[600],
                        main: cyan[600],
                        dark:'#505050',
                        contrastText: '#fff'
                    },
                    reversedPrimary: {
                        main: '#505050',
                        contrastText: '#fff'
                    },
                    background: {
                        // default: prefersDarkMode ? '#1f2124' : '#ffffff',
                        default: prefersDarkMode ? '#000' : '#ffffff',
                    }
                },
                custom: {
                    TableRowHover : prefersDarkMode ? 'rgba(255, 255, 255, 0.08)' : 'rgba(0, 0, 0, 0.04)',
                    RowLightUp: prefersDarkMode ? '#fff' : '#000',
                    ListHeader: prefersDarkMode ? 'rgba(38,41,43)' : 'rgb(230,244,248)',
                    MainButton: prefersDarkMode ? cyan[600] : '#262f36',
                    MainButtonHover: prefersDarkMode ? "#08cce6" : '#262f36',
                    GreenButton: prefersDarkMode ? "#aff591" : "#93c47e",
                    RedButton: prefersDarkMode ? "rgb(235, 87, 87)" : "rgb(235, 87, 87)",
                    RedButtonHover: prefersDarkMode ? "rgb(255, 100, 100)" : "rgb(255, 100, 100)",
                    GenericButton: prefersDarkMode ? "#505050" : "#505050",
                    GenericButtonHover: prefersDarkMode ? "#555555" : "#555555",
                    SmoothColor: prefersDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0,0,0,0.2)",
                    SmoothColorHover: prefersDarkMode ? "rgba(255, 255, 255, 0.38)" : "rgba(0, 0, 0, 0.04)",
                    SmoothColor2: prefersDarkMode ? "rgba(255, 255, 255, 0.7)" : "rgba(0,0,0,0.44)",
                    SmoothColor2Hover: prefersDarkMode ? "rgba(255, 255, 255, 0.38)" : "rgba(0, 0, 0, 0.24)",
                    MemberListBG: prefersDarkMode ? "rgb(39, 40, 43)" : "rgb(248, 249, 251)",
                    genericHighlightArea: prefersDarkMode ? "" : "rgb(205,242,245,1)",
                    genericHighlightText: prefersDarkMode ? "#ffffff" : "rgb(60, 65, 73)",
                    genericLongformText: prefersDarkMode ? "#cdced1" : "rgb(60, 65, 73)",
                    root: prefersDarkMode ? 'rgba(31,33,36)' : '#ffffff',
                    textColor: prefersDarkMode ? '#d7d8db' : '#000',
                    textColorInverted: prefersDarkMode ? '#fff' : '#000',
                    textColorWhiteBG: prefersDarkMode ? '#090909' : '#000',
                    textColorDarkBG: prefersDarkMode ? '#fff' : '#fff',
                    subtitle: prefersDarkMode ? '#6a6e75' : '#8e8e8e',
                    subtitleLight: prefersDarkMode ? '#6a6e75' : '#c7c7c7',
                    sidebarListItem : prefersDarkMode ? 'rgba(255, 255, 255, 0.08)' : '#cdf2f8',
                    sidedetailBackground: prefersDarkMode ? '#242424' : 'rgb(245, 246, 248)',
                    tableiconsize:'16px',
                    timelineConnector: prefersDarkMode ? '#303030':'rgb(240, 242, 245)',
                    timelineQuestion: prefersDarkMode ? 'rgba(255, 255, 255, 0.5)':'#888',
                    ListBGhover: prefersDarkMode ? 'rgba(255, 255, 255, 0.06)' : 'rgba(255, 255, 255, 0.9)',
                    ListBGhoverBlue: prefersDarkMode ? 'rgba(255, 255, 255, 0.06)' : 'rgba(205, 242, 248, 1)',
                    OutlineButtonBorder: prefersDarkMode ? 'rgba(255, 255, 255, 0.13)' : 'rgba(217, 224, 231, 1)',
                    boxShadowGlow: prefersDarkMode ? '5px 3px 15px -1px rgb(255 255 255 / 20%), 0px 15px 18px 0px rgb(255 255 255 / 14%), 0px 10px 14px 0px rgb(255 255 255 / 12%)' : 'rgb(0 0 0 / 19%) 0px 3px 12px',
                    boxShadowGlowSmall: prefersDarkMode ? '5px 3px 5px -1px rgb(255 255 255 / 20%), 0px 5px 8px 0px rgb(255 255 255 / 14%), 0px 5px 14px 0px rgb(255 255 255 / 12%)' : 'rgb(0 0 0 / 19%) 0px 3px 12px',
                    boxborder: prefersDarkMode ? '#1e1e1e' : '#5e6f72',
                    impactDataText: prefersDarkMode ? '#c06f10' : '#f2f2f2',
                    noBoxContent: prefersDarkMode ? '#6b6b6b' : '#dfdfdf',
                    textPop: prefersDarkMode ? 'rgb(6, 141, 153)' : '#dfdfdf',
                    impactBarSmall: prefersDarkMode ? '#ccc' : '#ccc',
                    impactBarGrey: prefersDarkMode ? 'rgb(6, 141, 153)' : 'rgb(145, 145, 145)',
                    impactBarRed: prefersDarkMode ? '#ff9999' : '#ff9999',
                    impactBarGreen: prefersDarkMode ? '#18F2B2' : '#18F2B2',

                }
            }),
        [prefersDarkMode],
    );


    useEffect(() => {

        auth().onAuthStateChanged((user) => {
            // console.log('auth state changed', user)
            if (user) {
                setState({
                    auth:true,
                    loading:false
                })
            } else {
                setState({
                    auth:false,
                    loading:false
                })
            }
        })

        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "app home page" });
    }, []);

    return state.loading === true ? (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <LoadScreen/>
        </MuiThemeProvider>
    ) : (
        <MuiThemeProvider theme={theme}>
            <CssBaseline/>
            <Router>
                <Switch>

                    <Route exact path={ROUTES.TERMS} render={() => <Terms />}/>
                    <Route exact path={ROUTES.ADMIN} render={() => <Admin store={props.store} user={state.user} />} />
                    <Route exact path={ROUTES.PRICING_HOMEPAGE} render={() => <Homepage_subpage_pricing />}/>
                    <Route exact path={ROUTES.BLOG} render={() => <Blog />}/>
                    <Route exact path={ROUTES.ABOUTUS} render={() => <AboutUs />}/>
                    <Route exact path={ROUTES.BLOGARTICLE} render={() => <Blog />}/>
                    <Route exact path={ROUTES.HUB} render={() => <Hub />}/>
                    <Route exact path={ROUTES.QUIZ} render={() => <Quiz />}/>
                    <Route path={ROUTES.FEEDBACK} component={Feedbacks}/>
                    <Route path={ROUTES.SHARE} component={Share}/>
                    <Route path={ROUTES.REPORT} component={ShareReport}/>
                    <Route path={ROUTES.TEMPLATE} component={Template}/>
                    <Route path={ROUTES.LOG_IN} authenticated={state.auth} component={Login}/>
                    <Route path={ROUTES.SIGN_UP} authenticated={state.auth} component={Signup}/>
                    <Route path={ROUTES.LOG_OUT} authenticated={state.auth} component={Logout}/>
                    <Route path={ROUTES.ERROR} component={Error}/>
                    <PrivateRoute path={ROUTES.JOIN} authenticated={state.auth} component={Join}/>
                    <PrivateRoute path={ROUTES.DENIED} authenticated={state.auth} component={Denied}/>
                    <PrivateRoute path={ROUTES.CREATE_WS} authenticated={state.auth} component={CreateWS}/>
                    <Route path={ROUTES.SETUP} component={Finishsetup}/>
                    <PrivateRoute path={ROUTES.WELCOME} authenticated={state.auth} component={Welcome}/>
                    {/* ROUTES.WORKSPACE has to be the last one */}
                    {/*<PrivateRoute path={ROUTES.BOARD} authenticated={state.auth} component={board}/>*/}
                    {/*<PrivateRoute path={ROUTES.CYCLES} authenticated={state.auth} component={board}/>*/}
                    {/*<PrivateRoute path={ROUTES.CYCLES_REQUEST} authenticated={state.auth} component={board}/>*/}

                    <Route exact path={ROUTES.ROLE_REPORT} render={() => <RoleWorkReport />}/>
                    <Route exact path={ROUTES.HOME} render={() => <Home />}/>
                    <CommunityRoute exact path={ROUTES.COMMUNITY}/>
                    {/*<Route exact path={ROUTES.HOME} render={() => <Home_new />}/>*/}
                    <PrivateRoute path={ROUTES.DASHBOARD} authenticated={state.auth} component={Dashboard_individual}/>
                    <PrivateRoute path={ROUTES.PROFILE} authenticated={state.auth} component={Account}/>
                    <PrivateRoute path={ROUTES.WORKSPACE} authenticated={state.auth} component={Dashboard_new}/>

                </Switch>
            </Router>
        </MuiThemeProvider>
    );

 }

export default App;

