import React, {useState} from "react";
import {makeStyles} from "@material-ui/core";
import Menu from "@material-ui/core/Menu/Menu";
import Fade from "@material-ui/core/Fade";
import ChevronRight from '@material-ui/icons/ChevronRight';
import Visibility from '@material-ui/icons/Visibility';
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    mainRow:{
        display:'flex',
        flexDirection:'row',
        transition:'150ms',
        "-webkit-box-align":'center',
        alignItems:'center',
        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            },
            "& $menuIcon2": {
                opacity: "1",
            }
        }
    },
    sideDetailValueLinkElement: {
        width:'fit-content',
        padding:'5px 10px',
        color:'inherit',
        border: '1px solid transparent',
        borderRadius:'4px',
        transitionProperty:'border, background, color',
        transitionDuration:'150ms',
        // "&:hover":{
        //     border:'1px solid rgb(239, 241, 244)',
        //     borderColor: theme.custom.OutlineButtonBorder,
        //     background:theme.custom.ListBGhover,
        //     textDecoration:'none',
        // }
    },
    sideDetailValueLinkElementCompleted: {
        textDecoration:'line-through'
    },
    menuItem:{
        display:'flex',
        flexDirection:'row',
        padding:'5px 10px',
        transition:'150ms',
        cursor: 'pointer',

    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        }
    },
    menuIcon2: {
        marginLeft:'10px',
        opacity:'0',
    },
    divider: {
        margin:'0'
    },
    link:{
        display:'flex',
        flexDirection:'row',
        transition:'150ms',
        "-webkit-box-align":'center',
        alignItems:'center',
        color:'inherit',

        textDecoration: 'none',
        cursor: 'default'
    }

}));

export default function ButtonForUseCases ({index, text, toggle, storiesRef, iconClick, icon, hasOkIcon, completedStatus, completedClick}) {
    const classes = useStyles();
    // const [usecaseAnchorEl, setUsecaseAnchorEl] = useState(null)
    // const [usecase_menu_open, setUsecase_menu_open] = useState(false);
    const [completed, setCompleted] = useState(completedStatus)

    const handleCompleteClick = () => {
        /** should save to db here */
        setCompleted(!completed)
        completedClick(index, !completed)
    }

    return (
        <div className={classes.mainRow}>

            <span className={classes.link} onClick={()=>{
                toggle(index)
            }}>
                {(completed) ? (
                    <span className={clsx(classes.sideDetailValueLinkElement, classes.sideDetailValueLinkElementCompleted)}>{text}</span>
                ) : (
                    <span className={classes.sideDetailValueLinkElement}>{text}</span>
                )}
            </span>
            {(hasOkIcon) ?
                <span onClick={() => {
                    iconClick(storiesRef)
                }}>
                    {(icon === 'eye') ?
                        <InsertLinkIcon className={classes.menuIcon}/>
                        :
                        <ChevronRight className={classes.menuIcon}/>
                    }
                </span>
                :
                null
            }
            <span onClick={()=>{}}>
            {(hasOkIcon) ?
                (completed) ?
                    <CheckCircleIcon className={classes.menuIcon2} style={{color:'rgba(199,232,172)'}} onClick={handleCompleteClick} />
                    :
                    <CheckCircleOutlineIcon className={classes.menuIcon2} onClick={handleCompleteClick} />
                :
                null
            }
            </span>

        </div>
    )
}