import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import "../../css/teamdetails.css"
import {Divider} from "@material-ui/core";
import GeneralDropdown from "../../util/generalDropdown";
import FreetextEditor from "../generalComponents/FreetextEditor";
import RoleTitleEditor from "../generalComponents/RoleTitleEditor";
import {PostAdd} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({

}))

export default function TeamRolesRow({userData, payload, team, readOnly, RowUpdate}) {
    const classes = useStyles()

    useEffect(()=>{

    }, [payload, readOnly])

    const HandleRoleTitleUpdate = (newState) => {
        RowUpdate("role", {newState: newState, roleData:payload})
    }

    const HandleUpdateAllocationState = (newState) => {
        RowUpdate("allocation", {newState: newState.newState, roleData:payload})

    }
    const HandleUpdateAssignee = (newState) => {
        RowUpdate("assignee", {newState: newState.newState, roleData:payload})
    }

    const HandleSpecsSave = (displayData) => {
        RowUpdate("requirements", {newState: displayData, roleData:payload})
    }

    const HandleOpenDocUpdate = () => {
        // console.log('payload log/',team,'/',payload.id)
        // history.push('log'+team+'/'+payload.id)
    }

    return <div>
            <div className={"role-wrap"}>
                <div className={"row"}>
                    <div className={"section"}>
                        <div className={"row-column"}>
                            <div className={"role-title"}>
                                <RoleTitleEditor payload={{key:"role", value:payload.role, placeholder:'Role title'}} save={HandleRoleTitleUpdate} />
                                {/*{payload.role}*/}
                            </div>
                        </div>
                        <div className={"row-column"}>
                            <div className={"allocation"}>
                                <span style={{float:"right", minWidth:'100px'}}>
                                <GeneralDropdown
                                    state={payload.allocation}
                                    statePlaceholder = "h/week"
                                    list={[
                                        {display:"Internal", key:'1_internal'},
                                        {display:"Flex allocation", key:'2_open'}
                                    ]}
                                    placeholderText={"set weekly hours"}
                                    updateStateChange={HandleUpdateAllocationState}
                                    showFilter
                                    small
                                />
                                </span>

                                {/*<div className={"button-simple"}>*/}
                                    {/*{payload.allocation && payload.allocation !== "" ? payload.allocation :*/}
                                    {/*    <span className={"placeholder"}>h/week</span>*/}
                                    {/*}*/}
                                {/*</div>*/}
                            </div>
                        </div>
                    </div>
                    <div className={"section"}>
                        <div className={"row-column"}>
                            <div className={"assignee"}>
                                <div className={"button-simple"}>
                                    <GeneralDropdown
                                        state={payload.assignee}
                                        statePlaceholder = "Unassigned"
                                        list={[]}
                                        placeholderText={"assignee email"}
                                        updateStateChange={HandleUpdateAssignee}
                                        showFilter
                                        small
                                    />
                                    {/*{payload.assignee && payload.assignee !== "" ? payload.assignee :*/}
                                    {/*    <span className={"placeholder"}>Unassigned</span>*/}
                                    {/*}*/}
                                </div>
                            </div>
                        </div>
                        <div className={"row-column"}>
                            <div className={"update_doc"}>
                                <div className={"button-simple button-simple_gray"}>
                                    <a href={'/log/'+team+'/'+payload.id} target={'_blank'}><PostAdd onClick={HandleOpenDocUpdate}/></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"section padding-top-section"}>
                        <div className={"row-column"}>
                            <div className={"specs"}>
                                <FreetextEditor
                                    payload={{key:"requirements", value:payload.requirements, placeholder:'Role requirements'}}
                                    save={HandleSpecsSave}
                                />



                                {/*{payload.specifications && payload.specifications !== "" ? payload.specifications :*/}
                                {/*    <span className={"placeholder"}>Role requirements</span>*/}
                                {/*}*/}
                            </div>
                        </div>
                    </div>
                </div>
                <Divider/>
            </div>
        </div>
}
