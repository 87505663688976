import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {db} from "../../util/firebase";
import dayjs from "dayjs";
import {useRouteMatch} from "react-router-dom";
import {useParams} from "react-router";
import relativeTime from "dayjs/plugin/relativeTime";
import DetailHeaderEditor from "../generalComponents/DetailHeaderEditor";
import FreetextEditor from "../generalComponents/FreetextEditor";
import TrelloBoard from "../../util/TrelloBoard";
import "../../css/Trello.css"
import useMediaQuery from "@material-ui/core/useMediaQuery";
import {FeatureBoard} from "./FeatureBoard";

const useStyles = makeStyles((theme) => ({
    content:{
        paddingTop:'20px',
        marginLeft:'30px',
        paddingBottom:'20px'
    },
    timeline_author:{
        fontWeight: 500
    },
    timeline_action: {
        color:theme.custom.textColor,
        padding:'10px',
        border: '1px solid #BEBEBE',
        borderRadius:'10px',
        cursor:'pointer',
        pointerEvents:'none'
    },
    timeline_createdAt: {
        color:theme.custom.subtitle

    },
    timeline_question:{
        fontWeight: 500,
        color:theme.custom.timelineQuestion
    },
    timeline_connector:{
        backgroundColor:theme.custom.timelineConnector
    },
    timeline_icon:{
        margin:'5px 0',
        fontSize:'16px'
    },
    largeTimeNumber: {
        fontSize: '20px'
    },
    timeline_comment: {
        margin: '10px 0',
        "boxShadow":"rgb(0 0 0 / 9%) 0px 3px 12px",
        "borderRadius":"4px",
        "padding":"12px 16px"
    },
    textareaStyle: {
        padding: '10px',
        minWidth: '260px',
        width: 'calc(100% - 35px)'
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover}
    },
    timelineItem: {
        // margin:'10px 0'
        minHeight:'50px'
    },
    timelineContent: {
        pointerEvents:'none'
    }

    })
)

export default function FeatureLogicSteps({userData, loadCardDetails}) {
    const classes = useStyles();
    let { featureId } = useParams();
    let { url } = useRouteMatch();

    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;

    dayjs.extend(relativeTime);

    const [featureData, setFeatureData] = useState({})
    const [loading, setLoading] = useState(true)

    React.useEffect(()=>{

        const unsubscribe = db
            .collection('features_confirmed')
            .doc(featureId)
            .onSnapshot( (doc) => {
                console.log('call 1')
                if (doc.exists) {
                    let observedData = {
                        id: doc.id,
                        ...doc.data()
                    }

                    setFeatureData(observedData)
                }
            }, (error) => {
                setLoading(false)
                console.log(error)
            })

        return () => {
            unsubscribe(); // detaches the listener
        }

    },[featureId, userData.activeWorkspace.id])


    const handleTitleSave = (savePayload) => {
        /** this will save the title to db */

        db
            .collection('features_confirmed')
            .doc(featureId)
            .update({
                title: savePayload.value
            })
            .then()

    }

    const keyValueSave = (desciptionSavePayload) => {
        db
            .collection('features_confirmed')
            .doc(featureId)
            .update({
                description: desciptionSavePayload.value
            })
            .then()

    }

    // /** handle drag data */
    // const cardChangeHandler = (cardInfo, newStatus, targetCardId) => {
    //     console.log('card Change handler')
    //     const { id, status: oldStatus } = cardInfo;
    //
    //     /** slice id identifiers from cardid */
    //     targetCardId = targetCardId.slice(6)
    //
    //     let dropCard = featureData.taskList[oldStatus].find((el) => el.id === id);
    //     let targetCard =
    //         targetCardId !== ""
    //             ? featureData.taskList[newStatus].find((el) => el.id === targetCardId)
    //             : null;
    //
    //     let newListOrderValueMax = featureData.taskList[newStatus]
    //         .map((item) => item.feature_order)
    //         .reduce((maxValue, a) => Math.max(maxValue, a), 0);
    //
    //     // CASE 1: If same list, work only this if block then return;
    //     if (oldStatus === newStatus) {
    //         let temp = featureData.taskList[oldStatus]
    //             .map((item) => {
    //                 if (item.id === dropCard.id){
    //                     /** dropcard handle */
    //                     console.log('dropcard order',targetCard.feature_order)
    //
    //                     if (dropCard.feature_order > targetCard.feature_order) {
    //                         console.log('high to low')
    //                         return {
    //                             ...dropCard,
    //                             feature_order: targetCard
    //                                 ? targetCard.feature_order - 1
    //                                 : newListOrderValueMax + 1,
    //                         };
    //                     }
    //                     if (dropCard.feature_order < targetCard.feature_order) {
    //                         console.log('low to high')
    //                         return {
    //                             ...dropCard,
    //                             feature_order: targetCard
    //                                 ? targetCard.feature_order + 1
    //                                 : newListOrderValueMax + 1,
    //                         };
    //                     }
    //
    //                 }
    //
    //                 console.log('other order',item.feature_order)
    //                 return item;
    //             })
    //             .sort((a, b) => a.feature_order - b.feature_order)
    //             .map((item, i) => {
    //                 return { ...item, feature_order: i + 1 };
    //             });
    //         console.log('---')
    //         let temp2 = {...featureData.taskList, [oldStatus]:temp}
    //         setFeatureData((d) => {
    //             return { ...d, taskList: temp2 };
    //         });
    //
    //         return;
    //     }
    //     // CASE 1 ENDS HERE
    //
    //     // CASE 2: Drag across multiple lists
    //     let tempGaveList = featureData.taskList[oldStatus]
    //         .filter((item) => item.id !== id)
    //         .sort((a, b) => a.feature_order - b.feature_order)
    //         .map((item, i) => {
    //             return { ...item, feature_order: i + 1 };
    //         });
    //
    //     let tempReceivedList = [
    //         ...featureData.taskList[newStatus],
    //         {
    //             ...dropCard,
    //             feature_order: targetCard ? targetCard.feature_order - 1 : newListOrderValueMax + 1,
    //         },
    //     ]
    //         .sort((a, b) => a.feature_order - b.feature_order)
    //         .map((item, i) => {
    //             return { ...item, feature_order: i + 1 };
    //         });
    //
    //     // At last, set state
    //     let temp3 = {...featureData.taskList, [oldStatus]:tempGaveList, [newStatus]: tempReceivedList}
    //
    //     setFeatureData((d) => {
    //         return { ...d, taskList: temp3 };
    //     });
    //
    //     // CASE 2 ENDS HERE
    // };
    // /** Handle Data ends here */

    return <div className={classes.content}>
        <DetailHeaderEditor
            payload={{key:"title", value:featureData.title}}
            save={handleTitleSave}
        />
        <FreetextEditor payload={{value:featureData.description, placeholder:'add key feature value...'}} save={keyValueSave}/>
        <p></p>

        {/* App Board */}
        <main className={"app-board "+(prefersDarkMode ? "app-board-dark":"")}>
            {/* Board */}
            <section className="board-body">
                <div className="wrap-lists">

                    <FeatureBoard userData={userData} featureData={featureData} loadCardDetails={loadCardDetails} />
                    {/*{featureData.listName && featureData.listName.map((l) => (*/}
                    {/*    <TrelloBoard*/}
                    {/*        data={featureData.taskList[l.status]}*/}
                    {/*        key={l.status}*/}
                    {/*        title={l.title}*/}
                    {/*        status={l.status}*/}
                    {/*        onChange={cardChangeHandler}*/}
                    {/*    />*/}
                    {/*))}*/}
                    {/*<div className="board-col">*/}
                    {/*    <div className="list">*/}
                    {/*        <a className="btn-newList addNewColumn">+ Add another scenario</a>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                </div>
            </section>
        </main>

    </div>

}
