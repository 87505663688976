import React, {useEffect, useState} from 'react';
import { useHistory, useParams } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import SPH_logo from '../img/sprinthack_logo_white_dark_small.png'
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import dayjs from "dayjs";
import axios from "axios";
import BASEURL from "../constants/baseurl";
import RequestDetailsEditor from "../components/activeExperiments/requestDetailsEditor"
import Divider from "@material-ui/core/Divider";
import Timeline from "@material-ui/lab/Timeline";
import ActivityTimeline from "../components/generalComponents/activityTimeline";
import LoadScreen from "../components/loadscreen";
import clsx from "clsx";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Button from "@material-ui/core/Button";
import ButtonForUseCases from "../components/activeExperiments/buttonForUseCases";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import ComponentStructure from "../components/activeExperiments/componentStructure";
import BDDscenarioList from "../components/activeExperiments/BDDscenarioList";

const useStyles = makeStyles((theme) => ({
    a:{
        all: 'unset',
        cursor:'pointer',
        textAlign:'center'
    },
    logowrap:{
        display:'flex',
        alignItems:'center',
        flexDirection:'column',
        marginBottom:'40px',
    },
    logo:{
        border: '1px solid transparent',
        padding:'20px',
        borderRadius:'4px',
        transitionProperty:'border, background, color',
        transitionDuration:'150ms',
        "&:hover":{
            border:'1px solid rgb(239, 241, 244)',
            borderColor: theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover,
            textDecoration:'none',
        }
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        alignItems:'center'
    },
    settingContent: {
        "display":"flex","flex":"1 0 auto","flexDirection":"column","WebkitBoxAlign":"center","alignItems":"center","margin":"20px 30px"
    },
    settingContentColumn: {
        "display":"flex","flex":"initial","flexDirection":"column","width":"100%"
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    freeTextContent: {
        color:theme.custom.genericLongformText,
        paddingBottom:'15px',
        whiteSpace:'pre-wrap'
    },
    reportBox:{
        minHeight:'80px',
        maxWidth:"1200px",
        margin: "0px auto 30px auto"
    },
    reportBoxHeader:{
        width:'100%',
        padding:'10px 0 10px 10px'
    },
    noContentReportBox:{
        width:'100%',
        height:'100%',
        textAlign:'center',
        lineHeight: '100px',
        color:theme.custom.noBoxContent,
        fontSize:'20px',
        fontWeight:'700'
    },
    noContentReportBoxSmall:{
        width:'100%',
        height:'100%',
        textAlign:'center',
        lineHeight: '50px',
        color:theme.custom.noBoxContent,
        fontSize:'20px',
        fontWeight:'700'
    },
    reportBoxSplit1:{
        display:'flex',
        flexDirection:'row',
    },
    reportBoxContent:{
    },
    reportBoxContentCenter:{
        textAlign:'center',
        lineHeight: '100px',
    },
    reportBoxLineHead: {
        marginTop:'20px',
        borderBottom: '1px solid #9b9b9b'
    },
    largeCountNumber: {
        fontSize:'42px'
    },
    headerFlex: {
        display:'flex',
        flexDirection:'row',
        position:'relative'
    },
    headerNumber: {
        position:'absolute',
        top:'-10px',
        right:'10px',
        fontSize:'42px',
    },



    maindetailsContent:{
        minHeight:'min-height',
        display:'flex',
        // flex:'1 1 0%'
        flexFlow:'row wrap'
    },
    main:{
        flex: '1 50%',
        padding:'20px',
        minHeight:'200px',
        minWidth:"250px",
        flexShrink:'0',
        "WebkitBoxFlex":"100","flexGrow":"100","paddingBottom":"48px"
    },

    aside:{
        flex: '1 50%',
        padding:'20px',
        background: theme.custom.sidedetailBackground,

        minHeight:'200px',
        // "maxWidth":"600px",
        "WebkitBoxFlex":"1",
        "flexGrow":"1"

    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'28px'
    },
    sidedetailsRowHeader: {
        fontWeight: 600,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'150px',
        flexShrink: '0'
    },
    sidedetailValue: {
        "verticalAlign":"top",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "minWidth":'0',
        "userSelect":'none',

        // "whiteSpace":"nowrap",
        // "overflow":"hidden !important",
        // "textOverflow":"ellipsis"
    },
    sidedetailValueLink: {
        // "display":"inline-block",
    },
    sidedetailValueArrRow: {
        marginBottom:'10px'
    },
    sideDetailValueLinkElement: {
        width:'fit-content',
        padding:'5px',
        color:'inherit',
        border: '1px solid transparent',
        borderRadius:'4px',
        transitionProperty:'border, background, color',
        transitionDuration:'150ms',
        "&:hover":{
            border:'1px solid rgb(239, 241, 244)',
            borderColor: theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover,
            textDecoration:'none',
        }
    },
    sideDetailLinkBorder:{
        borderColor:theme.custom.OutlineButtonBorder,
    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        }
    },
    link:{
        display:'flex',
        flexDirection:'row',
        transition:'150ms',
        "-webkit-box-align":'center',
        alignItems:'center',
        color:'inherit',
        textDecoration: 'none',
        cursor: 'default',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }

    },
    volatilityToggle:{
        transitionProperty:'border, background, color',
        borderColor:theme.custom.OutlineButtonBorder,
        transitionDuration:'150ms',
        "&:hover":{
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover,
            textDecoration:'none',
        }
    },
    metadataImageFullContainer: {
            display:'flex',
            width:'100vw',
            height:'100vh',
            flex: 1,
            alignItems: 'center',
            justifyContent: 'center',
            background:'rgba(0,0,0,0.628)'
        },
    metadataImageFull: {
            width: undefined,
            height: undefined,
            maxWidth:'100%',
            maxHeight:'100%'
        },

    [theme.breakpoints.up("xs")]: {
        // aside:{
        //     flex: '10 auto'
        // }
    },
    [theme.breakpoints.up("sm")]: {
        main:{
            flex:'100 0px',
            order:'2'
        },
        aside:{
            order:'3'
        }
    },
    [theme.breakpoints.up("md")]: {
    },

}));

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ShareReportScenario() {
    const classes = useStyles();
    const { hash } = useParams();
    const [loading, setLoading] = useState(true);
    const [activeTab, setActiveTab] = React.useState(0);
    const [requestData, setRequestData] = useState({})
    const [showVolatilities, setShowVolatilities] = useState(false)
    const [componentStructureOpen, setComponentStructureOpen] = useState(false);

    useEffect(()=>{
        fetchDetails()
    }, [])

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const fetchDetails = async () => {
        /** todo
         * Report should be about the project, and about the assumptions
         * */


        /** here we could also set a remote loading of the questions */

        /** get request data based on url hash */
        // const snapshot = await axios.get(BASEURL.api+'/cycles/shared/backlog/'+hash).then()

        // const responseData = snapshot.data

        // console.log('responseData::',responseData)

        /** demo purposes */
        // if (responseData.freetext === "Search for a product and add to a basket") {
        //     responseData.sprints = [
        //         {
        //             sprint: 'Sprint #1',
        //             issues: [
        //                 "Fetch products",
        //                 "Add to basket"
        //             ]
        //         }
        //     ]
        //     responseData.validationCount = 3
        //
        // }


        /** demo & test data */
        // responseData.validationCount = 21
        // responseData.phase = 2
        // responseData.metrics = ["Users","Revenue","Churn"]


        // setRequestData(responseData)
        setLoading(false)
    }

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    const buildStepDetails = ({detailsString}) => {
        /** does not exists */
        if (!detailsString) return null
        /** is a placeholder element */
        if (typeof detailsString != 'string') return detailsString
        /** is a text string */
        const detailsArr = detailsString.split(',')
        const detailsList = detailsArr.map((detail, index) => {
            if (detail.trim() === '') return false
            return <li key={index} value={index+1}>{detail}</li>
        })
        return <ol style={{margin:'0px', paddingLeft:'15px'}}>{detailsList}</ol>
    }

    const drawComponentMap = (text) => {
        setComponentStructureOpen(true);
    }

    const buildSidetailText = (text, detail) => {

        if (validURL(text) || ['Components','Estimates','Component scoring','Priority list'].includes(detail)) {
            if (typeof text === 'string' && text.substring(0,5) === "https") {
                if (['Components'].includes(detail)){
                    return <div className={classes.link} onClick={()=>{ drawComponentMap(text) }}>
                        <span className={clsx(classes.sideDetailValueLinkElement, classes.sideDetailLinkBorder)}>OPEN</span>
                        <ChevronRight className={classes.menuIcon} />
                    </div>
                } else {
                    const url = text
                    return <a className={classes.link} href={url} target={'_blank'}>
                        <span className={clsx(classes.sideDetailValueLinkElement, classes.sideDetailLinkBorder)}>OPEN</span>
                        <ChevronRight className={classes.menuIcon} />
                    </a>
                }
            }
        }
        return text
    }

    const buildSidetailVolatilityToggle = (index, detail, sidedetailText) => {
        return (
            <div key={index}>
                <div className={classes.sidedetailsRow}>
                    <div className={classes.sidedetailsRowHeader}>{detail}: </div>
                    {/*<div className={classes.sidedetailValue}>{sidedetailText}</div>*/}
                    <div>
                        <Button size='small' variant='outlined' className={classes.volatilityToggle} onClick={toggleVolatilities}>{showVolatilities ? "Hide" : "Show"}</Button>
                    </div>
                </div>
                {showVolatilities ?
                    <div className={classes.sidedetailsRow}>
                        <div className={classes.sidedetailsRowHeader}/>
                        <div className={classes.sidedetailValue}>{sidedetailText}</div>
                    </div> : null }
            </div>
        )
    }

    const buildSidetailUsecaseToggle = (index, detail, sidedetailData) => {
        let displayEl = "";
        if (sidedetailData instanceof Array) {
            /** array of objects */
            displayEl = sidedetailData.map((item, index)=>{
                const itemName = item.name.trim();
                return <ButtonForUseCases key={index} index={index} text={itemName} toggle={toggleMetadata} storiesRef={item.data} iconClick = {()=>{}} icon={'eye'} completedClick={()=>{}} />
            })


        } else {
            /** string of use cases separated by comma */
            const lisArr = sidedetailData.split(',')
            displayEl = lisArr.map((item, index)=>{
                item = item.trim();
                return <ButtonForUseCases key={index} text={item} requestId={requestData.requestId} toggle={toggleMetadata} />
            })
        }

        return (
            <div className={classes.sidedetailsRow} key={index}>
                <div className={classes.sidedetailsRowHeader}>{detail}: </div>
                <div className={classes.sidedetailValue}>{displayEl}</div>
            </div>
        )
    }

    const SidedetailsCustom = () => {
        if (!requestData.sidedetails) return false;

        let elements = ['','','','','']

        Object.keys(requestData.sidedetails).forEach((detail, index) => {
            let sidedetailText = requestData.sidedetails[detail]

            /** custom set for volatilies to come from user_needs */
            if (detail === 'Volatilities') {
                let user_need_volatilities = {}
                requestData.user_needs &&  requestData.user_needs.forEach(stakeholder=>{
                    stakeholder.needs && stakeholder.needs.forEach(need=>{
                        need.volatilities.forEach(volatility=>{
                            if(!user_need_volatilities[volatility]) {
                                user_need_volatilities[volatility] = 1
                            } else {
                                user_need_volatilities[volatility]++
                            }
                        })
                    })
                })
                sidedetailText = ''
                Object.keys(user_need_volatilities).forEach((volatility)=>{
                    /** build text */
                    sidedetailText += `${volatility} (${user_need_volatilities[volatility]}),`
                })
            }

            /** check if is array? */
            if (requestData.sidedetails[detail] instanceof Array) {
                sidedetailText = []

                requestData.sidedetails[detail].forEach((item, index) => {
                    const textItem = buildSidetailText(item, detail)
                    sidedetailText.push(<div key={index} className={classes.sidedetailValueArrRow}>{textItem}</div>)
                });

            } else {
                sidedetailText =  (!sidedetailText || sidedetailText === '')  ? (
                    <span />
                ) : (
                    sidedetailText
                );

                /** hardcoded list for now */
                if (detail === 'Use cases' || detail === 'Volatilities') {
                    sidedetailText = buildStepDetails({detailsString:sidedetailText})
                } else {
                    sidedetailText = buildSidetailText(sidedetailText, detail)
                }
            }


            const el = (
                <div className={classes.sidedetailsRow} key={index}>
                    <div className={classes.sidedetailsRowHeader}>{detail}: </div>
                    <div className={classes.sidedetailValue}>{sidedetailText}</div>
                </div>
            )

            if ( detail === 'Problem story') {elements[0] = el; return;}
            // if ( detail === 'Volatilities') {elements[1] = el; return;}
            if ( detail === 'Volatilities') {elements[1] = buildSidetailVolatilityToggle(index, detail, sidedetailText); return;}
            if ( detail === 'Components') {elements[2] = el; return;}
            // if ( detail === 'Use cases') {elements[3] = el; return;}
            if ( detail === 'Use cases') {elements[4] = buildSidetailUsecaseToggle(index, detail, requestData.sidedetails[detail]); return;}
            if ( detail === 'Activity list') {elements[5] = el; return;}
            if ( detail === 'Roadmap chart') {elements[6] = el; return;}
            if ( detail === 'Estimates') {elements[7] = ''; return;}
            if ( detail === 'Priority list') {elements[8] = ''; return;}
            if ( detail === 'Component scoring') {elements[9] = ''; return;}
            if ( detail === 'Diagrams') {elements[10] = ''; return;}

            elements.push(el)
        });

        return elements
    }

    const ExecutiveData = () => {
        let elements = []

        /** estimated number of sprints */
        elements.push(
            <div className={classes.sidedetailsRow} key={103}>
                <div className={classes.sidedetailsRowHeader}>Number of sprints </div>
                <div className={classes.sidedetailValue}>6</div>
            </div>
        )

        /** Planning time consumed */
        elements.push(
            <div className={classes.sidedetailsRow} key={100}>
                <div className={classes.sidedetailsRowHeader}>Planning time consumed: </div>
                <div className={classes.sidedetailValue}>7 days</div>
            </div>
        )

        /** estimated time to implement */
        elements.push(
            <div className={classes.sidedetailsRow} key={101}>
                <div className={classes.sidedetailsRowHeader}>Estimated time to implement: </div>
                <div className={classes.sidedetailValue}>55 days</div>
            </div>
        )

        /** estimated capital expenses */
        elements.push(
            <div className={classes.sidedetailsRow} key={102}>
                <div className={classes.sidedetailsRowHeader}>Estimated capital expenses: </div>
                <div className={classes.sidedetailValue}>€45 500</div>
            </div>
        )

        return elements

    }

    const toggleVolatilities = () => {
        toggleSidedetails('volatilities')
    }

    const toggleSidedetails = (sideDetail) => {
        if (sideDetail === 'volatilities') {
            setShowVolatilities(!showVolatilities);
        }
    }

    const toggleMetadata = (requestId) => {
        console.log('metadata ',requestId)
        // setShowMetadataPage(!showMetadataPage)
    }

    const showImageModal = (imageURL) => {
        // setFocusedImage(imageURL);
        // setImageOpen(true);
    }

    const demoImage_actionDiagram = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Faction_diagrams%2FSPH_action_diagram_sample.png?alt=media&token=76f3b256-a44f-4d7f-8a35-b7555dca1676";
    const demoImage_callChain = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Fcall_chains%2FSPH_call_chain_sample.png?alt=media&token=e05eb18e-238a-47bf-af64-5b38c1841b69";
    const demoImage_sequence = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Fsequence%2FSPH_sequence_sample.png?alt=media&token=12b4b4d6-cc31-4811-88d7-b5785f12df0b";


    /** new report data functions */
    const ListVolatilities = () => {
        /** no volatilities found */
        if (!requestData.user_needs || requestData.user_needs.length<1) return <div className={classes.noContentReportBox}> No volatilities </div>

        let sidedetailData = '';

        let user_need_volatilities = {}
        requestData.user_needs &&  requestData.user_needs.forEach(stakeholder=>{
            stakeholder.needs && stakeholder.needs.forEach(need=>{
                need.volatilities.forEach(volatility=>{
                    if(!user_need_volatilities[volatility]) {
                        user_need_volatilities[volatility] = 1
                    } else {
                        user_need_volatilities[volatility]++
                    }
                })
            })
        })
        Object.keys(user_need_volatilities).forEach((volatility)=>{
            /** build text */
            sidedetailData += `${volatility} (${user_need_volatilities[volatility]}),`
        })

        sidedetailData = buildStepDetails({detailsString:sidedetailData})

        return (
            <div className={classes.sidedetailValue}>{sidedetailData}</div>
        )
    }

    const ListUsecases = () => {
        /** no usecases found */
        if (!requestData.sidedetails || requestData.sidedetails['Use cases'] || requestData.sidedetails['Use cases']<1) return <div className={classes.noContentReportBox}> No use cases </div>

        let sidedetailData = requestData.sidedetails['Use cases']
        sidedetailData = buildStepDetails({detailsString:sidedetailData})

        /** iterate the use case elements */
        return sidedetailData.map((item, index)=>{
            const itemName = item.name.trim();
            return <ButtonForUseCases key={index} index={index} text={itemName} toggle={toggleMetadata} storiesRef={item.data} iconClick = {()=>{}} icon={'eye'} completedClick={()=>{}} />
        })

    }

    const ListSprints = () => {
        let demosprintdata = []
        // demosprintdata = [
        //     {
        //         sprint:'Sprint #1',
        //         issues: [
        //             "Save orders",
        //             "Get orders",
        //             "Send message",
        //         ]
        //     },
        //     {
        //         sprint:'Sprint #2',
        //         issues: [
        //             "Save orders",
        //             "Get orders",
        //             "Send message",
        //         ]
        //     }
        //
        // ]

        if (!requestData.sprints || requestData.sprints.length <1) return <div className={classes.noContentReportBox}> No sprints </div>

        return requestData.sprints.map((sprint, index) => {
            return (
                <div key={'_sprint_'+index}>
                    <div className={classes.reportBoxLineHead}>
                        {sprint.sprint}
                    </div>
                    <div>
                        {sprint.issues.map((issue) => {
                            return <div key={'_issue_'+index}>{issue}</div>
                        })}
                    </div>
                </div>
            )
        })
    }

    const GetPhaseText = ({phase}) => {
        if (phase === 1) return "Idea validation"
        if (phase === 2) return "Tech validation"
        if (phase === 3) return "Impact validation"
        return null
    }

    return (loading) ? <LoadScreen /> : <div style={{paddingLeft:'20px'}}>
        <div className={classes.content}>

            <div className={classes.logowrap}>
                <a href={"/"} className={classes.a}>
                    <div className={classes.logo}>
                        <div>
                            <img src={SPH_logo} style={{height:'30px'}} />
                        </div>
                        <strong>Sprinthack</strong>
                        <div>Unleash your potential</div>
                    </div>
                </a>
            </div>

            <Paper className={classes.reportBox}>

                <div className={classes.maindetailsContent}>
                    <div className={classes.main}>
                        <div className={classes.subtitle}>
                            Created {dayjs(requestData.createdAt).format('MMMM DD, YYYY')}
                        </div>
                        <h2 style={{fontWeight:'700'}}>{requestData.freetext}</h2>
                        <div className={classes.freeTextContent}>{requestData.freetext_details}</div>
                        <Divider/>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={activeTab} onChange={handleTabChange} aria-label="basic tabs">
                                    {/*<Tab label="User stories" {...a11yProps(1)} />*/}
                                    <Tab label="Project" {...a11yProps(0)} />
                                    <Tab label="Assumptions" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <TabPanel value={activeTab} index={0}>
                                (coming soon...)
                            </TabPanel>
                            <TabPanel value={activeTab} index={1}>
                                (coming soon...)
                            </TabPanel>
                            <TabPanel value={activeTab} index={2}>
                            </TabPanel>
                        </Box>
                    </div>
                </div>

            </Paper>
            <Paper className={classes.reportBox}>
                {requestData.learning ? (
                    <div className={classes.reportBoxHeader}>
                        <strong>Learning notes</strong>
                        <p>{requestData.learning}</p>
                    </div>
                ) : (
                    <div className={classes.reportBoxHeader}>
                        <div className={classes.noContentReportBox}>
                            No learnings added
                        </div>
                    </div>
                )}
            </Paper>

            <div style={{height:'300px'}}>

            </div>

            <Modal
                open={componentStructureOpen}
                onClick={()=>{setComponentStructureOpen(false)}}
                aria-labelledby="modal-image-zoom"
                aria-describedby="modal-image-zoom"
            >
                <Fade in={componentStructureOpen}>
                    <div className={classes.metadataImageFullContainer}>
                        <ComponentStructure payload={requestData} />
                    </div>
                </Fade>
            </Modal>

        </div>
    </div>
}
