import React from 'react';


export default function Terms_of_service() {

    return (
        <div style={{padding:'20px 40px'}}>
            <h1>Sprinthack - Terms of Service</h1>
            TL;DR;
            <ul>
                <li>
                    - Pricing is based on subscription
                </li>
                <li>
                    - You own your data
                </li>
                <li>
                    - Results of the work is your ownership.
                </li>
                <li>
                    - Sprinthack will use the data for research purposes. All the research data is anonymised.
                </li>
            </ul>
            <p>
                Sprinthack is a service offered by Fibo Labs Oy. Fibo Labs Ltd (business ID FI-27958526) is a limited liability company formed under Finnish law, which has its principal office at Capellankuja 1 apt 9, 00580 Helsinki, Finland. Hereinafter Fibo Labs Oy may be referred to as “we”, “us” or “Company”.
            </p>
            <p>
                Through the Sprinthack service, the Company carries out the business of providing scalable information technology services for its customers (“Customer”), which includes the provision of the cloud-based Sprinthack Platform for information technology project management as well as on-demand technology human resources.
            </p>
            <p>
                The Company has agreed to permit the Customer to use its services upon the terms of use hereinafter contained, which apply to the legal relationship between Customer and Company when the Customer uses the services provided by the Company.
            </p>
            <p>
                The Customer accepts these terms of use hereinafter contained as legally binding by using the service of the Company. In addition, a separate statement of work relating to these terms of use may be signed, and which becomes legally binding and annexed to these terms of use when the Customer has approved it as specified in the statement of work. These terms of use, and all possible subsequent statements of work as well as all work orders submitted through the Sprinthack Platform form together a contract, which creates legally binding terms between the Customer and the Company when the Customer uses the services of the Company (“Agreement”).
            </p>
            <h5>
                1. ESSENTIAL DEFINITIONS
            </h5>
            <p>
                “Sprinthack Platform” means the Company’s cloud-based platform meant for project management through which the Customer may submit work orders, and all thereto associated proprietary technology (including software, hardware, products, business concepts, and processes, logic algorithms, graphical user interfaces (GUI), techniques, designs and other tangible or intangible technical material or information) made available to the Customer by the Company in providing the Service.
            </p>
            <p>
                "Party" or "Parties" means the Company or the Customer, or them both.
            </p>
            <p>
                "Service" means the Sprinthack Platform (including a browser interface and data encryption, transmission, access and storage) and any other software related applications, tools and platforms that the Customer has subscribed to by the Agreement or that we otherwise make available to the Customer, and are developed, operated, and/or maintained by us, accessible via a designated URL, and any information technology products and services that we provide to the Customer through the Sprinthack Platform subject to either a work order or separate statement of work. The Service may also include hosting services as well as other thereto related services provided by the Company as agreed with the Customer.
            </p>
            <p>
                "Terms" means the terms of use specified herein.
            </p>
            <h5>
                2. GRANT OF RIGHTS; TERM AND RESTRICTIONS
            </h5>
            <p>
                2.1. Pursuant to the terms and conditions of the Agreement, the Company hereby grants the Customer a limited, non-exclusive, non-transferable and worldwide right to use the Service solely for the Customer’s own internal business purposes (“User Right”). This User Right shall be for the period the Customer selected through the Sprinthack Platform. The Company and its licensors reserve all rights not expressly granted to the Customer.
            </p>
            <p>
                2.2. The Customer shall not (i) license, grant, sell, resell, transfer, assign, distribute or otherwise commercially exploit or make available to any third party the Service in any way; (ii) reverse engineer or access the Service in order to (a) build a competitive product or service, (b) build a product using similar ideas, features, functions or graphics of the Service, or (c) copy any ideas, features, functions or graphics of the Service; (iii) modify or make derivative works based upon the Service or the content made available on the Service; or (iv) create Internet "links" to the Service or "frame" or "mirror" any content made available on the Service on any other server or wireless or Internet-based device.
            </p>
            <h5>
                3. THE SERVICE
            </h5>
            <p>
                3.1. The Company will provide the Customer the right to use the Service.
            </p>
            <p>
                3.2. The Customer may use the Service in regard to on-going and future information technology projects. The Customer can use the Service to develop and manage new information technology projects as well as define the level of technology human resources needed for specific projects (on-demand or subscription-based). Through the Service, the Company and the Customer can interact and continuously monitor the development of agreed information technology projects.
            </p>
            <p>
                3.3. The Customer may submit a new work order through the Sprinthack Platform, or by signing a separate statement of work. All details of on-going orders can thereafter be accessed through the Sprinthack Platform through which the Parties can continuously monitor the development and progress of individual work orders as well as accept all delivered work orders.
            </p>
            <h5>
                4. PAYMENTS
            </h5>
            <p>
                4.1. The Company charges a payment from the Customer for the Service in accordance with the price list of the Company. The payment model may be based on subscription-based recurring services.
            </p>
            <p>
                The Customer may put the Service on standby and reduce previously agreed hours by 30days’ prior written notice to the Company. The subscription of the Service will be automatically reactivated in the event that the previously agreed hours have been used.
            </p>
            <p>
                All previously agreed hours will be automatically transferred to the next month.
            </p>
            <h5>
                5. RESPONSIBILITIES OF CUSTOMER
            </h5>
            <p>
                5.1. The Customer is responsible for all activity occurring under the Customer’s user accounts and shall abide by all applicable laws, regulations or generally accepted policies or guidelines in connection with the Customer’s use of the Service, including those related to data privacy, international communications and the transmission of technical or personal data. The Customer shall: (i) notify the Company immediately of any unauthorized use of any password or account or any other known or suspected breach of security; (ii) report to the Company immediately and use reasonable efforts to stop immediately, any copying or distribution of content made available on the Sprinthack Platform that is known or suspected by the Customer or any Customer under this grant of rights; and (iii) not impersonate another Customer or provide false identity information to gain access to or use the Service.
            </p>
            <p>
                5.2. The Customer is solely responsible for his/her electronic devices, communication devices and other such devices and matters such as hardware condition, internet connection, antivirus, backup, and other similar issues.
            </p>
            <p>
                5.3. The Customer agrees not to take up any actions that disturb or in any other way hinder the Service or its servers or networks.
            </p>
            <p>
                5.4. The Service may contain links to third party websites. When the Customer visits third party websites, the Customer does so on its own responsibility and risk.
            </p>
            <h5>
                6. ACCOUNT INFORMATION AND DATA
            </h5>
            <p>
                6.1. By this User Right, the Company does not attain ownership in any data, information or material that the Customer submits to the Service in the course of using the Service ("Client Data"). The Customer, not Company, shall have sole responsibility for the daily-data backups, accuracy, quality, integrity, legality, reliability, appropriateness, and intellectual property ownership or right to use of all Client Data and the Company shall not be responsible or liable for the deletion, correction, destruction, damage, loss or failure to store any Client Data to the extent Client Data is used in the Customers technical environment.
            </p>
            <p>
                6.2. When accessing the Client Data  the Company shall use reasonable efforts to protect its technical environment with a secure firewall system and if separately agreed in a work order or a subscription agreement to conduct daily data backups of Client Data.
            </p>
            <p>
                6.3. In the event the Agreement is terminated, the Company will make available to the Customer a file of the Client Data within 30 days of termination, if the Customer so request in writing at the time of termination. The Customer agrees and acknowledges that the Company has no obligation to retain the Client Data, and may delete such Client Data, on the 31st day after termination. The Company reserves the right to withhold, remove and/or discard Client Data, without notice, for any breach, including, without limitation, the Customer’s non-payment as agreed in the Agreement.
            </p>
            <h5>
                7. DATA PROCESSING AGREEMENT (DPA)
            </h5>
            <p>
                7.1. For the Company to be able to provide the Service to the Customer, the Company has to process the personal data the Customer discloses to the Service. The Company processes such personal data on behalf of the Customer, and the Customer is ultimately in charge of such personal data. Therefore, according to the General Data Protection Regulation of the EU (679/2016) (hereinafter “GDPR”), the Company is a processor and the Customer is a controller for such personal data.
            </p>
            <p>
                7.2. This Section 7 contains the data processing agreement (DPA) between the Parties in accordance with Art. 28 of the GDPR as follows (the definitions used in this Section 7 shall have the same meaning as in the GDPR):
            </p>
            <p>
                7.2.1. Subject-matter and duration of the processing
                Personal data is processed as a part of the provision of the Service. Personal data is processed for the duration the Agreement is valid between the processor and the controller.
            </p>
            <p>
                7.2.2. Nature and purpose of processing
                Personal data is processed by the processor so that the controller could use the processor’s Service and the Parties could conclude the Agreement with each other.
            </p>
            <p>
                7.2.3. Type of personal data and categories of data subjects
                Types of personal data include contact information and other information relevant to the provision of the Service.   Categories of data subjects consist of the controller’s contact persons.
            </p>
            <p>
                7.2.4. Obligations and rights of the controller
                The controller is solely in charge of the legality of personal data it discloses into the Service, and the controller warrants that it has a right to process all personal data it discloses into the Service. If the controller unlawfully discloses personal data to the Service, the controller shall fully reimburse the processor for all the costs that arise to the processor for the said activity. The controller may, if necessary and possible in light of the provision of the Service, provide the processor with binding written instructions regarding this DPA.
            </p>
            <p>
                7.2.5. Documented instructions of the controller
                The processor: i) is not allowed to process the personal data for any other purposes than what the parties have specifically agreed on; ii) has an obligation to follow all applicable data protection laws; iii) ensures that only authorized persons gain access to personal data; and iv) ensures that all persons processing personal data are under an obligation of secrecy.  The processor is granted a general permission to hand over, transfer or in any similar way process personal data outside the EU/EEA. Upon such processing, the processor has an obligation to follow all applicable data protection laws, the instructions of the controller and the Agreement (including this DPA). If any of the prerequisites of the approval seize to exist, the processor has an obligation to immediately: (a) perform an action that ensures the lawful processing of the personal data and that the processing is conducted according to the instructions given by the controller and the Agreement; or (b) seize the transfer of personal data outside the EU/EEA and return the personal data transferred outside the EU/EEA to Finland.
            </p>
            <p>
                7.2.6. Confidentiality
                The processor is under an appropriate statutory obligation of confidentiality when it processes personal data.
            </p>
            <p>
                7.2.7. Data security
                The processor shall ensure that appropriate technical and organizational data protection measures is taken to prevent accidental or unlawful destruction, loss, alteration, unauthorized disclosure or access to personal data. Each Party is responsible for ensuring that the documented relevant risk management and security processes are applied to the processing of personal data. Such measures may include (i) encryption or pseudonymization of personal data, (ii) adequate protection of systems and communications, (iii) organization of ongoing confidentiality, integrity, availability and fault tolerance of processing systems and services and (iv) the ability to recover data quickly and to access data in the event of a physical or technical failure.
            </p>
            <p>
                The processor shall notify the controller of any data breaches immediately. In addition, the processor shall, without delay and within 48 hours of the occurrence or threat of the violation, provide the controller with the information necessary to fulfil the statutory obligations of the controller, to investigate the matter, to prevent similar violations and to make statutory declarations, including a description of the data breach and the consequences of the breach, and a description of the actions taken by the processor. The processor shall complete this information at the request of the controller and in accordance with the instructions.
            </p>
            <p>
                7.2.8. Subprocessors
                The processor has a right to use other processors (e.g. cloud services providers) (hereinafter ”subprocessor”) to process personal data. Subprocessors shall be under the same obligations as the processor.
            </p>
            <p>
                7.2.9. Obligation of the processor to assist the controller
                The processor has an obligation to assist the controller: (i) by appropriate measures, insofar as this is possible, in regard to the fulfilment of the data subjects’ rights; and (ii) in ensuring compliance with the obligations pursuant to the Articles 32 to 36 of the GDPR.  The processor shall have a right to a remuneration from the controller for all direct costs that arise from fulfillment of the processor’s obligation under this Section.
            </p>
            <p>
                7.2.10. Deletion or return of personal data
                After the Agreement is no longer in force between the processor and the controller, the processor and its subcontractors shall return, at their own expense and without delay, the personal data to the controller. If the return of personal data is not possible, then the Parties must agree on the destruction of the material in a separate agreement.
            </p>
            <p>
                7.2.11. Controller’s compliance
                At the written request of the controller, the processor shall make available to the controller all information necessary to demonstrate compliance with the obligations laid down in Article 28 of the GDPR, and the processor authorizes and participates in audits performed by the controller or another auditor authorized by the controller.
            </p>
            <p>
                The processor shall have a right to a remuneration from the controller for all direct costs that arise from fulfillment of the processor’s obligation under this Section.
            </p>
            <h5>
                8. INTELLECTUAL PROPERTY OWNERSHIP
            </h5>
            <p>
                8.1. The Company alone (and its licensors, where applicable) shall own all right, title and interest, including all related intellectual property rights to the Service. The Company name, logo(s), and the product names associated with the Service are trademarks of the Company or third parties, and no right or license is granted to use them. This Agreement is not a sale and does not convey to the Customer any rights of ownership in or related to the afore specified items, the Service or the intellectual property rights owned by the Company. The Customer acknowledges that, except as specifically provided under this Agreement, no other right, title, or interest in these items is granted.
            </p>
            <p>
                8.2. All works that have been developed for the Customer in connection with the Agreement, or any improvements made to Client Data by the Company in connection with the Agreement, belong exclusively to the Customer and are works made for hire.
            </p>
            <p>
                8.3 The Company will at its own cost and expense indemnify, defend and hold harmless the Customer from and against any third-party claims that the Service or the Results infringes the intellectual property rights of a third party provided that provided that the Customer (i) promptly notifies Company in writing of such claim; (ii) grants the Company sole conduct of the defense of any such claim; and (iii) acts in accordance with the reasonable instructions of the Company and gives Company such assistance and authorizations as it shall reasonably require to defend or settle such claim. Company shall pay any costs and damages finally awarded against the Customer by a competent court or in an out-of-court settlement. Notwithstanding the foregoing, it is acknowledged and agreed that the Company shall not be liable to the Customer if the infringement claim: (i) results from an alteration of the Service or Results or from compliance with the Customer’s instructions or information or use of the Customer’s materials; (ii) results from the use of the Service or Results in combination with any material or service not included in the delivery; (iii) could have been avoided by the use of a subsequently released version of the Service or Results made available to the Customer at no extra cost or in accordance with a valid agreement. This Section 8.3 stipulates Company’s sole and exclusive liability and the Customer’s sole remedy for infringement of intellectual rights of a third party by the Service or the Results or the use thereof.
            </p>
            <h5>
                9. LIMITATION OF LIABILITY
            </h5>
            <p>
                9.1. Unless otherwise provided for in any separate statement(s) of work, in no event shall the Company’s aggregate liability exceed fifty percent (50%) of the amounts actually paid by the Customer under the Agreement to the Company within the 3 month period prior the event causing the damage.
            </p>
            <p>
                9.2. In no event shall the Company and/or its licensors be liable to anyone for any indirect, punitive, special, exemplary, incidental, consequential or other damages of any type or kind (including loss of data, revenue, profits, use or other economic advantage) arising out of, or in any way connected with the use or inability to use the Service, or for any content obtained from or through the Service, any interruption, inaccuracy, error or omission, regardless of cause in the content, even if the party from which damages are being sought or such party's licensors have been previously advised of the possibility of such damages.
            </p>
            <p>
                9.3. Some jurisdictions do not allow the exclusion or limitation of certain damages, whereby some or all of the exclusions and limitations in this Section may not apply to the Customer.
            </p>
            <h5>
                10. REPRESENTATIONS AND WARRANTIES
            </h5>
            <p>
                10.1. Each Party represents and warrants that it has the legal power and authority to enter into this Agreement. The Company represents and warrants that it will provide the Service in a manner consistent with general industry standards reasonably applicable to the provision thereof. The Customer represents and warrants that the Customer has not provided any false information to gain access to the Service and that the Customer’s billing information is correct.
            </p>
            <p>
                10.2. The Company and its licensors make no representation, warranty, or guaranty as to the reliability, timeliness, quality, suitability, truth, availability, accuracy or completeness of the Service or any content made available on the Service. The Company and its licensors do not represent or warrant that (a) the use of the Service will be secure, timely, uninterrupted or error-free or operate in combination with any other hardware, software, system or data, (b) the Service will meet the Customer’s requirements or expectations, (c) any stored data will be accurate or reliable, (d errors or defects will be corrected, or (e) the service or the server(s) that make the Service available are free of viruses or other harmful components.
            </p>
            <p>
                10.3. The Service and all content made therein available is provided to the Customer strictly on an "as is" basis. All conditions, representations and warranties, whether express, implied, statutory or otherwise, including, without limitation, any implied warranty of merchantability, fitness for a particular purpose, or non-infringement of third party rights, are hereby disclaimed to the maximum extent permitted by applicable law by the Company and its licensors.
            </p>
            <h5>
                11. TERMINATION
            </h5>
            <p>
                11.1. This Agreement is valid for indefinitely. Either Party may terminate the Agreement by notifying the other party in writing at least thirty (30) business days prior to the termination date. By terminating the Agreement, the Customer is not however exempt from the obligations it has undertaken under any pending work orders or possible statement(s) of work prior to the termination.
            </p>
            <p>
                11.2. The Customer shall not have the right to use or the right to get refund for any previously agreed hours after the notice period. The Customer shall not have access to Sprinthack Platform after the notice period, and any integration to the Customer’s system shall be removed, as well as all User Rights shall not be in force after the notice period.
            </p>
            <p>
                11.3. Any breach of the Customer’s payment obligations as agreed in the Agreement or any use of the Service or Service contrary to the terms and conditions of the Agreement and the license granted hereby will be deemed a material breach of the Agreement. The Company, in its sole discretion, may terminate the Customer’s password, account or use of the Service if the Customer breaches or otherwise fails to comply with the Agreement.
            </p>
            <h5>
                12. GOVERNING LAW AND DISPUTES
            </h5>
            <p>
                12.1. The Agreement and the relationship between the Customer and the Company shall be governed by and construed and interpreted in accordance with the laws of Finland without regard to its principles and rules on conflict of laws.
            </p>
            <p>
                12.2. Any dispute, arising out of or relating to the Agreement, shall be finally settled by arbitration in accordance with the Arbitration Rules of the Finland Chamber of Commerce. The number of arbitrators shall be one (1). The seat of arbitration shall be Helsinki, Finland. The language of the arbitration shall be English. However, evidence may be submitted, and witnesses may be heard in Finnish/English, to the extent the arbitral tribunal deems it appropriate.
            </p>
            <h5>
                13. MISCELLANEOUS
            </h5>
            <p>
                13.1. The Company has the right to use the co-operation between the Customer and the Company in its own marketing and as a reference, in accordance with good practice.
            </p>
            <p>
                13.2. Each Party undertakes to keep in confidence all details of the Agreement as well as confidential information received from either Party, not to disclose them to any third party and not to use them for any purpose other than for the purpose of the Agreement, unless it is under an obligation to disclose such information pursuant to a law, decree, or other order issued by an authority.
            </p>
            <p>
                13.3. The Company reserves the right to change these Terms. The Company shall notify the Customer through email or the Service of such changes. The changes shall take effect 14 days after the notification. By using the Service after the changes, the Customer accepts the changes as legally binding.
            </p>
            <p>
                13.4. The Agreement may not be assigned by a Party without the prior written approval of the other Party but may be assigned without the other Party’s consent by Party to (i) a parent or subsidiary, (ii) an acquirer of assets, or (iii) a successor by merger. Any purported assignment in violation of this section shall be void.
            </p>
            <p>
                13.5. The Customer agrees that if the Company does not exercise or enforce any legal rights under the Agreement, it does not imply that the Company formally waives its rights, whereby the Company still has the right to exercise its rights.
            </p>
            <p>
                13.6. The Customer agrees that any cause of action that the Customer may have arising out of or related to the Agreement must commence within one (1) year after the cause of action accrues, otherwise, such cause of action is permanently barred.
            </p>
            <p>
                13.7. If, by a court decision, any provision of the Agreement is declared void, then only that invalid provision will be removed from the Agreement, in which case the Agreement will continue to be valid.
            </p>
            <p>
                13.8. Sections 5, 8-10 and 12 shall prevail and stay in force even after the termination of the Agreement.
            </p>

        </div>
    );
}

