import React, {useReducer, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useParams} from "react-router";
import {db, firebaseCore} from "../../util/firebase";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import {useTheme} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        height: 'calc(100vh - 65px)',
        flexFlow:'row wrap'
    },
    maindetails: {
        flex: '1 100%',
        // maxWidth: '700px',
        minWidth:'400px',
        maxHeight: '100%',
        overflowY: 'scroll',
        "position":"relative",
        "flexShrink":"0",
        "WebkitBoxFlex":"100",
        "flexGrow":"100",
        "paddingBottom":"82px"
    },
    maindetailsWrapper: {
        "flexGrow":"100",
        "WebkitBoxFlex":"100",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "overflowY":"overlay"
    },
    maindetailsContent:{
        "display":"flex",
        "flexBasis":"initial",
        "flexDirection":"column",
        "position":"relative",
        "width":"calc(100%)",
        // "marginLeft":"auto",
        // "marginRight":"auto",
        "WebkitBoxFlex":"1",
        "flexGrow":"1",
        "flexShrink":"0",
        "paddingTop":"20px"
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%",
        "display":"flex",
        "zIndex":"1100",
        "boxSizing":"border-box",
        "flexShrink":"0",
        "flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    textareaStyle: {
        padding: '10px',
        minWidth: '260px',
        width: 'calc(100% - 35px)'
    },
    submitButton: {
        background:theme.custom.MainButton
    },
    [theme.breakpoints.up("xs")]: {
    },
    [theme.breakpoints.up("sm")]: {
        maindetails:{
            flex:'100 0px',
            order:'2'
        },
    },
    [theme.breakpoints.up("md")]: {
    },
    table: {
        minWidth: 375,
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {

    },
    listLink:{
        cursor:'pointer'
    },
    rowText: {
        cursor:'default'
    },
    heading: {
        fontSize: '15px'
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize
    },
    isComplete:{
        color:'#93c47e'
    },


}));

export default function CycleDetails({userData, detailsPayload, loadIssueDetails}) {
    const classes = useStyles();
    const theme = useTheme();
    let { cycleId } = useParams();

    dayjs.extend(relativeTime);

    const [cycleData, setCycleData] = useState(detailsPayload)

    React.useLayoutEffect(() => {

        console.log('detailsPayload',detailsPayload)

        const unsubscribe = db
            .collection('cycles')
            .doc(cycleId)
            .onSnapshot(async (doc) => {
                if (doc.exists){
                    const observedData = {
                        cycleId: doc.id,
                        createdAt: doc.data().createdAt,
                        cycleNumber: doc.data().cycleNumber,
                        name: doc.data().name,
                        issues: doc.data().issues,
                        scenarios: doc.data().scenarios
                    }

                    console.log('observedData1',observedData);

                    if (observedData.issues && observedData.issues.length > 0) {
                        const issueData = await db
                            .collection('issues')
                            .where('cycleRef', '==', observedData.cycleId)
                            .where('workspace_id', '==', userData.activeWorkspace.id)
                            .get()

                        issueData && issueData.forEach(doc=>{
                            const issueObject = doc.data()
                            issueObject.issueId = doc.id
                            observedData.issues[observedData.issues.indexOf(doc.id)] = issueObject
                        })
                    }

                    if (observedData.scenarios && observedData.scenarios.length > 0) {
                        const scenarioData = await db
                            .collection('scenarios_bdd')
                            .where(firebaseCore.firestore.FieldPath.documentId(),'in', observedData.scenarios)
                            .where('workspace_id', '==', userData.activeWorkspace.id)
                            .get()

                        scenarioData && scenarioData.forEach(doc=>{
                            const scenarioObject = doc.data()
                            scenarioObject.id = doc.id
                            observedData.scenarios[observedData.scenarios.indexOf(doc.id)] = scenarioObject
                        })
                    }


                    console.log('observedData2',observedData);
                    setCycleData(observedData)
                } else {
                    setCycleData({
                        cycleId: cycleId
                    })
                }
            });

        return function cleanup() { unsubscribe() }
    // eslint-disable-next-line
    }, []);

    return (
        <div className={classes.root}>
            <div className={classes.maindetails}>
                <div className={classes.maindetailsWrapper}>
                    <div className={classes.maindetailsContent}>
                        <div className={classes.subtitle} style={{marginLeft:'30px'}}>
                            Created {dayjs(cycleData.createdAt).format('MMMM DD, YYYY')}
                        </div>
                        <h2 style={{fontWeight:'700', marginLeft:'30px'}}>{cycleData.name}</h2>
                        <TableContainer>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableBody>
                                    {(cycleData.issues && cycleData.issues.length < 1 && cycleData.scenarios && cycleData.scenarios.length < 1) ?
                                        <TableRow>
                                            <TableCell align="left" style={{color:theme.custom.subtitle, paddingLeft:'25px'}}>All completed</TableCell>
                                        </TableRow>
                                        :
                                        null
                                    }
                                    {
                                        cycleData.scenarios && cycleData.scenarios.map((item, index) => (
                                                <TableRow key={index} hover className={classes.listLink} onClick={()=>loadIssueDetails(item, cycleId)}>
                                                    <TableCell align="left" style={{ width: "1px", paddingRight:'0px' }} >{item.completed ? <CheckCircleIcon className={clsx(classes.tableIcon, classes.isComplete)} /> : null}</TableCell>
                                                    <TableCell component="th" scope="row" className={classes.rowText}>
                                                        <div className={classes.rowTitle}>{item.scenario}</div>
                                                    </TableCell>
                                                    {/*<TableCell align="right"><Chip variant="outlined" color="primary" size="small" /></TableCell>*/}
                                                    <TableCell align="right" style={{ width: "70px", paddingLeft:'0px' }} className={classes.dateColumn}>{dayjs(item.createdAt).format('MMM DD')}</TableCell>
                                                </TableRow>
                                            ))
                                    }
                                    {
                                        cycleData.issues && cycleData.issues.map((issue, index) => (
                                            <TableRow key={index} hover className={classes.listLink} onClick={()=>loadIssueDetails(issue, cycleId)}>
                                                <TableCell align="left" style={{ width: "1px", paddingRight:'0px' }} />
                                                <TableCell component="th" scope="row" className={classes.rowText}>
                                                    <div className={classes.rowTitle}>{issue.title}</div>
                                                </TableCell>
                                                {/*<TableCell align="right"><Chip variant="outlined" color="primary" size="small" /></TableCell>*/}
                                                <TableCell align="right" style={{ width: "70px", paddingLeft:'0px' }} className={classes.dateColumn}>{dayjs(issue.createdAt).format('MMM DD')}</TableCell>
                                            </TableRow>
                                        ))
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
        </div>
    );
};