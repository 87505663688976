import React, {useState} from 'react';
import {Button, makeStyles} from "@material-ui/core";
import {db} from "../../util/firebase";
import {
    CustomDimensions,
    DefaultDimensions,
    getWorkspaceNegativeImpact, getWorkspacePositiveImpact,
    matchInputText
} from "../../report_dimensions/DefaultDimensions"
import ImpactDetails from "../../components/impactReport/impactDetails";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import AddIcon from "@material-ui/icons/Add";
import {BrowserRouter as Router, Route, useRouteMatch} from "react-router-dom";
import AddNewImpact from "../../components/addNewImpact";
import EditIcon from "@material-ui/icons/Edit";
import EditImpactDimensions from "../../components/impactReport/editImpactDimensions";

const useStyles = makeStyles((theme) => ({
    RightToolbarButton: {
        float: 'right',
        background:theme.custom.MainButton,
        marginLeft:'5px',
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRow:{
        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 0px 0px 0px'
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder

    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },

    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

}));

export default function ImpactContent({userData, history, isMobile, loadFeaturesDetails}) {
    const classes = useStyles();
    let { url } = useRouteMatch();
    let route = useRouteMatch();

    const [docsState, setDocsState] = useState(
        {impactData:[], loading:true}
    );

    React.useEffect(() => {
        setDocsState({impactData:[], loading:false});

    }, [userData.activeWorkspace.id])


    const FetchAIData = async () => {
        console.log('fetch AI report')

        /** test code */

        const taskData = await db.collection('idea_validation')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            // .where('success','==', true)
            .get()

        let tasks = [];
        taskData.forEach((doc) => {
            const task = doc.data()
            if (task.success){
                tasks.push({
                    id: doc.id,
                    success:task.success
                });
            }

        });

        /** each task should be molded into
         * - set action verb
         * - set action target
         * - get verb dimension data from file
         */

        const defaultDimensions = DefaultDimensions()
        const customDimensions = await CustomDimensions({userData})

        let matches = []
        defaultDimensions.forEach(d => {
            matches.push([{
                dimension: d.dimension,
                group: d.group,
                type: 'empty',
            }])
        })
        customDimensions.forEach(d => {
            matches.push([{
                dimension: d.dimension,
                group: d.group,
                type: 'empty',
            }])
        })

        tasks.forEach(t => {
            const dimensionMatched = matchInputText({input:t.success, dimensions:defaultDimensions})
            if (dimensionMatched.length > 0) matches.push(dimensionMatched)

            const customMatched = matchInputText({input:t.success, dimensions:customDimensions})
            if (customMatched.length > 0) matches.push(customMatched)
        })


        /** get total team members */

        db.collection('workspaces').doc(userData.activeWorkspace.id)
            .collection('members')
            .get()
            .then(members => {

                matches.push(getWorkspaceNegativeImpact({score:members.docs.length}))
                matches.push(getWorkspacePositiveImpact({score:members.docs.length}))

                setDocsState({...docsState, impactData: matches})

            })
    }

    const RenderImpact = () => {

        /** render following
         * jobs
         * knowledge
         * scarce human capital
         * consumption
         * customs
         *
         * */


        console.log('docstate',docsState)

        const Feature_usage_metrics = {
            /** fetch this from database eventually */
            /** base electricity on
             * Feature usage requests on daily
             *
             * 50k = 3 instances
             * 30k = 2 instances
             * <15k = 1 instance
             *
             * One instance score is 0.6
             */

            featureUsageRequests: {
                daily:35000,
                monthly: 12000
            }
        }

        let impactParsed = {}

        docsState.impactData.forEach(impactArr => {
            impactArr.forEach(impact => {

                if (impact.group === 'My impacts') console.log('impact::',impact)

                if (!impact.group || !impact.dimension) return

                if (!impactParsed[impact.group]) impactParsed[impact.group] = {}
                if (!impactParsed[impact.group][impact.dimension]) impactParsed[impact.group][impact.dimension] = {pos:0, neg:0}

                if ((impact.match && impact.match.type === 'positive') || (impact.match && !impact.match.type)){

                    if (impact.dimension === 'electricity consumption'){
                        /** electricity score specialty */
                        if (Feature_usage_metrics.featureUsageRequests){
                            const instances = Math.ceil(Feature_usage_metrics.featureUsageRequests.daily/15000)

                            impact.score = instances*0.6
                        }
                    }

                    if (impact.score){
                        impactParsed[impact.group][impact.dimension]["pos"] += impact.score
                    } else {
                        impactParsed[impact.group][impact.dimension]["pos"]++
                    }
                }
                if (impact.match && impact.match.type === 'negative'){
                    if (impact.dimension === 'electricity consumption'){
                        /** electricity score specialty */
                        if (Feature_usage_metrics.featureUsageRequests){
                            const instances = Math.ceil(Feature_usage_metrics.featureUsageRequests.daily/15000)

                            impact.score = instances*0.6
                        }
                    }

                    if (impact.score){
                        impactParsed[impact.group][impact.dimension]["neg"] -= impact.score
                    } else {
                        impactParsed[impact.group][impact.dimension]["neg"]--
                    }
                }

            })

        })

        return <div>
            <ImpactDetails userData={userData} reportData={impactParsed} FetchAIData={FetchAIData}/>
        </div>

    }

    const loadAddNewImpactDimension = () => {
        history.push(`${url}/new`);
    }
    const loadEditImpactDimensions = () => {
        history.push(`${url}/edit`);
    }

    const RenderImpactRoutes = () => {
        return (
            <Router>
                <Route exact path={`${url}`} render={() => RenderImpact()} />
                <Route path={`${url}/new`} render={() => <AddNewImpact userData={userData} history={history} backToImpact={handleBackButton} />} />
                <Route path={`${url}/edit`} render={() => <EditImpactDimensions userData={userData} backToImpact={handleBackButton} />} />
            </Router>
        )
    };

    const handleBackButton = () => {
        history.goBack()
        // loadIdeasPage();
        return false
    };

    return (docsState.loading) ? <></> : (
        <div>
            <div
                className={clsx(classes.topBar, {
                    [classes.topBarShift]: isMobile,
                })}
            >
                <Toolbar>
                    {!route.isExact ? <IconButton onClick={handleBackButton} className={clsx(classes.menuButton)}>
                        <ChevronLeftIcon />
                    </IconButton> : <div />}
                    {route.isExact ?
                        <div style={{flexGrow:'1'}}>
                            <Button style={{flexGrow:'1', textTransform:'capitalize'}} onClick={FetchAIData}>Update report</Button>
                            <Button variant="contained" color="primary" size="small" className={classes.RightToolbarButton} onClick={loadAddNewImpactDimension}>
                                <AddIcon />
                            </Button>
                            <Button variant="contained" color="primary" size="small" className={classes.RightToolbarButton} onClick={loadEditImpactDimensions}>
                                <EditIcon />
                            </Button>
                        </div>
                        : <div/>}
                </Toolbar>
            </div>
            <div style={{padding: '20px'}}>
                <div style={{display:"flex", flexDirection:"column-reverse"}}>
                    <div style={{alignSelf:"center", maxWidth:'600px'}}>This is all the impact data, analysed by the Sprinthack platform from tasks, that are promoted to features.</div>
                </div>
                <RenderImpactRoutes />
            </div>
        </div>
    )
}
