import React, {useState} from 'react';
import {auth} from "../util/firebase";
import {useHistory} from "react-router-dom";

export default function Logout() {
    const history = useHistory()
    const [errorMsg, setErrorMsg] = useState('logging you out...')

    React.useEffect(()=>{
        auth().signOut().then(() => {
            // Sign-out successful.
            history.replace('/')
        }).catch((error) => {
            // An error happened.
            history.replace('/error')
        });
    })

    return <div>{errorMsg}</div>;
}

