import React from "react";
import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    container:{
    },
    img:{
        maxWidth:'100%'
    }
}));

export default function ComponentStructure ({payload}) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <img
                className={classes.img}
                src={payload.sidedetails.components || payload.sidedetails.Components}
                srcSet={payload.sidedetails.components || payload.sidedetails.Components}
                alt='components_img'
                loading="lazy"
            />
        </div>
    )
}