import {SearchClient as TypesenseSearchClient} from "typesense";

const searchClient = new TypesenseSearchClient({
        'nodes': [{
            'host': '658tguzqe37v4nc0p-1.a1.typesense.net', // For Typesense Cloud use xxx.a1.typesense.net
            'port': '443',      // For Typesense Cloud use 443
            'protocol': 'https'   // For Typesense Cloud use https
        }],
        'apiKey': 'DsLXT5iDFs4ImJGA0IeKihqb65MUUsDy',
        'connectionTimeoutSeconds': 2
    })

export {searchClient}