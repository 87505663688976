import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {db, auth} from "../util/firebase";
import dayjs from "dayjs";
import axios from "axios";
import BASEURL from "../constants/baseurl";
import SlowLoad from "./slowLoad";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import CategoryIcon from "@material-ui/icons/Category";
import {Assistant, AssistantOutlined, Autorenew, FlareOutlined} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    requestBoxWrap:{
        padding:"40px"
    },
    requestBox: {
        boxShadow:"rgb(0 0 0 / 50%) 0px 16px 70px",
        borderRadius:'10px',
        paddingTop:'20px',
        paddingLeft:'18px',
        paddingRight:'36px',
        paddingBottom:'20px',
        margin:'0px auto',
        maxWidth:'900px'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    addNewTitleHeader: {
        fontSize:'22px',
        fontWeight:500,
        fontFamily:'Inter,Roboto,Helvetica,Arial,sans-serif',
        border:'1px solid transparent',
        backgroundColor:"transparent",
        color: theme.custom.genericLongformText,
        resize:'none',
        overflow:'auto',
        outline:'none',
        "WebkitBoxShadow":"none",
        "MozBoxShadow":"none",
        "boxShadow":"none",
        minWidth:'350px',
        width:'100%',
        padding:'10px',
        marginTop:'0px',
        marginLeft:'-10px',
        borderRadius:'5px',
    },
    copilotButtonComment: {
        color:theme.custom.MainButton,
        borderColor:theme.custom.MainButton,
        marginLeft:'10px',
    },
    activityAuthor: {
        fontWeight:'500',
        marginBottom:'0px'
    },
    activityBody: {
        whiteSpace:'pre-line',
        marginTop:'0px'
    }

}));

export default function AddNewSprint({history, userData, backToProjects}) {
    const classes = useStyles();

    const [sprint, setSprint] = useState('')
    const [headerTitle, setHeaderTitle] = useState('')
    const [inputPlaceholder, setInputPlaceholder] = useState("Goal of your next sprint")
    const [taskAILoading, setTaskAILoading] = useState(false);
    const [copilotActivityErrorMsg, setCopilotActivityErrorMsg] = useState('')
    const [activity, setActivity] = useState([])

    const handleProjectChange = (event) => {
        setSprint(event.target.value)
    }

    const handleSaveNewSprint = async (event) => {
        event.preventDefault();
        const newTitle = (headerTitle !== '') ? headerTitle : sprint

        console.log('activity___',activity)

        const updateObject = {
            title: newTitle,
            description:activity[0].text || '',
            version:'',
            createdAt: dayjs().toISOString(),
            owner:auth().currentUser.uid
        }

        db
            .collection('sprints')
            .add(updateObject)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                backToProjects()
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });
    }

    const handleGetCopilotFeedback = async () => {
        setTaskAILoading(true)
        setInputPlaceholder('Comment')
        setHeaderTitle(sprint)
        setActivity([])

        /** parse the correct format of the messages to send to AI */
        const formattedActivities = activity
            .filter(ac => {
                return (
                    ac.type === "comment" ||
                    ac.type === "feedback" ||
                    ac.type === "bot_activity"
                )
            }).map(ac => {
                return (
                    (ac.type === "comment" || ac.type === "feedback") && (!ac.AI_created )
                ) ? {role: 'user', content:ac.text} : {role: 'system', content:ac.text}

            })

        const authToken = await auth().currentUser.getIdToken()

        const headers = {
            'Content-Type': 'application/json',
        }
        console.log('headerTitle',sprint)
        axios.defaults.headers.common = { 'Access-Control-Allow-Origin': '*', Authorization: `Bearer ${authToken}` };
        axios
            .post(BASEURL.api+'/AI/getChat', {
                "seed":formattedActivities,
                "seed_title":sprint
            }, {
                headers:headers
            })
            .then((response) => {
                setTaskAILoading(false)
                setSprint('')

                if (response && response.data && response.data.body) {
                    const responseMessage = JSON.parse(response.data.body)

                    console.log("responseMessage",responseMessage)

                    setActivity([{type:'comment', action: 'commented', text:responseMessage.message, author: "Copilot"}]);
                }
                if (response && response.data && response.data.message) {
                    console.error('response', response.data)
                    setCopilotActivityErrorMsg(response.data.message)
                }

            })
            .catch(err => {
                console.log('err',err.message)
            })

        return null
    }

    const RenderActivity = () => {
        return activity.map((ac, index) => {
            return <div key={index}>
                {/*<p className={classes.activityAuthor}>{ac.author}</p>*/}
                <p className={classes.activityBody}>{ac.text}</p>
            </div>
        })
    }

    return (
        <div>
            <div className={classes.requestBoxWrap}>
                <div className={classes.requestBox}>
                    <div style={{paddingLeft:'10px'}}>
                        <div className={classes.addNewTitleHeader}>
                            {headerTitle}
                        </div>
                    </div>
                    <div style={{paddingLeft:'10px'}}>
                        <RenderActivity/>
                    </div>
                    {taskAILoading ? <div style={{marginBottom:'5px'}}><SlowLoad/></div> : (copilotActivityErrorMsg && copilotActivityErrorMsg !== '') ? <p>{copilotActivityErrorMsg}</p> : <></>}
                    <div style={{marginBottom:'20px'}}>
                        <input
                            aria-label="minimum height"
                            className={classes.textareaStyle}
                            defaultValue=""
                            placeholder={inputPlaceholder}
                            onChange={handleProjectChange}
                        />

                    </div>
                    <div>
                        <Button
                            color="primary"
                            onClick={backToProjects}
                        >Cancel</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.submitButton}
                            onClick={(e) => {handleSaveNewSprint(e)}}
                        >Save</Button>
                        <Button
                            color="primary"
                            variant="outlined"
                            type="submit"
                            className={classes.copilotButtonComment}
                            onClick={(e) => {handleGetCopilotFeedback(e)}}
                        >
                            <ListItemIcon><FlareOutlined/></ListItemIcon> Generate AI Description
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

