import React, {useEffect, useState} from "react";
import {makeStyles, TextareaAutosize} from "@material-ui/core";
import {useRouteMatch} from "react-router-dom";
import Button from "@material-ui/core/Button";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import FreetextEditor from "../generalComponents/FreetextEditor";
import {db} from "../../util/firebase";
import Modal from "@material-ui/core/Modal";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import TaskCard from "../generalComponents/TaskCard";
import SlowLoad from "../slowLoad";
import GeneralDropdown from "../../util/generalDropdown";
import SideDetailsStatus from "./sideDetails_status";
import clsx from "clsx";
import {useIsPad} from "../../util/useIsMobile";
import {FlareOutlined} from "@material-ui/icons";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";

const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: 'rgba(0, 0, 0, 0.54)'
    },
    textareaStyle: {
        padding: '10px',
        width: 'calc(100% - 35px)'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    subtitleLight: {
        color:theme.custom.subtitleLight
    },
    subtitle_small: {
        color:theme.custom.subtitle,
        fontSize:'0.8rem'
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    createCycleButton: {
        background:theme.custom.MainButton
    },
    cycleTextDetail: {
        lineHeight:'20px'
    },
    approveButton: {
        background:theme.custom.GreenButton,
    },
    approveChip: {
        background:'transparent',
        fontWeight:'700',
        border:'1px solid transparent',
        transition:'200ms',
        '&:hover':{
            // background:'#fff',
            // border:'1px solid rgba(0, 0, 0, 0.12)'
        },
    },
    sidedetails: {
        display:'flex',
        marginTop:'-65px',
        flex: '1 100%',
        background: theme.custom.sidedetailBackground,
        padding:'0px 0px 0px 25px',
        position:"relative",
        flexShrink:"0",
        WebkitBoxFlex:"1",
        flexGrow:"1",
        minWidth: '320px',
        maxHeight: 'calc(100% + 65px)',
        overflow: 'initial',
        flexDirection:'column',

        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        })
    },
    sideTopMenu: {
        minHeight:'64px',
        // width:'300px',
        display:'flex',
        position:'relative',
        alignItems:'center'
    },
    sidedetailData: {
        padding: '20px 0 20px 0',
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'15px',
        // paddingLeft: '5px',
    },
    sidedetailsRow_FULL: {
        marginBottom:'15px',
        paddingRight: '15px',
    },
    sidedetailsRowHeader: {
        color:'rgb(107, 111, 118)',
        fontWeight: 500,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'100px',
        flexShrink: '0',
        textTransform: 'capitalize',
    },
    sidedetailsRowHeader_FULL: {
        color:'rgb(107, 111, 118)',
        fontWeight: 600,
        width:'100%',
        textTransform: 'capitalize',
        marginBottom:'15px'
    },
    sidedetailValue:{
        fontWeight: 400,
        width:'100%',
        display: 'flex',
        flex:'initial',
        flexDirection:'row',
    },
    sidedetailValue_FULL:{
        fontWeight: 400,
        width:'100%',
    },
    sideDetailHoverField:{
        flexGrow: '1',
        maxWidth:'100%',
        padding:'8px 8px',
        textAlign:'left',
        justifyContent:'flex-start',
        marginLeft:'-8px',
        marginRight:'32px',
        flexShrink:'1',
        overflow:'hidden',
        border:'1px solid transparent',
        borderRadius:'5px',
        background:'transparent',
        marginTop:'-8px',
        "&:hover":{
            border:'1px solid #c7c7c7'
        }
    },
    link:{
        display:'flex',
        flexDirection:'row',
        transition:'150ms',
        "-webkit-box-align":'center',
        alignItems:'center',
        color:'inherit',
        textDecoration: 'none',
        cursor: 'default',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }

    },
    whiteColorButton:{
        color:'#a2a2a2',
        borderColor:'#a2a2a2'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    nextActionToggle: {
        position:'absolute',
        top:'0px',
        right:'0px'
    },
    requestTaskButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    listButton: {
        padding:'10px',
        marginBottom:'10px',
        border:'1px solid #e0e2e1',
        borderRadius:'5px',
        flex:'1',

        "&:hover": {
            background: 'hsla(150,3%,88%,.3)',
        }

    },
    [theme.breakpoints.up("xs")]: {
        sidedetails:{
            flex: '10 auto'
        }
    },
    [theme.breakpoints.up("sm")]: {
        sidedetails:{
            order:'3'
        }
    },
    [theme.breakpoints.up("md")]: {
    },
    [theme.breakpoints.down("sm")]: {
        sidedetails:{
            order:'3'
        }

    },

    copilotOptionWrap:{
        display:'flex',
        cursor:'default'

    },
    dropDownWrap:{
        marginLeft:'auto',
        paddingLeft:'10px',
    },
    showCopilotButton:{
        "position":"absolute",
        "left":"-72px",
        "top":"10px",
        "width":"52px",
        "height":"52px",
        "paddingLeft":"8px",
        "zIndex":"10"
    },
    showCopilotButtonHide:{
        display:'none'
    }
}));

function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

function getPlaceholders() {
    return {
        "problem":"(Describes the user going through a domain-level process to achieve some valuable outcome)",
        "Why":'Add...',
    }
}

const availableLanguages = [
    {key: "en_US", display:"English"},
    {key: "fi_FI", display:"Finnish"},
    {key: "es_ES", display:"Spanish"},
    {key: "sv_SE", display:"Swedish"},
]


export default function Sprint_sideDetails_individual({payload, taskAILoading, writeDescription, writeTasks}) {
    const classes = useStyles();

    const isPad = useIsPad();
    const [requestTaskOpen, setRequestTaskOpen] = useState(false);
    const [taskActionsOpen, setTaskActionsOpen] = useState(false);
    const [newRequestSnackbarOpen, setNewRequestSnackbarOpen] = useState(false);
    const [taskActionSnackbarOpen, setTaskActionSnackbarOpen] = useState(false);
    const [showCopilotTasks, setShowCopilotTasks] = useState(false)
    const [tasksLoading, setTasksLoading] = useState(false)
    const [selectedDescriptionLanguage, setSelectedDescriptionLanguage] = useState("en_US")
    const [selectedTaskLanguage, setSelectedTaskLanguage] = useState("en_US")
    const [sideMargin, setSideMargin] = useState('0px') /** not sure if this is the best option but good for mvp */
    const [buttonLeft, setButtonLeft] = useState('-72px') /** not sure if this is the best option but good for mvp */

    useEffect(()=>{
        if (payload.copilot_task_list) setShowCopilotTasks((payload.copilot_task_list))
        setTasksLoading(false)
    }, [payload])

    useEffect(()=>{
        setTasksLoading(taskAILoading)
    }, [taskAILoading])

    const saveSideDetailChange = (save_object) => {
        /** saved only if the is NOT copilot tasks
         *  Copilot tasks are saved in one big update below
         * */
        if (payload.copilot_task_list) return null

        const update_object = {
            task_list : payload.task_list
        }
        update_object.task_list[save_object["z-index"]].title = save_object["title"]

        db
            .collection('sprints')
            .doc(payload.id)
            .update(update_object)
            .then()

    }

    const copilotCardUpdate = (updateObject) => {
        payload.copilot_task_list[updateObject["z-index"]].title = updateObject.title
    }
    const saveCopilotTasks = (event) => {
        event.preventDefault();
        console.log('payload::',payload)
        if (!payload.copilot_task_list) return null
        const taskListObject = {
            task_list:payload.copilot_task_list
        }
        console.log('taskListObject::',taskListObject)
        db
            .collection('sprints')
            .doc(payload.id)
            .update(taskListObject)
            .then()
    }

    const taskCardActionOpen = (cardId) => {
        console.log('cardIndex or id',cardId)
        setTaskActionsOpen(true)
    }

    const RenderTaskCards = () => {
        /** render copilot tasks */
        if (showCopilotTasks){
            if (!payload || !payload.copilot_task_list) return null
            return payload.copilot_task_list.map((task, id) => {
                return <TaskCard key={id} payload={task} id={id} cardUpdated={copilotCardUpdate} taskAction={taskCardActionOpen} />
            })
        }

        /** render tasks from server*/
        if (!payload || !payload.task_list) return null
        return payload.task_list.map((task, id) => {
            return <TaskCard payload={task} id={id} key={id} cardUpdated={saveSideDetailChange} taskAction={taskCardActionOpen} />
        })
    }

    const GetCopilotText = () => {
        if (tasksLoading) return 'Fetching... be patient'
        return 'Copilot generated'
    }

    const GetCopilotErrorMsg = () => {
        if (payload.copilot_error) return payload.copilot_error_msg
        return null
    }


    const RenderSideDetails = () => {
        const sidedetailData = [
            "Status",
            "Tasks",
        ]

        let elements = []

        sidedetailData.forEach((detail, index)=>{

            if (detail === 'Status') {

                elements.push(
                    <div key={'status-1'} className={classes.sidedetailsRow_FULL}>
                        <SideDetailsStatus payload={payload}/>
                    </div>
                )


            } else {
                elements.push(
                    <div className={classes.sidedetailsRow_FULL} key={index}>
                        <div className={classes.sidedetailsRowHeader_FULL}>{detail} </div>
                        {tasksLoading || showCopilotTasks ?
                            <div style={{paddingBottom:'5px'}}>
                                <SlowLoad />
                                <div className={classes.subtitle_small} style={{paddingLeft:'5px'}}><GetCopilotText/></div>
                            </div> : <></>}
                        <div className={classes.subtitle_small} style={{paddingLeft:'5px'}}><GetCopilotErrorMsg/></div>
                        {showCopilotTasks ?
                            <>
                                <div style={{marginBottom:'15px', marginTop:'10px'}}>
                                    <Button
                                        color="primary"
                                        size={'small'}
                                        onClick={()=>{setShowCopilotTasks(false)}}
                                    >Cancel</Button>
                                    <Button
                                        color="primary"
                                        size={'small'}
                                        variant="contained"
                                        type="submit"
                                        className={classes.submitButton}
                                        onClick={(e)=>{saveCopilotTasks(e)}}
                                    >Save</Button>
                                </div>

                            </>
                            :
                            <></>
                        }
                        <div className={classes.sidedetailValue_FULL}>
                            <RenderTaskCards/>
                        </div>
                    </div>
                )
            }
        })

        return elements
    }

    const handleWriteDescription = (event, reason) => {
        // setNewRequestSnackbarOpen(true)
        setRequestTaskOpen(false)
        writeDescription(selectedDescriptionLanguage)
    };

    const handleWriteTasks = (event, reason) => {
        // setNewRequestSnackbarOpen(true)
        setRequestTaskOpen(false)
        setTasksLoading(true)
        writeTasks(selectedTaskLanguage)
    };

    const handleSelectRequestType = (event, reason) => {
        setNewRequestSnackbarOpen(true)
        setRequestTaskOpen(false)
    };

    const handleSnackClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setNewRequestSnackbarOpen(false);
        setTaskActionSnackbarOpen(false)
    };

    const handleDescriptionLanguageSelection = (payload) => {
        setSelectedDescriptionLanguage(payload.newState.key)
    }
    const handleTaskLanguageSelection = (payload) => {
        setSelectedTaskLanguage(payload.newState.key)
    }
    const handleOpenCopilot = () => {
        if (sideMargin !== '0px') {
            setSideMargin('0px')
            setButtonLeft('-72px')
        } else {
            setSideMargin('-380px')
            setButtonLeft('308px')
        }
    }

    return (
        <div className={classes.sidedetails} style={{marginLeft:sideMargin}}>
            <div className={classes.sideTopMenu}>
                <IconButton
                    color="inherit"
                    aria-label="open copilot"
                    onClick={handleOpenCopilot}
                    edge="start"
                    className={clsx(classes.showCopilotButton, {[classes.showCopilotButtonHide]: !isPad})}
                    style={{left:buttonLeft}}
                >
                    <SlowLoad />
                </IconButton>

                <div>
                    <Button variant="contained" color="primary" size="small" className={classes.requestTaskButton} onClick={()=>{setRequestTaskOpen(true)}}>
                        <ListItemIcon><FlareOutlined/></ListItemIcon>{"Generate AI tasks"}
                    </Button>
                </div>
            </div>
            <hr style={{width:'100%', border:'1px solid #000', borderBottom:'none', marginTop: '0px', marginLeft:'-25px', marginBottom:'0px'}} />
            <div style={{maxWidth:'350px', minWidth:'350px', overflowY:'scroll', paddingTop:'20px'}}>
                <RenderSideDetails />
            </div>

            <Modal
                open={requestTaskOpen}
                // onClick={()=>{setConfirmFeatureOpen(false)}}
                onClose={(_, reason) => {
                    if (reason === "backdropClick") {
                        setRequestTaskOpen(false);
                    }
                }}
                className={classes.modal}
                aria-labelledby="modal-request-architect"
                aria-describedby="modal-request-architect"
                closeAfterTransition

            >
                <div className={classes.paper}>
                    <h2 style={{fontSize:'20px'}} id="transition-modal-title">What are you looking for?</h2>
                    <div className={classes.copilotOptionWrap}>
                        <div className={classes.listButton} onClick={handleWriteDescription}>Write Description</div>
                        <div className={classes.dropDownWrap}>
                            <GeneralDropdown
                                list={availableLanguages}
                                state={selectedDescriptionLanguage}
                                placeholderText={'Select language'}
                                updateStateChange={handleDescriptionLanguageSelection}
                                showFilter={true}
                            />
                        </div>
                    </div>
                    <div className={classes.copilotOptionWrap}>
                        <div className={classes.listButton} onClick={handleWriteTasks}>Write tasks</div>
                        <div className={classes.dropDownWrap}>
                            <GeneralDropdown
                                list={availableLanguages}
                                state={selectedTaskLanguage}
                                placeholderText={'Select language'}
                                updateStateChange={handleTaskLanguageSelection}
                                showFilter={true}
                            />
                        </div>
                    </div>
                    <div className={classes.listButton} onClick={handleSelectRequestType}>Architect feedback</div>
                </div>
            </Modal>
            <Modal
                open={taskActionsOpen}
                // onClick={()=>{setConfirmFeatureOpen(false)}}
                onClose={(_, reason) => {
                    if (reason === "backdropClick") {
                        setTaskActionsOpen(false);
                    }
                }}
                className={classes.modal}
                aria-labelledby="modal-task-action"
                aria-describedby="modal-task-action"
                closeAfterTransition
            >
                <div className={classes.paper}>
                    <h2 style={{fontSize:'20px'}} id="transition-modal-title">What are you looking for?</h2>
                    <div className={classes.copilotOptionWrap}>
                        <div className={classes.listButton} onClick={()=>setTaskActionSnackbarOpen(true)}>Send to Trello</div>
                    </div>
                    <div className={classes.copilotOptionWrap}>
                        <div className={classes.listButton} onClick={()=>setTaskActionSnackbarOpen(true)}>Send to Jira</div>
                    </div>
                </div>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={newRequestSnackbarOpen}
                onClose={handleSnackClose}
                autoHideDuration={6000}
                message={'Architect notified about the request. You will get an email about this.'}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={'123'}
            />
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={taskActionSnackbarOpen}
                onClose={handleSnackClose}
                autoHideDuration={6000}
                message={'Coming soon...'}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleSnackClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={'124'}
            />

        </div>
    )
}
