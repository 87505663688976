import React from 'react';
import Logo_for_dark from '../../../img/sprinthack_logo_white_light.png'
import Logo_for_light from '../../../img/sprinthack_logo_dark_on_white.png'
import useMediaQuery from "@material-ui/core/useMediaQuery";

const propTypes = {
    // topouterdivider: PropTypes.bool,
    // topdivider: PropTypes.bool
}

const defaultProps = {
    // topouterdivider: false,
    // topdivider: false
}

const Footer = ({...props}) => {
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;

    const logo = (prefersDarkMode) ? Logo_for_dark : Logo_for_light;


    return (
        <footer
            {...props}
        >
            <div className="footer-container">
                <div className={'footer-innerWrap'}>
                    <div className={'footer-logo'}>
                        <div>
                            <img alt={'logo'} src={logo} />
                        </div>
                        <div>
                            Designed by <a href="https://fibo.io">Fibo Labs</a> in the Nordics
                        </div>
                    </div>
                    <div className={"footer-column"}>
                        <div className={"footer-title"}>Company</div>
                        <div><a href={"/aboutus"}>About us</a></div>
                        <div><a href={"https://sprinthack.substack.com"}>Research Reports</a></div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

Footer.propTypes = propTypes;
Footer.defaultProps = defaultProps;

export default Footer;