import React, {useEffect, useLayoutEffect, useReducer, useRef, useState} from "react";
import {Divider, List, makeStyles, TextareaAutosize, TextField} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse/Collapse";
import Button from "@material-ui/core/Button";
import clsx from "clsx";
import {useHistory, useRouteMatch} from "react-router-dom";
import SideDetailsAnalysisStage from "./sideDetailStepper";
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import Switch from '@material-ui/core/Switch';
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Paper from '@material-ui/core/Paper';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import Typography from '@material-ui/core/Typography';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {db, firebaseFunctions} from "../../util/firebase";
import ButtonForUseCases from "./buttonForUseCases";
import ChevronRight from "@material-ui/icons/ChevronRight";
import ChevronDown from "@material-ui/icons/ExpandMore";
import SideDetailsNextActionDropdown from "./sideDetailsNextActionDropdown";
import BDDscenarioList from "./BDDscenarioList";
import activityUpdateManager from "../activityUpdates/activityUpdateManager"
import BDDfeatureTag from "./BDDfeatureTag";
import FreetextEditor from "../generalComponents/FreetextEditor";
import LinkWithAssumptions from "./sideDetailsLinkingWithAssumption";

const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: 'rgba(0, 0, 0, 0.54)'
    },
    textareaStyle: {
        padding: '10px',
        width: 'calc(100% - 35px)'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    subtitleLight: {
        color:theme.custom.subtitleLight
    },
    submitButton: {
        background:theme.custom.MainButton,
    },
    createCycleButton: {
        background:theme.custom.MainButton
    },
    cycleTextDetail: {
        lineHeight:'20px'
    },
    approveButton: {
        background:theme.custom.GreenButton,
    },
    approveChip: {
        background:'transparent',
        fontWeight:'700',
        border:'1px solid transparent',
        transition:'200ms',
        '&:hover':{
            // background:'#fff',
            // border:'1px solid rgba(0, 0, 0, 0.12)'
        },
    },
    sidedetails: {
        marginTop:'-65px',
        flex: '1 100%',
        background: theme.custom.sidedetailBackground,
        padding:'0px 0px 82px 25px',
        position:"relative",
        flexShrink:"0",
        WebkitBoxFlex:"1",
        flexGrow:"1",
        minWidth: '320px',
        maxHeight: 'calc(100% + 65px)',
        overflowY: 'scroll',
    },
    sideTopMenu: {
        minHeight:'64px',
        // width:'300px',
        display:'flex',
        position:'relative',
        alignItems:'center'
    },
    sidedetailData: {
        padding: '20px 0 20px 0',
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'15px',
    },
    sidedetailsRowHeader: {
        color:'rgb(107, 111, 118)',
        fontWeight: 500,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'100px',
        flexShrink: '0',
        textTransform: 'capitalize',
    },
    sidedetailValue: {
        fontWeight: 500,
        width:'100%',
        display: 'flex',
        flex:'initial',
        flexDirection:'row',

        // "whiteSpace":"nowrap",
        // "overflow":"hidden !important",
        // "textOverflow":"ellipsis"
    },
    sidedetailValueLink: {
        // "display":"inline-block",
    },
    sideDetailHoverField:{
        flexGrow: '1',
        maxWidth:'100%',
        padding:'8px 8px',
        textAlign:'left',
        justifyContent:'flex-start',
        marginLeft:'-8px',
        marginRight:'32px',
        flexShrink:'1',
        overflow:'hidden',
        border:'1px solid transparent',
        borderRadius:'5px',
        background:'transparent',
        marginTop:'-8px',
        "&:hover":{
            border:'1px solid #c7c7c7'
        }
    },
    volatilityToggle:{
        transitionProperty:'border, background, color',
        borderColor:theme.custom.OutlineButtonBorder,
        transitionDuration:'150ms',
        "&:hover":{
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover,
            textDecoration:'none',
        }
    },
    sidedetailValueArrRow: {
        marginBottom:'10px'
    },
    approveIcon: {
        marginRight: '10px',
        color:theme.custom.GreenButton
    },
    approveChipIcon: {
        color:theme.custom.GreenButton
    },
    sideDetailValueLinkElement: {
        width:'fit-content',
        padding:'5px',
        color:'inherit',
        border: '1px solid transparent',
        borderRadius:'4px',
        transitionProperty:'border, background, color',
        transitionDuration:'150ms',
        "&:hover":{
            border:'1px solid rgb(239, 241, 244)',
            borderColor: theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover,
            textDecoration:'none',
        }
    },
    sideDetailLinkBorder:{
        borderColor:theme.custom.OutlineButtonBorder,
    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        }
    },
    link:{
        display:'flex',
        flexDirection:'row',
        transition:'150ms',
        "-webkit-box-align":'center',
        alignItems:'center',
        color:'inherit',
        textDecoration: 'none',
        cursor: 'default',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }

    },
    sideDetailGuidance: {
        color:'rgba(0,0,0,0.8)',
        background:'rgb(205,242,245)',
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        boxShadow: theme.custom.boxShadowGlow
    },
    feedbackImage:{
        width:'100%',
        padding:'25px 50px 0px 50px'
    },
    feedbackLinks:{
        backgroundColor:theme.custom.MemberListBG,
        marginBottom:'5px',
        color:theme.custom.genericHighlightText,
    },
    whiteColorButton:{
        color:'#a2a2a2',
        borderColor:'#a2a2a2'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    nextActionToggle: {
        position:'absolute',
        top:'0px',
        right:'0px'
    },
    sidedetailTopBorderLargeScreen:{
        "display":"block"
    },
    sidedetailTopBorderSmallScreen:{
        "display":"none"
    },
    [theme.breakpoints.up("xs")]: {
        sidedetails:{
            flex: '10 auto'
        },
        sidedetailTopBorderLargeScreen:{
            "display":"none"
        },
        sidedetailTopBorderSmallScreen:{
            "display":"block"
        }
    },
    [theme.breakpoints.up("sm")]: {
        sidedetails:{
            order:'3'
        },
        sidedetailTopBorderLargeScreen:{
            "display":"none"
        },
        sidedetailTopBorderSmallScreen:{
            "display":"block"
        }
    },
    [theme.breakpoints.up("md")]: {
        sidedetailTopBorderLargeScreen:{
            "display":"block"
        },
        sidedetailTopBorderSmallScreen:{
            "display":"none"
        }
    },

}));

function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

const getDefaultDetailKeys = () => {
    return [
        'estimate',
        'success',
        'learnings'
    ]
}

function getPlaceholders() {
    return {
        'estimate':'Get your team to estimate as a number the amount of work required',
        'success':'In the experiment define what your stakeholder will do: If I do X, them stakeholder will do Y...',
        'learnings':'After your tests, what did you learn?...'
    }
}

export default function SideDetails({payload, history, toggleFunc, toggleMetadata, showVolatilities, drawComponentMap, showImage, showUseCaseLink, useCaseComplete, scenarioComplete}) {
    const classes = useStyles();
    let { url } = useRouteMatch();
    const [showNextAction, setShowNextAction] = useState(true)
    const [maxHeightNextActions, setMaxHeightNextActions] = useState('0px')

    const [templateLink,setTemplateLink] = useState({
        main:''
    })
    const [feedbackLink,setFeedbackLink] = useState({
        main:''
    })
    const [shareButtonText, setShareButtonText] = useState({
        main:'Copy link'
    })
    const [templateLinkText, setTemplateLinkText] = useState({
        main:'Copy link'
    })
    const shareRef = useRef(null);
    const [templateLinkOpen, setTemplateLinkOpen] = useState(false)
    const [shareOpen, setShareOpen] = useState(false)

    const [state, setState] = useState({
        addDetailsText: '',
        addDetailFocus:false
    });
    const [addDetailFocus, setAddDetailFocus] = useState(false);
    const [approveLoading, setApproveLoading] = useState(false);
    const [payloadState, setPayloadState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {ai_analysis_stage:0, status: 0}
    );
    const defaultKeys = getDefaultDetailKeys();
    const placeholders = getPlaceholders();

    const [usecaseAnchorEl, setUsecaseAnchorEl] = useState(null)
    const [usecase_menu_open, setUsecase_menu_open] = useState(false)

    useLayoutEffect(() => {
        setShowNextAction(payload.showNextAction)
        setMaxHeightNextActions(payload.showNextAction ? '1000px' : '64px')
        setPayloadState(payload)
    }, [payload]);

    useEffect(() => {
        setState({addDetailsText: '', addDetailFocus:false})
    }, [payloadState]);

    const buildStepDetails = ({detailsString}) => {
        /** does not exists */
        if (!detailsString) return null
        /** is a placeholder element */
        if (typeof detailsString != 'string') return detailsString
        /** is a text string */
        const detailsArr = detailsString.split(',')
        const detailsList = detailsArr.map((detail, index) => {
            if (detail.trim() === '') return false
            return <li key={index} value={index+1}>{detail}</li>
        })
        return <ol style={{margin:'0px', paddingLeft:'15px'}}>{detailsList}</ol>
    }


    const buildSidetailText = (text, detail) => {

        if (validURL(text) || ['Components', 'Activity list', 'Roadmap chart'].includes(detail)) {
            if (typeof text === 'string' && text.substring(0,5) === "https") {
                if (['Components'].includes(detail)) {
                    return <div className={classes.link} onClick={()=>{drawComponentMap(text)}}>
                        <span className={clsx(classes.sideDetailValueLinkElement, classes.sideDetailLinkBorder)}>OPEN</span>
                        <ChevronRight className={classes.menuIcon} />
                    </div>
                } else if (['Activity list', 'Roadmap chart'].includes(detail)){
                    return <div className={classes.link} onClick={()=>{showImage(text)}}>
                        <span className={clsx(classes.sideDetailValueLinkElement, classes.sideDetailLinkBorder)}>OPEN</span>
                        <ChevronRight className={classes.menuIcon} />
                    </div>
                } else {
                    return <a className={classes.link} href={text} target={'_blank'} rel={'noreferrer'}>
                        <span className={clsx(classes.sideDetailValueLinkElement, classes.sideDetailLinkBorder)}>OPEN</span>
                        <ChevronRight className={classes.menuIcon} />
                    </a>
                }
            }
        }
        return text
    }

    const toggleVolatilities = () => {
        toggleFunc('volatilities')
    }

    const buildSidetailVolatilityToggle = (index, detail, sidedetailText) => {
        return (
            <div key={index}>
                <div className={classes.sidedetailsRow}>
                    <div className={classes.sidedetailsRowHeader}>{detail}: </div>
                    {/*<div className={classes.sidedetailValue}>{sidedetailText}</div>*/}
                    <div>
                        <Button size='small' variant='outlined' className={classes.volatilityToggle} onClick={toggleVolatilities}>{showVolatilities ? "Hide" : "Show"}</Button>
                    </div>
                </div>
                {showVolatilities ?
                    <div className={classes.sidedetailsRow}>
                        <div className={classes.sidedetailsRowHeader}/>
                        <div className={classes.sidedetailValue}>{sidedetailText}</div>
                    </div> : null }
            </div>
        )
    }

    const demoUseCaseStructure = [
        {
            "text":'',
            "action_diagram":'',
            "call_chains":'',
            "sequence":'',
            "volatility_ref":'',
            "use_cases_ref":''
        }
    ]

    const useCaseIconClick = (payload) => {
        // console.log('payload::',payload)
        if (showUseCaseLink) showUseCaseLink(payload)
    }
    const scenarioIconClick = (payload) => {
        console.log('payload::',payload)
        if (showUseCaseLink) showUseCaseLink(payload)
    }

    const useCaseCompleteClick = (payloadIndex, isCompleted) => {
        /** delegate to parent for saving to database and handling between childrens.
         * Eventually should be much more simpler with mobX state tree
         * */
        useCaseComplete(payloadIndex, isCompleted)
    }
    const scenarioCompleteClick = (payloadIndex, isCompleted) => {
        /** delegate to parent for saving to database and handling between childrens.
         * Eventually should be much more simpler with mobX state tree
         * */
        scenarioComplete(payloadIndex, isCompleted)
    }


    const buildSidetailUsecaseToggle = (index, detail, sidedetailData) => {
        let displayEl = "";
        if (sidedetailData instanceof Array) {
            /** array of objects */

            // console.log('sidedetailData',sidedetailData)

            displayEl = sidedetailData.map((item, index)=>{
                const itemName = item.name.trim();
                return <ButtonForUseCases key={index} index={index} text={itemName} toggle={toggleMetadata} storiesRef={item.data} iconClick = {useCaseIconClick} icon={'eye'} hasOkIcon={true} completedStatus={item.completed} completedClick={useCaseCompleteClick} />
            })

        }else {
            /** string of use cases separated by comma */
            const lisArr = sidedetailData.split(',')
            displayEl = lisArr.map((item, index)=>{
                item = item.trim();
                return <ButtonForUseCases key={index} text={item} toggle={toggleMetadata} icon={'eye'} />
            })
        }

        return (
            <div className={classes.sidedetailsRow} key={index}>
                <div className={classes.sidedetailsRowHeader}>{detail}: </div>
                <div className={classes.sidedetailValue}>{displayEl}</div>
            </div>
        )
    }

    const handleCompleteStories = () => {
        setApproveLoading(true);
        setPayloadState({ai_analysis_stage : 0})
        db.collection('cycle_requests').doc(payloadState.requestId)
            .update({ai_analysis_stage:0, status:0, state:"Scenario discovery"})
            .then(()=>{
                activityUpdateManager.updateStatus(payloadState.requestId, 1, "Scenario discovery")
                setApproveLoading(false);
            })
            .catch(()=>{
                setApproveLoading(false);
            })
    }

    const handleApprove = () => {
        setApproveLoading(true);
        let newAnalysisStage = 4

        if (payloadState.ai_analysis_stage > 4){
            setPayloadState({ai_analysis_stage : 4})
            newAnalysisStage = 4
        } else {
            setPayloadState({ai_analysis_stage : 5})
            newAnalysisStage = 5
        }
        db.collection('cycle_requests').doc(payloadState.requestId)
            .update({ai_analysis_stage:newAnalysisStage})
            .then(()=>{
                setApproveLoading(false);
            })
            .catch(()=>{
                setApproveLoading(false);
                if (newAnalysisStage > 4){
                    setPayloadState({ai_analysis_stage : 4})
                } else {
                    setPayloadState({ai_analysis_stage : 5})
                }
            })
    }

    const RenderCompleteFeedbackChip = () => {
        const text = (payloadState.ai_analysis_stage >= 0) ? 'Done' : 'Complete stories'
        return (<div>
            <Switch color='primary' checked={payloadState.ai_analysis_stage >= 0} disabled={approveLoading} onChange={handleCompleteStories} name="Approve" /> <span className={classes.approveChip}>{text}</span>
        </div>)
    }

    const RenderApproveChip = () => {
        const text = (payloadState.ai_analysis_stage > 4) ? 'Done' : 'Approve'
        return (<div>
            <Switch color='primary' checked={payloadState.ai_analysis_stage > 4} disabled={approveLoading} onChange={handleApprove} name="Approve" /> <span className={classes.approveChip}>{text}</span>
        </div>)
    }

    const updateStateChange = ({newState, newStatus}) => {
        if (newStatus !== undefined) {
            const newStatusInt = parseInt(newStatus, 10)
            setPayloadState({state:newState, ai_analysis_stage:newStatusInt, status:newStatusInt})
        } else {
            setPayloadState({state:newState})
        }

        /** update state change by saving to database and it will ripple the changes*/
    }

    const SideDetailsFeature = () => {
        return null
        // return <div className={classes.sidedetailsRow} style={{marginBottom:'5px'}}>
        //     <div className={classes.sidedetailsRowHeader} style={{width:'70px'}}>Feature: </div>
        //     <div className={classes.sidedetailValue} >
        //         <BDDfeatureTag />
        //     </div>
        // </div>
    }

    const SidedetailsNextAction= () => {

        /** load the states switch of composition phase */
        return <SideDetailsNextActionDropdown payload={payloadState} updateStateChange={updateStateChange} />

    }

    const SidedetailsLinkAssumption = () => {
        /** load the dropdown of possible active assumptions */
        return <LinkWithAssumptions payload={payloadState} />
    }

    const SidedetailsGuidance = () => {
        let F_Links = {}
        Object.keys(feedbackLink).forEach(key=>{
            F_Links[key] = `sprinthack.com/f/${payloadState.requestId}`
        })

        return (payloadState.ai_analysis_stage < 0) ? (
            <div>
                <Paper elevation={5} className={classes.sideDetailGuidance}>
                    {/*<div className={classes.subtitle}>*/}
                    {/*    A task for you!*/}
                    {/*</div>*/}
                    <Typography variant="h5" component="h2">
                        Write user stories
                    </Typography>
                    <div className={classes.subtitle}>
                        Write stories and other stakeholder needs
                    </div>
                    {/*<img className={classes.feedbackImage} src={feedback_boxes} />*/}
                    {/*<List>*/}
                    {/*    <p>Feedback link</p>*/}
                    {/*    <ListItem className={classes.feedbackLinks}>*/}
                    {/*        <ListItemText primary={F_Links.main} ref={feedbackRef} />*/}
                    {/*        <ListItemSecondaryAction>*/}
                    {/*            <Button*/}
                    {/*                color="primary"*/}
                    {/*                variant="outlined"*/}
                    {/*                size="small"*/}
                    {/*                className={classes.whiteColorButton}*/}
                    {/*                onClick={(e)=>{*/}
                    {/*                    navigator.clipboard.writeText(F_Links.main);*/}
                    {/*                    setFeedbackButtonText(prevState => {*/}
                    {/*                        return {*/}
                    {/*                            ...prevState,*/}
                    {/*                            main: 'Copied!'*/}
                    {/*                        }*/}
                    {/*                    })}}*/}
                    {/*            >{feedbackButtonText.main}</Button>*/}
                    {/*        </ListItemSecondaryAction>*/}
                    {/*    </ListItem>*/}
                    {/*</List>*/}
                    {/*<div className={classes.subtitleLight} style={{fontSize:'12px'}}>*/}
                    {/*    Ask your architect about customizing the feedback questions!*/}
                    {/*</div>*/}

                </Paper>
            </div>
        ) : null
    }

    const SidedetailsTakeToCycles = () => {
        const cycleRender = () => {
            if (!payloadState.cycleRef || payloadState.cycleRef.constructor !== Array || payloadState.cycleRef.length < 1) return <div><p>Sprinthack Architect has been nofified and will set up the cycle</p></div>

            // return payloadState.cycleRef.map((cycleData,index)=>{
            //     return (
            //         <div key={index} style={{marginBottom:'5px'}}>
            //             <Button
            //                 variant="outlined"
            //                 size="small"
            //                 onClick={()=>{linkToCycle(cycleData.id)}}
            //             >
            //                 <PlayCircleOutlineIcon className={classes.approveIcon} /> {cycleData.name}
            //             </Button>
            //         </div>
            //     )
            // })
            return <div style={{marginBottom:'5px'}}>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={()=>{linkToCycle('')}}
                >
                    <PlayCircleOutlineIcon className={classes.approveIcon} /> {"Cycle is ready"}
                </Button>
            </div>
        }

        return (payloadState.ai_analysis_stage > 4) ? (
            <div className={classes.sidedetailsRow}>
                <div className={classes.sidedetailsRowHeader} />
                <div className={classes.sidedetailValue}>
                    {!payloadState.readyToCycle
                        ? (<div>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                className={clsx(classes.createCycleButton)}
                                onClick={()=>{sendRequestToCycle(payloadState.requestId)}}
                            ><PlayCircleOutlineIcon className={classes.approveIcon} /> Create Sprint Cycle</Button>
                        </div>)
                        : cycleRender()
                    }
                </div>
            </div>
    ) : null
    }

    const SidedetailsShowReport = () => {
        return (payloadState.ai_analysis_stage > 4) ? (
            <div className={classes.sidedetailsRow}>
                <div className={classes.sidedetailsRowHeader} />
                <div className={classes.sidedetailValue}>
                    <div>
                        <a className={classes.link} href={'/report/'+payloadState.requestId} target={'_blank'}>
                            <Button
                                color="primary"
                                variant="contained"
                                size="small"
                                className={clsx(classes.createCycleButton)}
                                onClick={()=>{}}
                            ><InsertLinkIcon style={{marginRight:'10px'}} /> Show Report</Button>
                        </a>
                    </div>
                </div>
            </div>
        ) : null
    }

    const linkToCycle = (cycleRef) => {
        /** get cycle_request */

        /** check security for writing and reading */

        /** create a new cycle */

        // console.log('link to cycle', cycleRef)
        const splittedUrl = url.split('/')
        history.push('/'+splittedUrl[1]+'/cycles')
    }

    const sendRequestToCycle = (requestRef) => {
        /** get cycle_request */

        /** check security for writing and reading */

        /** create a new cycle */

        console.log('send to cycle')
        db.collection('cycle_requests').doc(requestRef)
            .set({readyToCycle:true}, {merge: true} )
            .then(()=>{
                console.log('cycle set to ready. all good')
            })
            .catch(err=>{
                console.log('cycle set error',err)
            })
    }

    const SidedetailsCustom = () => {
        if (!payloadState.sidedetails) return false;

        console.log('sidedetails', payload.sidedetails)

        if (!payloadState.sidedetails['Learnings']) {payloadState.sidedetails['Learnings'] = ""}
        if (!payloadState.sidedetails['Hypothesis']) {payloadState.sidedetails['Hypothesis'] = ""}

        let elements = ['','','','','']

        Object.keys(payloadState.sidedetails).forEach((detail, index) => {
            let sidedetailText = payloadState.sidedetails[detail]

            /** custom set for volatilies to come from user_needs */
            if (detail === 'Volatilities') {
                let user_need_volatilities = {}
                payloadState.user_needs &&  payloadState.user_needs.forEach(stakeholder=>{
                    stakeholder.needs && stakeholder.needs.forEach(need=>{
                        need.volatilities.forEach(volatility=>{
                            if(!user_need_volatilities[volatility]) {
                                user_need_volatilities[volatility] = 1
                            } else {
                                user_need_volatilities[volatility]++
                            }
                        })
                    })
                })
                sidedetailText = ''
                Object.keys(user_need_volatilities).forEach((volatility)=>{
                    /** build text */
                    sidedetailText += `${volatility} (${user_need_volatilities[volatility]}),`
                })
            }

            /** check if is array? */
            if (payloadState.sidedetails[detail] instanceof Array) {
                sidedetailText = []

                // payloadState.sidedetails[detail].forEach((item, index) => {
                //     const textItem = buildSidetailText(item, detail)
                //     sidedetailText.push(<div key={index} className={classes.sidedetailValueArrRow}>{textItem}</div>)
                //  });

            } else {
                sidedetailText =  (!sidedetailText || sidedetailText === '')  ? (
                    <span className={classes.subtitleLight}>{placeholders[detail]}</span>
                ) : (
                    sidedetailText
                );

                /** hardcoded list for now */
                if (detail === 'Use cases' || detail === 'Volatilities') {
                    sidedetailText = buildStepDetails({detailsString:sidedetailText})
                } else {
                    sidedetailText = buildSidetailText(sidedetailText, detail)
                }
            }


            const el = (
                <div className={classes.sidedetailsRow} key={index}>
                    <div className={classes.sidedetailsRowHeader}>{detail}: </div>
                    <div className={classes.sidedetailValue}>{sidedetailText}</div>
                </div>
            )

            if ( detail === 'Learnings') {elements[0] = el; return;}
            if ( detail === 'Hypothesis') {elements[1] = el; return;}
            // if ( detail === 'Volatilities') {elements[1] = el; return;}
            // if ( detail === 'Volatilities') {elements[1] = buildSidetailVolatilityToggle(index, detail, sidedetailText); return;}
            // if ( detail === 'Components') {elements[2] = el; return;}
            // if ( detail === 'Use cases') {elements[3] = ''; return;}
            // if ( detail === 'Use cases') {elements[4] = buildSidetailUsecaseToggle(index, detail, payloadState.sidedetails[detail]); return;}
            // if ( detail === 'Activity list') {elements[5] = el; return;}
            // if ( detail === 'Roadmap chart') {elements[6] = el; return;}
            // if ( detail === 'Estimates') {elements[7] = ''; return;}
            // if ( detail === 'Priority list') {elements[8] = ''; return;}
            // if ( detail === 'Component scoring') {elements[9] = ''; return;}
            // if ( detail === 'Diagrams') {elements[10] = ''; return;}

            elements.push(el)
        });

        return elements
    }

    const SidedetailsAddEdit = () => {
        return (payloadState.ai_analysis_stage >= -1) ? (
            <div>
                <div>
                    <TextareaAutosize
                        rowsMax={1}
                        className={classes.textareaStyle}
                        placeholder="Add / edit detail as 'detail_name : text'"
                        value={state.addDetailsText}
                        onChange={(e) => {setState({...state, addDetailsText: e.target.value})}}
                        onFocus={handleAddDetailsFocus}
                        onBlur={handleAddDetailsFocus}
                    />
                </div>
                <div style={{color:'rgb(235, 87, 87)'}}>
                    Note: currently this data can be filled only by your dedicated architect. We are developing this...
                </div>
                <div>
                    <Collapse in={addDetailFocus} addEndListener={()=>{}}>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            size="small"
                            className={clsx(classes.submitButton)}
                            onClick={(e) => handleAddDetailsSend(e)}
                        >Save</Button>
                    </Collapse>
                </div>

            </div>
        ) : null
    }

    const handleAddDetailsFocus = () => {
        setAddDetailFocus((prev) => !prev);
    };

    const toggleNextActions = () => {
        /** set height of nextAction element */
        if (showNextAction) {setMaxHeightNextActions('64px')}
        else {setMaxHeightNextActions('1000px')}

        /** set nextAction element button and save to db... */
        const nextActionToggle = !showNextAction
        setShowNextAction(nextActionToggle)
        db.collection('cycle_requests').doc(payloadState.requestId)
            .update({showNextAction:nextActionToggle})
            .then(()=>{
            })
            .catch(()=>{
            })
    }

    const handleAddDetailsSend = (e) => {
        const text = state.addDetailsText.split(':');
        if (!text || !text[0] || !text[1]) {setState({...state, addDetailsText: ''}); return false;}
        const detailKey = text[0].trim();
        const detailValue = (text[2] && (text[1].substring(0,5) === 'https' || 'HTTPS')) ? text[1]+':'+text[2] : text[1];
        const newDetail = {};
        newDetail[detailKey] = detailValue;

        let updateObject = {sidedetails:{...payloadState.sidedetails, ...newDetail}}

        // console.log('payloadState.sidedetails:::',payloadState.sidedetails);
        // console.log('payloadState.ai_analysis_stage:::',payloadState.ai_analysis_stage);
        if (
            payloadState.sidedetails["Use cases"] && payloadState.sidedetails["Use cases"] !== "" &&
            payloadState.sidedetails["Volatility"] && payloadState.sidedetails["Volatility"] !== "" &&
            payloadState.sidedetails["Components"] && payloadState.sidedetails["Components"] !== "" &&
            ((payloadState.sidedetails["Diagrams"] && payloadState.sidedetails["Diagrams"]) || (detailKey === 'Diagrams')) &&
            payloadState.ai_analysis_stage < 4
        ) {
            updateObject = {...updateObject, ...{ai_analysis_stage:4}}
        }
        setPayloadState(updateObject)

        /** this should have save detail api call to the object backend */

    }

    // const handleEditDetailFocus = () => {
    //     console.log('edit detail focus')
    // };


    /** new default sidedetails for 2022 */
    const saveSideDetailChange = (save_object) => {
        const update_object = {}
        update_object[save_object["key"]] = save_object["value"]
        // console.log('payload',payload)
        // console.log('payload.requestId',payload.requestId)
        // console.log('update_object',update_object)
        db
            .collection('cycle_requests')
            .doc(payload.requestId)
            .update(update_object)
            .then(()=>{
            })
            .catch(err=>{
            })

    }

    const generateSideDetailsElement = (detail, text, index) => {

        return <div className={classes.sidedetailsRow} key={index}>
            <div className={classes.sidedetailsRowHeader}>{detail} </div>
            <div className={classes.sidedetailValue}>
                <div className={classes.sideDetailHoverField}>
                    <FreetextEditor payload={{key:detail, value:text, placeholder:placeholders[detail]}} save={saveSideDetailChange}/>
                </div>
            </div>
        </div>

    }

    const DefaultSideDetails = () => {
        if (!payload) return <></>
        let elements = ['',''];

        defaultKeys.forEach((defaultkey, idx)=>{
            elements.push(generateSideDetailsElement(defaultkey, payload[defaultkey], idx))
            // if (!payload[defaultkey]) {
            //     elements.push(generateSideDetailsElement(defaultkey, placeHolders[defaultkey], idx))
            // } else {
            // }
        })

        const currentIndex = defaultKeys.length
        return elements

    }

    return (
        <div className={classes.sidedetails}>
            <hr className={classes.sidedetailTopBorderSmallScreen} style={{border:'1px solid #000', borderBottom:'none', marginTop: '0px', marginLeft:'-25px', marginBottom:'0px'}} />
            <div className={classes.sideTopMenu}>
                <Button
                    color="primary"
                    variant="outlined"
                    size="small"
                    onClick={()=>{setShareOpen(true); setShareButtonText(prevState => {
                        return {
                        ...prevState,
                        main: 'Copy Link'
                    }
                    })}}
                ><InsertLinkIcon style={{marginRight:'10px'}} /> Share</Button>
                {/*<Button*/}
                {/*    style={{marginLeft:'10px'}}*/}
                {/*    color="primary"*/}
                {/*    variant="outlined"*/}
                {/*    size="small"*/}
                {/*    onClick={()=>{setTemplateLinkOpen(true); setTemplateLinkText(prevState => {*/}
                {/*        return {*/}
                {/*            ...prevState,*/}
                {/*            main: 'Copy Link'*/}
                {/*        }*/}
                {/*    })}}*/}
                {/*><InsertLinkIcon style={{marginRight:'10px'}} /> Feedback template</Button>*/}
            </div>
            <hr className={classes.sidedetailTopBorderLargeScreen} style={{border:'1px solid #000', borderBottom:'none', marginTop: '0px', marginLeft:'-25px', marginBottom:'10px'}} />
            {payloadState.include_architecture ? (<SideDetailsAnalysisStage payload={payloadState}/>) : null}
            <div style={{maxWidth:'450px'}}>
                <div className={classes.sidedetailData}>

                    <Paper style={{maxWidth:'560px', maxHeight:maxHeightNextActions, padding:'20px', position:'relative', overflow:'hidden'}}>
                        <div>
                            <SidedetailsNextAction />
                            {/*<SidedetailsGuidance />*/}
                            {/*<SidedetailsTakeToCycles/>*/}
                            {/*<SidedetailsShowReport/>*/}
                        </div>
                        {showNextAction ?
                            <Button className={classes.nextActionToggle} style={{minWidth:'30px', cursor:'pointer'}} size="small" onClick={toggleNextActions}>X</Button>
                            :
                            <Button className={classes.nextActionToggle} style={{minWidth:'30px', cursor:'pointer'}} size="small" onClick={toggleNextActions}><ChevronDown/></Button>
                        }

                    </Paper>
                    <div style={{padding:'10px 0 20px 0'}}>
                    </div>

                    <SidedetailsLinkAssumption/>
                    <DefaultSideDetails/>

                    {/*<SideDetailsFeature/>*/}
                    {/*<SidedetailsCustom />*/}
                    {/*{(payloadState && payloadState.status && payloadState.status > 0) ? () : null}*/}
                    {/*<BDDscenarioList payload={payloadState} linkIconClick={scenarioIconClick} />*/}
                </div>
                {/*<SidedetailsAddEdit/>*/}
            </div>
            <Modal //share modal
                className={classes.modal}
                open={shareOpen}
                onClose={()=>setShareOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={shareOpen}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Share</h2>
                        <List>
                            <p>Share the public link for simple read-only view</p>
                            <ListItem className={classes.feedbackLinks}>
                                <ListItemText primary={`sprinthack.com/report/${payloadState.requestId}`} ref={shareRef} />
                            </ListItem>
                        </List>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={(e)=>{
                                navigator.clipboard.writeText(`sprinthack.com/report/${payloadState.requestId}`);
                                setShareButtonText(prevState => {
                                    return {
                                        ...prevState,
                                        main: 'Copied!'
                                    }
                                })}}
                        >{shareButtonText.main}</Button>

                    </div>
                </Fade>
            </Modal>

            <Modal //template link modal
                className={classes.modal}
                open={templateLinkOpen}
                onClose={()=>setTemplateLinkOpen(false)}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={templateLinkOpen}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Feedback</h2>
                        <List>
                            <p>Get feedback to your user stories and feature</p>
                            <ListItem className={classes.feedbackLinks}>
                                <ListItemText primary={`sprinthack.com/f/${payloadState.requestId}`} ref={shareRef} />
                            </ListItem>
                        </List>
                        <Button
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={(e)=>{
                                navigator.clipboard.writeText(`sprinthack.com/f/${payloadState.requestId}`);
                                setTemplateLinkText(prevState => {
                                    return {
                                        ...prevState,
                                        main: 'Copied!'
                                    }
                                })}}
                        >{templateLinkText.main}</Button>

                    </div>
                </Fade>
            </Modal>

        </div>
    )
}
