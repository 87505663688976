import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import dayjs from 'dayjs';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import Chip from "@material-ui/core/Chip";

const useStyles = makeStyles((theme) => ({
    table: {
        minWidth: 375,
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {

    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent'
    },
    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

}));

function createData(title, context, freetext, createdAt) {
    return { title, context, freetext, createdAt };
}

const rows = [
    // createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    // createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    // createData('Eclair', 262, 16.0, 24, 6.0),
    // createData('Cupcake', 305, 3.7, 67, 4.3),
    // createData('Gingerbread', 356, 16.0, 49, 3.9),
];

export default function BacklogComplete({history, details, rows}) {
    const classes = useStyles();
    const theme = useTheme();

    // const [rows, setRows] = React.useState(cycleRequestsTempCache);
    const preventDefault = (event) => event.preventDefault();

    React.useEffect(() => {

    }, [])

    const getStakeholderTotalAmount = (current_row) => {
        if (!current_row.user_needs) return 0
        return current_row.user_needs.length
    }
    const getNeedTotalAmount = (current_row) => {
        let count = 0
        if (current_row.user_needs) {
            current_row.user_needs.forEach(item => {
                if (item.needs) count += item.needs.length
            })
        }
        return count
    }

    return (
        <TableContainer>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableBody>
                    {(rows.length < 1) ?
                        <TableRow style={{height:'44px'}}>
                            <TableCell align="left" style={{color:theme.custom.subtitle, paddingLeft:'25px'}}>All done</TableCell>
                        </TableRow>
                        :
                        rows.map((row, index) => (
                        <TableRow style={{height:'44px'}} key={index} hover onClick={() => {details(row)}}>

                            <TableCell align="left" style={{ width: "1px", paddingRight:'0px'}}><CheckCircleIcon className={classes.tableIcon} /></TableCell>
                            <TableCell align="left" scope="row" className={classes.rowText}>
                                <div className={classes.rowTitle}>{row.freetext}</div>
                                {/*<div className={classes.rowSubtitle}>{getStakeholderTotalAmount(row)} stakeholders - {getNeedTotalAmount(row)} needs</div>*/}
                            </TableCell>
                            <TableCell className={classes.stateChip} align="right"><Chip variant="outlined" color="primary" size="small" label={row.state} /></TableCell>
                            <TableCell align="right" className={classes.dateColumn}>{dayjs(row.createdAt).format('MMM DD')}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
