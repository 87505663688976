import React, {useEffect, useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import dayjs from "dayjs";
import {db} from "../../util/firebase";
import {Accordion, AccordionDetails, AccordionSummary} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const useStyles = makeStyles((theme) => ({
    root: {
        borderLeft:'1px solid #dddddd',
        borderBottom:'1px solid #dddddd',
        width:'1000px'
    },
    rootRow: {
        display:'flex',
        flexFlow:'row wrap',
    },
    dimensionGroup:{
        textTransform:'capitalize'
    },
    columnWrap:{
        flex:'0 1 100%',
        display:'flex',
    },
    dimensionColumn: {
        flex: '1',
        padding:'5px 0 5px 10px',
        borderRight:'1px solid #dddddd',
        borderTop:'1px solid #dddddd',
    },
    groupColumn: {
        flex: '1',
        padding:'5px 0 5px 10px',
        borderRight:'1px solid #dddddd',
        borderTop:'1px solid #dddddd',
    },
    inputColumn: {
        flex: '1',
        padding:'5px 0 5px 10px',
        borderRight:'1px solid #dddddd',
        borderTop:'1px solid #dddddd',
    },
    actionColumn:{
        flex:'1',
        padding:'5px 0 5px 10px',
        borderRight:'1px solid #dddddd',
        borderTop:'1px solid #dddddd',
    },
    targetColumn:{
        flex: '1',
        padding:'5px 0 5px 10px',
        borderRight:'1px solid #dddddd',
        borderTop:'1px solid #dddddd',
    },
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    boxHeader:{
        padding:'0 0 20px 0',
        fontWeight:700,
    },
    boxFooter:{
        padding:'20px 0 0 0',
    },
    boxTextField:{
        maxWidth:'350px'
    },
    requestBoxWrap:{
        padding:"40px"
    },
    requestBox: {
        boxShadow:"rgb(0 0 0 / 50%) 0px 16px 70px",
        borderRadius:'10px',
        paddingTop:'20px',
        paddingLeft:'18px',
        paddingRight:'36px',
        paddingBottom:'20px',
        margin:'0px auto',
        maxWidth:'900px'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },

}));

export default function EditImpactDimensions({userData, backToImpact}) {
    const classes = useStyles();

    const [dimension, setDimension] = useState('')
    const [group, setGroup] = useState('')
    const [trainingData, setTrainingData] = useState([])
    const [errorMsg, setErrorMsg] = useState('')

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(()=>{
        const unsubscribe1 = db.collection('impact_dimensions')
            .where('workspace_id','==',userData.activeWorkspace.id)
            .onSnapshot(snapshot => {
                let observedData = snapshot.docs.map(doc => {return {id:doc.id, ...doc.data()}});

                db.collection('impact_training_data')
                    .where('workspace_id','==',userData.activeWorkspace.id)
                    .onSnapshot(snapshot => {

                        let observedTrainingData = snapshot.docs.map(doc => {return {id:doc.id, ...doc.data()}});


                        let finalParsedData = {}
                        observedData.forEach((dimension)=>{
                            finalParsedData[dimension.id] = {...dimension, training:[]}
                        })
                        observedTrainingData.forEach((training)=>{
                            if (!finalParsedData[training.dimension]) finalParsedData[training.dimension] = {}
                            if (!finalParsedData[training.dimension].training) finalParsedData[training.dimension].training = []
                            finalParsedData[training.dimension].training.push(training)
                        })

                        setTrainingData(finalParsedData)
                    })
            })

        return function cleanup() { unsubscribe1() }

    }, [userData.activeWorkspace.id])

    const handleImpactChange = (event) => {
        setDimension(event.target.value)
    }
    const handleGroupChange = (event) => {
        setGroup(event.target.value)
    }

    const handleSaveNewImpact = async (event) => {
        event.preventDefault();
        // if (dimension === '' || !dimension) {setErrorMsg('Dimension required'); return}
        // if (group === '' || !group) {setErrorMsg('Dimension group required'); return}

        const updateObject = {
            dimension: dimension,
            group: group,
            workspace_id:userData.activeWorkspace.id,
            createdAt: dayjs().toISOString()
        }

        // db
        //     .collection('impact_dimensions')
        //     .add(updateObject)
        //     .then((docRef) => {
        //         console.log("Document written with ID: ", docRef.id);
        //         backToImpact()
        //     })
        //     .catch((error) => {
        //         console.error("Error adding document: ", error);
        //         setErrorMsg(error)
        //     });
    }

    const RenderTrainingItem = ({item}) => {
        return <div className={classes.columnWrap}>
            <div className={classes.inputColumn}>{item.input}</div>
            <div className={classes.actionColumn}>{item.action}</div>
            <div className={classes.targetColumn}>{item.target}</div>
        </div>
    }

    const RenderDimensionItem = ({item}) => {
        return <div className={classes.columnWrap}>
            <div className={classes.dimensionColumn}>{item.dimension}</div>
            <div className={classes.groupColumn}>{item.group}</div>
        </div>
    }

    const RenderTrainingList = () => {
        return Object.keys(trainingData).map((item, index) => {
            return <div key={index}>
                {/*<RenderDimensionItem item={trainingData[item]}/>*/}
                <Accordion expanded={expanded === index} onChange={handleChange(index)}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <div>
                            <span className={classes.dimensionGroup}>{trainingData[item].group}</span> - <span>{trainingData[item].dimension}</span>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div className={classes.root}>
                            <div className={classes.rootRow}>
                                {trainingData[item].training.map(data => {
                                    return <RenderTrainingItem item={data}/>
                                })}
                            </div>
                        </div>
                    </AccordionDetails>
                </Accordion>
            </div>
        })
    }

    return (
        <div className={classes.requestBoxWrap}>
            <div className={classes.requestBox}>
                <div className={classes.boxHeader}>Edit impact dimensions </div>
                <div>
                    <RenderTrainingList />
                </div>
            </div>
        </div>
    );
}

