import React, {useState} from 'react'
import {makeStyles} from "@material-ui/core";
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import FilterCenterFocusIcon from '@material-ui/icons/FilterCenterFocus';
import clsx from 'clsx';

import ListHeader from '../../components/listHeader';

import BacklogRequestList from "../../components/backlogLists/backlogRequestList";
import BacklogInProgressList from "../../components/backlogLists/backlogInProgressList";
import BacklogCompleteList from "../../components/backlogLists/backlogCompleteList";
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import Backlog_promo from "../../img/SPH_backlog_how_it_works.png"
import Button from "@material-ui/core/Button";

// const useStyles = makeStyles((theme) => ({
const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1,
        padding: theme.spacing(3)
    },
    toolbar: {
        height: '64px'
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1
    },
    submitButton: {
        display: 'block',
        color: 'white',
        textAlign: 'center',
        position: 'absolute',
        top: 14,
        right: 10
    },
    floatingButton: {
        position: 'fixed',
        bottom: 0,
        right: 0
    },
    form: {
        width: '98%',
        marginLeft: 13,
        marginTop: theme.spacing(3)
    },
    root: {
        minWidth: 470
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)'
    },
    pos: {
        marginBottom: 12
    },
    uiProgess: {
        position: 'fixed',
        zIndex: '1000',
        height: '31px',
        width: '31px',
        left: '50%',
        top: '35%'
    },
    dialogeStyle: {
        maxWidth: '50%'
    },
    viewRoot: {
        margin: 0,
        padding: theme.spacing(2)
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500]
    },
    headerRow: {
        paddingLeft: '18px',
        paddingRight: '16px'
    },
    dateColumn: {
        textTransform: 'uppercase',
        color:'rgba(0,0,0,0.4)'
    },
    addNewButton: {
        float: 'right',
        background: 'linear-gradient(180deg,#34444b,#262f36)'
    },
    listLink:{
        cursor:'pointer'
    },
    cycleListWrapper: {
        height: 'calc(100vh - 65px)',
        overflowY:'scroll'
    },
    animatedItem: {
        animation: `$scaleIn 6s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }


}));

export default function BacklogList ({history, loadAddNewRequest, requestDetails, rows, rowsLoading}) {
    const classes = useStyles();
    const [state, setState] = useState({
        cycle_requests: '',
        cycles: '',
        title: '',
        body: '',
        cycle_requestsId: '',
        errors: [],
        open: false,
        loading: rowsLoading,
        buttonType: '',
        viewOpen: false,
        rows:rows
    })

    const handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    dayjs.extend(relativeTime);
    const { open, errors, viewOpen } = state;
    const pagecontext = <FilterCenterFocusIcon/>;
    const promptheader = <div style={{textAlign: 'left'}}>Experiments: Why do them?</div>;
    const promptbody = () => {
        return (
            <div style={{textAlign: 'left'}}>
                <p>
                    In 99% of the plans you should approach tasks as assumptions. So there are no right or wrong way to solution, and it more about finding the right questions.
                </p>
                <p>
                    With experiment logs, you can define your smallest validation, and document the learning.
                </p>
                {/*<p>*/}
                {/*    <strong>1. Command</strong><br/>*/}
                {/*    Write the user stories, or other needs that should go to the actionable tasks. We encourage you to classify them in*/}
                {/*    either opportunity tests, tech test or usability/UX test*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    <strong>2. Run</strong><br/>*/}
                {/*    Send the requirement to Sprinthack Architects to build up and write the solution architecture from. The process includes volatility*/}
                {/*    encapsulation, system structure and system use case definition. We even throw in time estimations and sprint breakdown suggestion.*/}
                {/*</p>*/}
                {/*<p>*/}
                {/*    <strong>3. Analyse</strong><br/>*/}
                {/*    Add impact data in the architecture timeline. There you can start following up how your development impacts the metrics. Automatica documentation*/}
                {/*    of the plans provides a time machine back in to the decisions and unlocks insights on the impact.*/}
                {/*</p>*/}
                <p>
                    What you get in return is a package of insights, with the support of your architects.
                </p>
                <Button style={{marginTop:'20px'}} variant="contained" size="small" color="primary" onClick={loadAddNewRequest} >
                    New experiment
                </Button>
            </div>
        )};

    const RenderContent = () => {
        // console.log('rows.loading',state.loading)
        // console.log('rows.loading',state.rows)
        let emptyStates = true
        if (state.rows) {
            emptyStates = Object.keys(state.rows).every( key => {
                // console.log('key',key)
                // console.log('key', state.rows[key].length)
                // console.log('Object(state.rows)',state.rows[key].length < 1)
                return state.rows[key].length < 1
            })
            // console.log('emptystates',emptyStates)
        }

        return (emptyStates
        ) ? (
            <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
        ) : (
            <div className={clsx(classes.cycleListWrapper, classes.animatedItemFade)}>

                {/** note: using different Requests list for controlling the little pre-icon.
                 Should eventually have one where the icon is changed based on status **/}

                <ListHeader title={'Todo'} />
                <BacklogRequestList history={history} details={requestDetails} rows={state.rows.requests} />

                <ListHeader title={'Development'} />
                <BacklogInProgressList history={history} details={requestDetails} rows={state.rows.development} />

                <ListHeader title={'Test'} />
                <BacklogInProgressList history={history} details={requestDetails} rows={state.rows.test} />

                <ListHeader title={'Learn'} />
                <BacklogInProgressList history={history} details={requestDetails} rows={state.rows.learn} />

                <ListHeader title={'Document'} />
                <BacklogInProgressList history={history} details={requestDetails} rows={state.rows.document} />

                <ListHeader title={'Done'} />
                <BacklogCompleteList history={history} details={requestDetails} rows={state.rows.complete} />

            </div>

        )
    }

    return (state.loading) ? (<div/>) : (<RenderContent/>)
}