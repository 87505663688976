import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import {db, auth} from "../util/firebase";
import dayjs from "dayjs";
import axios from "axios";
import BASEURL from "../constants/baseurl";
import SlowLoad from "./slowLoad";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import CategoryIcon from "@material-ui/icons/Category";
import {Assistant, AssistantOutlined, Autorenew, FlareOutlined} from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    requestBoxWrap:{
        padding:"40px"
    },
    requestBox: {
        boxShadow:"rgb(0 0 0 / 50%) 0px 16px 70px",
        borderRadius:'10px',
        paddingTop:'20px',
        paddingLeft:'18px',
        paddingRight:'36px',
        paddingBottom:'20px',
        margin:'0px auto',
        maxWidth:'900px'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    addNewTitleHeader: {
        fontSize:'22px',
        fontWeight:500,
        fontFamily:'Inter,Roboto,Helvetica,Arial,sans-serif',
        border:'1px solid transparent',
        backgroundColor:"transparent",
        color: theme.custom.genericLongformText,
        resize:'none',
        overflow:'auto',
        outline:'none',
        "WebkitBoxShadow":"none",
        "MozBoxShadow":"none",
        "boxShadow":"none",
        minWidth:'350px',
        width:'100%',
        padding:'10px',
        marginTop:'0px',
        marginLeft:'-10px',
        borderRadius:'5px',
    },
    copilotButtonComment: {
        color:theme.custom.MainButton,
        borderColor:theme.custom.MainButton,
        marginLeft:'10px',
    },
    activityAuthor: {
        fontWeight:'500',
        marginBottom:'0px'
    },
    activityBody: {
        whiteSpace:'pre-line',
        marginTop:'0px'
    }

}));

export default function AddNewTeam({history, userData, backToTeams}) {
    const classes = useStyles();

    const [team, setTeam] = useState('New Team')
    const [headerTitle, setHeaderTitle] = useState('New Team')
    const [inputPlaceholder, setInputPlaceholder] = useState("Name of the team")
    const [activity, setActivity] = useState('')

    const handleTeamChange = (event) => {
        setTeam(event.target.value)
    }

    const handleSaveNewTeam = async (event) => {
        event.preventDefault();
        const newTitle = (team !== '') ? team : headerTitle

        const updateObject = {
            title: newTitle,
            createdAt: dayjs().toISOString(),
            activeAt: dayjs().toISOString(),
            owner: auth().currentUser.uid,
            members: {},
            status:'spec'
        }
        /** add the user to the member list */
        updateObject.members[auth().currentUser.uid] = true

        db
            .collection('teams')
            .add(updateObject)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
                backToTeams()
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
                setActivity('error in saving... we will look into it.')
            });
    }

    return (
        <div>
            <div className={classes.requestBoxWrap}>
                <div className={classes.requestBox}>
                    <div style={{paddingLeft:'10px'}}>
                        <div className={classes.addNewTitleHeader}>
                            {team || "_"}
                        </div>
                    </div>
                    <div style={{marginBottom:'5px'}}>
                        <input
                            aria-label="minimum height"
                            className={classes.textareaStyle}
                            defaultValue=""
                            placeholder={inputPlaceholder}
                            onChange={handleTeamChange}
                        />
                    </div>
                    <div className={classes.subtitle} style={{marginBottom:'20px', paddingLeft:'5px'}}>{activity}</div>
                    <div>
                        <Button
                            color="primary"
                            onClick={backToTeams}
                        >Cancel</Button>
                        <Button
                            color="primary"
                            variant="contained"
                            type="submit"
                            className={classes.submitButton}
                            onClick={(e) => {handleSaveNewTeam(e)}}
                        >Save</Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

