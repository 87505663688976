import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import '../../../css/header.css'
import '../../../css/animationMouseHover.css'
import Logo_for_dark from '../../../img/sprinthack_logo_white_light.png'
import Logo_for_light from '../../../img/sprinthack_logo_dark_on_white.png'
import useMediaQuery from "@material-ui/core/useMediaQuery";


const propTypes = {
    navPosition: PropTypes.string,
    hideNav: PropTypes.bool,
    hideSignin: PropTypes.bool,
    bottomOuterDivider: PropTypes.bool,
    bottomDivider: PropTypes.bool
}

const defaultProps = {
    navPosition: '',
    hideNav: false,
    hideSignin: false,
    bottomOuterDivider: false,
    bottomDivider: false
}



const Header = ({
                    className,
                    navPosition,
                    hideNav,
                    hideSignin,
                    bottomOuterDivider,
                    bottomDivider,
                    ...props
                }) => {

    const nav = useRef(null);
    const hamburger = useRef(null);

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;

    const logo = (prefersDarkMode) ? Logo_for_dark : Logo_for_light;
    const text_color_light = (prefersDarkMode) ? '' : 'text_color_light';

    // const aclick = () => {
    //   console.log('click')
    // }

    return (
        <header
            {...props}
        >
            <div className={'contentContainer'}>
                <a href={"/"}><img className={'logo'} alt={'logo'} src={logo} /></a>

                <div className={'desktop'}>

                    <div className={'navigation'}>
                        <a className={text_color_light+' login_header_spacing mouseHover'} href={'https://sprinthack.substack.com'}>
                            Research Reports
                        </a>
                        <a className={text_color_light+' login_header_spacing mouseHover'} href={'/pricing'}>
                            Pricing
                        </a>
                    </div>
                </div>
                <div className={'mobile'}>
                    <a className={text_color_light+' login_header_spacing mouseHover'} href={'/blog'}>
                        Research
                    </a>
                    {/*<a className={text_color_light+' login_header_spacing mouseHover'} href={'/pricing'}>*/}
                    {/*    Pricing*/}
                    {/*</a>*/}
                    {/*<span >*/}
                    {/*    |*/}
                    {/*</span>*/}
                    {/*<a className={text_color_light+' login_header_spacing mouseHover'} href={'/login'}>*/}
                    {/*    Login*/}
                    {/*</a>*/}
                    <a className={'button'} href={'/login'} style={{fontSize:'13px', marginLeft:'10px'}}>
                        Get started
                    </a>
                </div>
                <div className={'loginRow'}>
                    <span className={'vertical_divider'}>
                        |
                    </span>
                    <a className={text_color_light+' login_header_spacing mouseHover'} href={'/login'}>
                        Login
                    </a>
                    <a className={'button'} href={'/login'} style={{fontSize:'13px'}}>
                        Get started
                    </a>
                </div>

            </div>




            {/*<div className="container">*/}
            {/*    <div className={*/}
            {/*        classNames(*/}
            {/*            'site-header-inner',*/}
            {/*            bottomDivider && 'has-bottom-divider'*/}
            {/*        )}>*/}
            {/*        <Logo />*/}
            {/*        {!hideNav &&*/}
            {/*        <>*/}
            {/*            <button*/}
            {/*                ref={hamburger}*/}
            {/*                className="header-nav-toggle"*/}
            {/*                onClick={isActive ? closeMenu : openMenu}*/}
            {/*            >*/}
            {/*                <span className="screen-reader">Menu</span>*/}
            {/*                <span className="hamburger">*/}
            {/*      <span className="hamburger-inner"></span>*/}
            {/*    </span>*/}
            {/*            </button>*/}
            {/*            <nav*/}
            {/*                ref={nav}*/}
            {/*                className={*/}
            {/*                    classNames(*/}
            {/*                        'header-nav',*/}
            {/*                        isActive && 'is-active'*/}
            {/*                    )}>*/}
            {/*                <div className="header-nav-inner">*/}
            {/*                    <ul className={*/}
            {/*                        classNames(*/}
            {/*                            'list-reset text-xs',*/}
            {/*                            navPosition && `header-nav-${navPosition}`*/}
            {/*                        )}>*/}
            {/*                        <li>*/}
            {/*                            <Link to="#0" onClick={closeMenu}>Documentation</Link>*/}
            {/*                        </li>*/}
            {/*                    </ul>*/}
            {/*                    {!hideSignin &&*/}
            {/*                    <ul*/}
            {/*                        className="list-reset header-nav-right"*/}
            {/*                    >*/}
            {/*                        <li>*/}
            {/*                            <Link to="#0" className="button button-primary button-wide-mobile button-sm" onClick={closeMenu}>Sign up</Link>*/}
            {/*                        </li>*/}
            {/*                    </ul>}*/}
            {/*                </div>*/}
            {/*            </nav>*/}
            {/*        </>}*/}
            {/*    </div>*/}
            {/*</div>*/}
        </header>
    );
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
