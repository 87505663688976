import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useRouteMatch} from "react-router-dom";
import {useParams} from "react-router";
import {db} from "../../util/firebase";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Divider from '@material-ui/core/Divider';
import Timeline from '@material-ui/lab/Timeline';
import ActivityTimeline from "../generalComponents/activityTimeline"
import FreetextEditor from "../generalComponents/FreetextEditor";
import DetailHeaderEditor from "../generalComponents/DetailHeaderEditor";
import FeatureSideDetails from "./Tasks_sideDetails";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        height: 'calc(100vh - 65px)',
        flexFlow:'row wrap'
    },
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    maindetails: {
        flex: '1 100%',
        // maxWidth: '700px',
        minWidth:'400px',
        maxHeight: '100%',
        overflowY: 'scroll',
        "position":"relative",
        "flexShrink":"0",
        "WebkitBoxFlex":"100",
        "flexGrow":"100",
        "paddingBottom":"82px"
    },
    maindetailsWrapper: {
        "flexGrow":"100",
        "WebkitBoxFlex":"100",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "overflowY":"overlay"
    },
    maindetailsContent:{
        "display":"flex",
        "flexBasis":"initial",
        "flexDirection":"column",
        "position":"relative",
        "width":"calc(100% - 60px)",
        "marginLeft":"auto",
        "marginRight":"auto",
        "WebkitBoxFlex":"1",
        "flexGrow":"1",
        "flexShrink":"0",
        "paddingTop":"20px"
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%",
        "display":"flex",
        "zIndex":"1100",
        "boxSizing":"border-box",
        "flexShrink":"0",
        "flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    textareaStyle: {
        padding: '10px',
        minWidth: '260px',
        width: 'calc(100% - 35px)'
    },
    submitButton: {
        background:theme.custom.MainButton
    },
    [theme.breakpoints.up("xs")]: {
    },
    [theme.breakpoints.up("sm")]: {
        maindetails:{
            flex:'100 0px',
            order:'2'
        },
    },
    [theme.breakpoints.up("md")]: {
    },
    metadataPopup:{
        border:'1px solid transparent',
        borderColor:theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        boxShadow:'rgb(0 0 0 / 10%) 0px 4px 18px',
        height: 'calc(100vh - 105px)'
    },
    metadataTopBar:{
        height:'40px',
        padding:'10px',
        border:'1px solid transparent',
        borderBottomColor:theme.custom.OutlineButtonBorder
    },
    metadataClose:{
        // padding:'10px',
        color: theme.custom.subtitle,
        borderRadius:'2px',
        "&:hover":{
            background:theme.custom.ListBGhover
        }
    },
    metadataImage: {
        maxHeight:'365px',
        maxWidth:'100%',
        cursor:'zoom-in'
    },
    metadataImageFullContainer: {
        display:'flex',
        width:'100vw',
        height:'100vh',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        background:'rgba(0,0,0,0.628)'
    },
    metadataImageFull: {
        width: undefined,
        height: undefined,
        maxWidth:'100%',
        maxHeight:'100%'
    }


}));

export default function TasksDetails({userData, detailsPayload, loadExperimentDetails}) {
    const classes = useStyles();
    let { ideaId } = useParams();
    let { url } = useRouteMatch();

    dayjs.extend(relativeTime);

    const [ideaData, setIdeaData] = useState(detailsPayload)

    React.useEffect(() => {

        const unsubscribe = db
            .collection('idea_validation')
            .doc(ideaId)
            .onSnapshot(async (doc) => {
                if (doc.exists){
                    let observedData = {
                        id: doc.id,
                        ...doc.data()
                    }

                    db.collection('cycle_requests')
                        .where('workspace_id', '==', userData.activeWorkspace.id)
                        .orderBy('createdAt', 'asc')
                        .get()
                        .then(experiment_docs => {
                            let experimentList = []

                            experiment_docs.forEach(experiment=>{
                                if (experiment.data().assumption === doc.id) {
                                    experimentList.push({id:experiment.id, ...experiment.data()})
                                }
                            })

                            observedData = {
                                ...observedData,
                                experimentList:experimentList
                            }
                            setIdeaData(observedData)
                        })

                    /** get linked projects data */
                    db.collection('projects')
                        .where('workspace_id', '==', userData.activeWorkspace.id)
                        .orderBy('createdAt', 'asc')
                        .get()
                        .then(docs => {
                            let projectList = []
                            let linkedProjectTitle = null
                            docs.forEach(project=>{
                                projectList.push({id:project.id,...project.data()})

                                if (observedData.project === project.id && project.data().title) {
                                    linkedProjectTitle = project.data().title
                                }
                            })

                            observedData = {
                                ...observedData,
                                projectTitle:linkedProjectTitle,
                                projectList:projectList
                            }
                            setIdeaData(observedData)
                        })
                        .catch(err => {
                            setIdeaData(observedData)
                        })

                    if (observedData.feature){
                        console.log('observedData.feature',observedData.feature)
                        db.collection('features_confirmed')
                            .doc(observedData.feature)
                            .get()
                            .then(feature => {
                                observedData = {
                                    ...observedData,
                                    featureTitle:feature.data().title
                                }
                                setIdeaData(observedData)
                            })
                    }

                } else {
                    setIdeaData({
                        id: ideaId
                    })
                }
            });

        return function cleanup() { unsubscribe() }

    }, [detailsPayload]);

    const handleTitleSave = (savePayload) => {
        /** this will save the title to db */

        db
            .collection('idea_validation')
            .doc(ideaId)
            .update({
                idea: savePayload.value
            })
            .then()
    }

    const handleDescriptionSave = (savePayload) => {
        /** this will save the description to db */

        db
            .collection('idea_validation')
            .doc(ideaId)
            .update({
                description: savePayload.value
            })
            .then()

    }

    return (
        <div style={{height: 'calc(100vh - 65px)'}}>
            <div className={classes.root}>
                <div className={classes.maindetails}>
                    <div className={classes.maindetailsWrapper}>
                        <div className={classes.maindetailsContent}>
                            {/*<div className={classes.subtitle} style={{display:'flex'}}>*/}
                            {/*    <span>*/}
                            {/*        Created {dayjs(ideaData.createdAt).format('MMMM DD, YYYY')}*/}
                            {/*    </span>*/}
                            {/*    <span style={{marginLeft:'auto'}}>*/}
                            {/*        <Button variant="contained" color="primary" size="small" className={classes.addNewButton} onClick={()=>{}}>*/}
                            {/*            Ready for prototyping*/}
                            {/*        </Button>*/}
                            {/*    </span>*/}
                            {/*</div>*/}

                            <DetailHeaderEditor
                                payload={{key:"idea", value:ideaData.idea, placeholder:'Title'}}
                                save={handleTitleSave}
                            />
                            <FreetextEditor
                                payload={{key:"description", value:ideaData.description, placeholder:'Add description...'}}
                                save={handleDescriptionSave}
                            />
                            {/*<div>*/}
                            {/*    <TextareaAutosize*/}
                            {/*        aria-label="minimum height"*/}
                            {/*        minRows={3}*/}
                            {/*        className={classes.textareaStyle}*/}
                            {/*        value={descriptionInput}*/}
                            {/*        placeholder="Description"*/}
                            {/*        onChange={e=>handleIdeaDescriptionChange(e)}*/}
                            {/*        onFocus={handleCommentFocus}*/}
                            {/*        onBlur={handleCommentFocus}*/}
                            {/*    />*/}
                            {/*</div>*/}
                            {/*<div>*/}
                            {/*    <Collapse in={descriptionFocus}>*/}
                            {/*        <Button*/}
                            {/*            color="primary"*/}
                            {/*            variant="contained"*/}
                            {/*            type="submit"*/}
                            {/*            size="small"*/}
                            {/*            className={clsx(classes.submitButton)}*/}
                            {/*            onClick={handleCommentSend}*/}
                            {/*        >Save</Button>*/}
                            {/*    </Collapse>*/}
                            {/*</div>*/}
                            <div style={{margin:'10px 0'}} />

                            <Divider />
                            <h4 className={'activityTitle'}>Activity</h4>
                            {/*<div className={classes.subtitle}>*/}
                            {/*    <div>Gather insights. Define Problems. Set assumptions. List all the data, feedbacks and design thoughts, so that development is able to do a prototype.</div>*/}
                            {/*    <div>That means usually some UI designs, explanation of the UX, what happens where and when.</div>*/}
                            {/*</div>*/}
                            <Timeline>
                                <ActivityTimeline payload={ideaData} placeholder={"Add comment..."} />
                            </Timeline>
                        </div>
                    </div>
                </div>
                <FeatureSideDetails userData={userData} payload={ideaData} loadExperimentDetails={loadExperimentDetails} />
            </div>
        </div>
    );
};