import React from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import EmptyPageInfoPrompt from "../components/emptyPageInfoPrompt";
import Link from "@material-ui/core/Link";
import {auth} from "../util/firebase";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    animatedItem: {
        animation: `$scaleIn 6s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    }
}));

export default function Error() {
    const classes = useStyles();
    const history = useHistory()
        const logoutUser = () => {
        auth().signOut().then(() => {
            history.push('/login')
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    };

    const pageContext = 'hmmm...';
    const promptHeader = 'Sorry, we seem to be offline!';
    const promptBody = () => {
        return (
            <div>
                <p>

                </p>
                <p>
                    <Link style={{cursor:'pointer'}} onClick={logoutUser}>
                        Logout
                    </Link>
                </p>
            </div>
        )};

    React.useEffect(() => {

    }, [])

    return (
        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
            <div className={classes.toolbar} />
            <EmptyPageInfoPrompt pagecontext={pageContext} header={promptHeader} body={promptBody()} />
        </div>
    );
}

