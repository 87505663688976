import React from 'react';
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import dayjs from "dayjs";
import EmptyPageInfoPrompt from "../../components/emptyPageInfoPrompt";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import ListHeader from "../../components/listHeader";
import {BrowserRouter as Router, Route, useRouteMatch} from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";


const useStyles = makeStyles((theme) => ({
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRowWithHighlight:{
        "&:hover": {
            backgroundColor: theme.custom.TableRowHover
        }
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 0px 0px 0px'
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    linkIcon: {
        marginLeft:'10px',
        opacity:'1',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },

    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

}));

let topTitle = 'Validation Feedback'

export default function ArchFeedbackList({userData, history, isMobile, templateLink, linkDisplay, feedbacks}) {
    let { url } = useRouteMatch();
    let route = useRouteMatch();
    const classes = useStyles();

    const pagecontext = 'Product Architect Feedback';
    const promptheader = 'The product architect will set up your template soon';
    const promptbody = () => {
        return (
            <div style={{textAlign: 'left'}}>
                <p>
                    You will get a template set up for you by Sprinthack Product Architect. Using this template write down assumptions and hypothesis what you do in the product validation process.
                </p>
                {/*<p>*/}
                {/*    <Button style={{marginTop:'20px'}} variant="contained" size="small" color="primary" onClick={loadAddNewIdea} >*/}
                {/*        + New Idea*/}
                {/*    </Button>*/}
                {/*</p>*/}
            </div>
        )};

    const RenderTable = () => {
        return (
        <div>
            <div>
                <ListHeader title={'Template document'} />
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody>
                            {templateLink ?
                                <TableRow className={classes.tableRowWithHighlight}  style={{height:'44px'}} onClick={loadLink}>
                                    <TableCell align="left" scope="row" className={classes.rowText}>
                                        <div className={classes.rowTitle}>{linkDisplay} <ChevronRight className={classes.linkIcon} /></div>
                                    </TableCell>
                                </TableRow>
                                :
                                <TableRow className={classes.tableRow}  style={{height:'44px'}}>
                                    <TableCell align="left" scope="row" className={classes.rowText}>
                                        <div className={classes.rowTitle}>(Setting the template)</div>
                                    </TableCell>
                                </TableRow>
                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <div>
                {/** feedbacks **/}
                <ListHeader title={'Architect Feedbacks'} />
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody>
                            {feedbacks && feedbacks.map((item, index) => {
                                return (
                                    <TableRow key={index} className={classes.tableRow} style={{height:'44px'}} onClick={()=>{loadFeedbackDetails()}}>
                                        <TableCell align="left" style={{ width: "1px", paddingRight:'10px'}}><RadioButtonUncheckedIcon className={classes.tableIcon} /></TableCell>
                                        <TableCell align="left" scope="row" className={classes.rowText}>
                                            <div className={classes.rowTitle}>{item.feedback}</div>
                                        </TableCell>
                                        <TableCell align="right" className={classes.dateColumn}>{dayjs(item.createdAt).format('MMM DD')}</TableCell>
                                    </TableRow>
                                )
                            } )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </div>
        )
    }

    const RenderTableWrap = () => {
        return (feedbacks && feedbacks.length < 1) ? (
                <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
                    <div className={classes.toolbar} />
                    <div>
                        <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
                    </div>
                </div>
            ) :
            (
                <div>
                    <RenderTable/>
                </div>
            )
    }

    const loadFeedbackPage = () => {
        history.push(`${url}`);
    }
    const loadFeedbackDetails = (payload) => {
        // history.push(`${url}/idea/${payload.id}`);
    }
    const loadLink = () => {
        window.open(`${templateLink}`);
    }

    const RenderFeedbacksLists = () => {
        return (
            <Router>
                <Route exact path={`${url}`} render={() => RenderTableWrap()} />
                {/*<Route path={`${url}${ROUTES.VALIDATION_FEEDBACK_DETAILS}`} render={() => <TasksDetails backToIdeas={loadFeedbackPage} detailsPayload={feedbacks} />} />*/}
            </Router>
        )
    };

    const handleBackButton = () => {
        loadFeedbackPage();
        return false
    };

    /** the topBar has to be own component, Toolbar within it and toolbar always customised to view */
    return <div>
        <div
            className={clsx(classes.topBar, {
                [classes.topBarShift]: isMobile,
            })}
        >
            <Toolbar>
                {!route.isExact ? <IconButton onClick={handleBackButton} className={clsx(classes.menuButton)}>
                    <ChevronLeftIcon />
                </IconButton> : <div />}
                <Typography variant="button" style={{flexGrow:'1', textTransform:'capitalize'}}>
                    {topTitle}
                </Typography>
                {/*{route.isExact ? <Button variant="contained" color="primary" size="small" className={classes.addNewButton} onClick={loadAddNewIdea}>*/}
                {/*    <AddIcon />*/}
                {/*</Button> : <div/>}*/}
            </Toolbar>
        </div>
        <RenderFeedbacksLists/>
    </div>

}
