import React, {useState} from 'react';
import { useHistory } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    textColor:{
        color:theme.custom.textColor
    }

}));

export default function RoadmapList({backToSprintCycleList}) {
    const classes = useStyles();
    let history = useHistory();

    React.useEffect(() => {

    }, [])

    return (
        <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
            <div className={classes.toolbar} />
            <p className={classes.textColor}>
                You will see ready-to-develop cycles here with architect time estimate. Share a link with your developers to fill in time estimates.
            </p>
            <div className={classes.textColor}>
                <h2>
                    Q1 2021
                </h2>
                <h2>
                    Q2 2021
                </h2>
                <h2>
                    Q3 2021
                </h2>
                <h2>
                    Q4 2021
                </h2>
            </div>
        </div>
    );
}

