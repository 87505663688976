import React, {useReducer, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useParams} from "react-router";
import {db, firebaseFunctions} from "../../util/firebase";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Divider from '@material-ui/core/Divider';
import Timeline from '@material-ui/lab/Timeline';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import clsx from "clsx";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import Chip from "@material-ui/core/Chip";
import TableContainer from "@material-ui/core/TableContainer";
import {useTheme} from "@material-ui/core";
import ListHeader from "../listHeader";

const useStyles = makeStyles((theme) => ({
    root: {
        display:'flex',
        height: 'calc(100vh - 65px)',
        flexFlow:'row wrap'
    },
    maindetails: {
        flex: '1 100%',
        // maxWidth: '700px',
        minWidth:'400px',
        maxHeight: '100%',
        overflowY: 'scroll',
        "position":"relative",
        "flexShrink":"0",
        "WebkitBoxFlex":"100",
        "flexGrow":"100",
        "paddingBottom":"82px"
    },
    maindetailsWrapper: {
        "flexGrow":"100",
        "WebkitBoxFlex":"100",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "overflowY":"overlay"
    },
    maindetailsContent:{
        "display":"flex",
        "flexBasis":"initial",
        "flexDirection":"column",
        "position":"relative",
        "width":"calc(100%)",
        // "marginLeft":"auto",
        // "marginRight":"auto",
        "WebkitBoxFlex":"1",
        "flexGrow":"1",
        "flexShrink":"0",
        "paddingTop":"20px"
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%",
        "display":"flex",
        "zIndex":"1100",
        "boxSizing":"border-box",
        "flexShrink":"0",
        "flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    textareaStyle: {
        padding: '10px',
        minWidth: '260px',
        width: 'calc(100% - 35px)'
    },
    submitButton: {
        background:theme.custom.MainButton
    },
    sidedetails: {
        marginTop:'-65px',
        flex: '1 100%',
        background: theme.custom.sidedetailBackground,
        padding:'0px 0px 82px 25px',
        position:"relative",
        flexShrink:"0",
        WebkitBoxFlex:"1",
        flexGrow:"1",
        minWidth: '320px',
        maxHeight: 'calc(100% + 65px)',
        overflowY: 'scroll',
    },
    sideTopMenu: {
        minHeight:'64px',
        width:'100px',
        display:'flex',
        position:'relative',
        alignItems:'center'
    },
    [theme.breakpoints.up("xs")]: {
        sidedetails:{
            flex: '1 auto'
        }
    },
    [theme.breakpoints.up("sm")]: {
        maindetails:{
            flex:'100 0px',
            order:'2'
        },
        sidedetails:{
            order:'3'
        }
    },
    [theme.breakpoints.up("md")]: {
        maindetails:{
            // marginLeft:'240px'
        }
    },
    table: {
        minWidth: 375,
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {

    },
    listLink:{
        cursor:'pointer'
    },
    rowText: {
        cursor:'default'
    },
    heading: {
        fontSize: '15px'
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'28px'
    },
    sidedetailsRowHeader: {
        fontWeight: 600,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'150px',
        flexShrink: '0'
    },
    sidedetailValue: {
        "verticalAlign":"top",
        "display":"flex",
        "flex":"initial",
        "flexDirection":"column",
        "minWidth":'0',
        "userSelect":'none',

        // "whiteSpace":"nowrap",
        // "overflow":"hidden !important",
        // "textOverflow":"ellipsis"
    },
    metadataImage: {
        maxHeight:'365px',
        maxWidth:'100%',
        cursor:'zoom-in'
    },
    metadataImageFullContainer: {
        display:'flex',
        width:'100vw',
        height:'100vh',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        background:'rgba(0,0,0,0.628)'
    },
    metadataImageFull: {
        width: undefined,
        height: undefined,
    }


}));

export default function IssueDetails({userData, detailsPayload, loadSubTaskDetails}) {
    const classes = useStyles();
    const theme = useTheme();
    let { issueId } = useParams();

    dayjs.extend(relativeTime);

    const [issueData, setIssueData] = useState(detailsPayload)
    const [displayRequestEdit, setDisplayEdit] = useState(false);

    const [state, setState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {activity: [], payload:detailsPayload}
    );

    React.useEffect(() => {

        const unsubscribe = db
            .collection('issues')
            .doc(issueId)
            .onSnapshot(async (doc) => {

                console.log('issue detail page',doc.data())
                let observedData = doc.data()
                observedData.issueId = doc.id

                const issueList = await db
                    .collection('issues')
                    .where('cycleRef', '==', observedData.cycleRef)
                    .where('workspace_id', '==', userData.activeWorkspace.id)
                    .get()

                issueList && issueList.forEach(doc=>{
                    const issueObject = doc.data()
                    issueObject.issueId = doc.id
                    observedData.subtasks[observedData.subtasks.indexOf(doc.id)] = issueObject
                })

                setIssueData(observedData)
            });

        return function cleanup() { unsubscribe() }

        // eslint-disable-next-line
    }, []);

    // const demoImage_actionDiagram = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Faction_diagrams%2FSPH_action_diagram_sample.png?alt=media&token=76f3b256-a44f-4d7f-8a35-b7555dca1676";
    // const demoImage_callChain = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Fcall_chains%2FSPH_call_chain_sample.png?alt=media&token=e05eb18e-238a-47bf-af64-5b38c1841b69";
    // const demoImage_sequence = "https://firebasestorage.googleapis.com/v0/b/sprinthack.appspot.com/o/issues%2Fusecases%2Fsequence%2FSPH_sequence_sample.png?alt=media&token=12b4b4d6-cc31-4811-88d7-b5785f12df0b";

    return (
        <div className={classes.root}>
            <div className={classes.maindetails}>
                <div className={classes.maindetailsWrapper}>
                    <div className={classes.maindetailsContent}>
                        <div style={{marginLeft:'30px'}}>
                            <div className={classes.subtitle}>
                                Created {dayjs(issueData.createdAt).format('MMMM DD, YYYY')}
                            </div>
                            <h2 style={{fontWeight:'700'}}>{issueData.title}</h2>
                            <div className={classes.sidedetailsRow}>
                                <div className={classes.sidedetailsRowHeader}>Description: </div>
                                <div className={classes.sidedetailValue}>{issueData.description}</div>
                            </div>
                            {/*<div className={classes.sidedetailsRow}>*/}
                                {/*<div className={classes.sidedetailsRowHeader}>Use case: </div>*/}
                                {/*<div className={classes.sidedetailValue}>*/}
                                {/*    <div style={{maxWidth:'700px'}}>*/}
                                        {/*<h2 style={{fontWeight:'700'}}>Action diagram</h2>*/}
                                        {/*<div>*/}
                                        {/*    <img*/}
                                        {/*        className={classes.metadataImage}*/}
                                        {/*        // onClick={()=>{showImageModal(demoImage_actionDiagram)}}*/}
                                        {/*        src={`${demoImage_actionDiagram}`}*/}
                                        {/*        srcSet={`${demoImage_actionDiagram} 2x`}*/}
                                        {/*        alt='action_diagram_img'*/}
                                        {/*        loading="lazy"*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<h2 style={{fontWeight:'700'}}>Call chains</h2>*/}
                                        {/*<div>*/}
                                        {/*    <img*/}
                                        {/*        className={classes.metadataImage}*/}
                                        {/*        // onClick={()=>{showImageModal(demoImage_callChain)}}*/}
                                        {/*        src={`${demoImage_callChain}`}*/}
                                        {/*        srcSet={`${demoImage_callChain} 2x`}*/}
                                        {/*        alt='action_diagram_img'*/}
                                        {/*        loading="lazy"*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                        {/*<h2 style={{fontWeight:'700'}}>Sequence</h2>*/}
                                        {/*<div>*/}
                                        {/*    <img*/}
                                        {/*        className={classes.metadataImage}*/}
                                        {/*        // onClick={()=>{showImageModal(demoImage_sequence)}}*/}
                                        {/*        src={`${demoImage_sequence}`}*/}
                                        {/*        srcSet={`${demoImage_sequence} 2x`}*/}
                                        {/*        alt='action_diagram_img'*/}
                                        {/*        loading="lazy"*/}
                                        {/*    />*/}
                                        {/*</div>*/}
                                    {/*</div>*/}
                                {/*</div>*/}
                            {/*</div>*/}
                            <div className={classes.sidedetailsRow}>
                                <div className={classes.sidedetailsRowHeader}>Definition of done: </div>
                                <div className={classes.sidedetailValue}>{issueData.dod}</div>
                            </div>
                            <div className={classes.sidedetailsRow}>
                                <div className={classes.sidedetailsRowHeader}>Changelog: </div>
                                <div className={classes.sidedetailValue}>{issueData.changelog}</div>
                            </div>
                        </div>
                        {(issueData.subtasks && issueData.subtasks.length > 0) ? <ListHeader title={'Subtasks'} /> : null}
                        <TableContainer>
                            <Table className={classes.table} size="small" aria-label="a dense table">
                                <TableBody>
                                    {(issueData.subtasks && issueData.subtasks.length > 0) ?
                                        issueData.subtasks && issueData.subtasks.map((subtask, index) => (
                                            <TableRow key={index} hover className={classes.listLink} onClick={()=>loadSubTaskDetails(subtask, issueData.issueId)}>
                                                <TableCell align="left" style={{ width: "1px", paddingRight:'0px' }} />
                                                <TableCell component="th" scope="row" className={classes.rowText}>
                                                    <div className={classes.rowTitle}>{subtask.title}</div>
                                                </TableCell>
                                                {/*<TableCell align="right"><Chip variant="outlined" color="primary" size="small" /></TableCell>*/}
                                                <TableCell align="right" style={{ width: "70px", paddingLeft:'0px' }} className={classes.dateColumn}>{dayjs(subtask.createdAt).format('MMM DD')}</TableCell>
                                            </TableRow>
                                        ))
                                        :
                                        null
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <div className={classes.sidedetails}>
                <div className={classes.sideTopMenu}>

                </div>
                <hr style={{border:'1px solid #000', borderBottom:'none', marginTop: '0px', marginLeft:'-25px', marginBottom:'10px'}} />

            </div>
        </div>
    );
};