import {applySnapshot, destroy, flow, getSnapshot, types} from 'mobx-state-tree'
import BASEURL from "../constants/baseurl";

export const ScenarioItem = types.model({
    id: types.optional(types.identifier, ""),
    completed:types.boolean,
    feature: types.string,
    requestRef: types.string,
    scenario: types.string,
    steps: types.array(types.string),
    published: types.boolean,
    workspace_id: types.string
}).actions(self => ({

    addEmptyStep() {
        self?.steps.push("")
    },
    setScenarioText(text) {
        self.scenario = text
    },
    setStepText(text, idx) {
        self.steps[idx] = text
    },
    saveScenario: flow(function* saveScenario() {
        if (!self?.id || self.id === "") return false
        let updateObject = getSnapshot(self)
        try {
            const saveResponse = yield window.fetch(BASEURL.api+`/admin/savescenario/${self.id}`, {
                method: "PUT",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(updateObject)
            })
            console.log("saved successfully")
        } catch (e) {
            console.error("Uh oh, failed to save: ", e)
        }

    })

}))


export const ScenarioList = types.model({
    data: types.optional(types.array(ScenarioItem), [])
})
    .actions(self => ({
        fetchScenarioDetails: flow(function* fetchScenarioDetails(ids){
            // console.log('fetchItemDetails(1)')
            if (ids.length < 1) return false
            const response = yield fetch(BASEURL.api+'/admin/requestscenarios/'+ids)
            // console.log('response', response)
            const scenarioList = yield response.json()

            // const scenarioData = ScenarioList.create(scenarioList)
            applySnapshot(self, scenarioList)

        }),
        addEmpty: flow(function* addEmpty(requestRef, workspace_id, scenarioString){
            if (!workspace_id || !requestRef) return false
            let newScenario = {
                completed:false,
                feature: "",
                requestRef: requestRef,
                scenario: scenarioString || "",
                steps: [],
                workspace_id: workspace_id
            }

            try {
                let res = yield window.fetch(BASEURL.api+`/admin/newscenario`, {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(newScenario)
                })

                if (res.ok) {
                    console.log('res',res)
                    res = yield res.json()
                    newScenario.id = res.docRef
                    self.data.push(newScenario)
                    console.log('data push ok')
                    return newScenario
                }

            } catch (e) {
                console.error("Uh oh, failed to save: ", e)
            }
        })

    }))
    .views(self => ({
        get totalScenarios() {
            return self.data.reduce((sum, entry) => sum + entry.length, 0)
        }
    }))

