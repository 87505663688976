import {applySnapshot, flow, types} from 'mobx-state-tree'
import BASEURL from "../constants/baseurl";
import {firebaseFunctions} from "../util/firebase";


export const MessageItem = types.model({
    text:types.optional(types.string,""),
    author:types.optional(types.string,""),
    createdAt:types.optional(types.string,""),
    imageUrl:types.optional(types.string, "")
})

export const MessageList = types.model({
    data:types.optional(types.array(MessageItem),[])
}).actions(self=>({
    fetchChatMessages: flow(function* fetchItemDetails(authToken, id){
        // console.log('fetchItemDetails(1)')
        const response = yield fetch(BASEURL.api+'/admin/chatmessages/'+id, {
            method:'GET',
            headers: {'Authorization': `Bearer ${authToken}`}
        })
        // console.log('response', response)
        const details = yield response.json()

        // console.log('details',details)

        const filteredChatMessages = {
            data:[]
        }

        if (details)
        details.forEach((msg)=>{
            let tempMsg = {
                text:msg.text || '',
                author:msg.author || '',
                createdAt:msg.createdAt || '',
                imageUrl:msg.imageUrl || ''
            }
            filteredChatMessages.data.push(MessageItem.create(tempMsg))
        })

        console.log('filteredChatMessages:',filteredChatMessages)
        // const stringDetails = JSON.stringify(details)

        // const messageItemsString = MessageItems.create(filteredChatMessage)
        applySnapshot(self, filteredChatMessages)

    }),
    sendNewMessage: flow(function* sendNewMessage({authToken, chatContextId, workspace_id, message, imageUrl, systemUpdate, authRef}){
        if (!workspace_id || !chatContextId) return false
        const date = new Date().toISOString()

        let newMessage = {
            contextId: chatContextId,
            workspace_id: workspace_id,
            text: message,
            imageUrl:imageUrl || null,
            type:'text',
            author: 'Sprinthack',
            authorRef: authRef,
            published:true,
            createdAt:date,
            systemUpdate: systemUpdate || 'New message from Sprinthack'
        }

        let viewMessage = {
            text:newMessage.text,
            author:newMessage.author,
            createdAt:date,
            imageUrl:imageUrl
        }
        // self?.data.push(viewMessage)

        try {
            let res = yield fetch(BASEURL.api+`/admin/newmessage`, {
                method: "POST",
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${authToken}`
                },
                body: JSON.stringify(newMessage)
            })

            if (res.ok) {
                console.log('res',res)
                res = yield res.json()
                newMessage.id = res.docRef
                self.data.push(viewMessage)
                return newMessage
            }
            return newMessage

        } catch (e) {
            console.error("Uh oh, failed to save: ", e)
        }
    })
}))

export const ChatItem = types.model({
    id: types.identifier,
    createdAt:types.optional(types.string,""),
    updatedAt:types.optional(types.string,""),
    systemStatus: types.string,
    updated:types.boolean,
    title:types.string,
    messageList: types.optional(MessageList, {}),
    metaTags: types.array(types.string),
    workspace_id: types.string
})

export const ChatList = types.model({
    data: types.optional(types.array(ChatItem), [])
})