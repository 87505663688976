import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Velocity_map_light from '../../../img/velocity_map.png'
import Velocity_map_dark from '../../../img/velocity_map_dark.png'
import '../../../css/general.css'
import '../../../css/hero.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";
import HeroIMG from "../../../img/undraw_Business_decisions.png";

const useStyles = makeStyles((theme) => ({
    textpop:{
        color:'#ffffff',
        backgroundColor:'rgb(6, 141, 153)',
        padding: '10px 5px',
        margin:'15px 5px',
        borderRadius:'10px',
        maxWidth:'200px'
    },
    [theme.breakpoints.down("xs")]: {
        container:{
            maxWidth:'600px'
        }
    },
    heroContainer:{
        display:'flex'
    }

}));

const Hero = ({
                  ...props
              }) => {

    const classes = useStyles();
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const velocity_map = (prefersDarkMode) ? Velocity_map_dark : Velocity_map_light;
    const text_color_light = (prefersDarkMode) ? '' : 'text_color_light';

    return (
        <section>
            <div className={'container'}>
                <div className={'maingrid'}>
                    <div className={'gridwrap'}>
                        <div className={'grid-hero'}>
                            <h1>
                                {/*A better standard for managing product features*/}
                                Manage the product feature impact!
                            </h1>
                            <div className={'heroSubtitleText '+text_color_light}>
                                <p>
                                    Remove the speed limits of your venture. We'll back you up with technical clarity of a feature writing & tracking.
                                </p>
                                <div className={'featurebox'}>
                                    <div className={'featureboxcolumn'}>
                                        <p className={'textpop dot-color-rose'}>Development Task Writing</p>
                                        <p className={'textpop dot-color-purple'}>Feature Lifecycle Tracking</p>
                                        <p className={'textpop dot-color-light_blue'}>Techical Due Diligence</p>
                                    </div>
                                    {/*<div className={'featureboxcolumn'}>*/}
                                    {/*    <p className={'textpop dot-color-light_blue'}>Interim Roles Service</p>*/}
                                    {/*    <p className={'textpop dot-color-cyan'}>Development Cost Control</p>*/}
                                    {/*    <p className={'textpop dot-color-pinky'}>Curated Tech Advisory Board</p>*/}
                                    {/**/}
                                    {/*</div>*/}

                                </div>

                                <a className={'button button-red'} href={'/login'}>
                                    Signup for free
                                </a>
                                {/*<a className={'button button-red'} href={'https://form.jotform.com/201062132467041'}>*/}
                                {/*    Book a 30min intro*/}
                                {/*</a>*/}
                            </div>

                            <h3 className={'padding-top-20'}>Starting at only €190/mo</h3>
                        </div>
                        <div className={'grid-img'}>
                            <img alt={'hero'} src={HeroIMG} width="540" height="540" />
                        </div>
                    </div>
                </div>

{/*
                <div className={'heroSubtitleText '+text_color_light}>
                    <p>
                        1. Product operations for smooth impactful discovery and integration<br/>
                        2. On-demand technical development to enable your growth.<br/>
                        3. Upfront cash flow to fund your growth.
                    </p>
                    <p className={'small-quote'}>
                        What you get, and should aim for, is a smooth constant completion of tasks.
                    </p>
                    <img alt={"velocity_map"} src={velocity_map} />
                </div>
*/}

            </div>
            <div className={'footerspace'} />
        </section>
    );
}

export default Hero;