import React, {useEffect, useState} from 'react';
import {Button, Link, makeStyles, TextField, useTheme} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer/Drawer";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import CategoryIcon from '@material-ui/icons/Category';
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import DrawerHeader from "./drawerHeader";
import {auth, db} from "../../util/firebase";
import {useRouteMatch} from "react-router";
import {Divider} from "@material-ui/core";
import TimelineDot from "@material-ui/lab/TimelineDot/TimelineDot";
import Fade from '@material-ui/core/Fade';
import InvitePeople from "./invitePeople";
import PricingTab from "./pricingTab";
import AddIcon from "@material-ui/icons/Add";
import LoopIcon from "@material-ui/icons/Loop";
import {DashboardOutlined} from "@material-ui/icons";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    subtitle:{
        color:theme.custom.subtitle,
        paddingLeft:'15px'
    },
    sidedrawerTitle:{
        color:theme.custom.subtitle,
        // backgroundColor:'rgb(240, 243, 249)',
        padding:'25px 5px 0px 15px',
        fontWeight:'300',
        fontSize:'0.8rem'
    },
    menuItem:{
        fontSize:'0.775rem'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWidth,
        borderRight: '1px solid #000',
        backgroundColor: theme.custom.root
    },
    sidebarListPadding: {
        padding:'3px 10px'
    },
    sidebarListItem: {
        borderRadius:'8px',
        padding:'5px',
        fontSize:'12px'
    },
    sidebarListItemText: {
        margin:'0px'
    },
    drawerBottom: {
        position:'absolute',
        bottom:'30px',
        left:'0',
        width:'100%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    modalButtons: {
        "display":"flex","flex":"initial","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center","WebkitBoxPack":"justify","justifyContent":"space-between"
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
        marginTop:'20px'

    },
    sidebarWrap:{
        display:'flex',
        flexDirection:'column',
        flexGrow:'1'
    }
}));

/** this page manages
 * - the sidedrawer,
 * - the sideheader,
 * - settings popup and
 * - invite people modal
 **/

export default function SideDrawer_team({userData, isMobile, open, closeDrawer}) {
    const classes = useStyles();
    const theme = useTheme();
    const history = useHistory();
    let { url } = useRouteMatch();
    let location = useLocation();

    /** settings states */
    const [anchorEl, setAnchorEl] = useState(null);
    const settings_open = Boolean(anchorEl);
    const [selected, setSelected] = useState('backlog');

    /** activeChat states */
    const [latestChats, setLatestChats] = useState([])

    const handleSettingsOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleSettingsClose = () => {
        setAnchorEl(null);
    };

    const logoutUser = () => {
        handleSettingsClose();
        auth().signOut().then(() => {
            // Sign-out successful.
        }).catch((error) => {
            // An error happened.
        });
    };

    useEffect(()=>{

        /** load latest chats */
        // db.collection('chat_requests')
        //     .where('workspace_id','==',userData.activeWorkspace.id)
        //     .orderBy('createdAt', 'desc')
        //     .limit(6)
        //     .onSnapshot(snapshot => {
        //         let latestChats = []
        //         snapshot.forEach(doc => {
        //             latestChats.push({
        //                 id:doc.id, ...doc.data()
        //             })
        //         })
        //         setLatestChats(latestChats)
        //     })


        if (location.pathname){
            /** [0] = empty
             * [1] = workspace url
             * [2] = page url
             */

            const pathArray = location.pathname.split("/")

            if (pathArray[2] === "ideas") setSelected('ideas')
            if (pathArray[2] === "backlog") setSelected('backlog')
            if (pathArray[2] === "cycles") setSelected('cycles')
            if (pathArray[2] === "folders") setSelected('folders')
            if (pathArray[2] === "projects") setSelected('projects')
            if (pathArray[2] === "roadmap") setSelected('roadmap')
            if (pathArray[2] === "docs") setSelected('docs')
            if (pathArray[2] === "features") setSelected('features')
            if (pathArray[2] === "timeline") setSelected('timeline')
            if (pathArray[2] === "funding") setSelected('funding')
            if (pathArray[2] === "frame") setSelected('frame')
            if (pathArray[2] === "develop") setSelected('develop')
            if (pathArray[2] === "assumptions") setSelected('assumptions')
            if (pathArray[2] === "experiments") setSelected('experiments')
            if (pathArray[2] === "deliver") setSelected('deliver')
            if (pathArray[2] === "validation_feedback") setSelected('validation_feedback')
            if (pathArray[2] === "request") setSelected('request')
            if (pathArray[2] === "dashboard") setSelected('dashboard')
            if (pathArray[2] === "playbook") setSelected('playbook')
            if (pathArray[2] === "sprints") setSelected('sprints')

        }

    }, [location.pathname])

    const WorkspacesList = () => {
        console.log('userData',userData)
        return userData.workspaces.map((space, index) => {

            const ActiveTimelineDot = (userData.activeWorkspace.namespace === space.namespace) ? <TimelineDot color="primary" /> : null

            return (
                <MenuItem key={index} className={classes.menuItem} onClick={() => {handleWorkspaceListClick(space)}}>
                    {ActiveTimelineDot}
                    <span style={{marginLeft:'10px'}}>{space.workspaceName}</span>
                </MenuItem>
            )
        })
    }

    const handleWorkspaceListClick = (space) => {
        /** user selects what workspace, it is set in the backend and then process start again at homepage */

        db.collection('/users').doc(auth().currentUser.uid)
            .update({
                activeWorkspace:space.id
            })
            .then(()=>{
                history.push("/")
            })
            .catch(err=>{
                console.log('err',err)
            })
    }


    const RenderLatestChats = () => {
        /** renders the 3 latest chats into the sidebar... */
        return latestChats.map((c, index) => {
                return <div className={classes.sidebarListPadding} key={index}>
                    <ListItem button key={'chat_single'} onClick={() => {closeDrawer(); history.push(`${url}/request/${c.id}`); setSelected('chat_single_'+index)}} className={classes.sidebarListItem} style={(selected==='chat_single_'+index) ? {background:theme.custom.sidebarListItem} : {}}>
                        {/*<ListItemText className={classes.sidebarListItemText} secondary={'Tasks'} />*/}
                        <div className={classes.sidebarListItemText}>{c.title}</div>
                    </ListItem>
                </div>
        })
    }

    return (
        <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={!isMobile || open}
            classes={{
                paper: classes.drawerPaper,
            }}
        >
            <DrawerHeader history={history} userData={userData} openSettings={handleSettingsOpen} />
            <Menu
                id="settings-menu"
                anchorEl={anchorEl}
                keepMounted
                open={settings_open}
                onClose={handleSettingsClose}
                TransitionComponent={Fade}
                PaperProps={{
                    style: {
                        minWidth: '200px',
                    },
                }}
            >
                {/*<div className={classes.subtitle}>Workspaces</div>*/}
                {/*<WorkspacesList/>*/}
                {/*<hr/>*/}
                <MenuItem className={classes.menuItem} onClick={() => {handleSettingsClose(); closeDrawer(); history.push(`${url}/settings/profile`)}}>Profile</MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => {handleSettingsClose(); closeDrawer(); history.push(`${url}/settings`)}}>Settings</MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => {handleSettingsClose(); closeDrawer(); history.push(`/create`)}}>Create a new workspace</MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => {handleSettingsClose(); closeDrawer(); history.push(`/join`)}}>Join a workspace</MenuItem>
                <MenuItem className={classes.menuItem} onClick={logoutUser}>Logout</MenuItem>
            </Menu>
            <div className={classes.sidebarWrap}>
                <List>
                    <div className={classes.sidebarListPadding}>
                        <ListItem button key={'Home'} onClick={() => {closeDrawer(); history.push(`/home`); setSelected('dashboard')}} className={classes.sidebarListItem} style={(selected==='dashboard') ? {background:theme.custom.sidebarListItem} : {}}>
                            <ListItemIcon><DashboardOutlined/></ListItemIcon>
                            <ListItemText className={classes.sidebarListItemText} secondary={'Home'} />
                        </ListItem>
                    </div>
                    <div className={classes.sidebarListPadding}>
                        <ListItem button key={'teams'} onClick={() => {closeDrawer(); history.push(`/home/teams`); setSelected('teams')}} className={classes.sidebarListItem} style={(selected==='teams') ? {background:theme.custom.sidebarListItem} : {}}>
                            <ListItemIcon><LoopIcon/></ListItemIcon>
                            <ListItemText className={classes.sidebarListItemText} secondary={'Teams'} />
                        </ListItem>
                    </div>
                    <div className={classes.sidebarListPadding}>
                        <ListItem button key={'playbook'} onClick={() => {closeDrawer(); history.push(`/home/knowledgebase`); setSelected('playbook')}} className={classes.sidebarListItem} style={(selected==='playbook') ? {background:theme.custom.sidebarListItem} : {}}>
                            <ListItemIcon><CategoryIcon /></ListItemIcon>
                            <ListItemText className={classes.sidebarListItemText} secondary={'Playbook'} />
                        </ListItem>
                    </div>
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'projects'} onClick={() => {closeDrawer(); history.push(`${url}/projects`); setSelected('projects')}} className={classes.sidebarListItem} style={(selected==='projects') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><CategoryIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Projects'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'features'} onClick={() => {closeDrawer(); history.push(`${url}/features`); setSelected('features')}} className={classes.sidebarListItem} style={(selected==='features') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><CategoryIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Questlines'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'impact'} onClick={() => {closeDrawer(); history.push(`${url}/impact`); setSelected('impact')}} className={classes.sidebarListItem} style={(selected==='impact') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><CategoryIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Impact'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}


                    <div className={classes.sidedrawerTitle}>Team</div>
                    <Divider />
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'tasks'} onClick={() => {closeDrawer(); history.push(`${url}/tasks`); setSelected('tasks')}} className={classes.sidebarListItem} style={(selected==='tasks') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><IdeasIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Tasks'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'backlog'} onClick={() => {closeDrawer(); history.push(`${url}/experiments`); setSelected('experiments')}} className={classes.sidebarListItem} style={(selected==='experiments') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><FilterCenterFocusIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Experiment logs'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'validation_feedback'} onClick={() => {closeDrawer(); history.push(`${url}/validation_feedback`); setSelected('validation_feedback')}} className={classes.sidebarListItem} style={(selected==='validation_feedback') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><RepeatOutlined /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Feedback'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}

                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'backlog'} onClick={() => {closeDrawer(); history.push(`${url}/deliver`); setSelected('deliver')}} className={classes.sidebarListItem} style={(selected==='deliver') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><FilterCenterFocusIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Deliver'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<Divider/>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'cycles'} onClick={() => {closeDrawer(); history.push(`${url}/cycles`); setSelected('cycles')}} className={classes.sidebarListItem} style={(selected==='cycles') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><LoopIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Sprint Cycles'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'roadmap'} onClick={() => {closeDrawer(); history.push(`${url}/roadmap`); setSelected('roadmap')}} className={classes.sidebarListItem} style={(selected==='roadmap') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><CategoryIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Roadmap'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'validation'} onClick={() => {history.push(`${url}/validate`); setSelected('validation')}} className={classes.sidebarListItem} style={(selected==='validation') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><DoneAllIcon /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Validate'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}

                    {/*<div className={classes.sidedrawerTitle}>Roles</div>*/}
                    {/*<Divider/>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'roles'} onClick={() => {closeDrawer(); history.push(`${url}/roles`); setSelected('roles')}} className={classes.sidebarListItem} style={(selected==='roles') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><AccountCircle /></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Active Roles'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'hub'} onClick={() => {closeDrawer(); history.push(`/hub`); setSelected('hub')}} className={classes.sidebarListItem} style={(selected==='hub') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon><DeviceHub/></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Opportunity Hub'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}

                    {/*<div className={classes.sidedrawerTitle}></div>*/}
                    {/*/!*<Divider/>*!/*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'due diligence'} onClick={() => {closeDrawer(); history.push(`${url}/due_diligence`); setSelected('due diligence')}} className={classes.sidebarListItem} style={(selected==='due diligence') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Due Diligence'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'Cost control'} onClick={() => {closeDrawer(); history.push(`${url}/costs`); setSelected('costs')}} className={classes.sidebarListItem} style={(selected==='costs') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Cost Control'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}
                    {/*<div className={classes.sidebarListPadding}>*/}
                    {/*    <ListItem button key={'Advisory board'} onClick={() => {closeDrawer(); history.push(`${url}/advisory`); setSelected('advisory')}} className={classes.sidebarListItem} style={(selected==='advisory') ? {background:theme.custom.sidebarListItem} : {}}>*/}
                    {/*        <ListItemIcon></ListItemIcon>*/}
                    {/*        <ListItemText className={classes.sidebarListItemText} secondary={'Advisory'} />*/}
                    {/*    </ListItem>*/}
                    {/*</div>*/}

                </List>
                <div style={{flexGrow:'1'}} />
                <PricingTab/>
            </div>
        </Drawer>
    )
}
