import React, {useState} from 'react';
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import {BrowserRouter as Router, Route, useRouteMatch} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import SprintDetails from "../../components/activeSprints/sprintDetails";
import SprintDetails_individual from "../../components/activeSprints/sprintDetails_individual";
import SprintTableWrap from "../../components/activeSprints/sprintTableWrap";
import AddNewSprint from "../../components/addNewSprint";
import TeamsTableWrap from "../../components/activeTeams/teamsTableWrap";
import AddNewTeam from "../../components/addNewTeam";
import TeamDetails from "../../components/activeTeams/teamDetails";

const useStyles = makeStyles((theme) => ({
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    mobileNav: {
        paddingLeft:'20px',
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRowSelected: {
        // backgroundColor: theme.custom.TableRowHover,
        "& $taskIcon": {
            opacity: "1",
        }
    },
    selectedLightUp: {
        backgroundColor: theme.custom.RowLightUp,
        width:'4px',
        padding:'0 !important'
    },
    tableRow:{
        "&:hover": {
            backgroundColor: theme.custom.TableRowHover,
            "& $taskIcon": {
                opacity: "1",
            }
        }
    },
    hideCell: {
        display:'none',
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 0px 0px 0px'
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    taskIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        padding:'3px 5px',
        fontSize:'11px',
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        },
    },
    taskChevron: {
        fontSize:'12px',
        verticalAlign:'middle',
        marginBottom:'2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },
    root:{
        minHeight:'min-height',
        display:'flex',
        // flex:'1 1 0%'
        flexFlow:'row wrap'
    },
    wrap:{
        // padding: '20px',
        flex:'100 0px',
        flexShrink:'0',
        "WebkitBoxFlex":"100","flexGrow":"100","paddingBottom":"10px",
        borderLeft:'1px solid rgba(0, 0, 0, 0.12)',
    },


    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

let topTitle = 'Teams'

export default function TeamsList_individual({selectedOne, userData, history, isMobile, teams}) {
    let { url } = useRouteMatch();
    let route = useRouteMatch();
    let mobileNavClasses = (isMobile) ? "classes.mobileNav" : "";

    const classes = useStyles();

    const loadTeamsPage = () => {
        history.push(`${url}`);
    }
    const loadAddNewTeam = () => {
        history.push(`${url}/newteam`);
    }
    const loadTeamDetails = (e, team) => {
        console.log('teamId',team)
        history.push(`${url}/team/`+team.id);
    }

    const RenderTeamsContent = () => {
        return (
            <Router>
                <Route exact path={`${url}`} render={() => <TeamsTableWrap userData={userData} teams={teams} addNewTeam={loadAddNewTeam} loadTeamDetails={loadTeamDetails} />} />
                <Route path={`${url}/newteam`} render={() => <AddNewTeam userData={userData} history={history} backToTeams={loadTeamsPage}/>} />
                <Route path={`${url}/team/:teamId`} render={() => <TeamDetails userData={userData} backToTeams={loadTeamsPage}/>} />
            </Router>
        )
    };

    const handleBackButton = () => {
        loadTeamsPage();
        return false
    };

    /** the topBar has to be own component, Toolbar within it and toolbar always customised to view */
    return <div>
        <div
            className={clsx(classes.topBar, {[classes.topBarShift]: isMobile})}
        >
            <Toolbar>
                {!route.isExact ? <IconButton onClick={handleBackButton} className={clsx(classes.menuButton)}>
                    <ChevronLeftIcon />
                </IconButton> : <div />}
                <div style={{flexGrow:'1'}}>
                    {topTitle}
                </div>
                {/*{route.isExact ? <Button variant="contained" color="primary" size="small" className={classes.addNewButton} onClick={loadAddNewProject}>*/}
                {/*    <AddIcon /> New Team*/}
                {/*</Button> : <div/>}*/}
            </Toolbar>
        </div>
        <RenderTeamsContent/>
    </div>

}
