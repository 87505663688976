import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {db, auth} from "../../util/firebase";
import SprintsList from "../listWrappers/sprintsList";
import TeamsList_individual from "../listWrappers/teamsList_individual";
import ReactGA from "react-ga4";

const useStyles = makeStyles((theme) => ({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

}));

export default function TeamsContent_individual({userData, history, isMobile}) {
    const classes = useStyles();
    const [docsState, setDocsState] = useState(
        {teamsData:[], loading:true}
    );

    React.useEffect(() => {
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "teamscontent_individual page" });

        /** load data here */
        const unsubscribe = db.collection('teams')
            .where('members.'+userData.id, '==', true)
            .orderBy('activeAt','desc')
            .onSnapshot(snap => {
                let teams = []
                snap.forEach(doc => {
                    teams.push({...doc.data(), id:doc.id})
                })
                setDocsState({teamsData:teams, loading:false});
            },error => {
                // console.log('sprints ::: ',error)
                setDocsState({teamsData:[], loading:false});
            });

        return function cleanup() { unsubscribe() }



    }, [userData])

    return (docsState.loading)
        ?
        <></>
        :
        <TeamsList_individual userData={userData} history={history} teams={docsState.teamsData} isMobile={isMobile} />

}