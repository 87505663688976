// Material UI components
import React, { Component } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

import {auth, signInWithGoogle} from '../util/firebase'

class Login extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            errors: [],
            loading: false,
            errorOpen: false,
            templinkHelp:false
        };

    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value
        });
    };

    handleSubmit = (event) => {
        event.preventDefault();
        this.setState({ loading: true });

        var actionCodeSettings = {
            // URL you want to redirect back to. The domain (www.example.com) for this
            // URL must be in the authorized domains list in the Firebase Console.
            url: 'http://sprinthack.com/finishsetup',
            // This must be true.
            handleCodeInApp: true
        };
        auth().sendSignInLinkToEmail(this.state.email, actionCodeSettings)
            .then(() => {
                // The link was successfully sent. Inform the user.
                // Save the email locally so you don't need to ask the user for it again
                // if they open the link on the same device.
                window.localStorage.setItem('emailForSignIn', this.state.email);
                this.setState({
                    templinkHelp:true
                })
                // ...
            })
            .catch((error) => {
                var errorCode = error.code;
                var errorMessage = error.message;
                this.setState({
                    errors: error.message,
                    loading: false,
                    errorOpen: true
                })
            });
        // /** this needs to be sdk login */
        // auth().signInWithEmailAndPassword(this.state.email, this.state.password)
        //     .catch((error) => {
        //         this.setState({
        //             errors: error,
        //             loading: false,
        //             errorOpen: true
        //         });
        //
        //     });


        // event.preventDefault();
        // this.setState({ loading: true });
        // const userData = {
        //     email: this.state.email,
        //     password: this.state.password
        // };
        // axios
        //     .post(BASEURL.api+'/login', userData)
        //     .then((response) => {
        //         console.log('response',response);
        //         localStorage.setItem('AuthToken', `Bearer ${response.data.token}`);
        //         this.setState({
        //             loading: false,
        //         });
        //         this.props.history.push('/b');
        //     })
        //     .catch((error) => {
        //         console.log('error',error);
        //         this.setState({
        //             errors: error.response.data,
        //             loading: false,
        //             errorOpen: true
        //         });
        //     });
    };

    handleGoogleSignin = () => {
        signInWithGoogle()
            .then(()=>{
                this.props.history.push('/');}
            )
            .catch(

            )
    }

    render() {
        const { classes } = this.props;
        const { errors, loading, errorOpen } = this.state;
        const handleError = () => this.setState({errorOpen: !this.state.errorOpen});
        const Alert = (props) => <MuiAlert elevation={6} variant="filled" {...props} />;

        return (!this.state.templinkHelp) ? (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Log in to Sprinthack
                    </Typography>
                    <div style={{padding: "40px 0"}}>
                         <div className={classes.loginButtons}>
                            <button className={classes.loginProviderButton} onClick={this.handleGoogleSignin}>
                                <img style={{height:'15px', margin:'0 15px'}} src="https://img.icons8.com/ios-filled/50/ffffff/google-logo.png" alt="google icon"/>
                                <span> Continue with Google</span>
                            </button>
                        </div>
                    </div>
                    <form className={classes.form} noValidate>
                        <TextField
                            variant="outlined"
                            fullWidth
                            id="email"
                            label="Enter your email address..."
                            name="email"
                            autoComplete="email"
                            error={!!errors.message}
                            onChange={this.handleChange}
                        />
                        {/*<TextField*/}
                        {/*    variant="outlined"*/}
                        {/*    margin="normal"*/}
                        {/*    required*/}
                        {/*    fullWidth*/}
                        {/*    name="password"*/}
                        {/*    label="Password"*/}
                        {/*    type="password"*/}
                        {/*    id="password"*/}
                        {/*    autoComplete="current-password"*/}
                        {/*    helperText={errors.password}*/}
                        {/*    error={!!errors.password}*/}
                        {/*    onChange={this.handleChange}*/}
                        {/*/>*/}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            className={classes.submit}
                            onClick={this.handleSubmit}
                            disabled={loading || !this.state.email}
                        >
                            Log In
                            {loading && <CircularProgress size={30} className={classes.progess} />}
                        </Button>
                        {/*<Grid container>*/}
                        {/*    <Grid item>*/}
                        {/*        <Link href="signup" variant="body2">*/}
                        {/*            {"Don't have an account? Sign Up"}*/}
                        {/*        </Link>*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                        <Snackbar
                            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            open={errorOpen}
                            onClose={handleError}
                            message={<Alert severity="error">{errors}</Alert>}
                            key={'123'}
                        />
                        {errors && (
                            <Typography variant="body2" className={classes.customError}>
                                {errors}
                            </Typography>

                            )}
                    </form>
                </div>
            </Container>
        ) : (
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Check your email
                    </Typography>
                    <p>
                        We've sent a temporary login link.
                    </p>
                    <p>
                        Please check your inbox at <span className={classes.bold}>{this.state.email}</span>
                    </p>
                </div>
            </Container>
        );
    }
}


const styles = (theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1)
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        backgroundColor: "#1595a5",

        "&:hover":{
            backgroundColor: "#19bed2"
        }
    },
    customError: {
        color: 'red',
        fontSize: '0.8rem',
        marginTop: 10
    },
    progess: {
        position: 'absolute'
    },
    loginButtons:{

    },
    loginProviderButton: {
        "height":"48px",
        "fontSize":"14px",
        "color":"#fff",
        "background":"#1595a5",
        "width":"336px",
        "display":"inline-flex",
        "WebkitBoxAlign":"center",
        "alignItems":"center",
        "WebkitBoxPack":"center",
        "justifyContent":"center",
        "whiteSpace":"nowrap",
        "borderRadius":"4px",
        "flexShrink":"0",
        "margin":"0px",
        "fontWeight":"500",
        "minWidth":"32px",
        "padding":"0px 21px",
        "border":"none",

        "&:hover":{
            "background":"#19bed2",

        }
    }


});

export default withStyles(styles)(Login);