import React, {useState, useEffect} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {db, firebaseCore} from "../../util/firebase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete"
import ThumbUp from "@material-ui/icons/ThumbUp"
import {Divider} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({

    subtitle: {
        color:theme.custom.subtitle
    },
    scenarioWrap:{
        position:'relative',
        border: '1px solid transparent',
        padding:'20px 20px 20px 5px',
        marginBottom:'5px',
        borderRadius:'4px',
        transitionProperty:'border, background, color',
        transitionDuration:'150ms',
        "&:hover":{
            border:'1px solid rgb(239, 241, 244)',
            borderColor: theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover,
            textDecoration:'none',
            "& $buttonRowButton": {
                opacity: "1",
                cursor:'pointer'
            },
        },
        color: props => props.completed
            ? theme.custom.subtitle
            : 'inherit',
    },
    scenarioWrapReadOnly: {
        position:'relative',
        padding:'20px',
        borderRadius:'4px',
        transitionProperty:'border, background, color',
        transitionDuration:'150ms',
        border:'1px solid rgb(239, 241, 244)',
        borderColor: theme.custom.OutlineButtonBorder,
        background:theme.custom.ListBGhover,
        textDecoration:'none',
        marginBottom:'20px'
    },
    scenarioWrapCompleted:{
        background:'#e1f7cb',
        position:'absolute',
        height:'100%',
        width:'5px',
        top:0,
        left:0,
    },
    scenarioContainer: {
        padding:'10px',
        marginBottom:'15px'
    },
    featureField: {
        marginLeft:'0px',
        marginBottom:'5px',
        backgroundColor:'rgba(0,0,0,0.04)',
        width:'calc(100% - 0px)'
    },
    scenarioField: {
        marginLeft:'0px',
        marginBottom:'5px',
        backgroundColor:'rgba(0,0,0,0.04)',
        width:'calc(100% - 0px)'
    },
    stepField: {
        marginLeft:'20px',
        padding:'5px',
        borderRadius:'8px',
        width:'calc(100% - 15px)'
    },
    selectedNeedField: {
        backgroundColor:'rgba(205,242,248,1)'
    },
    addNewNeed: {
        cursor:'pointer',
        color: theme.custom.SmoothColor,
        marginLeft:'25px',
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    input: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.875em',
        backgroundColor:''
    },

    scenario: {
        paddingLeft:'0px'
    },
    step: {
        paddingLeft:'20px'
    },
    stepHead: {
        fontWeight:'700'
    },
    buttonRow:{
        position:'absolute',
        bottom:'-12px',
        paddingLeft:'15px',
    },
    buttonRowButton: {
        opacity:'0',
        display:'inline',
        margin: '0 10px 0 5px',
        padding:'3px',
        background:'#fff',
        borderRadius:'8px',
        fontSize:'28px',
        border: '1px solid #eeeeee',
        color:theme.custom.textColorWhiteBG
    },
    actionButton: {
        background:theme.custom.MainButton
    },
    approveIcon: {
        display:'inline',
        margin: '5px 10px -5px 15px',
        padding:'3px',
        background:'transparent',
        borderRadius:'8px',
        fontSize:'20px',
        border: '1px solid transparent',
        borderColor: theme.custom.subtitle,
        "&:hover":{
            borderColor: theme.custom.textColor,
            color:theme.custom.textColor,
        },

    }

}))


export default function BDDscenarioList({payload ,readOnly, linkIconClick}) {
    const classes = useStyles()
    const [scenarios, setScenarios] = useState([])
    const [newScenarioFormOpen, setNewScenarioFormOpen] = useState(false)
    const [newScenarioString, setNewScenarioString] = useState('')

    const handleCompleteClick = (id, completed) => {
        /** should save to db here */
        if (!id) return null
        db.collection('scenarios_bdd').doc(id)
            .update({completed:!completed})
            .then(()=>{
            })
            .catch(()=>{
            })
    }

    useEffect(()=>{

        if (!payload.scenarios || payload.scenarios.constructor !== Array || payload.scenarios.length < 1) return null

        const unsubscribe = db
            .collection('scenarios_bdd')
            .where(firebaseCore.firestore.FieldPath.documentId(),'in', payload.scenarios)
            .onSnapshot(querySnapshot => {

                let scenarioData = []
                querySnapshot.forEach(doc => {
                    const scenario = doc.data()
                    scenario.id = doc.id
                    scenarioData.push(
                        scenario
                    )
                })
                setScenarios(scenarioData);
            });

        return function cleanup() { unsubscribe() }

    }, [payload])

    const handleAddNewScenario = (e) => {
        /** save the scenario as new scenario item to bdd_scenarios
         * wait for return and
         * save the new scenario id to list in scenarios in cycle_request item
         *
         */

        if (!payload.workspace_id || !payload.requestId) return false

        let scenarioObject = {
            completed:false,
            feature: "",
            requestRef: payload.requestId,
            scenario: newScenarioString || "",
            steps: [],
            published:true,
            workspace_id: payload.workspace_id
        }

        db.collection('scenarios_bdd')
            .add(scenarioObject)
            .then(newScenarioObject=>{
                const newscenariosArr = [...payload.scenarios, newScenarioObject.id]
                const cycleRequestUpdate = {
                    scenarios : newscenariosArr
                }
                db.collection('cycle_requests')
                    .doc(payload.requestId)
                    .update(cycleRequestUpdate)
                    .then(()=> {
                        setNewScenarioFormOpen(false)
                    })
                    .catch((err) => {
                        console.error(err);
                    });
            })
    }

    return <>
        {(scenarios.length < 1) ? null :
            scenarios.map((item, index) => {
                if (item.published) return <BDDscenarioListItem key={index} item={item} readOnly={readOnly} linkIconClick={linkIconClick} completedClick={handleCompleteClick} />
                return false
            })
        }
        {!readOnly ? (newScenarioFormOpen ?
                <div>
                    <div style={{margin:'12px 0 5px 0'}}>
                        <TextField className={classes.scenarioField} inputProps={{className:classes.input}} variant="outlined" placeholder="Scenario" size="small" value={newScenarioString} onChange={e=>{setNewScenarioString(e.target.value)}} />
                    </div>
                    <div>
                        <Button variant="contained" size="small" onClick={e=>handleAddNewScenario(e)}>Save</Button>
                        <Button style={{minWidth:'30px', marginLeft:'10px', cursor:'pointer'}} size="small" onClick={()=>{setNewScenarioFormOpen(false)}}>X</Button>
                    </div>
                </div>
                :
                <div style={{padding: '20px 0 20px 20px'}}>
                    <Button color="primary" variant="contained" size="small" className={classes.actionButton} onClick={()=>{setNewScenarioFormOpen(true)}}>+ Add a scenario</Button>
                </div>
        ) : null}

    </>
}

export function BDDscenarioListItem({item, readOnly, linkIconClick, completedClick}) {
    const classes = useStyles(item);
    const [newFeatureString, setNewFeatureString] = useState('')
    const [newScenarioString, setNewScenarioString] = useState('')
    const [newScenarioSteps, setNewScenarioSteps] = useState([])
    const [isEditingScenario, setIsEditingScenario] = useState(false)

    useEffect(()=>{
        setNewScenarioString(item.scenario)
        setNewScenarioSteps(item.steps)
    }, [item])

    const handleAddNewStep = (e) => {
        setNewScenarioSteps([...newScenarioSteps, ""])
    }

    const toggleEditScenario = () => {
        setIsEditingScenario(!isEditingScenario)
    }

    const SaveEditScenario = () => {
        /** update the scenario in bdd_scenarios */

        setIsEditingScenario(false)
        db.collection('scenarios_bdd')
            .doc(item.id)
            .update({scenario:newScenarioString, steps: newScenarioSteps, arch_suggestion:false})
            .then(()=> {
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const handleUpdateFeatureString = (e) => {
        setNewFeatureString(e.target.value)
    }
    const handleUpdateScenarioString = (e) => {
        setNewScenarioString(e.target.value)
    }
    const handleUpdateStepString = (e, idx) => {
        let scenarioSteps = newScenarioSteps
        scenarioSteps[idx] = e.target.value
        setNewScenarioSteps([...scenarioSteps])
    }
    const handleRemoveScenario = () => {
        db.collection('scenarios_bdd')
            .doc(item.id)
            .update({published:false, unpublish_date: new Date().toISOString()})
            .then(()=> {
            })
            .catch((err) => {
                console.error(err);
            });
    }

    const handleApproveSuggestion = () => {
        db.collection('scenarios_bdd')
            .doc(item.id)
            .update({arch_suggestion:false})
            .then(()=> {
            })
            .catch((err) => {
                console.error(err);
            });
    }


    return <div className={(readOnly) ? classes.scenarioWrapReadOnly : classes.scenarioWrap} style={{borderLeft:item.arch_suggestion && !readOnly && "1px solid rgb(149,153,153)"}}>

        {item.arch_suggestion && (
            <div className={classes.subtitle} style={{marginTop:'-18px', marginBottom:'18px'}}>Sprinthack suggestion <Tooltip title="Approve" placement="top"><ThumbUp className={classes.approveIcon} onClick={handleApproveSuggestion} /></Tooltip></div>
        )}


        {isEditingScenario ? (
            <>
                {/*<TextField className={classes.featureField} inputProps={{className:classes.input}} variant="outlined" placeholder="Feature (high level functionality)" size="small" value={newFeatureString} onChange={e=>{handleUpdateFeatureString(e)}} />*/}
                <TextField className={classes.scenarioField} inputProps={{className:classes.input}} variant="outlined" placeholder="Scenario" size="small" value={newScenarioString} onChange={e=>{handleUpdateScenarioString(e)}} />
                {newScenarioSteps && newScenarioSteps.map((step, index) => {
                    return <div key={index}>
                        <TextField
                            className={classes.stepField} inputProps={{className:classes.input}} variant="outlined" placeholder="given/when/then step" size="small" value={step} onChange={e=>handleUpdateStepString(e, index)} />
                    </div>
                })}
                <div style={{"marginTop":"5px"}}> <AddCircleOutlineIcon className={classes.addNewNeed} onClick={e=>handleAddNewStep(e)} /></div>
                <Divider style={{margin:'5px 0 10px 0'}}/>
                <Button variant="contained" size="small" onClick={SaveEditScenario}>Save</Button>
                <Button style={{minWidth:'30px', marginLeft:'10px', cursor:'pointer'}} size="small" onClick={toggleEditScenario}>X</Button>

            </>

            ) : (

            <>
                <div className={classes.scenario}><span
                    className={classes.stepHead}>Scenario: </span>{item.scenario}
                </div>

                {item.steps.map((step, idx)=>{
                    let stepBody = step.split(" ")
                    const stepHead = stepBody.shift()
                    stepBody = stepBody.join(" ")
                    return <div key={idx} className={classes.step}>
                        <span className={classes.stepHead}>{stepHead}</span> {stepBody}
                    </div>
                })}
                {!readOnly ?
                    <div className={classes.buttonRow}>
                        <Tooltip title="Completed" placement="top">
                            {(item.completed) ?
                                <CheckCircleIcon className={classes.buttonRowButton} style={{color:'rgba(199,232,172)'}} onClick={()=>completedClick(item.id, item.completed)} />
                                :
                                <CheckCircleOutlineIcon className={classes.buttonRowButton} onClick={()=>completedClick(item.id, item.completed)} />}
                        </Tooltip>
                        {/*<Tooltip title="Story link">*/}
                        {/*    <InsertLinkIcon className={classes.buttonRowButton} onClick={()=>linkIconClick(item.storiesRef)} />*/}
                        {/*</Tooltip>*/}
                        <Tooltip title="Edit" placement="top">
                            <EditIcon className={classes.buttonRowButton} onClick={toggleEditScenario} />
                        </Tooltip>
                        <Tooltip title="Remove" placement="top">
                            <DeleteIcon className={classes.buttonRowButton} onClick={handleRemoveScenario} />
                        </Tooltip>
                    </div>
                    : null}
            </>

            )
        }


    </div>

}