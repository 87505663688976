import React, {useEffect, useState} from 'react';
import {makeStyles} from "@material-ui/core";
import {db} from "../../util/firebase";
import dayjs from "dayjs";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    dataWrap:{
        padding:'0 20px 10px 20px',
        display: 'flex',
    },
    dataSquare:{
        flexDirection:'column',
        display: 'flex',
        marginRight:'10px',
        border:'2px solid #1e1e1e',
        borderColor: theme.custom.boxborder,
    },
    dataDetails: {
        color:theme.custom.textColor,
        padding:'10px',
        flexDirection:'column',
        display:'flex',
        alignItems: 'center',
    },
    dataTitle: {
        fontSize:'13px',
        padding:'2px',
        color:theme.custom.impactDataText,
        background:theme.custom.boxborder,
        textTransform:'uppercase',
        textAlign: 'center'
    },
    mainDetail: {
        fontSize:'20px'
    },
    secondaryDetail:{
        fontSize:'12px',
        color:theme.custom.subtitle
    }

}));

const sampleImpactData = [
    {
        createdAt:'2021-09-18T15:52:54.238Z',
        workspace_id:'MdQFApkp2JEA7s4WocBw',
        data:{
            users:{
                value:1223,
                type:'number'
            },
            revenue:{
                value:23000,
                type:'currency',
                currency_unit:'€'
            }
        }
    },
    {
        createdAt:'2021-09-28T15:52:54.238Z',
        workspace_id:'MdQFApkp2JEA7s4WocBw',
        data:{
            users:{
                value:1623,
                type:'number'
            },
            revenue:{
                value:25600,
                type:'currency',
                currency_unit:'€'
            }
        }
    },
    {
        createdAt:'2021-10-09T15:52:54.238Z',
        workspace_id:'MdQFApkp2JEA7s4WocBw',
        data:{
            users:{
                value:2423,
                type:'number'
            },
            revenue:{
                value:38000,
                type:'currency',
                currency_unit:'€'
            }
        }
    }
]

export default function ImpactDataList({row, userData}) {
    const classes = useStyles();
    const [impactData, setImpactData] = useState({})

    useEffect(()=>{

        // const startTime = dayjs(row.createdAt).subtract(1, 'year').toISOString()

        /** load the data */
        const unsubscribe = db
            .collection('lifecycle_data')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            .where('createdAt', '>', row.createdAt)
            .orderBy('createdAt', 'desc')
            .onSnapshot(impactSnapshot => {
                let impactData = impactSnapshot.docs.map(doc => doc.data());

                let dataKeys = {}
                impactData.forEach((impact) => {
                    Object.keys(impact.data).forEach(k => {
                        if (!dataKeys[k]) dataKeys[k] = true
                    })
                });

                let lastData = impactData[0]
                let baselineData = impactData[impactData.length -1]

                console.log('baselineData',dataKeys)
                console.log('baselineData',baselineData)
                console.log('lastData',lastData)
                console.log('impactData',impactData)

                setImpactData({
                    dataKeys:dataKeys,
                    baselineData: baselineData,
                    lastData: lastData,
                    allData: impactData
                })

            })

        return function cleanup() { unsubscribe() }
    }, [row, userData.activeWorkspace.id])

    const BuildImpactData = () => {

        /** what do we need ?
         * - know what was the last data point of a certain data?
         * - what is the latest dataset? The data included are the main data to analyse
         * - if a certain data is not anymore included, consider it not tracked anymore. Show grey for 3 dataset iterations. After that remove.
         * - look for 1 year timespan as default. Later add more timespan. Do this in the db query.
         * - First point of a data (within the timespan) is the baseline.
         */

        if (!impactData || !impactData.lastData || !impactData.baselineData) return null

        let lastKnownBaseline = {}
        let lastKnownData = {}

        impactData.allData.forEach((impact) => {
            Object.keys(impact.data).forEach(key => {
                if (!lastKnownData[key] && impact.data[key].value) lastKnownData[key] = impact.data[key].value
                if (impact.data[key].value) lastKnownBaseline[key] = impact.data[key].value
            })
        })

        const sortedLastKnownBaseline = Object.keys(lastKnownBaseline)
            .sort()
            .reduce((acc, key) => ({
                ...acc, [key]: lastKnownBaseline[key]
            }), {})

        return Object.keys(sortedLastKnownBaseline).map((key,idx) => {

            const changeToBaselinePercent = ((lastKnownData[key] / lastKnownBaseline[key])*100-100).toFixed(2)

            return (<div className={classes.dataSquare} key={idx}>
                <div className={classes.dataTitle}>{key}</div>
                <div className={classes.dataDetails}>
                    <div className={classes.mainDetail}>{changeToBaselinePercent}%</div>
                    <div className={classes.secondaryDetail}>{lastKnownData[key]}</div>
                </div>
            </div>)
        })

    }

    return (
        <div>

            <div className={classes.dataWrap}>
                <BuildImpactData />
            </div>

        </div>
    )
}
