import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import {makeStyles} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Divider} from "@material-ui/core";
import {db, auth, firebaseCore} from "../util/firebase"

const useStyles = makeStyles((theme) => ({
    joinRow: {
        "display":"flex","flex":"1 1 auto","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center",
        padding:'10px',
        borderTop:'1px solid rgba(255, 255, 255, 0.12)',
        minWidth:'400px'
    },
    joinData:{
        "display":"flex","flex":"1 1 auto","flexDirection":"column","flexGrow":"4","textAlign":"left"
    },
    joinButton: {
        "display":"flex","flex":"initial","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center"
    },

}));

export default function JoinList({rows, showWelcome}) {
    const classes = useStyles();
    const history = useHistory();

    const joinWorkspace = (workspaceId) => {
        if (!auth().currentUser) return null

        /** first for the audit purposes.. event driven style -> this should trigger backend function to add to members of workspace */
        db.collection("user_workspace").doc(auth().currentUser.uid+'_'+workspaceId)
            .set({
                userId:auth().currentUser.uid,
                workspaceId:workspaceId
            })
            .then(()=>{
                db.collection("workspaces").doc(workspaceId)
                    .collection("members").doc(auth().currentUser.uid)
                    .set({
                        removed:false,
                        suspended:false,
                        user:auth().currentUser.uid,
                        email:auth().currentUser.email
                    }, {merge:true})
                    .then(async ()=>{
                        /** remove the join info */
                        db.collection("join")
                            .doc(auth().currentUser.email)
                            .set({
                                [`${workspaceId}`]: firebaseCore.firestore.FieldValue.delete()
                            }, {merge:true})
                            .then(async (resp)=>{
                                db.collection("rejoin")
                                    .doc(auth().currentUser.uid)
                                    .set({
                                        [`${workspaceId}`]: firebaseCore.firestore.FieldValue.delete()
                                    }, {merge:true})
                                    .then((resp)=>{
                                        /** update the user info */
                                        const userUpdateObject = {
                                            activeWorkspace: workspaceId,
                                            workspaces: {}
                                        }
                                        userUpdateObject.workspaces[workspaceId] = true
                                        db.collection('/users').doc(auth().currentUser.uid)
                                            .set(userUpdateObject, {merge:true})
                                            .then(()=>{
                                                if (showWelcome) history.push('/welcome')
                                                history.push('/')
                                            })
                                            .catch(err=>{
                                                console.log('err',err)
                                                history.push('/error')
                                            })
                                    }).catch(err=>{
                                    console.log('err',err)
                                    history.push('/error')
                                })
                            })
                            .catch(err=>{
                                console.log('err',err)
                                history.push('/error')
                            })
                    }).catch(err=>{
                        console.log('err',err)
                        history.push('/error')
                    })
                })
            .catch((err)=>{
                console.log('err',err)
                history.push('/error')
            })
    }

    const RowItems = () => {
        return rows.map((space, index) => {
            return (
                <div key={index}>
                    <div key={index} className={classes.joinRow}>
                        <div className={classes.joinData}>{space.workspaceName || space.namespace}</div>
                        <div className={classes.joinButton}>
                            <Button
                                variant="contained"
                                size="small"
                                color="default"
                                onClick={()=>joinWorkspace(space.id)}
                            >
                                <span>JOIN</span>
                            </Button>
                        </div>
                    </div>
                    <Divider />
                </div>
            )
        })
    }

    return (
        <div style={{marginTop:'15px'}}>
            <RowItems/>
        </div>
    )

}
