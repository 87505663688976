import React from "react";
import {makeStyles, useTheme} from "@material-ui/core";
import Logo from "../img/sprinthack_logo_white_dark_small.png"
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    content: {

        display:'flex',
        flex:'1 1 auto',
        flexDirection:'row',
        "WebkitBoxAlign":"center","alignItems":"center","WebkitBoxPack":"center","justifyContent":"center","overflowY":"auto",

        flexGrow: 1,
        // padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    title:{
        marginBottom:'20px',
    },
    card: {
        marginTop:'50px',
        position:'relative',
        overflow:'visible',
        maxWidth:'580px',
        padding:'0px'
    },
    logo: {
        position: 'absolute',
        zIndex: '1001',
        height: '31px',
        width: '31px',
        left: 'calc(50% - 16px)',
        top: '-15px'
    },
    promptTextColor: {
        color:'rgb(107, 111, 118)'
    }
}));

export default function EmptyPageInfoPrompt({pagecontext, header, body, image}) {
    const classes = useStyles();
    const theme = useTheme();
    const bull = <span className={classes.bullet}>•</span>;

    return (
        <main className={classes.content}>
            <Card variant="outlined" className={classes.card}>
                <CardContent>
                    <img  src={Logo} className={classes.logo} alt="Sprinthack"/>
                    <Typography className={classes.title} color="textSecondary" gutterBottom>
                        {pagecontext}
                    </Typography>
                    <Typography variant="h5" component="h2" style={{textAlign:'center'}}>
                        {header}
                    </Typography>
                    {(image) ? <img style={{width:'100%'}} src={image} /> : null}
                    <Typography variant="body2" component="div" color="textSecondary" style={{textAlign:'center'}}>
                        {body}
                    </Typography>
                </CardContent>
                {/*<CardActions>*/}
                {/*    <Button size="small">Learn More</Button>*/}
                {/*</CardActions>*/}
            </Card>
        </main>
    )
}
