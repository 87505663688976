import React, {useState} from "react";
import {Divider, makeStyles, TextField} from "@material-ui/core";
import {useRouteMatch} from "react-router-dom";
import Button from "@material-ui/core/Button";
import InsertLinkIcon from "@material-ui/icons/InsertLink";
import LinkingWithProject from "./LinkingWithProject";
import FreetextEditor from "../generalComponents/FreetextEditor";
import {auth, db} from "../../util/firebase";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import PromoteToFeatures from "./PromoteToFeatures";
import SetProjectPriority from "../activeProjects/SetProjectPriority";
import SetTaskStatus from "./SetTaskStatus";

const useStyles = makeStyles((theme) => ({
    indicator: {
        backgroundColor: 'rgba(0, 0, 0, 0.54)'
    },
    textareaStyle: {
        padding: '10px',
        width: 'calc(100% - 35px)'
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    subtitleLight: {
        color:theme.custom.subtitleLight
    },
    createCycleButton: {
        background:theme.custom.MainButton
    },
    cycleTextDetail: {
        lineHeight:'20px'
    },
    approveButton: {
        background:theme.custom.GreenButton,
    },
    approveChip: {
        background:'transparent',
        fontWeight:'700',
        border:'1px solid transparent',
        transition:'200ms',
        '&:hover':{
            // background:'#fff',
            // border:'1px solid rgba(0, 0, 0, 0.12)'
        },
    },
    sidedetails: {
        marginTop:'-65px',
        flex: '1 100%',
        background: theme.custom.sidedetailBackground,
        padding:'0px 0px 82px 25px',
        position:"relative",
        flexShrink:"0",
        WebkitBoxFlex:"1",
        flexGrow:"1",
        minWidth: '320px',
        maxHeight: 'calc(100% + 65px)',
        overflowY: 'scroll',
    },
    sideTopMenu: {
        minHeight:'64px',
        // width:'300px',
        display:'flex',
        position:'relative',
        alignItems:'center'
    },
    sidedetailData: {
        padding: '20px 0 20px 0',
    },
    sidedetailsRow: {
        display:'flex',
        flex:'initial',
        flexDirection:'row',
        marginBottom:'15px',
        // paddingLeft: '5px'
    },
    sidedetailsRowHeader: {
        color:'rgb(107, 111, 118)',
        fontWeight: 500,
        display:'flex',
        flexGrow:'initial',
        flexBasis:'initial',
        flexDirection:'row',
        width:'100px',
        flexShrink: '0',
        textTransform: 'capitalize',
    },
    sidedetailValue:{
        fontWeight: 500,
        width:'100%',
        display: 'flex',
        flex:'initial',
        flexDirection:'column',
    },
    sideDetailHoverField:{
        flexGrow: '1',
        maxWidth:'100%',
        padding:'8px 8px',
        textAlign:'left',
        justifyContent:'flex-start',
        marginLeft:'-8px',
        marginRight:'32px',
        flexShrink:'1',
        overflow:'hidden',
        border:'1px solid transparent',
        borderRadius:'5px',
        background:'transparent',
        marginTop:'-8px',
        "&:hover":{
            border:'1px solid #c7c7c7'
        }
    },
    experimentListItem:{
        display:"flex",
        flexDirection:'row',
        paddingBottom:'8px',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }
    },
    menuIcon:{
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
        }
    },
    link:{
        display:'flex',
        flexDirection:'row',
        transition:'150ms',
        "-webkit-box-align":'center',
        alignItems:'center',
        color:'inherit',
        textDecoration: 'none',
        cursor: 'default',

        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }

    },
    whiteColorButton:{
        color:'#a2a2a2',
        borderColor:'#a2a2a2'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    modalButtons: {
        "display":"flex","flex":"initial","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center","WebkitBoxPack":"justify","justifyContent":"space-between"
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
        marginTop:'20px'

    },
    nextActionToggle: {
        position:'absolute',
        top:'0px',
        right:'0px'
    },
    confirmFeatureButton: {

        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    [theme.breakpoints.up("xs")]: {
        sidedetails:{
            flex: '10 auto'
        }
    },
    [theme.breakpoints.up("sm")]: {
        maindetails:{
            flex:'100 0px',
            order:'2'
        },
        sidedetails:{
            order:'3'
        }
    },
    [theme.breakpoints.up("md")]: {
        maindetails:{
            marginLeft:'240px'
        }
    },

}));

function validURL(str) {
    const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
        '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
}

const getDefaultDetailKeys = () => {
    return [
        'status',
        // 'stakeholder',
        // 'success',
        'estimate'
    ]
}

function getPlaceholders() {
    return {
        "stakeholder": "add...",
        "status":"add...",
        "success": "Write the task output as 'If I do X, then Y will happen'",
        "estimate": "Estimate the amount of work"
    }
}

export default function FeatureSideDetails({userData, payload, loadExperimentDetails}) {
    const classes = useStyles();
    let { url } = useRouteMatch();
    const defaultKeys = getDefaultDetailKeys()
    const placeHolders = getPlaceholders()
    const [confirmFeatureOpen, setConfirmFeatureOpen] = useState(false);
    const [newFeatureTitle, setNewFeatureTitle] = useState('')
    const [newFeatureSnackbarOpen, setNewFeatureSnackbarOpen] = useState(false);


    /** save sidedetails change to db */
    const saveSideDetailChange = (save_object) => {
        const update_object = {}
        update_object[save_object["key"]] = save_object["value"]
        db
            .collection('idea_validation')
            .doc(payload.id)
            .update(update_object)
            .then()

    }

    const GenerateSideDetailsElement = (detail, text, index) => {

        if (detail === "status") {
            return <div key={index}><SetTaskStatus payload={payload} /></div>
        } else {
            return <div className={classes.sidedetailsRow} key={index}>
                <div className={classes.sidedetailsRowHeader}>{detail} </div>
                <div className={classes.sidedetailValue}>
                    <div className={classes.sideDetailHoverField}>
                        <FreetextEditor payload={{key:detail, value:text, placeholder:placeHolders[detail]}} save={saveSideDetailChange}/>
                    </div>
                </div>

                {/*<div className={classes.sidedetailsRowHeader}>{detail}: </div>*/}
                {/*<div className={classes.sidedetailValue}>{text}</div>*/}
            </div>

        }

    }

    const OutputSuccessCase = () => {
        if (!payload) return <></>
        let elements = ['',''];

        elements.push(GenerateSideDetailsElement('success', payload['success'], '-100'))
        return elements

    }


    const DefaultSideDetails = () => {
        if (!payload) return <></>
        let elements = ['',''];

        defaultKeys.forEach((defaultkey, idx)=>{
            elements.push(GenerateSideDetailsElement(defaultkey, payload[defaultkey], idx))
            // if (!payload[defaultkey]) {
            //     elements.push(generateSideDetailsElement(defaultkey, placeHolders[defaultkey], idx))
            // } else {
            // }
        })

        const currentIndex = defaultKeys.length
        return elements

    }

    const ListExperiments = () => {
        if (!payload || !payload.experimentList) return null
        const emptyList = (payload.experimentList.length < 1) ? <div className={classes.subtitle}>(add experiments in the logs...)</div> : null

        const elementsList = payload.experimentList.map((exp,i)=> {
            return <div key={i} className={classes.experimentListItem}><div>{exp.freetext}</div><ChevronRight onClick={()=>{loadExperimentDetails(exp)}} className={classes.menuIcon} /></div>
        })

        return <div className={classes.sidedetailsRow}>
            <div className={classes.sidedetailsRowHeader}>{'Experiments'} </div>
            <div className={classes.sidedetailValue}>
                {emptyList}
                {elementsList}
            </div>
        </div>
    }

    const ListLearnings = () => {
        if (!payload || !payload.experimentList) return null
        const emptyList = (payload.experimentList.length < 1) ? <div className={classes.subtitle}>(add learnings in the logs...)</div> : null

        const elementsList = payload.experimentList.map((exp,i)=> {
            return <div key={i}><div>{exp.learnings}</div></div>
        })

        return <div className={classes.sidedetailsRow}>
            <div className={classes.sidedetailsRowHeader}>{'Learnings'} </div>
            <div className={classes.sidedetailValue}>
                {emptyList}
                {elementsList}
            </div>
        </div>
    }

    const saveNewFeature = () => {
        setConfirmFeatureOpen(false);

        /** this should be event driven */
        setNewFeatureSnackbarOpen(true)

        // db.collection('features_confirmed').add({
        //     title:newFeatureTitle,
        //     workspace:userData.activeWorkspace.id
        // }).then(()=>{
        //
        //     setNewFeatureSnackbarOpen(true)
        // }).catch(err=>{
        //     console.log(err)
        // })
    }

    const handleNewFeatureSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setNewFeatureSnackbarOpen(false);
    };

    const RenderModalFeatures = () => {
        // eslint-disable-next-line no-lone-blocks
        if (!confirmFeatureOpen) return null
        return (payload.success && payload.success.length > 2) ? <PromoteToFeatures payload={payload} closeModal={()=>
            {
                setConfirmFeatureOpen(false)
            }
        } /> : <div>
            No success definition found. <br/>
            Please write it on the sidedetails 'Success' slot
        </div>
    }


    return (
        <div className={classes.sidedetails}>
            <div className={classes.sideTopMenu}>
                {(payload)
                    ?
                    <div>
                        <Button variant="contained" color="primary" size="small" className={classes.confirmFeatureButton} onClick={()=>{setConfirmFeatureOpen(true)}}>
                            {payload.featureTitle || payload.feature || "Promote to a feature"}
                        </Button>
                    </div>
                    :
                    null
                }
            </div>
            <hr style={{border:'1px solid #000', borderBottom:'none', marginTop: '0px', marginLeft:'-25px', marginBottom:'20px'}} />
            <div style={{maxWidth:'450px'}}>
                <OutputSuccessCase/>
                <Divider style={{marginBottom:'30px'}}/>
                <LinkingWithProject userData={userData} payload={payload} />
                <DefaultSideDetails />
                <ListExperiments/>
                <ListLearnings/>

                {/*sidedetails...will have linking to projects, stakeholder, priority (1-3 scale), "success definition"*/}
            </div>
            <Modal
                open={confirmFeatureOpen}
                // onClick={()=>{setConfirmFeatureOpen(false)}}
                onClose={(_, reason) => {
                    if (reason === "backdropClick") {
                        setConfirmFeatureOpen(false);
                    }
                }}
                className={classes.modal}
                aria-labelledby="modal-confirm-feature"
                aria-describedby="modal-confirm-feature"
                closeAfterTransition

            >
                <div className={classes.paper}>
                    <h2 id="transition-modal-title">Promoto to a feature</h2>
                    <RenderModalFeatures/>
                </div>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={newFeatureSnackbarOpen}
                onClose={handleNewFeatureSnack}
                autoHideDuration={6000}
                message={'Feature created!'}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleNewFeatureSnack}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={'123'}
            />

        </div>

    )
}
