import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {TextareaAutosize} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    freeTextContent: {
        color:theme.custom.genericLongformText
    },
    textareaStyleTransparent:{
        // padding: '10px',
        marginBottom:'20px',
        fontSize:'15px',
        lineHeight:"23px",
        fontFamily:'"Roboto", "Helvetica", "Arial", sans-serif',
        minWidth: '260px',
        width: 'calc(100%)',
        border:'none',
        backgroundColor:"transparent",
        color: theme.custom.genericLongformText,
        resize:'none',
        overflow:'auto',
        outline:'none',
        "WebkitBoxShadow":"none",
        "MozBoxShadow":"none",
        "boxShadow":"none",
        paddingLeft:'0px',
        paddingRight:'0px'
    },
    hide: {
        display:'none !important'
    },
    requestDetailText:{
        "fontStyle":"normal",
        "fontWeight":"normal",
        "textAlign":"left",
        "color":theme.custom.genericLongformText,
        "lineHeight":"23px",
        "fontSize":"15px",
        "display":"block",
        "marginBottom":"50px",
        "cursor":"default",
        whiteSpace:'pre-wrap'
    },
    freeTextPlaceholder: {
        color:"rgb(98, 102, 109)"
    },
    requestDetailGuide:{
        color:"rgb(98, 102, 109)",
        marginBottom:'15px',
        fontSize:'14px',
        fontStyle:'italic'
    },
    whiteColor:{
        color:'#fff'
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover}
    }

}));


export default function RequestFreetextEditor({payload, save}) {

    const classes = useStyles();
    const [requestData, setRequestData] = useState({})
    const [displayRequestEdit, setDisplayEdit] = useState(false);
    const TextInputRef = useRef(null);


    /** should really create a custom hook for this... */
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;

    useEffect(()=>{
        setRequestData(payload)
    }, [payload])

    const toggleHide = () => {
        setDisplayEdit(!displayRequestEdit)

        setTimeout(() => {
            TextInputRef.current.focus()
        }, 100);

    }

    const handleSave = () => {
        save(requestData.freetext_details)
        toggleHide()
    }

    return (
       <>
           <div onClick={toggleHide} className={clsx(classes.requestDetailText, {
               [classes.hide]: displayRequestEdit
           })}>
               {(requestData && requestData.freetext_details && requestData.freetext_details.trim() !== '')
                   ?
                   <div className={classes.freeTextContent}>{requestData.freetext_details}</div>
                   :
                   <div className={classes.freeTextPlaceholder}>Add some details...</div>
               }
           </div>
           <div style={{paddingBottom:'30px'}} className={clsx({[classes.hide]: !displayRequestEdit})}>
               <TextareaAutosize
                   minRows={1}
                   ref={TextInputRef}
                   className={clsx(classes.textareaStyleTransparent)}
                   placeholder={'Add some details...'}
                   value={requestData.freetext_details}
                   onChange={(e)=>{setRequestData({...requestData, ...{freetext_details: e.target.value}})}}
               />
               <Button
                   color="primary"
                   variant="contained"
                   type="submit"
                   size="small"
                   className={clsx(classes.submitButton)}
                   onClick={handleSave}
               >Save</Button>
               <Button style={{minWidth:'30px', marginLeft:'10px', cursor:'pointer'}} size="small" onClick={toggleHide}>X</Button>
           </div>
       </>

    )
}
