import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {useRouteMatch} from "react-router-dom";
import {useParams} from "react-router";
import {db} from "../../util/firebase";
import dayjs from 'dayjs';
import relativeTime from "dayjs/plugin/relativeTime";
import Divider from '@material-ui/core/Divider';
import Timeline from '@material-ui/lab/Timeline';
import ActivityTimeline from "../generalComponents/activityTimeline"
import Project_sideDetails from "./projects_sideDetails";
import DetailHeaderEditor from "../generalComponents/DetailHeaderEditor";
import ChevronRight from "@material-ui/icons/ChevronRight";
import EmptyPageInfoPrompt from "../emptyPageInfoPrompt";
import clsx from "clsx";
import ProjectsListTasks from "../../pages/listWrappers/projectsListTasks";
import Button from "@material-ui/core/Button";
import ListHeader from "../listHeader";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import RadioButtonUncheckedIcon from "@material-ui/icons/RadioButtonUnchecked";
import Chip from "@material-ui/core/Chip";
import AdjustIcon from "@material-ui/icons/Adjust";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

const useStyles = makeStyles((theme) => ({
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "75px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRowSelected: {
        // backgroundColor: theme.custom.TableRowHover,
        "& $taskIcon": {
            opacity: "1",
        }
    },
    selectedLightUp: {
        backgroundColor: theme.custom.RowLightUp,
        width:'4px',
        padding:'0 !important'
    },
    tableRow:{
        "&:hover": {
            backgroundColor: theme.custom.TableRowHover,
            "& $taskIcon": {
                opacity: "1",
            }
        }
    },
    tableRowEmpty:{

    },
    hideCell: {
        display:'none',
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    tableIconNow: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    tableIconNext: {
        fontSize:theme.custom.tableiconsize,
        color:'rgb(242, 201, 76)'
    },
    tableIconLater: {
        fontSize:theme.custom.tableiconsize,
        color:'#BEBEBE'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 5px 0px 0px',
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    taskIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder,
        padding:'3px 5px',
        fontSize:'11px',
        "&:hover":{
            border:'1px solid rgb(107, 111, 118)',
            borderColor:theme.custom.OutlineButtonBorder,
            background:theme.custom.ListBGhover

        },
    },
    taskChevron: {
        fontSize:'12px',
        verticalAlign:'middle',
        marginBottom:'2px'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },
    root:{
        minHeight:'min-height',
        display:'flex',
        // flex:'1 1 0%'
        flexFlow:'row wrap'
    },
    wrap:{
        // padding: '20px',
        flex:'100 0px',
        flexShrink:'0',
        "WebkitBoxFlex":"100","flexGrow":"100","paddingBottom":"10px",
        borderLeft:'1px solid rgba(0, 0, 0, 0.12)',
    },
    emptyRow:{
        color:theme.custom.subtitle,
        marginLeft:'25px'
    },

    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

    animatedItemFade: {
        animation: `$fadeIn 3s cubic-bezier(0.19, 1, 0.22, 1) 0s 1 normal both running`
    },
    "@keyframes scaleIn": {
        "0%": {
            opacity: 0,
            transform: "translateY(-200%)"
        },
        "100%": {
            opacity: 1,
            transform: "translateY(0)"
        }
    },
    "@keyframes fadeIn": {
        "0%": {
            opacity: 0,
        },
        "100%": {
            opacity: 1,
        }
    }

}));

export default function ProjectTableWrap({userData, projects, loadTaskDetails, loadAddNewProject, loadProjectDetails}) {
    const classes = useStyles();
    let { url } = useRouteMatch();
    let route = useRouteMatch();
    let { selectedParam } = useParams();

    const [projectsNow, setProjectsNow] = useState([])
    const [projectsNext, setProjectsNext] = useState([])
    const [projectsLater, setProjectsLater] = useState([])
    const [projectsCompleted, setProjectsCompleted] = useState([])
    const [showTaskColumn, setShowTaskColumn] = useState(false)
    const [selectedProject, setSelectedProject] = useState('')


    const pagecontext = 'Projects';
    const promptheader = 'Draft your next plans or features here';
    const promptbody = () => {
        return (
            <div>
                <p>
                    Write down what you think you should do?
                </p>
                <p>
                    Sprinthack architects will provide feedback to the project scope and risks.
                </p>
                <p>
                    <Button style={{marginTop:'20px'}} variant="contained" size="small" color="primary" onClick={loadAddNewProject} >
                        + New Project
                    </Button>
                </p>
            </div>
        )};

    React.useEffect(() => {
        if (selectedParam) {
            setShowTaskColumn(true)
            setSelectedProject(selectedParam)
        }

        setProjectsNow(projects.filter(p => p.priority === 0 || !p.priority ))
        setProjectsNext(projects.filter(p => p.priority === 1 ))
        setProjectsLater(projects.filter(p => p.priority === 2 ))
        setProjectsCompleted(projects.filter(p => p.priority === 3 ))

    }, [selectedParam, projects])

    const handleTaskOpen = (project) => {
        setSelectedProject(project.id)
        setShowTaskColumn(true)

        const pathSplit = route.path.split('/')
        console.log('pathSplit',pathSplit.length)
        if (pathSplit.length < 4) {
            window.history.pushState({}, 'Sprinthack',url+'/tasks/'+project.id);
        } else {
            const url2 = '/'+pathSplit[1]+'/'+pathSplit[2]
            window.location.replace(url2+'/tasks/'+project.id);
        }

    }

    const saveNewProject = (newTask) => {
        console.log('newTask',newTask)
        const updateObject = {
            idea: newTask,
            feedbacks:[],
            project:selectedProject,
            workspace_id:userData.activeWorkspace.id,
            createdAt: dayjs().toISOString()
        }

        db
            .collection('idea_validation')
            .add(updateObject)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

    }

    const GetIcon = ({title}) => {
        if (title === 'Now') return <AdjustIcon className={classes.tableIconNow}/>
        if (title === 'Next') return <RadioButtonUncheckedIcon className={classes.tableIconNext}/>
        if (title === 'Completed') return <CheckCircleIcon className={classes.tableIconNow}/>
        return <RadioButtonUncheckedIcon className={classes.tableIconLater}/>
    }

    const GetTable = ({title, projects}) => {
        return (
            <div>
                <ListHeader title={title} />
                <TableContainer>
                    <Table className={classes.table} size="small" aria-label="a dense table">
                        <TableBody>
                            {(projects.length) < 1 ?
                                <TableRow key={-1}
                                          className={classes.tableRowEmpty}
                                          style={{height: '44px'}}
                                >
                                    <TableCell align="left" className={classes.emptyRow}>All empty here</TableCell>
                                </TableRow>
                                :
                                projects.map((project, index) => {
                                    const isSelected = (project.id === selectedProject.id)
                                    return (
                                        <TableRow key={index}
                                                  className={(isSelected) ? classes.tableRowSelected : classes.tableRow}
                                                  style={{height: '44px'}} onClick={(e) => {
                                            loadProjectDetails(e, project)
                                        }}>
                                            <TableCell align="left" style={{
                                                width: "1px",
                                                paddingRight: '10px'
                                            }}>{<GetIcon title={title} />}</TableCell>
                                            <TableCell align="left" scope="row" className={classes.rowText}>
                                                <div className={classes.rowTitle}>
                                                    {project.title}
                                                    <span id={'taskLink'} className={classes.taskIcon} onClick={() => {
                                                        handleTaskOpen(project)
                                                    }}>Tasks <ChevronRight id={'taskLinkChevron'}
                                                                           className={classes.taskChevron}/></span>
                                                </div>
                                            </TableCell>
                                            <TableCell className={classes.stateChip} align="right">{(project.version) ?
                                                <Chip variant="outlined" color="primary" size="small"
                                                      label={project.version}/> : <div></div>}</TableCell>
                                            <TableCell align="right"
                                                       className={classes.dateColumn}>{dayjs(project.createdAt).format('MMM DD')}</TableCell>
                                            <TableCell align="right"
                                                       className={(isSelected) ? classes.selectedLightUp : classes.hideCell}></TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        )
    }

    const RenderTable = () => {
        return (
            <div>
                <GetTable title={'Now'} projects={projectsNow} />
                <GetTable title={'Next'} projects={projectsNext} />
                <GetTable title={'Later'} projects={projectsLater} />
                <GetTable title={'Completed'} projects={projectsCompleted} />
            </div>
        )
    }

    return (
        <div>
            {projects.length < 1 ? (
                    <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
                        <div className={classes.toolbar} />
                        <div>
                            <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
                        </div>
                    </div>
                ) :
                (
                    <div className={classes.root}>
                        <div className={classes.wrap}>
                            <div>
                                <RenderTable/>
                            </div>
                        </div>
                        {(showTaskColumn) ?
                            <div className={clsx([classes.wrap,classes.tasklist])}>
                                <ProjectsListTasks userData={userData} project={selectedProject} saveNewProject={saveNewProject} loadTaskDetails={loadTaskDetails} />
                            </div>
                            :
                            <></>
                        }
                    </div>
                )}
        </div>
    );
};