import React, {useReducer, useState} from 'react';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link, useRouteMatch
} from "react-router-dom";
import {makeStyles, useTheme} from "@material-ui/core";
import CycleList from "./cycleList";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import {auth, db} from "../../util/firebase";
import * as ROUTES from '../../constants/routes';
import CycleDetails from "../../components/activeCycles/cycleDetails";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import IssueDetails from "../../components/activeCycles/issueDetails";
import {ISSUE_DETAILS} from "../../constants/routes";


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    addNewButton: {
        float: 'right',
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    }

}));

let topTitle = 'Cycles'

let demoPayload = {} /** remove this from final db accessed version */
let demoParentCycle = null /** remove this from final db accessed version */
let demoParentIssue = null /** remove this from final db accessed version */

export default function CycleContent({userData, history, isMobile, activeView}) {
    let { url } = useRouteMatch();
    let route = useRouteMatch();
    const classes = useStyles();
    const theme = useTheme();

    const [render, setRender] = useState(activeView);
    const [cycleState, setCycleState] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {cycleRequestData: [], cycleData: [], loading:true}
    );

    const [showDetails, setShowDetails] = useState(false);

    React.useLayoutEffect(() => {
        console.log('loading test');
        console.log('userData.activeWorkspace',userData.activeWorkspace)
        if (!route.isExact) {
            setCycleState({loading:false})
            return function cleanup() {
            }
        }

        const unsubscribe = db
            .collection('cycle_groups')
            .where('workspace_id', '==', userData.activeWorkspace.id)
            // .orderBy('createdAt', 'desc')
            .onSnapshot((querySnapshot) => {
                let cycles = [];
                querySnapshot.forEach((doc) => {
                    cycles.push({
                        cycleId: doc.id,
                        freetext: doc.data().freetext,
                        createdAt: doc.data().createdAt,
                        cycles:doc.data().cycles
                    });
                });

                setCycleState({cycleData:cycles, loading:false});
            });

        return function cleanup() {
            unsubscribe()
        }
        // eslint-disable-next-line
    }, []);

    const loadCyclesPage = () => {
        history.push(`${url}`);
    }

    const loadCycleDetailsPage = (prevCycleParam) => {
        history.push(`${url}/cycle/${prevCycleParam}`)
    }

    const loadAddNewCyclePage = () => {
        history.push(`${url}/new`);
        // setRender({selected:'New Cycle', appbarTitle:'Active Cycles > New Cycle', })
    }
    const loadCycleDetails = (payload) => {
        console.log('payload::',payload);
        demoPayload = payload
        history.push(`${url}/cycle/${payload.cycleId}`);
        // activeViewStateChange({selected:'Cycle Request Details', appbarTitle:'Active Cycles > Request Details', payload:payload});
        // setRender({selected:'Cycle Request Details', appbarTitle:'Active Cycles > Request Details', payload:payload})
    }
    const loadIssueDetails = (payload, parentCycleId) => {
        console.log('payload::',payload);
        demoPayload = payload
        demoParentCycle = parentCycleId
        const id = payload.issueId || payload.id
        history.push(`${url}/issue/${id}`);
        // activeViewStateChange({selected:'Cycle Request Details', appbarTitle:'Active Cycles > Request Details', payload:payload});
        // setRender({selected:'Cycle Request Details', appbarTitle:'Active Cycles > Request Details', payload:payload})
    }
    const loadIssueSubtask = (payload, parentIssueId) => {
        console.log('payload::',payload);
        demoPayload = payload
        demoParentIssue = parentIssueId
        history.push(`${url}/issue/${payload.issueId}`);
        // activeViewStateChange({selected:'Cycle Request Details', appbarTitle:'Active Cycles > Request Details', payload:payload});
        // setRender({selected:'Cycle Request Details', appbarTitle:'Active Cycles > Request Details', payload:payload})
    }

    const RenderCyclesLists = () => {

        return cycleState.loading ? (
            <div />
        ) : (
            <Router>
                <Route exact path={`${url}`} render={() => <CycleList userData={userData} history={history} cycleDetails={loadCycleDetails} cycleGroups={cycleState.cycleData} />} />
                <Route path={`${url}${ROUTES.CYCLE_DETAILS}`} render={() => <CycleDetails userData={userData} backToSprintCycleList={loadCyclesPage} loadIssueDetails={loadIssueDetails} detailsPayload={demoPayload} />} />
                <Route path={`${url}${ROUTES.ISSUE_DETAILS}`} render={() => <IssueDetails userData={userData} backToCycleDetails={loadCycleDetailsPage} loadSubTaskDetails={loadIssueSubtask} parentCycleId={demoParentCycle} detailsPayload={demoPayload} />} />
            </Router>
        )
    };

    const handleBackButton = () => {
        if (demoParentIssue) {
            history.push(`${url}/issue/${demoParentIssue}`);
            demoParentIssue = null;
        } else if (demoParentCycle) {
            history.push(`${url}/cycle/${demoParentCycle}`);
            demoParentCycle = null;
        } else {
            loadCyclesPage();
        }
        return false
    };

    /** the topBar has to be own component, Toolbar within it and toolbar always customised to view */
    return (
        <div>
            <div
                className={clsx(classes.topBar, {
                    [classes.topBarShift]: isMobile,
                })}
            >
                <Toolbar>
                    {!route.isExact ? <IconButton onClick={handleBackButton} className={clsx(classes.menuButton)}>
                        <ChevronLeftIcon />
                    </IconButton> : <div />}
                    <Typography variant="button" style={{flexGrow:'1', textTransform:'capitalize'}}>
                        {topTitle}
                    </Typography>
                </Toolbar>
            </div>
            <RenderCyclesLists/>
        </div>

    )

}
