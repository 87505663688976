import React, {} from 'react';
import {makeStyles} from "@material-ui/core";
import clsx from "clsx";
import Toolbar from "@material-ui/core/Toolbar";
import {BrowserRouter as Router, Route, useRouteMatch} from "react-router-dom";
import * as ROUTES from '../../constants/routes';
import ChatView from "../../components/activeRequestChat/ChatView";
import ChatDashboard from "../../components/activeRequestChat/ChatDashboard";

const useStyles = makeStyles((theme) => ({
    RightToolbarButton: {
        float: 'right',
        background:theme.custom.MainButton,
        marginLeft:'5px',
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    topBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        color: theme.custom.textColor,
        boxShadow: 'none',
        borderBottom: '1px solid #000',
        "width":"100%","display":"flex","zIndex":"1100","boxSizing":"border-box","flexShrink":"0","flexDirection":"column"

    },
    topBarShift: {
        paddingLeft: '40px',
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    title:{
        padding:'10px 10px 10px 20px',
        fontWeight: '600',
        // backgroundColor: theme.custom.ListHeader,
        color: theme.custom.textColor
    },
    subtitle: {
        color:theme.custom.subtitle
    },
    smallinfo: {
        fontSize:'11px'
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    table: {
        minWidth: 375
    },
    tableHead: {
        background: '#ccc',
        fontWeight: '700'
    },
    dateColumn: {
        color:theme.custom.subtitle,
        width: "70px",
        paddingLeft:'0px'
    },
    listLink:{
        cursor:'pointer',
        width:'100%'
    },
    tableRow:{
        "&:hover": {
            cursor: 'pointer',
            "& $menuIcon": {
                opacity: "1",
            }
        }
    },
    tableIcon: {
        fontSize:theme.custom.tableiconsize,
        color:'#93c47e'
    },
    rowSubtitle: {
        color:theme.custom.subtitle
    },
    rowTitle: {
        display:'flex',
        alignItems:'center'
    },
    rowText: {
        cursor:'default'
    },
    stateChip:{
        background:'transparent',
        width: "35px",
        padding:'0px 0px 0px 0px'
    },
    docsActionBar:{
        padding:'20px 20px 15px 20px'
    },
    fullDocsButton:{
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
    },
    dataPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'150px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    iterationPanel: {
        color:'rgba(0,0,0,0.8)',
        minHeight:'50px',
        background:theme.custom.ListBGhoverBlue,
        marginBottom:'20px',
        padding:'10px 10px 10px 10px',
        border:'1px solid rgb(205,242,245)',
        borderColor:'rgba(255, 255, 255, 0.33)',
        // boxShadow: theme.custom.boxShadowGlowSmall
    },
    menuIcon: {
        marginLeft:'10px',
        opacity:'0',
        border:'1px solid rgb(223, 225, 228)',
        borderRadius:'8px',
        borderColor:theme.custom.OutlineButtonBorder

    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    newDataWrap: {
        border: '1px solid #000',
        borderColor: theme.custom.OutlineButtonBorder,
        borderRadius:'8px',
        padding:'10px',
        marginTop:'30px'

    },
    addNewData: {
        color: theme.custom.SmoothColor,
        "&:hover":{
            color:theme.custom.SmoothColorHover
        }
    },
    submitButton: {
        background: 'linear-gradient(180deg,#34444b,#262f36)',
        color:'#fff',
        marginLeft:'8px'
    },
    buttonsHolder:{
        display:'flex',
        justifyContent:'right'
    },

    mainBoxWrap:{
        // padding:"40px"
    },
    mainBox: {
        // boxShadow:"rgb(0 0 0 / 50%) 0px 16px 70px",
        borderRadius:'10px',
        paddingTop:'20px',
        paddingLeft:'18px',
        paddingRight:'36px',
        paddingBottom:'20px',
        // margin:'0px auto',
        // maxWidth:'900px'
    },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    },
    rolesWrap: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    roleBox:{
        width: '300px',
        height: '200px',
        margin: '20px 20px',
        borderRadius:'8px',
        border: '1px solid #555555'
    },
    roleBoxEmpty:{
        background: '#f2f2f2',
        border:'1px solid #f2f2f2',
        // padding:'10px',
        textAlign:'center',
        lineHeight:'190px',
        cursor:'pointer',

        '&:hover':{
            border:'1px solid #555555',
        }
    },
    roleBoxContent:{
        display:'flex',
        flexDirection:'column',
        padding:'10px',
        marginTop:'-20px',
        overflow:'hidden',
        maxHeight:'200px',
    },
    roleBoxImage:{
        width:'30px',
        height:'30px',
        background:'#18F2B2',
        borderRadius:'5px'
    },
    roleBoxRole:{
        fontWeight:'700',
        fontSize:'14px',
        margin:'5px 0',
        maxHeight:'25px'
    },
    roleBoxDescription:{
        color: theme.custom.subtitle,
        maxHeight:'80px',
        overflow:'hidden'
    },
    roleBoxMore:{
        marginTop:'3px',
        fontSize:'12px',
        color: theme.custom.subtitle,
        cursor:'pointer',
        textDecoration:'underline'
    },

    [theme.breakpoints.down("sm")]: {
        stateChip: {display:'none'},
        dateColumn: {display:'none'}
    },

}));

export default function RequestChatContent({userData, history, isMobile}) {
    const classes = useStyles();
    let { url } = useRouteMatch();
    let route = useRouteMatch();

    const RenderRoutes = () => {
        return (
            <Router>
                <Route exact path={`${url}`} render={() => <ChatDashboard userData={userData} history={history} />} />
                <Route path={`${url}${ROUTES.REQUEST_CHAT_DETAILS}`} render={() => <ChatView userData={userData} />} />
            </Router>
        )
    };
    const handleBackButton = () => {
        history.goBack()
        // loadIdeasPage();
        return false
    };

    return (
        <div>
            <div
                className={clsx(classes.topBar, {
                    [classes.topBarShift]: isMobile,
                })}
            >
                <Toolbar>
                    {/*{!route.isExact ? <IconButton onClick={handleBackButton} className={clsx(classes.menuButton)}>*/}
                    {/*    <ChevronLeftIcon />*/}
                    {/*</IconButton> : <div />}*/}
                    {route.isExact ?
                        <div style={{flexGrow:'1'}}>
                        </div>
                        : <div/>}
                </Toolbar>
            </div>
            <div style={{padding: '0px'}}>
                <RenderRoutes />
            </div>
        </div>
    )
}
