import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {db, auth} from '../../util/firebase'
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import AddIcon from "@material-ui/icons/Add";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import {Button, TextField} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles((theme) => ({
    sidebarListPadding: {
        margin:'0px 10px -8px 10px',
    },
    sidebarListItem: {
        borderRadius:'8px',
        padding:'10px',
        fontSize:'12px'
    },
    sidebarListItemText: {
        margin:'0px'
    },
    drawerBottom: {
        // position:'absolute',
        // bottom:'70px',
        // left:'0',
        width:'100%'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '1px solid rgba(0,0,0)',
        borderRadius:'8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        position:'relative'
    },
    modalButtons: {
        "display":"flex","flex":"initial","flexDirection":"row","WebkitBoxAlign":"center","alignItems":"center","WebkitBoxPack":"justify","justifyContent":"space-between"
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover},
        marginTop:'20px'

    }

}));

export default function InvitePeople({workspace}) {
    const classes = useStyles();
    const [inviteOpen, setInviteOpen] = useState(false)
    const [inviteEmail, setInviteEmail] = useState('')
    const [inviteSnackbarOpen, setSnackbarOpen] = useState(false)

    /** manage invite modal open*/
    const handleOpen = () => {
        setInviteOpen(true);
    };

    const handleClose = () => {
        setInviteOpen(false);
    };

    /** manage invite people send by adding them to table in backend. backend manages the rest */
    const sendInvite = () => {
        setInviteOpen(false);

        /** this should be event driven */

        db.collection('events').add({
            type:'action',
            name:'invite_with_email',
            inviter:auth().currentUser.uid,
            inviteEmail:inviteEmail,
            workspace:workspace.id || null
        }).then(()=>{

            setSnackbarOpen(true)
        }).catch(err=>{
            console.log(err)
        })
    }

    const handleInviteSnack = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setSnackbarOpen(false);
    };

    return (
        <List className={classes.drawerBottom}>
            <div className={classes.sidebarListPadding}>
                <ListItem button onClick={handleOpen}>
                    <ListItemIcon><AddIcon /></ListItemIcon>
                    <ListItemText className={classes.sidebarListItemText} secondary={'Invite People'} />
                </ListItem>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={inviteOpen}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={inviteOpen}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Invite people to collaborate</h2>
                        <div id="transition-modal-description">
                            <TextField
                                style={{width:'100%'}}
                                label="Email"
                                id="outlined-size-small"
                                variant="outlined"
                                size="small"
                                onChange = {e => setInviteEmail(e.target.value)}
                            />
                            <div className={classes.modalButtons}>
                                <div />
                                <Button
                                    color="primary"
                                    variant="contained"
                                    type="submit"
                                    className={classes.submitButton}
                                    onClick={sendInvite}
                                >Send</Button>
                            </div>
                        </div>
                    </div>
                </Fade>
            </Modal>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                open={inviteSnackbarOpen}
                onClose={handleInviteSnack}
                autoHideDuration={6000}
                message={'Invitation sent'}
                action={
                    <React.Fragment>
                        <IconButton size="small" aria-label="close" color="inherit" onClick={handleInviteSnack}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </React.Fragment>
                }
                key={'123'}
            />
        </List>
    );
}