import React, {useState, useEffect, useRef} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {db, firebaseCore} from "../../util/firebase";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import EditIcon from "@material-ui/icons/Edit";
import TextField from "@material-ui/core/TextField";
import AddCircleOutlineIcon from "@material-ui/icons/AddCircleOutline";
import DeleteIcon from "@material-ui/icons/Delete"
import {Divider} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({


    featureField: {
        marginLeft:'0px',
        marginBottom:'5px',
        backgroundColor:'rgba(0,0,0,0.04)',
        width:'calc(100% - 0px)'
    },
    input: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.875em',
        backgroundColor:''
    },
    actionButton: {
        background:theme.custom.MainButton
    },

}))


export default function BDDfeatureTag({payload}) {
    const classes = useStyles()
    const [isEditing, setIsEditing] = useState(false)
    const [featureText, setFeatureText] = useState('')

    useEffect(()=>{
        /** demo */

        // setFeatureText('test string')

    }, [payload])

    const ToggleEditClick = () => {
        setIsEditing(!isEditing)
    }

    const SaveEditFeature = () => {

    }

    return (isEditing) ?
        <div style={{width:'100%'}}>
            <TextField className={classes.featureField} autoFocus inputProps={{className:classes.input}} variant="outlined" placeholder="" size="small" value={featureText} onChange={()=>{}} />
            <Divider style={{margin:'5px 0 10px 0'}}/>
            <Button variant="contained" size="small" onClick={SaveEditFeature}>Save</Button>
            <Button style={{minWidth:'30px', marginLeft:'10px', cursor:'pointer'}} size="small" onClick={ToggleEditClick}>X</Button>

        </div>
        :
        <>
            <span onClick={ToggleEditClick}>{featureText || "(+ click to attach a feature)"}</span>
        </>

}