import React, { } from 'react';
import {makeStyles} from "@material-ui/core/styles";
import '../../../css/general.css'
import '../../../css/joblist.css'
import useMediaQuery from "@material-ui/core/useMediaQuery";

import {Card} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    textpop:{
        color:theme.custom.textColorInverted,
        backgroundColor:  theme.custom.textPop,
        padding: '0px 5px',
        margin:'0px 5px',
    },
    [theme.breakpoints.down("xs")]: {
        container:{
            maxWidth:'600px'
        }
    },
    heroContainer:{
        display:'flex'
    }

}));

const joblist_demo = [
    {
        id:'123_1',
        createdAt:'11.08.2022',
        title:'Paid Survey',
        location:'Remote',
        startDate:'24.08.2022',
        roles:["Senior Software Developer"],
        skills:["React","ReactNative"],
        description:'The goal of this survey is to understand usage and preferences for React framework development.\n' +
            '\n' +
            'Anticipated time to complete: 15min\n' +
            '\n' +
            'Compensation: $50.00\n' +
            '\n' +
            'You can participate by applying to the opportunity'
    },
    {
        id:'123_2',
        createdAt:'11.08.2022',
        title:'GPT-3 integration microservice',
        location:'Remote',
        startDate:'24.08.2022',
        roles:["Data engineering","AI engineering"],
        skills:["GPT-3","Serverless"],
        description:'The client is in need of integration to GPT-3 API. Incoming call should be one simple payload of a sample pattern and few GPT-3 configuration logics. With this input, the integration should return the GPT-3 response and config the model in use. Possible expansion would be to include certain states and such database inclusions.'
    },
    {
        id:'123_3',
        createdAt:'11.08.2022',
        title:'iOS Developer',
        location:'Remote',
        startDate:'24.08.2022',
        roles:["Mobile Developer"],
        skills:["Swift","Objective-C"],
        description:'Our customer, who changed the way the world listens to music, is looking for an iOS Developer to join their top notch team to work with Design Systems. Does this sound interesting to you? Keep reading to find out more!'

    }
]

const JobList = ({
                  ...props
              }) => {

    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;
    const text_color_light = (prefersDarkMode) ? '' : 'text_color_light';
    const text_color_dark = (!prefersDarkMode) ? '' : 'text_color_dark';

    const RenderOpporunityItems = () => {

        return joblist_demo.map(item => {
            return <div className={'opportunityItem'}>
                <div className={'columns'}>
                    <div className={'job-column'}>
                        <div>{item.location}</div>
                        <div><a href={'hub/opportunity/'+item.id} className={text_color_dark+' opportunity_title'}><span>{item.title}</span></a></div>
                        <div>{item.roles.map((role, index) => {
                            if (index === 0) return <span className={'roletag'}>{role}</span>
                            return <span className={'roletag'}> - {role}</span>
                        })}</div>
                        <div>{item.skills.map(skill => {
                            return <span className={'skilltag'}>{skill}</span>
                        })}</div>
                        <div className={'small_date'}>published at {item.createdAt}</div>
                    </div>
                </div>
            </div>
        })
    }

    return (
        <section>
            <div className={'job-container'}>
                <div className={'notification'}>

                </div>
                <div className={'columns'}>
                    <div className={'column'}>
                        <Card>
                            {/*<div className={'top-filter-box'}>*/}
                            {/*    filter box*/}
                            {/*</div>*/}
                            <div className={'opportunity-list'}>
                                <RenderOpporunityItems />
                            </div>
                        </Card>
                    </div>
                    <div className={'column is-narrow'}>
                        <div className={'sidebar'}>
                            <Card className={'card'}>
                                <div className={'sidebar-title'}>What?</div>
                                There roles are remote roles where you have a change to test how the company works. If all successfull from both sides, you most likely get a offer to join the company payroll.
                            </Card>
                            <Card className={'card'}>
                                <div className={'sidebar-title'}>How?</div>
                                To apply, send mail to to team@sprinthack.com
                            </Card>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default JobList;