import { auth } from './firebase';

export const authMiddleWare = (history) => {
    const user = auth().currentUser;
    // console.log('user at auth',user)
    if (!user) {
        if (!history){window.location.replace("/login"); return}
        history.push('/login');
    } else {
        return auth().currentUser.getIdToken()
    }
};

