import React, {Component, useState, useRef} from "react"
import { observer } from "mobx-react"
import {Button, Checkbox} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import dayjs from "dayjs";
import {auth} from "../../util/firebase";

const useStyles = makeStyles((theme) => ({
    savebutton: {
        float:"right",
        background:'#64ccd9'
    },
    listLink:{
        cursor:'pointer',

        "&:hover":{
            background:'#f8f8f8'
        }
    },
    selectField: {
        marginTop:'10px',
        marginBottom:'10px',
    },
    textField: {
        width:'100%',
        marginTop:'20px',
        marginBottom:'20px',
    },
    menuInput: {
        paddingTop:'6px',
        paddingBottom:'6px',
        fontSize:'0.8em'
    },
    volChip: {
        marginRight:'5px'
    },
    addChip: {
        borderColor: 'transparent',
        "&:hover": {
            borderColor: 'rgba(255, 255, 255, 0.23)'
        }
    },
    chatDivider: {
        borderColor: 'rgba(255, 255, 255, 0.23)'
    },
    chatInput: {
        width:'100%',
        padding:'10px',
        background: 'rgba(255, 255, 255, 0.83)',
        border:'none',
        marginBottom:'10px'
    }

}));

function AdminTaskDetails ({authToken, stateClone, toggleTaskOpen, saveChanges, saveReply}) {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null);
    const [focusedNeed, setFocusedNeed] = useState(null)
    const volatility_open = Boolean(anchorEl);
    const [addingVolatility, setAddingVolatility] = useState('');
    const volInputRef = useRef();
    const [scenarioRenderCount, setScenarioRenderCount] = useState(0);
    const [chatInputValue, setChatInputValue] = useState('')
    const [chatRenderCount, setChatRenderCount] = useState(0);
    const [showImageField, setShowImageField] = useState(false);
    const [chatImageValue, setChatImageValue] = useState('')
    const [systemUpdateValue, setSystemUpdateValue] = useState('')
    const [sendNotification, setSendNotification] = useState(false)



    const handleVolatilityOpen = (event, stakeholder_need, idx) => {
        setAnchorEl(event.currentTarget);
        setFocusedNeed(stakeholder_need)

        /** for some reason the focus has to be on setTimeout */
        setTimeout(() => {
            volInputRef.current.focus()
        }, 100);
    };

    const handleVolatilityClose = () => {
        setAnchorEl(null);
    };

    const saveVolatility = (ev) => {
        if (ev.key === 'Enter') {
            // Do code here
            ev.preventDefault();

            /** add to model object */
            focusedNeed.addVolatility(addingVolatility)

            handleVolatilityClose()
            setAddingVolatility('')
        }
    }

    const deleteVolatility = (index, stakeholder_need) => {
        stakeholder_need.removeVolatility(index)
    }

    const handleChange = (target, newData) => {
        if (!target || !newData) return false

        // stateClone.updateData(target, newData)
    }

    const handleSideDetailsChange = (target, newData) => {
        if (!target || !newData) return false

        // stateClone.updateSideDetail(target, newData)
    }

    const handleScenarioUpdate = () => {
        setScenarioRenderCount(scenarioRenderCount+1)
    }
    const handleChatUpdate = () => {
        setChatRenderCount(chatRenderCount+1)
    }

    const RenderChatMessages = () => {
        return stateClone.messageList.data.map((msg, index) => {
            return <div key={index}>
                <div style={{fontWeight:'700'}}>{msg.author} - {dayjs(msg.createdAt).format('MMM DD YYYY HH:mm:ss')}</div>
                <div>{msg.text}</div>
                <img style={{maxWidth:'600px'}} src={msg.imageUrl} />
                <hr className={classes.chatDivider}/>
            </div>
        })
    }

    const replyToChat = () => {
        stateClone.messageList.sendNewMessage({authToken:authToken, chatContextId:stateClone.id, workspace_id:stateClone.workspace_id, message: chatInputValue, systemUpdate:systemUpdateValue, sendNotification:sendNotification, authRef:auth().currentUser.uid})
            .then((newmsg)=>{
                console.log('reply from send new', newmsg);
                handleChatUpdate()
            })
        // saveReply()
    }

    const handleSendNoticationState = () => {
        setSendNotification(!sendNotification)
    }


    return (
        <div>
            <div>
                <Button variant='outlined' onClick={()=>toggleTaskOpen()}>Close</Button>
                <Button variant='outlined' className={classes.savebutton} onClick={() => saveChanges()}>Save</Button>
            </div>
            <hr/>
            <div>{stateClone.id}</div>
            <div>{stateClone.metaTags}</div>
            <div>workspace id {stateClone.workspace_id}</div>
            <br />
            <div>
                <RenderChatMessages />
            </div>
            <div>
                {/* text reply */}
                <input className={classes.chatInput} placeholder={'Write reply'} value={chatInputValue} onChange={(e) => setChatInputValue(e.target.value)} />
            </div>
            <Button variant='outlined' onClick={replyToChat}>Reply</Button>
            <div>
                <div style={{margin:'10px 0'}}>
                    {(showImageField) ?
                        <input className={classes.chatInput} placeholder={'Image url'} value={chatImageValue} onChange={(e) => setChatImageValue(e.target.value)} />
                        :
                        <Button variant='outlined' hidden={showImageField} onClick={()=>setShowImageField(!showImageField)}>Add image</Button>
                    }
                </div>
                <div style={{margin:'10px 0'}}>
                    <input className={classes.chatInput} placeholder={'System update message'} value={systemUpdateValue} onChange={(e) => setSystemUpdateValue(e.target.value)} />
                </div>
                <div style={{margin:'10px 0'}}>
                    <Checkbox checked={sendNotification} onChange={handleSendNoticationState} /><span>Send notification</span>
                </div>

                {/* TODO: include image with reply */}

                {/* TODO: include links with reply */}
            </div>

        </div>
    )
}

export default observer(AdminTaskDetails)