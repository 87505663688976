import React, {useEffect, useRef, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {TextareaAutosize} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import useMediaQuery from "@material-ui/core/useMediaQuery";

const useStyles = makeStyles((theme) => ({
    freeTextContent: {
        color:theme.custom.genericLongformText
    },
    valueRow: {
        minWidth:'auto',
    },
    textareaStyleTransparent:{
        // padding: '10px',
        // marginBottom:'20px',
        fontSize:'0.875rem',
        fontWeight:400,
        lineHeight:"1.43",
        fontFamily:'Inter,Roboto,Helvetica,Arial,sans-serif',
        // minWidth: '260px',
        width: 'calc(100%)',
        border:'none',
        backgroundColor:"transparent",
        color: theme.custom.genericLongformText,
        resize:'none',
        overflow:'auto',
        outline:'none',
        "WebkitBoxShadow":"none",
        "MozBoxShadow":"none",
        "boxShadow":"none",
        minWidth:'300px',
        padding:'0px',
    },
    hide: {
        display:'none !important'
    },
    requestDetailText:{
        "fontStyle":"normal",
        "fontWeight":"normal",
        "textAlign":"left",
        "color":theme.custom.genericLongformText,
        "lineHeight":"23px",
        "fontSize":"15px",
        "display":"block",
        "marginBottom":"50px",
        "cursor":"default",
        whiteSpace:'pre-wrap'
    },
    freeTextPlaceholder: {
        color:"rgb(98, 102, 109)"
    },
    requestDetailGuide:{
        color:"rgb(98, 102, 109)",
        marginBottom:'15px',
        fontSize:'14px',
        fontStyle:'italic'
    },
    whiteColor:{
        color:'#fff'
    },
    submitButton: {
        background:theme.custom.MainButton,
        "&:hover":{background:theme.custom.MainButtonHover}
    }

}));


export default function FreetextEditor({payload, save}) {

    const classes = useStyles();
    const [displayData, setDisplayData] = useState({})
    const [displayEdit, setDisplayEdit] = useState(false);
    const TextInputRef = useRef(null);

    /** should really create a custom hook for this... */
    let prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)') || false;

    useEffect(()=>{
        setDisplayData(payload)
    }, [payload])

    const toggleHide = () => {
        setDisplayEdit(!displayEdit)

        setTimeout(() => {
            TextInputRef.current.focus()
        }, 100);

    }

    const handleSave = () => {
        save(displayData)
        // toggleHide()
    }

    return (
        <>
            {/*<div onClick={toggleHide} className={clsx(classes.valueRow,{*/}
            {/*    [classes.hide]: displayEdit*/}
            {/*})}>*/}
            {/*    {(displayData && displayData.value && displayData.value.trim() !== '')*/}
            {/*        ?*/}
            {/*        <div className={classes.freeTextContent}>{displayData.value}</div>*/}
            {/*        :*/}
            {/*        <div className={classes.freeTextPlaceholder}>Add...</div>*/}
            {/*    }*/}
            {/*</div>*/}
            <div className={clsx(classes.valueRow, {})}>
                <TextareaAutosize
                    minRows={1}
                    ref={TextInputRef}
                    className={clsx(classes.textareaStyleTransparent)}
                    placeholder={payload.placeholder}
                    value={displayData.value}
                    onChange={(e)=>{setDisplayData({...displayData, ...{value: e.target.value}})}}
                    onBlur={handleSave}
                />
                {/*<Button*/}
                {/*    color="primary"*/}
                {/*    variant="contained"*/}
                {/*    type="submit"*/}
                {/*    size="small"*/}
                {/*    className={clsx(classes.submitButton)}*/}
                {/*    onClick={handleSave}*/}
                {/*>Save</Button>*/}
                {/*<Button style={{minWidth:'30px', marginLeft:'10px', cursor:'pointer'}} size="small" onClick={toggleHide}>X</Button>*/}
            </div>
        </>

    )
}
