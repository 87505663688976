import React, {useEffect, useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EmptyPageInfoPrompt from "../components/emptyPageInfoPrompt";
import {db} from "../util/firebase";
import dayjs from "dayjs";
import SlowLoad from "../components/slowLoad";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles({
    content: {
        flexGrow: 1
    },
    toolbar: {
        height: '64px'
    },
    subtitle: {
        color:'rgba(0,0,0,0.4)'
    },
    // submitButton: {
    //     background: 'linear-gradient(180deg,#34444b,#262f36)',
    //     color:'#fff',
    //     marginLeft:'8px'
    // },
    textareaStyle: {
        width:'100%',
        margin: '8',
        padding:'14px'
    }

});

export default function PlayBook({userData}) {
    const classes = useStyles();

    const [loading, setLoading] = useState(true)
    const [notes, setNotes] = useState([])

    const pagecontext = '';
    const promptheader = 'Diary';
    const promptbody = () => {
        return (
            <div>
                <p>
                    We all hate documentation, right? So lets redo it.
                </p>
                <p>
                    Diary can be searched for so that it is easy to always find what the teams have done and how things work.
                </p>
                <p>
                    It is your playbook for the future.
                </p>

            </div>
        )
    };

    useEffect( () => {
        setLoading (true);

        /** in which teams do we have the user id in */
        db.collection('teams')
            .where('members.'+userData.id, '==', true)
            .orderBy('activeAt','desc')
            .get()
            .then(docs => {
                let teams_id = []
                docs.forEach(team => {
                    teams_id.push(team.id)
                })
                db.collection('team_activity')
                    .where('team','in', teams_id)
                    .orderBy('createdAt','desc')
                    .get()
                    .then(notes => {
                        let notesData = []
                        notes.forEach(note => {
                            notesData.push(note.data())
                        })
                        setNotes(notesData)
                        setLoading(false);
                    })
                    .catch(error => {console.log(error); setLoading(false);})
            })
            .catch(err=>{console.log(err); setLoading(false);})
    }, [userData.id]);

    const RenderNotes = () => {
        return notes.map((note,index) => {
            return <div key={index} style={{padding:"10px 0"}}>
                <div style={{fontWeight:'800'}}>{dayjs(note.createdAt).format('MMMM DD, YYYY')}</div>
                <div><span>{note.author}</span> for <span>{note.role}</span></div>
                <div>{(note.reportData) ? note.reportData.map((rep,ind) => <p key={ind}>{rep.a}</p>) : <></>}</div>
                <Divider/>
            </div>
        })
    }

    return <div style={{paddingTop:'10px', paddingLeft:'18px', paddingRight:'36px'}}>
        <div className={classes.toolbar} />
        <div>
            {(loading) ? <SlowLoad /> :
                    <div>
                        {(notes && notes.length > 0) ?
                            <div>
                                <div style={{fontSize:'24px', fontWeight:'500'}}>DIARY</div>
                                <RenderNotes/>
                            </div>
                            :
                            <EmptyPageInfoPrompt pagecontext={pagecontext} header={promptheader} body={promptbody()} />
                        }
                    </div>
            }
        </div>
    </div>
}

