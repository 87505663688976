import React, {useState, useEffect} from "react";
import {db} from "../../util/firebase";
import {useParams} from "react-router";
// import {makeStyles} from "@material-ui/core/styles";
import "../../css/teamdetails.css"
import TeamRolesRow from "./teamRolesRow";
import SlowLoad from "../slowLoad";

// const useStyles = makeStyles((theme) => ({
//
// }))

export default function TeamRoleListManager({userData, payload, readOnly}) {
    // const classes = useStyles()
    let { teamId } = useParams();

    const [loading, setLoading] = useState(true)
    const [roles, setRoles] = useState([])


    useEffect(()=>{

        /** how to manage user info and get access to it based on it role in team */

        // console.log('payload',payload)
        // console.log('teamId',teamId)

        db.collection('team_roles')
            .where('team','==', teamId)
            .onSnapshot(snapshot => {
                let roles = []
                snapshot.forEach((doc)=>{
                    roles.push({
                        id: doc.id,
                        ...doc.data()
                    })
                })
                setRoles(roles)
                setLoading(false)
            })

    }, [payload, readOnly, teamId])

    const RenderRows = () => {
        return roles.map((data, index) => {
            return <TeamRolesRow key={index} payload={data} team={teamId} RowUpdate={rowUpdate} />
        })
    }

    const rowUpdate = (action, savePayload) => {
        console.log('click on:: ',action, savePayload)
        let updateObject = {}

        if (action === 'role') {
            console.log('role update')
            updateObject.role = savePayload.newState.value
        }
        if (action === 'assignee') {
            console.log('assignee update', savePayload.newState)
            updateObject.assignee = savePayload.newState.display
        }
        if (action === 'allocation') {
            console.log('allocation update')
            updateObject.allocation = savePayload.newState.display
        }
        if (action === 'requirements') {
            console.log('SPECS update')
            updateObject.requirements = savePayload.newState.value
        }

        db.collection('team_roles')
            .doc(savePayload.roleData.id)
            .update(updateObject)
            .then()

    }

    const AddNewRole = () => {
        const updateObject = {role:"", allocation:"", specification:"", assignee:"", team:payload.id, active:true}
        db.collection('team_roles')
            .add(updateObject)
            .then((docRef) => {
                console.log("Document written with ID: ", docRef.id);
            })
            .catch((error) => {
                console.error("Error adding document: ", error);
            });

    }

    return loading ? <div style={{padding:'10px 0'}}><SlowLoad show /></div> :
        <div>
            <div className={"role-wrap"}>
                <RenderRows/>
                <div className={"button-simple"} style={{margin:"10px 0", display:"block"}} onClick={AddNewRole}>Add role +</div>
            </div>
        </div>
}
